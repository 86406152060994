import React, { Component } from "react";
import { Table, Button, Popconfirm, Modal, message, Input, Select } from "antd";
import Request from "../../../utils/client";
import AddAccessories from "./components/AddAccessories";
import root from "../../../config/root";
import { GET_PART_LIST, DELETE_PART } from "../../../graphql/admin/clock";

import axiosRequest from "../../../utils/request";
import { clockRequestPath } from "../../../request/requestPath";

import "./size.scss";

const { Option } = Select;
export default class log extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "名称",
        dataIndex: "part_name",
      },
      // {
      //   title: "型号",
      //   dataIndex: "identification",
      // },
      {
        title: "规格",
        dataIndex: "specification",
      },
      {
        title: "重量（t）",
        dataIndex: "weight",
      },

      {
        title: "价格",
        dataIndex: "price",
      },

      {
        title: "是否设为默认值",
        dataIndex: "isDefaultName",
      },
    ];
    let userInfo = localStorage.getItem("userInfo");

    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify || root[role][pathname].delete) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {root[role][pathname].modify ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  编辑
                </Button>
              ) : (
                ""
              )}
              {root[role][pathname].delete ? (
                <Popconfirm
                  title="确定删除吗？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    this.deleteItem(item);
                  }}
                >
                  <Button type="danger" style={{ marginLeft: "20px" }}>
                    删除
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }

    this.state = {
      data: [],
      sizeData: [], // 存储获取的公司数据，用于用户搜索时
      columns,
      role,
      pathname,
      searchType: "type",
    };
  }

  state = {
    modalVisible: false,
  };
  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    axiosRequest
      .post(clockRequestPath.GetPartList, {})
      .then((res) => {
        // console.log("配件规格", res);
        if (res.code === 200) {
          res.data.forEach((item, index) => {
            item.sort = index + 1;
            item.key = item.id;
            item.isDefaultName = item.isDefault ? "是" : "否";
          });
          this.setState({ data: res.data, sizeData: res.data });
        }
      })
      .catch((error) => {
        console.log("获取配件列表错误");
      });
  };
  deleteItem = (item) => {
    Request.client
      .mutate({
        mutation: DELETE_PART, // 封装好的GraphQL,
        variables: {
          id: item.id,
        },
      })
      .then((result) => {
        message.success("删除配件成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加配件" });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加配件") {
      this.addAcc.addExpress();
    } else {
      this.addAcc.modifyProjectSubmit();
    }
  };

  // 关闭弹窗，由addAcc组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  // 修改项目
  modify = (project) => {
    this.setState({ modalVisible: true, title: "编辑配件" }, () => {
      this.addAcc.modify(project);
    });
  };
  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { sizeData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    let result = [];
    if (searchType === "type") {
      result = sizeData.filter((obj) => {
        return obj.type.indexOf(keyword) != -1;
      });
    } else if (searchType === "size") {
      result = sizeData.filter((obj) => {
        return obj.specification.indexOf(keyword) != -1;
      });
    } else if (searchType === "length") {
      result = sizeData.filter((obj) => {
        return obj.length + "" === keyword;
      });
    } else {
      result = sizeData.filter((obj) => {
        return obj.weight + "" === keyword;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "type",
      },
      () => {
        this.aquireData();
      }
    );
  };
  render() {
    let { data, columns, role, pathname, title } = this.state;
    return (
      <div className="size_box">
        <div className="btn_box">
          <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div>
          <div>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="type">类型</Option>
              <Option value="size">规格</Option>
              <Option value="length">长度</Option>
              <Option value="weight">重量</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>

        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={() => this.setModalVisible(false)}
        >
          <AddAccessories
            ref={(addAcc) => {
              this.addAcc = addAcc;
            }}
            closeModal={this.closeModal}
            aquireData={this.aquireData}
          ></AddAccessories>
        </Modal>
      </div>
    );
  }
}
