import React, { Component } from "react";
import { Table } from "antd";
// import Request from "../../../utils/client";
// import { MAINTENANCE_ORDER_DETAIL } from "../../../graphql/admin/maintenance";

import axiosRequest from "../../../utils/request";
import { maintenanceRequestPath } from "../../../request/requestPath";
import "./order.scss";
export default class OrderDetail extends Component {
  state = {
    comColumns: [
      {
        title: "规格",
        dataIndex: "specification",
        width: 150,
      },
      {
        title: "重量",
        dataIndex: "weight",
        width: 150,
      },
      // {
      //   title: "图片",
      //   dataIndex: "backgroundPicture",
      //   width: 150,
      //   align: "center", // 设置文本居中
      //   render: (text) => <img src={text} style={{ width: "130px" }} />, //这里放后台返回的图片的路径或者整个<img/>
      // },
      // {
      //   title: "备注",
      //   dataIndex: "remark",
      //   width: 150,
      // },
    ],
    steelData: [],
    partData: [],
    equipmentData: [],
  };
  steelCloumns = [
    {
      title: "序号",
      dataIndex: "sort",
      fixed: "left",
      width: 100,
    },
    {
      title: "识别码",
      dataIndex: "identifier",
      width: 150,
    },
    ...this.state.comColumns,
  ];
  equipmentColumns = [
    {
      title: "序号",
      dataIndex: "sort",
      fixed: "left",
      width: 100,
    },
    {
      title: "识别码",
      dataIndex: "identifier",
      width: 150,
    },
    {
      title: "名称",
      dataIndex: "partName",
      width: 150,
    },
    ...this.state.comColumns,
  ];
  partColums = [
    {
      title: "序号",
      dataIndex: "sort",
      fixed: "left",
      width: 100,
    },
    {
      title: "名称",
      dataIndex: "partName",
      width: 150,
    },
    ...this.state.comColumns,
  ];

  componentDidMount() {
    if (this.props.match.params.orderNo) {
      this.aquireData();
    } else {
      this.props.history.push({
        pathname: "/admin/maintenanceOrder",
      });
    }
  }
  // 获取需求单数据
  aquireData = () => {
    this.setState({ loading: true });
    axiosRequest
      .post(maintenanceRequestPath.GetMorderList, {
        morderId: this.props.match.params.orderNo * 1,
      })
      .then((res) => {
        console.log("需求单详情", res);
        if (res.code === 200) {
          let { EquipmentList, PartList, SteelList } = res.data;
          EquipmentList
            ? EquipmentList.forEach((item, index) => {
                item.sort = index + 1;
                item.key = index;
              })
            : (EquipmentList = []);
          PartList
            ? PartList.forEach((item, index) => {
                item.sort = index + 1;
                item.key = index;
              })
            : (PartList = []);
          SteelList
            ? SteelList.forEach((item, index) => {
                item.sort = index + 1;
                item.key = index;
              })
            : (SteelList = []);
          this.setState({
            steelData: SteelList,
            partData: PartList,
            equipmentData: EquipmentList,
          });
        }
      })
      .catch((error) => {
        console.log("获取维修单详情错误", error);
      });
    // Request.client
    //   .query({
    //     query: MAINTENANCE_ORDER_DETAIL,
    //     variables: {
    //       input: {
    //         id: this.props.match.params.orderNo * 1,
    //       },
    //     },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     console.log("获取的维修单", result);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  onChange = () => {};
  render() {
    let { steelData, partData, equipmentData } = this.state;
    return (
      <div
        style={{ background: "white", padding: 20 }}
        className="order_detail_box"
      >
        <div className="btn_box">维修单详情</div>
        <div style={{ marginTop: 20 }}>
          <div style={{ fontWeight: "bold", fontSize: 16 }}>型钢</div>
          <Table
            onChange={this.onChange}
            columns={this.steelCloumns}
            dataSource={steelData}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
          <div style={{ fontWeight: "bold", marginTop: 20, fontSize: 16 }}>
            设备
          </div>
          <Table
            onChange={this.onChange}
            columns={this.equipmentColumns}
            dataSource={equipmentData}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
          <div style={{ fontWeight: "bold", marginTop: 20, fontSize: 16 }}>
            配件
          </div>
          <Table
            onChange={this.onChange}
            columns={this.partColums}
            dataSource={partData}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}
