/**
 * 公司管理员主页相关接口
 */
import Request from "../../../utils/client";
// 获取资产概况
export const GET_STEEL_SUMMARY_FOR_DASHBOARD = Request.gql`
    query($input: GetSteelSummaryForDashboardInput!) {
        getSteelSummaryForDashboard(input:$input){
            Using
            sumSteel
            stored
            maintaining
        }
    }
`;

// 获取资产状态
export const GET_SUMMARY = Request.gql`
    query  {
        getSummary{
            leaseWeightTotal
            maintenanceWeightTotal
            scrapWeightTotal
            newReceipt
        }
    }
`;
// 获取仓库概况
export const GET_STEEL_SUMMARY = Request.gql`
    query($input: GetSteelSummaryForDashboardInput!) {
        getSteelSummaryForDashboard(input:$input){
            UsingPercent
            maintainingPercent
            storedPercent
            lostPercent
            crappedPercent
        }
    }
`;
// 型钢使用率
export const GET_RESEPOSITORY_STEEL_AVERAGEUSEAGE = Request.gql`
    query($input: GetRepositorySteelAverageUsageInput!) {
        getRepositorySteelAverageUsage(input:$input) 
    }
`;

// 获取项目型钢状态
export const GET_STEEL_STAT_OF_PROJECT = Request.gql`
    query($input: GetSteelStateOfProjectInput!) {
        getSteelStateOfProject(input:$input){
            totalWeight
            idleWeightPercent
            beUse
            usingPercent
            toProjectPercent
            toRepository
            readyToRepository
            abnormalPercent
            using
            beUse
            toRepository
            readyToRepository
        }
    }
`;
// 获取型钢最新动向
export const GET_NEW_STATE = Request.gql`
    query($input: GetSteelnewSatesListInput!) {
        getNewState(input:$input){
            updateTime
            operationUserId{name}
            orderNo
            projectName
            repositoryName
            code
            specification
            state
            useState
            locationCode
            OutTime
            entTime
            outTraceTime
            entraceTime
        }
    }
`;
// 型钢查询
export const GET_STEEL_FOR_BACK = Request.gql`
   query($input: GetSteelForBackInput!) {
        getSteelForBack(input:$input){
            id
            identifier
            createUser{
            name
            }
            state
            stateInfo{
            desc
            }
            companyId
            repository{
            name
            }
            materialManufacturer{
            name
            }
            manufacturer{
            name
            }
            turnover
            oneSteelUseDay
            oneSteelUsage
            producedDate
            specifcation{
            specification
            }
            steelInProject{
            projectName
            }
            steelInMaintenance{
            maintenance{
                name
            }
            }
            code
            createdAt
        }
    }
`;
// 根据时间段获取资产状态
export const GET_DURATION_SUMMARY = Request.gql`
    query($input: GetDurationSummaryInput!) {
        getDurationSummary(input:$input){
            durationToRepositoryTotalOfSteel
            leaseTotal
            maintenanceTotal
            scrapTotal
        }
    }
`;
// 项目现场型钢数据
export const GET_PROJECT_STEEL_SATTE = Request.gql`
    query($input: ProjectSteelUsingDynamicInput!) {
        getProjectSteelDate(input:$input){
            projectSteelTotal
            steelEnterTotal
            steelOutTotal
        }
    }
`;
