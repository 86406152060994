import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, Input, DatePicker, Select, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Request, { uploadClient } from "../../../../utils/client";
import managementType from "../../../../utils/managementType";
import { SINGLE_UPLOAD } from "../../../../graphql/upload/upload";
import {
  ADD_PERSONEL,
  MODIFY_PERSONEL,
} from "../../../../graphql/admin/personal";

import axiosRequest from "../../../../utils/request";
import { companyRequestPath } from "../../../../request/requestPath";

const roleObject = {
  仓库管理员: "repositoryAdmin",
  维修管理员: "maintenanceAdmin",
  项目管理员: "projectAdmin",
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddPersonal(props, ref) {
  const [loading, setLoad] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [imgId, setImgId] = useState("");
  let [id, setId] = useState([]);
  const [form] = Form.useForm();
  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  // 自定义logo上传的方法
  let doImgUpload = (options) => {
    // const { onSuccess, onError, file, onProgress } = options;
    const { file } = options;
    console.log("file", file);
    setLoad(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("图片格式只能是JPG/PNG");
      setLoad(false);
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("图片大小不能超过2MB!");
      setLoad(false);
      return;
    }
    uploadClient
      .mutate({
        mutation: SINGLE_UPLOAD,
        variables: {
          file,
        },
      })
      .then((res) => {
        console.log(res);
        let { url, id } = res.data.singleUpload;
        let img = url;
        setImgId(id);
        setImgUrl(img);
        message.success("图片上传成功");
        setLoad(false);
      })
      .catch((err) => {
        message.error(err);
        setLoad(false);
      });
  };
  // 暴露给父组件调用的方法
  useImperativeHandle(ref, () => {
    return {
      // 添加
      addPersonal() {
        form
          .validateFields()
          .then((res) => {
            console.log(form.getFieldsValue());
            let { name, phone, password, role, wechat, status } =
              form.getFieldsValue();
            Request.client
              .mutate({
                mutation: ADD_PERSONEL, // 封装好的GraphQL,
                variables: {
                  input: {
                    name,
                    phone,
                    password,
                    role,
                    wechat,
                    avatarId: imgId,
                    // status,
                  },
                },
              })
              .then((result) => {
                console.log(result);
                message.success("添加成功");
                form.resetFields();
                props.closeModal();
              })
              .catch((error) => {
                message.error(error.message);
                // console.log(error);
              });
          })
          .catch((err) => {
            // console.log(err);
          });
      },
      // 修改
      modify(obj) {
        // return
        let {
          userName,
          phone,
          password,
          roleName,
          wechat,
          status,
          avatar,
          userId,
        } = obj;
        setId(userId);
        // setImgId(avatar.id);
        // setImgUrl(avatar.url);

        // console.log(newStartAt, newEndedAt);
        // return
        form.setFieldsValue({
          name: userName,
          phone,
          password: password ? password : "",
          role: roleObject[roleName],
          wechat,
          status,
          avatar,
          // role: role.tag,
        });
      },
      // 修改公司时提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { name, phone, role, status } = form.getFieldsValue();
            let isAble = status === "正常" ? true : false;
            axiosRequest
              .post(companyRequestPath.EditCompanyUser, {
                name,
                phone,
                roleId: managementType[role],
                id,
                isAble,
              })
              .then((res) => {
                if (res.code === 200) {
                  // console.log(result);
                  message.success("修改成功");
                  form.resetFields();
                  props.closeModal();
                } else {
                  // console.log(result);
                  message.success(res.message);
                }
              })
              .catch((error) => {
                console.log("编辑人员信息错误", error);
              });

            // Request.client
            //   .mutate({
            //     mutation: MODIFY_PERSONEL, // 封装好的GraphQL,
            //     variables: {
            //       input: {
            //         name,
            //         phone,
            //         roleId: managementType[role] + "",
            //         // wechat,
            //         // avatarId: imgId,
            //         id,
            //         isAble,
            //       },
            //     },
            //   })
            //   .then((result) => {
            //     // console.log(result);
            //     message.success("修改成功");
            //     form.resetFields();
            //     props.closeModal();
            //   })
            //   .catch((error) => {
            //     message.error(error.message);
            //     // console.log(error);
            //   });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handlerResetForm() {
        form.resetFields();
        setImgUrl("");
      },
    };
  });
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="name"
          label="人员名称"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>

        <Form.Item
          label="电话"
          name="phone"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        {props.type == "add" ? (
          <Form.Item
            name="wechat"
            label="微信"
            rules={[{ required: true, message: "不能为空" }]}
          >
            <Input style={{ width: 300 }} placeholder="请输入" />
          </Form.Item>
        ) : (
          ""
        )}
        <Form.Item
          name="role"
          label="角色"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择角色",
            },
          ]}
        >
          <Select placeholder="选择角色" style={{ width: 300 }}>
            <Option value="repositoryAdmin">仓库管理员</Option>
            <Option value="projectAdmin">项目管理员</Option>
            <Option value="maintenanceAdmin">维修管理员</Option>
          </Select>
        </Form.Item>
        {props.type == "modify" ? (
          <Form.Item
            name="status"
            label="状态"
            hasFeedback
            rules={[
              {
                required: true,
                message: "请选择状态",
              },
            ]}
          >
            <Select placeholder="选择状态" style={{ width: 300 }}>
              <Option value="正常">正常</Option>
              <Option value="已停用">已停用</Option>
            </Select>
          </Form.Item>
        ) : (
          ""
        )}
        {props.type == "add" ? (
          <Form.Item
            label="头像"
            name="avatar"
            rules={[{ required: true, message: "不能为空" }]}
            valuePropName="avatarfileList"
            getValueFromEvent={normFile}
          >
            <Upload
              name="imgUrl"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={doImgUpload}
              accept=".jpeg,.jpg,.png"
            >
              {imgUrl ? (
                <img src={imgUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
        ) : (
          ""
        )}
        {props.type == "add" ? (
          <Form.Item
            label="初始密码"
            name="password"
            rules={[{ required: true, message: "不能为空" }]}
          >
            <Input style={{ width: 300 }} placeholder="请输入" />
          </Form.Item>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
}
export default forwardRef(AddPersonal);
