/**
 * 材料清单表格的属性
 */
export const steelColumns = [
  {
    title: "序号",
    dataIndex: "sort",
    fixed: "left",
    width: 100,
  },
  {
    title: "提交时间",
    dataIndex: "createdDate",
    width: 200,
  },
  {
    title: "仓库名称",
    dataIndex: "repositoryName",
    width: 200,
  },

  {
    title: "规格",
    dataIndex: "specification",
    width: 200,
  },
  {
    title: "数量",
    dataIndex: "total",
    width: 150,
  },
  {
    title: "重量",
    dataIndex: "weight",
    width: 150,
  },
];
export const partColums = [
  {
    title: "序号",
    dataIndex: "sort",
    width: 100,
  },
  {
    title: "提交时间",
    dataIndex: "createdDate",
    width: 200,
  },
  {
    title: "仓库名称",
    dataIndex: "repositoryName",
    width: 200,
  },
  {
    title: "名称",
    dataIndex: "name",
    width: 200,
  },
  {
    title: "规格",
    dataIndex: "specification",
    width: 200,
  },

  {
    title: "数量",
    dataIndex: "total",
    width: 150,
  },
  {
    title: "重量",
    dataIndex: "weight",
    width: 150,
  },
];
export const equipmentColums = [
  {
    title: "序号",
    dataIndex: "sort",
    width: 100,
  },
  {
    title: "提交时间",
    dataIndex: "createdDate",
    width: 200,
  },
  {
    title: "仓库名称",
    dataIndex: "repositoryName",
    width: 200,
  },
  {
    title: "设备名",
    dataIndex: "name",
    width: 200,
  },
  {
    title: "规格",
    dataIndex: "specification",
    width: 200,
  },
  {
    title: "数量",
    dataIndex: "total",
    width: 150,
  },
  {
    title: "重量",
    dataIndex: "weight",
    width: 150,
  },
];

export const common = [
  {
    title: "序号",
    dataIndex: "orderId",
    fixed: "left",
    width: 80,
  },
  {
    title: "需求单提交时间",
    dataIndex: "createdDate",
    width: 150,
  },
  {
    title: "需求单单号",
    dataIndex: "orderNo",
    width: 160,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 150,
  },
  {
    title: "型钢总重（t）",
    dataIndex: "steelWeight",
    width: 100,
  },
  {
    title: "配件总重（t）",
    dataIndex: "partWeight",
    width: 100,
  },
  {
    title: "设备个数（个）",
    dataIndex: "equipmentTotal",
    width: 100,
  },
];

export const refusedCloumns = [
  {
    title: "序号",
    dataIndex: "orderId",
    fixed: "left",
    width: 100,
  },
  {
    title: "需求单提交时间",
    dataIndex: "createdDate",
    width: 150,
  },
  {
    title: "需求单号",
    dataIndex: "orderNo",
    width: 160,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 150,
  },
  {
    title: "型钢总重（t）",
    dataIndex: "steelToBeWeight",
    width: 100,
  },
  {
    title: "配件总重（t）",
    dataIndex: "partToBeWeight",
    width: 100,
  },
  {
    title: "设备个数（个）",
    dataIndex: "equipmentToBeTotal",
    width: 100,
  },
];

export const awaitColumns = [...common];
export const distributionColumns = [...common];

// 已分配点击状态的弹窗
export const infoColumns = [
  {
    title: "审批时间",
    dataIndex: "confirmedDate",
    width: 100,
  },
  {
    title: "审批人",
    dataIndex: "confirmedUser",
    width: 100,
  },
];
