import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Button,
  Table,
  InputNumber,
} from "antd";
import moment from "moment";
import Request from "../../../../utils/client";
// import { useDispatch, useSelector } from "react-redux";
import {
  DEMAND_PROJECT,
  DEMAND_REPOSITY,
  // DEMAND_SIZE,
  // ADD_DEMAND,
  MODIFY_DEMAND,
  // AQUIRE_EQUIPMENT_LIST,
  // AQUIRE_ACCESSORIES_LIST,
} from "../../../../graphql/admin/demand";
import axiosRequest from "../../../../utils/request";
import {
  clockRequestPath,
  demandRequestPath,
  ProjectRequestPath,
} from "../../../../request/requestPath";

import PubSub from "pubsub-js";

import "./addDemand.scss";

// const { TextArea } = Input;
const { Option } = Select;
// 提交的方法
// const Demo = () => {
//   const onFinish = (values) => {
//     console.log("Received values of form: ", values);
//   };
// };
const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddCompany(props, ref) {
  let [children, setChildren] = useState([]);
  let [repChildren, setrRepChildren] = useState([]);
  // let [sizeChildren, setSizeChildren] = useState([]);
  let [size, setSize] = useState([]);
  let [tabelData, setData] = useState([]);
  let [steelTotalCount, setSteelTotalCount] = useState(0);
  let [steelTotalWeight, setSteelTotalWeight] = useState(0);
  let [equTotalCount, setEquTotalCount] = useState(0);
  let [equTotalWeight, setEquTotalWeight] = useState(0);
  let [accTotalCount, setAccTotalCount] = useState(0);
  let [accTotalWeight, setAccTotalWeight] = useState(0);
  let [errorMess, setErrorMess] = useState("");
  let [id, setId] = useState("");
  let [proDisable, setProDisable] = useState(false);
  let [repDisable, setRepDisable] = useState(false);

  let [steelArray, setSteelArray] = useState([]);
  let [steelChildren, setSteelChildren] = useState([]);
  let [steelList, setSteelList] = useState([]);
  let [steelId, setSteelId] = useState("");
  let [steelNum, setSteelNum] = useState("");

  let [accArray, setAccArray] = useState([]);
  let [accChildren, setAcc] = useState([]);
  let [accList, setAccList] = useState([]);
  let [accId, setAccId] = useState("");
  let [accNum, setAccNum] = useState("");

  let [equArray, setEquArray] = useState([]);
  let [equChildren, setEquip] = useState([]);
  let [equList, setEquList] = useState([]);
  let [equId, setEquId] = useState("");
  let [equlNum, setEquNum] = useState("");

  const [form] = Form.useForm();

  // const dispatch = useDispatch();
  // // 获取redux里的配件数据
  // let accessories = useSelector((state) => {
  //   return state.accessories;
  // });
  // // 获取redux里的设备数据
  // let equipment = useSelector((state) => {
  //   return state.equipment;
  // });
  // // 获取redux里的型钢数据
  // let steel = useSelector((state) => {
  //   return state.steel;
  // });
  let commonColumns = [
    {
      title: "序号",
      dataIndex: "id",
      fixed: "left",
      width: 100,
    },
    {
      title: "名称",
      dataIndex: "name",
      width: 150,
    },
    {
      title: "规格",
      dataIndex: "size",
      width: 150,
    },
    {
      title: "数量",
      dataIndex: "total",
      width: 100,
      align: "center",
    },
  ];
  let steelCloumn = [
    ...commonColumns,
    {
      title: "操作",
      key: "operation",
      width: 200,
      fixed: "right",
      align: "center",
      render: (item) => (
        <div>
          <Button
            type="text"
            onClick={() => {
              deleteSteelItem(item);
            }}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];
  let accessoriesCloumn = [
    ...commonColumns,
    {
      title: "操作",
      key: "operation",
      width: 200,
      fixed: "right",
      align: "center",
      render: (item) => (
        <div>
          <Button
            type="text"
            onClick={() => {
              deleteAccessoriesItem(item);
            }}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];
  let equipmentCloumn = [
    ...commonColumns,
    {
      title: "操作",
      key: "operation",
      width: 200,
      fixed: "right",
      align: "center",
      render: (item) => (
        <div>
          <Button
            type="text"
            onClick={() => {
              deleteEquipmentItem(item);
            }}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];
  let deleteSteelItem = (obj) => {
    let arr = steelList.filter((item) => {
      return item.id !== obj.id;
    });
    setSteelList([...arr]);
  };
  let deleteEquipmentItem = (obj) => {
    let arr = equList.filter((item) => {
      return item.id !== obj.id;
    });
    setEquList([...arr]);
  };
  let deleteAccessoriesItem = (obj) => {
    let arr = accList.filter((item) => {
      return item.id !== obj.id;
    });
    setAccList([...arr]);
  };

  useImperativeHandle(ref, () => {
    return {
      // 获取项目列表
      getProject() {
        axiosRequest
          .post(ProjectRequestPath.GetProjectList, {})
          .then((res) => {
            if (res.code === 200) {
              // console.log("获取项目", res.data);
              let children = [];
              res.data.forEach((item) => {
                children.push(
                  <Option key={item.projectId}>{item.projectName}</Option>
                );
              });
              setChildren(children);
            }
          })
          .catch((error) => {
            console.log("获取项目报错", error);
          });
        // Request.client
        //   .query({
        //     query: DEMAND_PROJECT,
        //   })
        //   .then((result) => {
        //     // let arr = result.data.getCompanyUser;
        //     console.log("获取的项目", result);
        //     let { getProjectLis } = result.data;
        //     let children = [];
        //     getProjectLis.forEach((item) => {
        //       children.push(<Option key={item.id}>{item.name}</Option>);
        //     });
        //     setChildren(children);
        //   })
        //   .catch((err) => {
        //     message.error("无法从服务器中获取到项目数据");
        //   });
      },
      // 获取仓库列表
      getReposity() {
        Request.client
          .query({
            query: DEMAND_REPOSITY,
          })
          .then((result) => {
            // console.log("仓库列表", result);
            let arr = result.data.getRepositoryList;
            let children2 = [];
            arr.forEach((item) => {
              children2.push(<Option key={item.id}>{item.name}</Option>);
            });
            setrRepChildren(children2);
          })
          .catch((err) => {
            console.log(err);
            message.error("无法从服务器中获取到仓库数据");
          });
      },
      // 获取规格尺寸
      getSize() {
        axiosRequest
          .post(clockRequestPath.GetSpecification, {})
          .then((res) => {
            if (res.code === 200) {
              let children = [];
              res.data.forEach((item, index) => {
                children.push(
                  <Option key={item.id}>
                    {item.type + "(规格：" + item.specification + ")"}
                  </Option>
                );
              });
              setSteelChildren(children);
              setSteelArray(res.data);
            }
          })
          .catch((error) => {
            console.log("获取型钢规格列表错误");
          });
      },
      // 获取配件列表
      getAccessories() {
        axiosRequest
          .post(clockRequestPath.GetPartList, {})
          .then((res) => {
            if (res.code === 200) {
              let children = [];
              res.data.forEach((item) => {
                children.push(
                  <Option key={item.id}>
                    {item.part_name + "(规格：" + item.specification + ")"}
                  </Option>
                );
              });
              setAcc(children);
              setAccArray(res.data);
            }
          })
          .catch((error) => {
            console.log("获取型钢规格列表");
          });
      },
      // 获取设备列表
      getEquipment() {
        axiosRequest
          .post(clockRequestPath.GetEquipmentList, {})
          .then((res) => {
            if (res.code === 200) {
              let children = [];
              res.data.forEach((item) => {
                children.push(
                  <Option key={item.id}>
                    {item.equipment_name + "(规格：" + item.specification + ")"}
                  </Option>
                );
              });
              setEquip(children);
              setEquArray(res.data);
            }
          })
          .catch((error) => {
            console.log("获取型钢规格列表");
          });
      },
      // 添加需求
      addDemand() {
        setErrorMess("");
        form
          .validateFields()
          .then((res) => {
            let { project, date, remark, usetime } = form.getFieldsValue();
            let expectedReturnAt = moment(date).format();
            let newSteelList = [];
            let newEqument = [];
            let newAccList = [];

            steelList.forEach((item) => {
              newSteelList.push({
                specificationId: item.id * 1,
                total: item.total,
              });
            });
            equList.forEach((item) => {
              newEqument.push({
                total: item.total,
                equipmentId: item.id * 1,
              });
            });

            accList.forEach((item) => {
              newAccList.push({
                total: item.total,
                partInfoId: item.id * 1,
              });
            });
            // console.log("newSteelList", newSteelList);
            // console.log("newEqument", newEqument);
            // console.log("accList", newAccList);
            // return;
            axiosRequest
              .post(demandRequestPath.CreateOrder, {
                expectedReturnAt: moment(expectedReturnAt).format("YYYY-MM-DD"),
                projectId: project * 1,
                usetime: usetime * 1,
                remark,
                steelList: newSteelList,
                equipmentList: newEqument,
                partList: newAccList,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("添加成功");
                  form.resetFields();
                  props.closeModal();
                  PubSub.publish("aquireDemand");
                } else {
                  message.error(res.message);
                }
                // let { data } = res;
              })
              .catch((error) => {
                setErrorMess(error.message);
                console.log(error);
              });
            // Request.client
            //   .mutate({
            //     mutation: ADD_DEMAND, // 封装好的GraphQL,
            //     variables: {
            //       input: {
            //         expectedReturnAt,
            //         projectId: project * 1,
            //         usetime: usetime * 1,
            //         remark,
            //         steelList: newSteelList,
            //         equipmenList: newEqument,
            //         partList: newAccList,
            //       },
            //     },
            //   })
            //   .then((result) => {
            //     console.log(result);
            //     message.success("添加成功");
            //     form.resetFields();
            //     props.closeModal();
            //     PubSub.publish("aquireDemand");
            //   })
            //   .catch((error) => {
            //     // message.error(error.message);
            //     setErrorMess(error.message);
            //     console.log(error);
            //   });
          })
          .catch((err) => {
            setErrorMess(err.message);
            console.log(err);
          });
      },
      // 修改项目
      modifyProject(demand) {
        setProDisable(true);
        setRepDisable(true);
        let {
          id,
          repository,
          confirmedAt,
          remark,
          project,
          partList,
          orderSpecificationList,
        } = demand;
        let arr = [];
        orderSpecificationList.forEach((item) => {
          arr.push({
            id: item.id,
            sort: arr.length + 1,
            total: item.total,
            size: item.specification,
            weight: Math.floor(item.weight * 1000) / 1000,
            key: arr.length + 1,
          });
        });
        setId(id);
        setData([...arr]);
        // let leaderIdS = leaderList.map((item) => {
        //   return item.id;
        // });
        let date = confirmedAt ? moment(confirmedAt) : null;
        form.setFieldsValue({
          project: project.id + "",
          repository: repository.id + "",
          date,
          remark,
          list: partList,
        });
      },
      // 修改需求单提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { project, date, remark, list } = form.getFieldsValue();
            let expectedReturnAt = moment(date).format();
            console.log(expectedReturnAt);
            if (tabelData.length === 0) {
              message.error("请先添加型钢数据");
              return;
            }
            let steelList = [];
            tabelData.forEach((item) => {
              steelList.push({
                total: item.total,
                specificationId: item.id,
              });
            });
            Request.client
              .mutate({
                mutation: MODIFY_DEMAND, // 封装好的GraphQL,
                variables: {
                  input: {
                    expectedReturnAt,
                    partList: list,
                    projectId: project,
                    // repositoryId: repository,
                    remark,
                    steelList,
                    id,
                  },
                },
              })
              .then((result) => {
                message.success("添加成功");
                form.resetFields();
                props.closeModal();
              })
              .catch((error) => {
                // message.error(error.message);
                setErrorMess(error.message);
                console.log(error);
              });
          })
          .catch((err) => {
            message.error(err.megssage);
          });
      },
      // 重置表格数据
      handlerResetForm() {
        form.resetFields();
        setData([]);
        setProDisable(false);
        setRepDisable(false);
        setErrorMess("");
      },
    };
  });

  // 配件规格改变
  let accSelectChange = (e) => {
    setAccId(e);
  };
  // 配件数量改变
  let accInputChange = (e) => {
    setAccNum(e);
  };
  // 添加配件
  let addAccess = (e) => {
    if (accId === undefined) {
      message.error("请先选择配件");
      return;
    }
    if (accNum === undefined) {
      message.error("请输入配件数量");
      return;
    }
    let index = accArray.findIndex((item) => {
      return item.id === accId * 1;
    });
    let obj = {
      id: accId,
      sort: accList.length + 1,
      total: accNum,
      name: accArray[index].part_name,
      size: accArray[index].specification,
      weight: accArray[index].weight,
      key: accList.length + 1,
    };
    let list = accList.filter((item) => {
      return item.id !== accId;
    });
    setAccList([...list, obj]);

    // console.log("添加", selectSize, num);
  };
  // 设备规格改变
  let equSelectChange = (e) => {
    console.log(e);
    setEquId(e);
  };
  // 设备数量改变
  let equInputChange = (e) => {
    setEquNum(e);
  };
  // 添加设备
  let addEquip = () => {
    if (equId === undefined) {
      message.error("请先选择设备");
      return;
    }
    if (equlNum === undefined) {
      message.error("请输入设备数量");
      return;
    }
    let index = equArray.findIndex((item) => {
      return item.id === equId * 1;
    });

    let obj = {
      id: equId,
      sort: equList.length + 1,
      total: equlNum,
      name: equArray[index].equipment_name,
      size: equArray[index].specification,
      weight: equArray[index].weight,
      key: equList.length + 1,
    };
    let list = equList.filter((item) => {
      return item.id !== equId;
    });
    setEquList([...list, obj]);
  };
  // 型钢规格改变
  let steelSelectChage = (e) => {
    setSteelId(e);
  };
  // 型钢数量改变
  let steelInputChange = (e) => {
    setSteelNum(e);
  };
  // 添加型钢
  let addSteel = () => {
    if (steelId === undefined) {
      message.error("请先选择型钢规格");
      return;
    }
    if (steelNum === undefined) {
      message.error("请输入型钢数量");
      return;
    }
    let index = steelArray.findIndex((item) => {
      return item.id === steelId * 1;
    });
    let obj = {
      id: steelId,
      sort: steelList.length + 1,
      total: steelNum,
      name: steelArray[index].type,
      size: steelArray[index].specification,
      weight: steelArray[index].weight,
      key: steelList.length + 1,
    };
    let list = steelList.filter((item) => {
      return item.id !== steelId;
    });
    setSteelList([...list, obj]);
  };
  // 监听表格数据源 tableData的变化，重新计算总数量和总质量
  useEffect(() => {
    calculate("steel");
  }, [steelList]);
  useEffect(() => {
    calculate("equ");
  }, [equList]);
  useEffect(() => {
    calculate("acc");
  }, [accList]);
  // 计算总质量和总重量
  function calculate(type) {
    let count = 0;
    let weight = 0;
    switch (type) {
      case "steel":
        steelList.forEach((item) => {
          count = count + item.total;
          weight = weight + item.weight * item.total;
        });
        setSteelTotalCount(count);
        setSteelTotalWeight(Math.floor(weight * 1000) / 1000);
        break;
      case "equ":
        equList.forEach((item) => {
          count = count + item.total;
          weight = weight + item.weight * item.total;
        });
        setEquTotalCount(count);
        setEquTotalWeight(Math.floor(weight * 1000) / 1000);
        break;
      default:
        accList.forEach((item) => {
          count = count + item.total;
          weight = weight + item.weight * item.total;
        });
        setAccTotalCount(count);
        setAccTotalWeight(Math.floor(weight * 1000) / 1000);
    }
  }
  return (
    <div className="add_demand_box">
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="project"
          label="项目名称"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择项目",
            },
          ]}
        >
          <Select
            placeholder="选择项目"
            style={{ width: 300 }}
            disabled={proDisable}
          >
            {children}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="repository"
          label="接单仓库"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择仓库",
            },
          ]}
        >
          <Select
            placeholder="选择仓库"
            style={{ width: 300 }}
            disabled={repDisable}
          >
            {repChildren}
          </Select>
        </Form.Item> */}
        <Form.Item
          name="usetime"
          label="使用时间"
          rules={[{ required: true, message: "请输入" }]}
        >
          <Input style={{ width: 300 }} type="number" />
        </Form.Item>
        <Form.Item
          name="date"
          label="预计归还时间"
          rules={[{ required: true, message: "请输入" }]}
        >
          <DatePicker style={{ width: 300 }} showTime />
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          rules={[{ required: true, message: "请输入" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
      </Form>

      <div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5,
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 20,
                width: 100,
              }}
            >
              型钢
            </div>
            <div>
              <Select
                placeholder="选择规格"
                style={{ width: 300 }}
                onChange={steelSelectChage}
              >
                {steelChildren}
              </Select>
              <InputNumber
                style={{ width: 200, marginLeft: 10 }}
                placeholder="请输入"
                onChange={steelInputChange}
              />
              <Button
                type="primary"
                style={{ marginLeft: 20 }}
                onClick={addSteel}
              >
                添加
              </Button>
            </div>
          </div>
          <Table
            columns={steelCloumn}
            dataSource={steelList}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0 20px 0",
              fontWeight: "bold",
            }}
          >
            <span>合计：</span>
            <span>数量：{steelTotalCount}根，</span>
            <span>重量：{steelTotalWeight}吨</span>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5,
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 20,
                width: 100,
              }}
            >
              设备
            </div>
            <div>
              <Select
                placeholder="选择设备"
                style={{ width: 250 }}
                onChange={equSelectChange}
              >
                {equChildren}
              </Select>
              <InputNumber
                style={{ width: 200, marginLeft: 10 }}
                placeholder="请输入"
                onChange={equInputChange}
              />
              <Button
                type="primary"
                style={{ marginLeft: 20 }}
                onClick={addEquip}
              >
                添加
              </Button>
            </div>
          </div>
          <Table
            columns={equipmentCloumn}
            dataSource={equList}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0 20px 0",
              fontWeight: "bold",
            }}
          >
            <span>合计：</span>
            <span>数量：{equTotalCount}台，</span>
            <span>重量：{equTotalWeight}吨</span>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 5,
              marginTop: 10,
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: 20,
                width: 100,
              }}
            >
              配件
            </div>
            <div>
              <Select
                placeholder="选择配件"
                style={{ width: 250 }}
                onChange={accSelectChange}
              >
                {accChildren}
              </Select>
              <InputNumber
                style={{ width: 200, marginLeft: 10 }}
                placeholder="请输入"
                onChange={accInputChange}
              />
              <Button
                type="primary"
                style={{ marginLeft: 20 }}
                onClick={addAccess}
              >
                添加
              </Button>
            </div>
          </div>
          <Table
            columns={accessoriesCloumn}
            bordered
            dataSource={accList}
            scroll={{ x: 600 }}
            pagination={false}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "10px 0 20px 0",
              fontWeight: "bold",
            }}
          >
            <span>合计：</span>
            <span>数量：{accTotalCount}件，</span>
            <span>重量：{accTotalWeight}吨</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default forwardRef(AddCompany);
