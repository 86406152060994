import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Index from "../../index/Index";
import Retrieve from "../../retrieve/Retrieve";
import Company from "../../company/Company";
import Personnel from "../../personnel/Personnel";
import Warehouse from "../../warehouse/Warehouse";
import Detail from "../../warehouse/Detail";
import SteelHistory from "../../warehouse/SteelHistory";
import EquipmentHistory from "../../warehouse/EquipmentHistory";
import Mac from "../../mac/Mac";
import Steel from "../../steel/steel";
import Maintenance from "../../maintenance/Maintenance";
import MaintenanceDetail from "../../maintenance/MaintenanceDetail";
import MaintenanceOrder from "../../maintenance/MaintenanceOrder";
import MaintenanceMangement from "../../maintenance/MaintenanceMangement";
import ScrapList from "../../maintenance/ScrapList";
import OrderDetail from "../../maintenance/OrderDetail";
import Project from "../../project/Project";
import ProjectDetail from "../../project/ProjectDetail";
import Management from "../../demand/Management";
import Demand from "../../demand/Demand";
import DemandDetail from "../../demand/DemandDetail";
import SelectAccessories from "../../demand/SelectAccessories";
import SelectEquipment from "../../demand/SelectEquipment";
import SelectSteel from "../../demand/SelectSteel";
import Size from "../../clock/Size";
import Accessories from "../../clock/Accessories";
import Equipment from "../../clock/Equipment";
import Material from "../../clock/Material";
import Manufacturing from "../../clock/Manufacturing";
import Transport from "../../clock/Transport";
import Price from "../../clock/Price";
import NotPage from "../../error/NotPage";
import RedirectPage from "../../error/RedirectPage";
import SystemError from "../../error/SystemError";
import Message from "../../message/message";
import Log from "../../syslog/Log";
import Modify from "../../modify/Modify";

import Cart from "../../cart/Cart";

export default class Router extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path="/admin/index" component={Index}></Route>
          <Route path="/admin/rtrieve" component={Retrieve}></Route>
          <Route path="/admin/company" component={Company}></Route>
          <Route path="/admin/personnel" component={Personnel}></Route>
          <Route exact path="/admin/warehouse" component={Warehouse}></Route>
          <Route
            exact
            path="/admin/warehouse/detail"
            component={Detail}
          ></Route>
          <Route
            exact
            path="/admin/warehouse/steelhistory"
            component={SteelHistory}
          ></Route>
          <Route
            exact
            path="/admin/warehouse/equipmenthistory"
            component={EquipmentHistory}
          ></Route>
          <Route path="/admin/mac" component={Mac}></Route>
          <Route path="/admin/steel" component={Steel}></Route>
          <Route
            exact
            path="/admin/maintenance"
            component={Maintenance}
          ></Route>
          <Route
            exact
            path="/admin/maintenance/detail"
            component={MaintenanceDetail}
          ></Route>
          <Route
            exact
            path="/admin/maintenanceOrder"
            component={MaintenanceOrder}
          ></Route>
          <Route
            exact
            path="/admin/maintenanceMangement"
            component={MaintenanceMangement}
          ></Route>
          <Route exact path="/admin/scrapList" component={ScrapList}></Route>

          <Route
            exact
            path="/admin/orderDetail/:orderNo"
            component={OrderDetail}
          ></Route>
          <Route exact path="/admin/project" component={Project}></Route>
          <Route
            exact
            path="/admin/project/detail"
            component={ProjectDetail}
          ></Route>
          <Route exact path="/admin/demand/list" component={Demand}></Route>
          <Route
            exact
            path="/admin/demand/management"
            component={Management}
          ></Route>
          <Route
            exact
            path="/admin/demand/detail"
            component={DemandDetail}
          ></Route>
          <Route
            exact
            path="/admin/demand/accessories"
            component={SelectAccessories}
          ></Route>
          <Route
            exact
            path="/admin/demand/equipemnt"
            component={SelectEquipment}
          ></Route>
          <Route
            exact
            path="/admin/demand/steel"
            component={SelectSteel}
          ></Route>
          <Route path="/admin/cart" component={Cart}></Route>
          <Route path="/admin/clock/size" component={Size}></Route>
          <Route
            path="/admin/clock/accessories"
            component={Accessories}
          ></Route>
          <Route path="/admin/clock/equipment" component={Equipment}></Route>
          <Route path="/admin/clock/material" component={Material}></Route>
          <Route
            path="/admin/clock/manufacturing"
            component={Manufacturing}
          ></Route>
          <Route path="/admin/clock/transport" component={Transport}></Route>
          <Route path="/admin/clock/price" component={Price}></Route>
          <Route path="/admin/log" component={Log}></Route>
          <Route path="/admin/modify" component={Modify}></Route>
          <Route path="/admin/message" component={Message}></Route>
          <Route path="/admin/error/not" component={NotPage}></Route>
          <Route path="/admin/error/redirect" component={RedirectPage}></Route>
          <Route
            path="/admin/error/systemerror"
            component={SystemError}
          ></Route>
          <Redirect to="/admin/error/not"></Redirect>
        </Switch>
      </div>
    );
  }
}
