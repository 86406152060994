import Request from "../../utils/client";

export const LOGIN = Request.gql`
   mutation ($phone: String!, $password: String!,$mac: String!,$input:LoginRoleInput!){
  login (phone: $phone, password: $password, mac:$mac,input:$input) {
    accessToken
    role
    roleName
    
  }
}
`;
