/**
 * 项目管理员主页相关接口
 */
import Request from "../../../utils/client";
// 项目概况
export const PROJECT_SITUATION = Request.gql`
    query($input: GeneralSituationOfProjectInput!) {
        getGeneralSituationOfProject(input:$input){
            startTime
            endTime
            steelTotal
            equipmentTotal
        }
    }
`;

// 使用状态
export const PROJECT_USING = Request.gql`
    query($input: ProjectSteelUsingDynamicInput!) {
        getProjectSteelUsingDynamic(input:$input){
            enterSteelTotal
            outSteelTotal
            usingTotal
            beLibraryTotal
        }
    }
`;
// 型钢最新动向
export const GET_THE_LAST_TREND = Request.gql`
    query($input: GetTheLastTrendInput!) {
        getTheLastTrend(input:$input){
            orderNo
            projectName
            repositoryName
            state
            useTime
            returonTime
            steelList{
            name
            total
            }
            partList{
            name
            total
            }
            equipList{
            name
            total
            }
            repositoryAdmin
            projectAdmin
            companyAdmin
            outRepositoryTime
            outRepositoryExpressInfo
            outSiteTime
            outSiteExpressInfo
            remark
        }
    }
`;
// 项目型钢状态
export const PROJECT_STEEL_STATE = Request.gql`
    query($input: GetSteelStateOfProjectInput!) {
        getSteelStateOfProject(input:$input){
            totalWeight
            idleWeightPercent
            beUse
            usingPercent
            toProjectPercent
            toRepository
            readyToRepository
            abnormalPercent
            using
            beUse
            toRepository
            readyToRepository
        }
    }
`;
// 型钢查询
export const GET_STEEL_FOR_BACK = Request.gql`
   query($input: GetSteelForBackInput!) {
    getSteelForBack(input:$input){
        id
        identifier
        createUser{
        name
        }
        state
        stateInfo{
        desc
        }
        companyId
        repository{
        name
        }
        materialManufacturer{
        name
        }
        manufacturer{
        name
        }
        turnover
        oneSteelUseDay
        oneSteelUsage
        producedDate
        specifcation{
        specification
        }
        steelInProject{
        projectName
        }
        steelInMaintenance{
        maintenance{
            name
        }
        }
        code
        createdAt
    }
    }
`;

// 项目现场型钢数据
export const GET_PROJECT_STEEL_DATA = Request.gql`
    query($input: ProjectSteelUsingDynamicInput!) {
        getProjectSteelDate(input:$input){
                projectSteelTotal
            steelEnterTotal
            steelOutTotal
        }
    }
`;
