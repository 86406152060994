import Request from "../../utils/client";

// 获取维修厂(简单数据)
export const AQUIRE_SINPLE_MAINTENANCE = Request.gql`
  query {
    getMaintenanceList {
      id
      # 维修厂名称
      name
    }
  }
`;

// 获取维修厂
export const AQUIRE_MAINTENANCE = Request.gql`
  query {
    getMaintenanceList {
      id
      # 维修厂名称
      name
      # 地址
      address
      # 管理员列表 
      admin {
        id
        name
        phone
        wechat
      }
      # 备注
      remark
      # 是否可用
      isAble
      # 重量
      weightTotal
      # 数量 
      total
    }
  }
`;
// 删除维修厂
export const DELETE_MAINTENANCE = Request.gql`
  mutation ($input: DelMaintenanceInput!){
    delMaintenance(input: $input)
  }
`;
// 添加维修厂
export const ADD_MAINTENANCE = Request.gql`
  mutation ($input: CreateMaintenanceInput!){
    createMaintenance(input: $input) {
    id
    # 备注
    remark
    # 地址

    address
    # 维修厂名
    name
    # 管理员列表
    admin{
      id
        #名字
      name
      #电话
      phone
      # 微信 
      wechat
    }
  }
  }
`;
// 编辑维修厂
export const MODIFY_MAINTENANCE = Request.gql`
  mutation ($input: EditMaintenanceInput!) {
    editMaintenance(input: $input) {
      id
      # 备注
      remark
      # 地址

      address
      # 维修厂名
      name
      # 管理员列表
      admin{
        id
        #名字
        name
        #电话
        phone
        # 微信 
        wechat
      }     
    }
  }
`;
// 维修厂详情
export const DETAIL_MAINTENANCE = Request.gql`
  query ($input: GetMaintenanceDetailInput!){
    getMaintenanceDetail(input: $input) {
      list {
        id
        stateInfo {
          desc # 维修状态
          state
        }
        steel {
          repository{
            id 
            name #仓库
          }
          code # 型钢编码
          specifcation {
            id
            specification # 尺寸          
          }
          stateInfo {
            desc # 当前状态说明
            state # 当前状态
          }
        }
        enteredAt # 入厂时间
        outedAt # 出厂时间
        useDays # 维修天数
      }
      total #  数量 
      weight # 重量
    }
  }
`;

// 获取维修单列表
export const MAINTENANCE_ORDER = Request.gql`
query($input: GetMaintenanceOrderListInput!) {
  GetMaintenanceOrderList(input:$input){
    id
    maintenance{
      name
    }
    repository{
      name
    }
    state
    createUser{
      role{
        tag
        name
      }
    }
    confirmedUser{
      role{
        tag
        name
      }
    }
    expressList{
      id
    }
    remark
    orderNo
    equipmentList{
      equipmentItemid
      equimentSpecification
      weight
      equipmentName
    }
    partList{
      partName
      partid
      weight
      total
    }
    steelList{
      specificationItemid
      weight
      photo
      steelSpecification
    }
    createdAt
    confirmedAt
    expectedReturnAt
  }
}
`;

// 获取维修单详情
export const MAINTENANCE_ORDER_DETAIL = Request.gql`
query($input: getOrderDetailInput!){
  getMorderDetail(input:$input){
    id
    maintenance{name}
    repository{name}
    state
    createUser{name}
    confirmedUser{name}
    expressList{id}
    remark
    orderNo
    equipmentList{
      equipmentName
      Identification
      weight
      photo
      equimentSpecification
    }
    partList{
      partName
      weight
      partid
      photo
      partSpecification
    }
    steelList{
      Identification
      weight
      photo
      steelSpecification
    }
  }
}
`;
// 创建维修单
export const MAINTENANCE_ORDER_CREATE = Request.gql`
  mutation($input: CreateMaintenanceOrderInput!) {
  createMaintenanceOrder(input:$input){
   id
    maintenance{
      id
      name
    }
    repository{
      name
    }
    state
    createUser{
      role{
        name
      }
    }
    confirmedUser{
      name
    }
    expressList{
      id
      receiveAt
    }
    remark
    orderNo
    equipmentList{
      weight
      Identification
      equipmentItemid
    }
    partList{
      partid
      weight
      partName
    }
    steelList{
      weight
      photo
      Identification
    }
    createdAt
    createdAt
    expectedReturnAt
  }
}
`;
// 编辑维修单
export const MAINTENANCE_ORDER_MODIFY = Request.gql`
mutation($input: EditMaintenanceOrderInput!){
  editMaintenanceOrder(input: $input) {
    id
  }
}
`;

// 删除维修单
export const MAINTENANCE_ORDER_DELETE = Request.gql`
mutation($input: DeleteMaintenanceOrderInput!){
  deleteMaintenanceOrder(input: $input) 
}
`;

// 审核维修单
export const MAINTENANCE_ORDER_CONFIRM = Request.gql`
mutation($input: ConfirmMorderInput!){
  confirmOrrejectMorder(input: $input){
    id
  } 
}
`;
