const options = [
  {
    code: "110000",
    value: "北京市",
    label: "北京市",
    children: [
      {
        code: "110101",
        value: "东城区",
        label: "东城区",
      },
      {
        code: "110102",
        value: "西城区",
        label: "西城区",
      },
      {
        code: "110105",
        value: "朝阳区",
        label: "朝阳区",
      },
      {
        code: "110106",
        value: "丰台区",
        label: "丰台区",
      },
      {
        code: "110107",
        value: "石景山区",
        label: "石景山区",
      },
      {
        code: "110108",
        value: "海淀区",
        label: "海淀区",
      },
      {
        code: "110109",
        value: "门头沟区",
        label: "门头沟区",
      },
      {
        code: "110111",
        value: "房山区",
        label: "房山区",
      },
      {
        code: "110112",
        value: "通州区",
        label: "通州区",
      },
      {
        code: "110113",
        value: "顺义区",
        label: "顺义区",
      },
      {
        code: "110114",
        value: "昌平区",
        label: "昌平区",
      },
      {
        code: "110115",
        value: "大兴区",
        label: "大兴区",
      },
      {
        code: "110116",
        value: "怀柔区",
        label: "怀柔区",
      },
      {
        code: "110117",
        value: "平谷区",
        label: "平谷区",
      },
      {
        code: "110118",
        value: "密云区",
        label: "密云区",
      },
      {
        code: "110119",
        value: "延庆区",
        label: "延庆区",
      },
    ],
  },
  {
    code: "120000",
    value: "天津市",
    label: "天津市",
    children: [
      {
        code: "120101",
        value: "和平区",
        label: "和平区",
      },
      {
        code: "120102",
        value: "河东区",
        label: "河东区",
      },
      {
        code: "120103",
        value: "河西区",
        label: "河西区",
      },
      {
        code: "120104",
        value: "南开区",
        label: "南开区",
      },
      {
        code: "120105",
        value: "河北区",
        label: "河北区",
      },
      {
        code: "120106",
        value: "红桥区",
        label: "红桥区",
      },
      {
        code: "120110",
        value: "东丽区",
        label: "东丽区",
      },
      {
        code: "120111",
        value: "西青区",
        label: "西青区",
      },
      {
        code: "120112",
        value: "津南区",
        label: "津南区",
      },
      {
        code: "120113",
        value: "北辰区",
        label: "北辰区",
      },
      {
        code: "120114",
        value: "武清区",
        label: "武清区",
      },
      {
        code: "120115",
        value: "宝坻区",
        label: "宝坻区",
      },
      {
        code: "120116",
        value: "滨海新区",
        label: "滨海新区",
      },
      {
        code: "120117",
        value: "宁河区",
        label: "宁河区",
      },
      {
        code: "120118",
        value: "静海区",
        label: "静海区",
      },
      {
        code: "120119",
        value: "蓟州区",
        label: "蓟州区",
      },
    ],
  },
  {
    code: "130000",
    value: "河北省",
    label: "河北省",
    children: [
      {
        code: "130100",
        value: "石家庄市",
        label: "石家庄市",
        children: [
          {
            code: "130102",
            value: "长安区",
            label: "长安区",
          },
          {
            code: "130104",
            value: "桥西区",
            label: "桥西区",
          },
          {
            code: "130105",
            value: "新华区",
            label: "新华区",
          },
          {
            code: "130107",
            value: "井陉矿区",
            label: "井陉矿区",
          },
          {
            code: "130108",
            value: "裕华区",
            label: "裕华区",
          },
          {
            code: "130109",
            value: "藁城区",
            label: "藁城区",
          },
          {
            code: "130110",
            value: "鹿泉区",
            label: "鹿泉区",
          },
          {
            code: "130111",
            value: "栾城区",
            label: "栾城区",
          },
          {
            code: "130121",
            value: "井陉县",
            label: "井陉县",
          },
          {
            code: "130123",
            value: "正定县",
            label: "正定县",
          },
          {
            code: "130125",
            value: "行唐县",
            label: "行唐县",
          },
          {
            code: "130126",
            value: "灵寿县",
            label: "灵寿县",
          },
          {
            code: "130127",
            value: "高邑县",
            label: "高邑县",
          },
          {
            code: "130128",
            value: "深泽县",
            label: "深泽县",
          },
          {
            code: "130129",
            value: "赞皇县",
            label: "赞皇县",
          },
          {
            code: "130130",
            value: "无极县",
            label: "无极县",
          },
          {
            code: "130131",
            value: "平山县",
            label: "平山县",
          },
          {
            code: "130132",
            value: "元氏县",
            label: "元氏县",
          },
          {
            code: "130133",
            value: "赵县",
            label: "赵县",
          },
          {
            code: "130181",
            value: "辛集市",
            label: "辛集市",
          },
          {
            code: "130183",
            value: "晋州市",
            label: "晋州市",
          },
          {
            code: "130184",
            value: "新乐市",
            label: "新乐市",
          },
        ],
      },
      {
        code: "130200",
        value: "唐山市",
        label: "唐山市",
        children: [
          {
            code: "130202",
            value: "路南区",
            label: "路南区",
          },
          {
            code: "130203",
            value: "路北区",
            label: "路北区",
          },
          {
            code: "130204",
            value: "古冶区",
            label: "古冶区",
          },
          {
            code: "130205",
            value: "开平区",
            label: "开平区",
          },
          {
            code: "130207",
            value: "丰南区",
            label: "丰南区",
          },
          {
            code: "130208",
            value: "丰润区",
            label: "丰润区",
          },
          {
            code: "130209",
            value: "曹妃甸区",
            label: "曹妃甸区",
          },
          {
            code: "130224",
            value: "滦南县",
            label: "滦南县",
          },
          {
            code: "130225",
            value: "乐亭县",
            label: "乐亭县",
          },
          {
            code: "130227",
            value: "迁西县",
            label: "迁西县",
          },
          {
            code: "130229",
            value: "玉田县",
            label: "玉田县",
          },
          {
            code: "130281",
            value: "遵化市",
            label: "遵化市",
          },
          {
            code: "130283",
            value: "迁安市",
            label: "迁安市",
          },
          {
            code: "130284",
            value: "滦州市",
            label: "滦州市",
          },
        ],
      },
      {
        code: "130300",
        value: "秦皇岛市",
        label: "秦皇岛市",
        children: [
          {
            code: "130302",
            value: "海港区",
            label: "海港区",
          },
          {
            code: "130303",
            value: "山海关区",
            label: "山海关区",
          },
          {
            code: "130304",
            value: "北戴河区",
            label: "北戴河区",
          },
          {
            code: "130306",
            value: "抚宁区",
            label: "抚宁区",
          },
          {
            code: "130321",
            value: "青龙满族自治县",
            label: "青龙满族自治县",
          },
          {
            code: "130322",
            value: "昌黎县",
            label: "昌黎县",
          },
          {
            code: "130324",
            value: "卢龙县",
            label: "卢龙县",
          },
        ],
      },
      {
        code: "130400",
        value: "邯郸市",
        label: "邯郸市",
        children: [
          {
            code: "130402",
            value: "邯山区",
            label: "邯山区",
          },
          {
            code: "130403",
            value: "丛台区",
            label: "丛台区",
          },
          {
            code: "130404",
            value: "复兴区",
            label: "复兴区",
          },
          {
            code: "130406",
            value: "峰峰矿区",
            label: "峰峰矿区",
          },
          {
            code: "130407",
            value: "肥乡区",
            label: "肥乡区",
          },
          {
            code: "130408",
            value: "永年区",
            label: "永年区",
          },
          {
            code: "130423",
            value: "临漳县",
            label: "临漳县",
          },
          {
            code: "130424",
            value: "成安县",
            label: "成安县",
          },
          {
            code: "130425",
            value: "大名县",
            label: "大名县",
          },
          {
            code: "130426",
            value: "涉县",
            label: "涉县",
          },
          {
            code: "130427",
            value: "磁县",
            label: "磁县",
          },
          {
            code: "130430",
            value: "邱县",
            label: "邱县",
          },
          {
            code: "130431",
            value: "鸡泽县",
            label: "鸡泽县",
          },
          {
            code: "130432",
            value: "广平县",
            label: "广平县",
          },
          {
            code: "130433",
            value: "馆陶县",
            label: "馆陶县",
          },
          {
            code: "130434",
            value: "魏县",
            label: "魏县",
          },
          {
            code: "130435",
            value: "曲周县",
            label: "曲周县",
          },
          {
            code: "130481",
            value: "武安市",
            label: "武安市",
          },
        ],
      },
      {
        code: "130500",
        value: "邢台市",
        label: "邢台市",
        children: [
          {
            code: "130502",
            value: "襄都区",
            label: "襄都区",
          },
          {
            code: "130503",
            value: "信都区",
            label: "信都区",
          },
          {
            code: "130505",
            value: "任泽区",
            label: "任泽区",
          },
          {
            code: "130506",
            value: "南和区",
            label: "南和区",
          },
          {
            code: "130522",
            value: "临城县",
            label: "临城县",
          },
          {
            code: "130523",
            value: "内丘县",
            label: "内丘县",
          },
          {
            code: "130524",
            value: "柏乡县",
            label: "柏乡县",
          },
          {
            code: "130525",
            value: "隆尧县",
            label: "隆尧县",
          },
          {
            code: "130528",
            value: "宁晋县",
            label: "宁晋县",
          },
          {
            code: "130529",
            value: "巨鹿县",
            label: "巨鹿县",
          },
          {
            code: "130530",
            value: "新河县",
            label: "新河县",
          },
          {
            code: "130531",
            value: "广宗县",
            label: "广宗县",
          },
          {
            code: "130532",
            value: "平乡县",
            label: "平乡县",
          },
          {
            code: "130533",
            value: "威县",
            label: "威县",
          },
          {
            code: "130534",
            value: "清河县",
            label: "清河县",
          },
          {
            code: "130535",
            value: "临西县",
            label: "临西县",
          },
          {
            code: "130581",
            value: "南宫市",
            label: "南宫市",
          },
          {
            code: "130582",
            value: "沙河市",
            label: "沙河市",
          },
        ],
      },
      {
        code: "130600",
        value: "保定市",
        label: "保定市",
        children: [
          {
            code: "130602",
            value: "竞秀区",
            label: "竞秀区",
          },
          {
            code: "130606",
            value: "莲池区",
            label: "莲池区",
          },
          {
            code: "130607",
            value: "满城区",
            label: "满城区",
          },
          {
            code: "130608",
            value: "清苑区",
            label: "清苑区",
          },
          {
            code: "130609",
            value: "徐水区",
            label: "徐水区",
          },
          {
            code: "130623",
            value: "涞水县",
            label: "涞水县",
          },
          {
            code: "130624",
            value: "阜平县",
            label: "阜平县",
          },
          {
            code: "130626",
            value: "定兴县",
            label: "定兴县",
          },
          {
            code: "130627",
            value: "唐县",
            label: "唐县",
          },
          {
            code: "130628",
            value: "高阳县",
            label: "高阳县",
          },
          {
            code: "130629",
            value: "容城县",
            label: "容城县",
          },
          {
            code: "130630",
            value: "涞源县",
            label: "涞源县",
          },
          {
            code: "130631",
            value: "望都县",
            label: "望都县",
          },
          {
            code: "130632",
            value: "安新县",
            label: "安新县",
          },
          {
            code: "130633",
            value: "易县",
            label: "易县",
          },
          {
            code: "130634",
            value: "曲阳县",
            label: "曲阳县",
          },
          {
            code: "130635",
            value: "蠡县",
            label: "蠡县",
          },
          {
            code: "130636",
            value: "顺平县",
            label: "顺平县",
          },
          {
            code: "130637",
            value: "博野县",
            label: "博野县",
          },
          {
            code: "130638",
            value: "雄县",
            label: "雄县",
          },
          {
            code: "130681",
            value: "涿州市",
            label: "涿州市",
          },
          {
            code: "130682",
            value: "定州市",
            label: "定州市",
          },
          {
            code: "130683",
            value: "安国市",
            label: "安国市",
          },
          {
            code: "130684",
            value: "高碑店市",
            label: "高碑店市",
          },
        ],
      },
      {
        code: "130700",
        value: "张家口市",
        label: "张家口市",
        children: [
          {
            code: "130702",
            value: "桥东区",
            label: "桥东区",
          },
          {
            code: "130703",
            value: "桥西区",
            label: "桥西区",
          },
          {
            code: "130705",
            value: "宣化区",
            label: "宣化区",
          },
          {
            code: "130706",
            value: "下花园区",
            label: "下花园区",
          },
          {
            code: "130708",
            value: "万全区",
            label: "万全区",
          },
          {
            code: "130709",
            value: "崇礼区",
            label: "崇礼区",
          },
          {
            code: "130722",
            value: "张北县",
            label: "张北县",
          },
          {
            code: "130723",
            value: "康保县",
            label: "康保县",
          },
          {
            code: "130724",
            value: "沽源县",
            label: "沽源县",
          },
          {
            code: "130725",
            value: "尚义县",
            label: "尚义县",
          },
          {
            code: "130726",
            value: "蔚县",
            label: "蔚县",
          },
          {
            code: "130727",
            value: "阳原县",
            label: "阳原县",
          },
          {
            code: "130728",
            value: "怀安县",
            label: "怀安县",
          },
          {
            code: "130730",
            value: "怀来县",
            label: "怀来县",
          },
          {
            code: "130731",
            value: "涿鹿县",
            label: "涿鹿县",
          },
          {
            code: "130732",
            value: "赤城县",
            label: "赤城县",
          },
        ],
      },
      {
        code: "130800",
        value: "承德市",
        label: "承德市",
        children: [
          {
            code: "130802",
            value: "双桥区",
            label: "双桥区",
          },
          {
            code: "130803",
            value: "双滦区",
            label: "双滦区",
          },
          {
            code: "130804",
            value: "鹰手营子矿区",
            label: "鹰手营子矿区",
          },
          {
            code: "130821",
            value: "承德县",
            label: "承德县",
          },
          {
            code: "130822",
            value: "兴隆县",
            label: "兴隆县",
          },
          {
            code: "130824",
            value: "滦平县",
            label: "滦平县",
          },
          {
            code: "130825",
            value: "隆化县",
            label: "隆化县",
          },
          {
            code: "130826",
            value: "丰宁满族自治县",
            label: "丰宁满族自治县",
          },
          {
            code: "130827",
            value: "宽城满族自治县",
            label: "宽城满族自治县",
          },
          {
            code: "130828",
            value: "围场满族蒙古族自治县",
            label: "围场满族蒙古族自治县",
          },
          {
            code: "130881",
            value: "平泉市",
            label: "平泉市",
          },
        ],
      },
      {
        code: "130900",
        value: "沧州市",
        label: "沧州市",
        children: [
          {
            code: "130902",
            value: "新华区",
            label: "新华区",
          },
          {
            code: "130903",
            value: "运河区",
            label: "运河区",
          },
          {
            code: "130921",
            value: "沧县",
            label: "沧县",
          },
          {
            code: "130922",
            value: "青县",
            label: "青县",
          },
          {
            code: "130923",
            value: "东光县",
            label: "东光县",
          },
          {
            code: "130924",
            value: "海兴县",
            label: "海兴县",
          },
          {
            code: "130925",
            value: "盐山县",
            label: "盐山县",
          },
          {
            code: "130926",
            value: "肃宁县",
            label: "肃宁县",
          },
          {
            code: "130927",
            value: "南皮县",
            label: "南皮县",
          },
          {
            code: "130928",
            value: "吴桥县",
            label: "吴桥县",
          },
          {
            code: "130929",
            value: "献县",
            label: "献县",
          },
          {
            code: "130930",
            value: "孟村回族自治县",
            label: "孟村回族自治县",
          },
          {
            code: "130981",
            value: "泊头市",
            label: "泊头市",
          },
          {
            code: "130982",
            value: "任丘市",
            label: "任丘市",
          },
          {
            code: "130983",
            value: "黄骅市",
            label: "黄骅市",
          },
          {
            code: "130984",
            value: "河间市",
            label: "河间市",
          },
        ],
      },
      {
        code: "131000",
        value: "廊坊市",
        label: "廊坊市",
        children: [
          {
            code: "131002",
            value: "安次区",
            label: "安次区",
          },
          {
            code: "131003",
            value: "广阳区",
            label: "广阳区",
          },
          {
            code: "131022",
            value: "固安县",
            label: "固安县",
          },
          {
            code: "131023",
            value: "永清县",
            label: "永清县",
          },
          {
            code: "131024",
            value: "香河县",
            label: "香河县",
          },
          {
            code: "131025",
            value: "大城县",
            label: "大城县",
          },
          {
            code: "131026",
            value: "文安县",
            label: "文安县",
          },
          {
            code: "131028",
            value: "大厂回族自治县",
            label: "大厂回族自治县",
          },
          {
            code: "131081",
            value: "霸州市",
            label: "霸州市",
          },
          {
            code: "131082",
            value: "三河市",
            label: "三河市",
          },
        ],
      },
      {
        code: "131100",
        value: "衡水市",
        label: "衡水市",
        children: [
          {
            code: "131102",
            value: "桃城区",
            label: "桃城区",
          },
          {
            code: "131103",
            value: "冀州区",
            label: "冀州区",
          },
          {
            code: "131121",
            value: "枣强县",
            label: "枣强县",
          },
          {
            code: "131122",
            value: "武邑县",
            label: "武邑县",
          },
          {
            code: "131123",
            value: "武强县",
            label: "武强县",
          },
          {
            code: "131124",
            value: "饶阳县",
            label: "饶阳县",
          },
          {
            code: "131125",
            value: "安平县",
            label: "安平县",
          },
          {
            code: "131126",
            value: "故城县",
            label: "故城县",
          },
          {
            code: "131127",
            value: "景县",
            label: "景县",
          },
          {
            code: "131128",
            value: "阜城县",
            label: "阜城县",
          },
          {
            code: "131182",
            value: "深州市",
            label: "深州市",
          },
        ],
      },
    ],
  },
  {
    code: "140000",
    value: "山西省",
    label: "山西省",
    children: [
      {
        code: "140100",
        value: "太原市",
        label: "太原市",
        children: [
          {
            code: "140105",
            value: "小店区",
            label: "小店区",
          },
          {
            code: "140106",
            value: "迎泽区",
            label: "迎泽区",
          },
          {
            code: "140107",
            value: "杏花岭区",
            label: "杏花岭区",
          },
          {
            code: "140108",
            value: "尖草坪区",
            label: "尖草坪区",
          },
          {
            code: "140109",
            value: "万柏林区",
            label: "万柏林区",
          },
          {
            code: "140110",
            value: "晋源区",
            label: "晋源区",
          },
          {
            code: "140121",
            value: "清徐县",
            label: "清徐县",
          },
          {
            code: "140122",
            value: "阳曲县",
            label: "阳曲县",
          },
          {
            code: "140123",
            value: "娄烦县",
            label: "娄烦县",
          },
          {
            code: "140181",
            value: "古交市",
            label: "古交市",
          },
        ],
      },
      {
        code: "140200",
        value: "大同市",
        label: "大同市",
        children: [
          {
            code: "140212",
            value: "新荣区",
            label: "新荣区",
          },
          {
            code: "140213",
            value: "平城区",
            label: "平城区",
          },
          {
            code: "140214",
            value: "云冈区",
            label: "云冈区",
          },
          {
            code: "140215",
            value: "云州区",
            label: "云州区",
          },
          {
            code: "140221",
            value: "阳高县",
            label: "阳高县",
          },
          {
            code: "140222",
            value: "天镇县",
            label: "天镇县",
          },
          {
            code: "140223",
            value: "广灵县",
            label: "广灵县",
          },
          {
            code: "140224",
            value: "灵丘县",
            label: "灵丘县",
          },
          {
            code: "140225",
            value: "浑源县",
            label: "浑源县",
          },
          {
            code: "140226",
            value: "左云县",
            label: "左云县",
          },
        ],
      },
      {
        code: "140300",
        value: "阳泉市",
        label: "阳泉市",
        children: [
          {
            code: "140302",
            value: "城区",
            label: "城区",
          },
          {
            code: "140303",
            value: "矿区",
            label: "矿区",
          },
          {
            code: "140311",
            value: "郊区",
            label: "郊区",
          },
          {
            code: "140321",
            value: "平定县",
            label: "平定县",
          },
          {
            code: "140322",
            value: "盂县",
            label: "盂县",
          },
        ],
      },
      {
        code: "140400",
        value: "长治市",
        label: "长治市",
        children: [
          {
            code: "140403",
            value: "潞州区",
            label: "潞州区",
          },
          {
            code: "140404",
            value: "上党区",
            label: "上党区",
          },
          {
            code: "140405",
            value: "屯留区",
            label: "屯留区",
          },
          {
            code: "140406",
            value: "潞城区",
            label: "潞城区",
          },
          {
            code: "140423",
            value: "襄垣县",
            label: "襄垣县",
          },
          {
            code: "140425",
            value: "平顺县",
            label: "平顺县",
          },
          {
            code: "140426",
            value: "黎城县",
            label: "黎城县",
          },
          {
            code: "140427",
            value: "壶关县",
            label: "壶关县",
          },
          {
            code: "140428",
            value: "长子县",
            label: "长子县",
          },
          {
            code: "140429",
            value: "武乡县",
            label: "武乡县",
          },
          {
            code: "140430",
            value: "沁县",
            label: "沁县",
          },
          {
            code: "140431",
            value: "沁源县",
            label: "沁源县",
          },
        ],
      },
      {
        code: "140500",
        value: "晋城市",
        label: "晋城市",
        children: [
          {
            code: "140502",
            value: "城区",
            label: "城区",
          },
          {
            code: "140521",
            value: "沁水县",
            label: "沁水县",
          },
          {
            code: "140522",
            value: "阳城县",
            label: "阳城县",
          },
          {
            code: "140524",
            value: "陵川县",
            label: "陵川县",
          },
          {
            code: "140525",
            value: "泽州县",
            label: "泽州县",
          },
          {
            code: "140581",
            value: "高平市",
            label: "高平市",
          },
        ],
      },
      {
        code: "140600",
        value: "朔州市",
        label: "朔州市",
        children: [
          {
            code: "140602",
            value: "朔城区",
            label: "朔城区",
          },
          {
            code: "140603",
            value: "平鲁区",
            label: "平鲁区",
          },
          {
            code: "140621",
            value: "山阴县",
            label: "山阴县",
          },
          {
            code: "140622",
            value: "应县",
            label: "应县",
          },
          {
            code: "140623",
            value: "右玉县",
            label: "右玉县",
          },
          {
            code: "140681",
            value: "怀仁市",
            label: "怀仁市",
          },
        ],
      },
      {
        code: "140700",
        value: "晋中市",
        label: "晋中市",
        children: [
          {
            code: "140702",
            value: "榆次区",
            label: "榆次区",
          },
          {
            code: "140703",
            value: "太谷区",
            label: "太谷区",
          },
          {
            code: "140721",
            value: "榆社县",
            label: "榆社县",
          },
          {
            code: "140722",
            value: "左权县",
            label: "左权县",
          },
          {
            code: "140723",
            value: "和顺县",
            label: "和顺县",
          },
          {
            code: "140724",
            value: "昔阳县",
            label: "昔阳县",
          },
          {
            code: "140725",
            value: "寿阳县",
            label: "寿阳县",
          },
          {
            code: "140727",
            value: "祁县",
            label: "祁县",
          },
          {
            code: "140728",
            value: "平遥县",
            label: "平遥县",
          },
          {
            code: "140729",
            value: "灵石县",
            label: "灵石县",
          },
          {
            code: "140781",
            value: "介休市",
            label: "介休市",
          },
        ],
      },
      {
        code: "140800",
        value: "运城市",
        label: "运城市",
        children: [
          {
            code: "140802",
            value: "盐湖区",
            label: "盐湖区",
          },
          {
            code: "140821",
            value: "临猗县",
            label: "临猗县",
          },
          {
            code: "140822",
            value: "万荣县",
            label: "万荣县",
          },
          {
            code: "140823",
            value: "闻喜县",
            label: "闻喜县",
          },
          {
            code: "140824",
            value: "稷山县",
            label: "稷山县",
          },
          {
            code: "140825",
            value: "新绛县",
            label: "新绛县",
          },
          {
            code: "140826",
            value: "绛县",
            label: "绛县",
          },
          {
            code: "140827",
            value: "垣曲县",
            label: "垣曲县",
          },
          {
            code: "140828",
            value: "夏县",
            label: "夏县",
          },
          {
            code: "140829",
            value: "平陆县",
            label: "平陆县",
          },
          {
            code: "140830",
            value: "芮城县",
            label: "芮城县",
          },
          {
            code: "140881",
            value: "永济市",
            label: "永济市",
          },
          {
            code: "140882",
            value: "河津市",
            label: "河津市",
          },
        ],
      },
      {
        code: "140900",
        value: "忻州市",
        label: "忻州市",
        children: [
          {
            code: "140902",
            value: "忻府区",
            label: "忻府区",
          },
          {
            code: "140921",
            value: "定襄县",
            label: "定襄县",
          },
          {
            code: "140922",
            value: "五台县",
            label: "五台县",
          },
          {
            code: "140923",
            value: "代县",
            label: "代县",
          },
          {
            code: "140924",
            value: "繁峙县",
            label: "繁峙县",
          },
          {
            code: "140925",
            value: "宁武县",
            label: "宁武县",
          },
          {
            code: "140926",
            value: "静乐县",
            label: "静乐县",
          },
          {
            code: "140927",
            value: "神池县",
            label: "神池县",
          },
          {
            code: "140928",
            value: "五寨县",
            label: "五寨县",
          },
          {
            code: "140929",
            value: "岢岚县",
            label: "岢岚县",
          },
          {
            code: "140930",
            value: "河曲县",
            label: "河曲县",
          },
          {
            code: "140931",
            value: "保德县",
            label: "保德县",
          },
          {
            code: "140932",
            value: "偏关县",
            label: "偏关县",
          },
          {
            code: "140981",
            value: "原平市",
            label: "原平市",
          },
        ],
      },
      {
        code: "141000",
        value: "临汾市",
        label: "临汾市",
        children: [
          {
            code: "141002",
            value: "尧都区",
            label: "尧都区",
          },
          {
            code: "141021",
            value: "曲沃县",
            label: "曲沃县",
          },
          {
            code: "141022",
            value: "翼城县",
            label: "翼城县",
          },
          {
            code: "141023",
            value: "襄汾县",
            label: "襄汾县",
          },
          {
            code: "141024",
            value: "洪洞县",
            label: "洪洞县",
          },
          {
            code: "141025",
            value: "古县",
            label: "古县",
          },
          {
            code: "141026",
            value: "安泽县",
            label: "安泽县",
          },
          {
            code: "141027",
            value: "浮山县",
            label: "浮山县",
          },
          {
            code: "141028",
            value: "吉县",
            label: "吉县",
          },
          {
            code: "141029",
            value: "乡宁县",
            label: "乡宁县",
          },
          {
            code: "141030",
            value: "大宁县",
            label: "大宁县",
          },
          {
            code: "141031",
            value: "隰县",
            label: "隰县",
          },
          {
            code: "141032",
            value: "永和县",
            label: "永和县",
          },
          {
            code: "141033",
            value: "蒲县",
            label: "蒲县",
          },
          {
            code: "141034",
            value: "汾西县",
            label: "汾西县",
          },
          {
            code: "141081",
            value: "侯马市",
            label: "侯马市",
          },
          {
            code: "141082",
            value: "霍州市",
            label: "霍州市",
          },
        ],
      },
      {
        code: "141100",
        value: "吕梁市",
        label: "吕梁市",
        children: [
          {
            code: "141102",
            value: "离石区",
            label: "离石区",
          },
          {
            code: "141121",
            value: "文水县",
            label: "文水县",
          },
          {
            code: "141122",
            value: "交城县",
            label: "交城县",
          },
          {
            code: "141123",
            value: "兴县",
            label: "兴县",
          },
          {
            code: "141124",
            value: "临县",
            label: "临县",
          },
          {
            code: "141125",
            value: "柳林县",
            label: "柳林县",
          },
          {
            code: "141126",
            value: "石楼县",
            label: "石楼县",
          },
          {
            code: "141127",
            value: "岚县",
            label: "岚县",
          },
          {
            code: "141128",
            value: "方山县",
            label: "方山县",
          },
          {
            code: "141129",
            value: "中阳县",
            label: "中阳县",
          },
          {
            code: "141130",
            value: "交口县",
            label: "交口县",
          },
          {
            code: "141181",
            value: "孝义市",
            label: "孝义市",
          },
          {
            code: "141182",
            value: "汾阳市",
            label: "汾阳市",
          },
        ],
      },
    ],
  },
  {
    code: "150000",
    value: "内蒙古自治区",
    label: "内蒙古自治区",
    children: [
      {
        code: "150100",
        value: "呼和浩特市",
        label: "呼和浩特市",
        children: [
          {
            code: "150102",
            value: "新城区",
            label: "新城区",
          },
          {
            code: "150103",
            value: "回民区",
            label: "回民区",
          },
          {
            code: "150104",
            value: "玉泉区",
            label: "玉泉区",
          },
          {
            code: "150105",
            value: "赛罕区",
            label: "赛罕区",
          },
          {
            code: "150121",
            value: "土默特左旗",
            label: "土默特左旗",
          },
          {
            code: "150122",
            value: "托克托县",
            label: "托克托县",
          },
          {
            code: "150123",
            value: "和林格尔县",
            label: "和林格尔县",
          },
          {
            code: "150124",
            value: "清水河县",
            label: "清水河县",
          },
          {
            code: "150125",
            value: "武川县",
            label: "武川县",
          },
        ],
      },
      {
        code: "150200",
        value: "包头市",
        label: "包头市",
        children: [
          {
            code: "150202",
            value: "东河区",
            label: "东河区",
          },
          {
            code: "150203",
            value: "昆都仑区",
            label: "昆都仑区",
          },
          {
            code: "150204",
            value: "青山区",
            label: "青山区",
          },
          {
            code: "150205",
            value: "石拐区",
            label: "石拐区",
          },
          {
            code: "150206",
            value: "白云鄂博矿区",
            label: "白云鄂博矿区",
          },
          {
            code: "150207",
            value: "九原区",
            label: "九原区",
          },
          {
            code: "150221",
            value: "土默特右旗",
            label: "土默特右旗",
          },
          {
            code: "150222",
            value: "固阳县",
            label: "固阳县",
          },
          {
            code: "150223",
            value: "达尔罕茂明安联合旗",
            label: "达尔罕茂明安联合旗",
          },
        ],
      },
      {
        code: "150300",
        value: "乌海市",
        label: "乌海市",
        children: [
          {
            code: "150302",
            value: "海勃湾区",
            label: "海勃湾区",
          },
          {
            code: "150303",
            value: "海南区",
            label: "海南区",
          },
          {
            code: "150304",
            value: "乌达区",
            label: "乌达区",
          },
        ],
      },
      {
        code: "150400",
        value: "赤峰市",
        label: "赤峰市",
        children: [
          {
            code: "150402",
            value: "红山区",
            label: "红山区",
          },
          {
            code: "150403",
            value: "元宝山区",
            label: "元宝山区",
          },
          {
            code: "150404",
            value: "松山区",
            label: "松山区",
          },
          {
            code: "150421",
            value: "阿鲁科尔沁旗",
            label: "阿鲁科尔沁旗",
          },
          {
            code: "150422",
            value: "巴林左旗",
            label: "巴林左旗",
          },
          {
            code: "150423",
            value: "巴林右旗",
            label: "巴林右旗",
          },
          {
            code: "150424",
            value: "林西县",
            label: "林西县",
          },
          {
            code: "150425",
            value: "克什克腾旗",
            label: "克什克腾旗",
          },
          {
            code: "150426",
            value: "翁牛特旗",
            label: "翁牛特旗",
          },
          {
            code: "150428",
            value: "喀喇沁旗",
            label: "喀喇沁旗",
          },
          {
            code: "150429",
            value: "宁城县",
            label: "宁城县",
          },
          {
            code: "150430",
            value: "敖汉旗",
            label: "敖汉旗",
          },
        ],
      },
      {
        code: "150500",
        value: "通辽市",
        label: "通辽市",
        children: [
          {
            code: "150502",
            value: "科尔沁区",
            label: "科尔沁区",
          },
          {
            code: "150521",
            value: "科尔沁左翼中旗",
            label: "科尔沁左翼中旗",
          },
          {
            code: "150522",
            value: "科尔沁左翼后旗",
            label: "科尔沁左翼后旗",
          },
          {
            code: "150523",
            value: "开鲁县",
            label: "开鲁县",
          },
          {
            code: "150524",
            value: "库伦旗",
            label: "库伦旗",
          },
          {
            code: "150525",
            value: "奈曼旗",
            label: "奈曼旗",
          },
          {
            code: "150526",
            value: "扎鲁特旗",
            label: "扎鲁特旗",
          },
          {
            code: "150581",
            value: "霍林郭勒市",
            label: "霍林郭勒市",
          },
        ],
      },
      {
        code: "150600",
        value: "鄂尔多斯市",
        label: "鄂尔多斯市",
        children: [
          {
            code: "150602",
            value: "东胜区",
            label: "东胜区",
          },
          {
            code: "150603",
            value: "康巴什区",
            label: "康巴什区",
          },
          {
            code: "150621",
            value: "达拉特旗",
            label: "达拉特旗",
          },
          {
            code: "150622",
            value: "准格尔旗",
            label: "准格尔旗",
          },
          {
            code: "150623",
            value: "鄂托克前旗",
            label: "鄂托克前旗",
          },
          {
            code: "150624",
            value: "鄂托克旗",
            label: "鄂托克旗",
          },
          {
            code: "150625",
            value: "杭锦旗",
            label: "杭锦旗",
          },
          {
            code: "150626",
            value: "乌审旗",
            label: "乌审旗",
          },
          {
            code: "150627",
            value: "伊金霍洛旗",
            label: "伊金霍洛旗",
          },
        ],
      },
      {
        code: "150700",
        value: "呼伦贝尔市",
        label: "呼伦贝尔市",
        children: [
          {
            code: "150702",
            value: "海拉尔区",
            label: "海拉尔区",
          },
          {
            code: "150703",
            value: "扎赉诺尔区",
            label: "扎赉诺尔区",
          },
          {
            code: "150721",
            value: "阿荣旗",
            label: "阿荣旗",
          },
          {
            code: "150722",
            value: "莫力达瓦达斡尔族自治旗",
            label: "莫力达瓦达斡尔族自治旗",
          },
          {
            code: "150723",
            value: "鄂伦春自治旗",
            label: "鄂伦春自治旗",
          },
          {
            code: "150724",
            value: "鄂温克族自治旗",
            label: "鄂温克族自治旗",
          },
          {
            code: "150725",
            value: "陈巴尔虎旗",
            label: "陈巴尔虎旗",
          },
          {
            code: "150726",
            value: "新巴尔虎左旗",
            label: "新巴尔虎左旗",
          },
          {
            code: "150727",
            value: "新巴尔虎右旗",
            label: "新巴尔虎右旗",
          },
          {
            code: "150781",
            value: "满洲里市",
            label: "满洲里市",
          },
          {
            code: "150782",
            value: "牙克石市",
            label: "牙克石市",
          },
          {
            code: "150783",
            value: "扎兰屯市",
            label: "扎兰屯市",
          },
          {
            code: "150784",
            value: "额尔古纳市",
            label: "额尔古纳市",
          },
          {
            code: "150785",
            value: "根河市",
            label: "根河市",
          },
        ],
      },
      {
        code: "150800",
        value: "巴彦淖尔市",
        label: "巴彦淖尔市",
        children: [
          {
            code: "150802",
            value: "临河区",
            label: "临河区",
          },
          {
            code: "150821",
            value: "五原县",
            label: "五原县",
          },
          {
            code: "150822",
            value: "磴口县",
            label: "磴口县",
          },
          {
            code: "150823",
            value: "乌拉特前旗",
            label: "乌拉特前旗",
          },
          {
            code: "150824",
            value: "乌拉特中旗",
            label: "乌拉特中旗",
          },
          {
            code: "150825",
            value: "乌拉特后旗",
            label: "乌拉特后旗",
          },
          {
            code: "150826",
            value: "杭锦后旗",
            label: "杭锦后旗",
          },
        ],
      },
      {
        code: "150900",
        value: "乌兰察布市",
        label: "乌兰察布市",
        children: [
          {
            code: "150902",
            value: "集宁区",
            label: "集宁区",
          },
          {
            code: "150921",
            value: "卓资县",
            label: "卓资县",
          },
          {
            code: "150922",
            value: "化德县",
            label: "化德县",
          },
          {
            code: "150923",
            value: "商都县",
            label: "商都县",
          },
          {
            code: "150924",
            value: "兴和县",
            label: "兴和县",
          },
          {
            code: "150925",
            value: "凉城县",
            label: "凉城县",
          },
          {
            code: "150926",
            value: "察哈尔右翼前旗",
            label: "察哈尔右翼前旗",
          },
          {
            code: "150927",
            value: "察哈尔右翼中旗",
            label: "察哈尔右翼中旗",
          },
          {
            code: "150928",
            value: "察哈尔右翼后旗",
            label: "察哈尔右翼后旗",
          },
          {
            code: "150929",
            value: "四子王旗",
            label: "四子王旗",
          },
          {
            code: "150981",
            value: "丰镇市",
            label: "丰镇市",
          },
        ],
      },
      {
        code: "152200",
        value: "兴安盟",
        label: "兴安盟",
        children: [
          {
            code: "152201",
            value: "乌兰浩特市",
            label: "乌兰浩特市",
          },
          {
            code: "152202",
            value: "阿尔山市",
            label: "阿尔山市",
          },
          {
            code: "152221",
            value: "科尔沁右翼前旗",
            label: "科尔沁右翼前旗",
          },
          {
            code: "152222",
            value: "科尔沁右翼中旗",
            label: "科尔沁右翼中旗",
          },
          {
            code: "152223",
            value: "扎赉特旗",
            label: "扎赉特旗",
          },
          {
            code: "152224",
            value: "突泉县",
            label: "突泉县",
          },
        ],
      },
      {
        code: "152500",
        value: "锡林郭勒盟",
        label: "锡林郭勒盟",
        children: [
          {
            code: "152501",
            value: "二连浩特市",
            label: "二连浩特市",
          },
          {
            code: "152502",
            value: "锡林浩特市",
            label: "锡林浩特市",
          },
          {
            code: "152522",
            value: "阿巴嘎旗",
            label: "阿巴嘎旗",
          },
          {
            code: "152523",
            value: "苏尼特左旗",
            label: "苏尼特左旗",
          },
          {
            code: "152524",
            value: "苏尼特右旗",
            label: "苏尼特右旗",
          },
          {
            code: "152525",
            value: "东乌珠穆沁旗",
            label: "东乌珠穆沁旗",
          },
          {
            code: "152526",
            value: "西乌珠穆沁旗",
            label: "西乌珠穆沁旗",
          },
          {
            code: "152527",
            value: "太仆寺旗",
            label: "太仆寺旗",
          },
          {
            code: "152528",
            value: "镶黄旗",
            label: "镶黄旗",
          },
          {
            code: "152529",
            value: "正镶白旗",
            label: "正镶白旗",
          },
          {
            code: "152530",
            value: "正蓝旗",
            label: "正蓝旗",
          },
          {
            code: "152531",
            value: "多伦县",
            label: "多伦县",
          },
        ],
      },
      {
        code: "152900",
        value: "阿拉善盟",
        label: "阿拉善盟",
        children: [
          {
            code: "152921",
            value: "阿拉善左旗",
            label: "阿拉善左旗",
          },
          {
            code: "152922",
            value: "阿拉善右旗",
            label: "阿拉善右旗",
          },
          {
            code: "152923",
            value: "额济纳旗",
            label: "额济纳旗",
          },
        ],
      },
    ],
  },
  {
    code: "210000",
    value: "辽宁省",
    label: "辽宁省",
    children: [
      {
        code: "210100",
        value: "沈阳市",
        label: "沈阳市",
        children: [
          {
            code: "210102",
            value: "和平区",
            label: "和平区",
          },
          {
            code: "210103",
            value: "沈河区",
            label: "沈河区",
          },
          {
            code: "210104",
            value: "大东区",
            label: "大东区",
          },
          {
            code: "210105",
            value: "皇姑区",
            label: "皇姑区",
          },
          {
            code: "210106",
            value: "铁西区",
            label: "铁西区",
          },
          {
            code: "210111",
            value: "苏家屯区",
            label: "苏家屯区",
          },
          {
            code: "210112",
            value: "浑南区",
            label: "浑南区",
          },
          {
            code: "210113",
            value: "沈北新区",
            label: "沈北新区",
          },
          {
            code: "210114",
            value: "于洪区",
            label: "于洪区",
          },
          {
            code: "210115",
            value: "辽中区",
            label: "辽中区",
          },
          {
            code: "210123",
            value: "康平县",
            label: "康平县",
          },
          {
            code: "210124",
            value: "法库县",
            label: "法库县",
          },
          {
            code: "210181",
            value: "新民市",
            label: "新民市",
          },
        ],
      },
      {
        code: "210200",
        value: "大连市",
        label: "大连市",
        children: [
          {
            code: "210202",
            value: "中山区",
            label: "中山区",
          },
          {
            code: "210203",
            value: "西岗区",
            label: "西岗区",
          },
          {
            code: "210204",
            value: "沙河口区",
            label: "沙河口区",
          },
          {
            code: "210211",
            value: "甘井子区",
            label: "甘井子区",
          },
          {
            code: "210212",
            value: "旅顺口区",
            label: "旅顺口区",
          },
          {
            code: "210213",
            value: "金州区",
            label: "金州区",
          },
          {
            code: "210214",
            value: "普兰店区",
            label: "普兰店区",
          },
          {
            code: "210224",
            value: "长海县",
            label: "长海县",
          },
          {
            code: "210281",
            value: "瓦房店市",
            label: "瓦房店市",
          },
          {
            code: "210283",
            value: "庄河市",
            label: "庄河市",
          },
        ],
      },
      {
        code: "210300",
        value: "鞍山市",
        label: "鞍山市",
        children: [
          {
            code: "210302",
            value: "铁东区",
            label: "铁东区",
          },
          {
            code: "210303",
            value: "铁西区",
            label: "铁西区",
          },
          {
            code: "210304",
            value: "立山区",
            label: "立山区",
          },
          {
            code: "210311",
            value: "千山区",
            label: "千山区",
          },
          {
            code: "210321",
            value: "台安县",
            label: "台安县",
          },
          {
            code: "210323",
            value: "岫岩满族自治县",
            label: "岫岩满族自治县",
          },
          {
            code: "210381",
            value: "海城市",
            label: "海城市",
          },
        ],
      },
      {
        code: "210400",
        value: "抚顺市",
        label: "抚顺市",
        children: [
          {
            code: "210402",
            value: "新抚区",
            label: "新抚区",
          },
          {
            code: "210403",
            value: "东洲区",
            label: "东洲区",
          },
          {
            code: "210404",
            value: "望花区",
            label: "望花区",
          },
          {
            code: "210411",
            value: "顺城区",
            label: "顺城区",
          },
          {
            code: "210421",
            value: "抚顺县",
            label: "抚顺县",
          },
          {
            code: "210422",
            value: "新宾满族自治县",
            label: "新宾满族自治县",
          },
          {
            code: "210423",
            value: "清原满族自治县",
            label: "清原满族自治县",
          },
        ],
      },
      {
        code: "210500",
        value: "本溪市",
        label: "本溪市",
        children: [
          {
            code: "210502",
            value: "平山区",
            label: "平山区",
          },
          {
            code: "210503",
            value: "溪湖区",
            label: "溪湖区",
          },
          {
            code: "210504",
            value: "明山区",
            label: "明山区",
          },
          {
            code: "210505",
            value: "南芬区",
            label: "南芬区",
          },
          {
            code: "210521",
            value: "本溪满族自治县",
            label: "本溪满族自治县",
          },
          {
            code: "210522",
            value: "桓仁满族自治县",
            label: "桓仁满族自治县",
          },
        ],
      },
      {
        code: "210600",
        value: "丹东市",
        label: "丹东市",
        children: [
          {
            code: "210602",
            value: "元宝区",
            label: "元宝区",
          },
          {
            code: "210603",
            value: "振兴区",
            label: "振兴区",
          },
          {
            code: "210604",
            value: "振安区",
            label: "振安区",
          },
          {
            code: "210624",
            value: "宽甸满族自治县",
            label: "宽甸满族自治县",
          },
          {
            code: "210681",
            value: "东港市",
            label: "东港市",
          },
          {
            code: "210682",
            value: "凤城市",
            label: "凤城市",
          },
        ],
      },
      {
        code: "210700",
        value: "锦州市",
        label: "锦州市",
        children: [
          {
            code: "210702",
            value: "古塔区",
            label: "古塔区",
          },
          {
            code: "210703",
            value: "凌河区",
            label: "凌河区",
          },
          {
            code: "210711",
            value: "太和区",
            label: "太和区",
          },
          {
            code: "210726",
            value: "黑山县",
            label: "黑山县",
          },
          {
            code: "210727",
            value: "义县",
            label: "义县",
          },
          {
            code: "210781",
            value: "凌海市",
            label: "凌海市",
          },
          {
            code: "210782",
            value: "北镇市",
            label: "北镇市",
          },
        ],
      },
      {
        code: "210800",
        value: "营口市",
        label: "营口市",
        children: [
          {
            code: "210802",
            value: "站前区",
            label: "站前区",
          },
          {
            code: "210803",
            value: "西市区",
            label: "西市区",
          },
          {
            code: "210804",
            value: "鲅鱼圈区",
            label: "鲅鱼圈区",
          },
          {
            code: "210811",
            value: "老边区",
            label: "老边区",
          },
          {
            code: "210881",
            value: "盖州市",
            label: "盖州市",
          },
          {
            code: "210882",
            value: "大石桥市",
            label: "大石桥市",
          },
        ],
      },
      {
        code: "210900",
        value: "阜新市",
        label: "阜新市",
        children: [
          {
            code: "210902",
            value: "海州区",
            label: "海州区",
          },
          {
            code: "210903",
            value: "新邱区",
            label: "新邱区",
          },
          {
            code: "210904",
            value: "太平区",
            label: "太平区",
          },
          {
            code: "210905",
            value: "清河门区",
            label: "清河门区",
          },
          {
            code: "210911",
            value: "细河区",
            label: "细河区",
          },
          {
            code: "210921",
            value: "阜新蒙古族自治县",
            label: "阜新蒙古族自治县",
          },
          {
            code: "210922",
            value: "彰武县",
            label: "彰武县",
          },
        ],
      },
      {
        code: "211000",
        value: "辽阳市",
        label: "辽阳市",
        children: [
          {
            code: "211002",
            value: "白塔区",
            label: "白塔区",
          },
          {
            code: "211003",
            value: "文圣区",
            label: "文圣区",
          },
          {
            code: "211004",
            value: "宏伟区",
            label: "宏伟区",
          },
          {
            code: "211005",
            value: "弓长岭区",
            label: "弓长岭区",
          },
          {
            code: "211011",
            value: "太子河区",
            label: "太子河区",
          },
          {
            code: "211021",
            value: "辽阳县",
            label: "辽阳县",
          },
          {
            code: "211081",
            value: "灯塔市",
            label: "灯塔市",
          },
        ],
      },
      {
        code: "211100",
        value: "盘锦市",
        label: "盘锦市",
        children: [
          {
            code: "211102",
            value: "双台子区",
            label: "双台子区",
          },
          {
            code: "211103",
            value: "兴隆台区",
            label: "兴隆台区",
          },
          {
            code: "211104",
            value: "大洼区",
            label: "大洼区",
          },
          {
            code: "211122",
            value: "盘山县",
            label: "盘山县",
          },
        ],
      },
      {
        code: "211200",
        value: "铁岭市",
        label: "铁岭市",
        children: [
          {
            code: "211202",
            value: "银州区",
            label: "银州区",
          },
          {
            code: "211204",
            value: "清河区",
            label: "清河区",
          },
          {
            code: "211221",
            value: "铁岭县",
            label: "铁岭县",
          },
          {
            code: "211223",
            value: "西丰县",
            label: "西丰县",
          },
          {
            code: "211224",
            value: "昌图县",
            label: "昌图县",
          },
          {
            code: "211281",
            value: "调兵山市",
            label: "调兵山市",
          },
          {
            code: "211282",
            value: "开原市",
            label: "开原市",
          },
        ],
      },
      {
        code: "211300",
        value: "朝阳市",
        label: "朝阳市",
        children: [
          {
            code: "211302",
            value: "双塔区",
            label: "双塔区",
          },
          {
            code: "211303",
            value: "龙城区",
            label: "龙城区",
          },
          {
            code: "211321",
            value: "朝阳县",
            label: "朝阳县",
          },
          {
            code: "211322",
            value: "建平县",
            label: "建平县",
          },
          {
            code: "211324",
            value: "喀喇沁左翼蒙古族自治县",
            label: "喀喇沁左翼蒙古族自治县",
          },
          {
            code: "211381",
            value: "北票市",
            label: "北票市",
          },
          {
            code: "211382",
            value: "凌源市",
            label: "凌源市",
          },
        ],
      },
      {
        code: "211400",
        value: "葫芦岛市",
        label: "葫芦岛市",
        children: [
          {
            code: "211402",
            value: "连山区",
            label: "连山区",
          },
          {
            code: "211403",
            value: "龙港区",
            label: "龙港区",
          },
          {
            code: "211404",
            value: "南票区",
            label: "南票区",
          },
          {
            code: "211421",
            value: "绥中县",
            label: "绥中县",
          },
          {
            code: "211422",
            value: "建昌县",
            label: "建昌县",
          },
          {
            code: "211481",
            value: "兴城市",
            label: "兴城市",
          },
        ],
      },
    ],
  },
  {
    code: "220000",
    value: "吉林省",
    label: "吉林省",
    children: [
      {
        code: "220100",
        value: "长春市",
        label: "长春市",
        children: [
          {
            code: "220102",
            value: "南关区",
            label: "南关区",
          },
          {
            code: "220103",
            value: "宽城区",
            label: "宽城区",
          },
          {
            code: "220104",
            value: "朝阳区",
            label: "朝阳区",
          },
          {
            code: "220105",
            value: "二道区",
            label: "二道区",
          },
          {
            code: "220106",
            value: "绿园区",
            label: "绿园区",
          },
          {
            code: "220112",
            value: "双阳区",
            label: "双阳区",
          },
          {
            code: "220113",
            value: "九台区",
            label: "九台区",
          },
          {
            code: "220122",
            value: "农安县",
            label: "农安县",
          },
          {
            code: "220182",
            value: "榆树市",
            label: "榆树市",
          },
          {
            code: "220183",
            value: "德惠市",
            label: "德惠市",
          },
          {
            code: "220184",
            value: "公主岭市",
            label: "公主岭市",
          },
        ],
      },
      {
        code: "220200",
        value: "吉林市",
        label: "吉林市",
        children: [
          {
            code: "220202",
            value: "昌邑区",
            label: "昌邑区",
          },
          {
            code: "220203",
            value: "龙潭区",
            label: "龙潭区",
          },
          {
            code: "220204",
            value: "船营区",
            label: "船营区",
          },
          {
            code: "220211",
            value: "丰满区",
            label: "丰满区",
          },
          {
            code: "220221",
            value: "永吉县",
            label: "永吉县",
          },
          {
            code: "220281",
            value: "蛟河市",
            label: "蛟河市",
          },
          {
            code: "220282",
            value: "桦甸市",
            label: "桦甸市",
          },
          {
            code: "220283",
            value: "舒兰市",
            label: "舒兰市",
          },
          {
            code: "220284",
            value: "磐石市",
            label: "磐石市",
          },
        ],
      },
      {
        code: "220300",
        value: "四平市",
        label: "四平市",
        children: [
          {
            code: "220302",
            value: "铁西区",
            label: "铁西区",
          },
          {
            code: "220303",
            value: "铁东区",
            label: "铁东区",
          },
          {
            code: "220322",
            value: "梨树县",
            label: "梨树县",
          },
          {
            code: "220323",
            value: "伊通满族自治县",
            label: "伊通满族自治县",
          },
          {
            code: "220382",
            value: "双辽市",
            label: "双辽市",
          },
        ],
      },
      {
        code: "220400",
        value: "辽源市",
        label: "辽源市",
        children: [
          {
            code: "220402",
            value: "龙山区",
            label: "龙山区",
          },
          {
            code: "220403",
            value: "西安区",
            label: "西安区",
          },
          {
            code: "220421",
            value: "东丰县",
            label: "东丰县",
          },
          {
            code: "220422",
            value: "东辽县",
            label: "东辽县",
          },
        ],
      },
      {
        code: "220500",
        value: "通化市",
        label: "通化市",
        children: [
          {
            code: "220502",
            value: "东昌区",
            label: "东昌区",
          },
          {
            code: "220503",
            value: "二道江区",
            label: "二道江区",
          },
          {
            code: "220521",
            value: "通化县",
            label: "通化县",
          },
          {
            code: "220523",
            value: "辉南县",
            label: "辉南县",
          },
          {
            code: "220524",
            value: "柳河县",
            label: "柳河县",
          },
          {
            code: "220581",
            value: "梅河口市",
            label: "梅河口市",
          },
          {
            code: "220582",
            value: "集安市",
            label: "集安市",
          },
        ],
      },
      {
        code: "220600",
        value: "白山市",
        label: "白山市",
        children: [
          {
            code: "220602",
            value: "浑江区",
            label: "浑江区",
          },
          {
            code: "220605",
            value: "江源区",
            label: "江源区",
          },
          {
            code: "220621",
            value: "抚松县",
            label: "抚松县",
          },
          {
            code: "220622",
            value: "靖宇县",
            label: "靖宇县",
          },
          {
            code: "220623",
            value: "长白朝鲜族自治县",
            label: "长白朝鲜族自治县",
          },
          {
            code: "220681",
            value: "临江市",
            label: "临江市",
          },
        ],
      },
      {
        code: "220700",
        value: "松原市",
        label: "松原市",
        children: [
          {
            code: "220702",
            value: "宁江区",
            label: "宁江区",
          },
          {
            code: "220721",
            value: "前郭尔罗斯蒙古族自治县",
            label: "前郭尔罗斯蒙古族自治县",
          },
          {
            code: "220722",
            value: "长岭县",
            label: "长岭县",
          },
          {
            code: "220723",
            value: "乾安县",
            label: "乾安县",
          },
          {
            code: "220781",
            value: "扶余市",
            label: "扶余市",
          },
        ],
      },
      {
        code: "220800",
        value: "白城市",
        label: "白城市",
        children: [
          {
            code: "220802",
            value: "洮北区",
            label: "洮北区",
          },
          {
            code: "220821",
            value: "镇赉县",
            label: "镇赉县",
          },
          {
            code: "220822",
            value: "通榆县",
            label: "通榆县",
          },
          {
            code: "220881",
            value: "洮南市",
            label: "洮南市",
          },
          {
            code: "220882",
            value: "大安市",
            label: "大安市",
          },
        ],
      },
      {
        code: "222400",
        value: "延边朝鲜族自治州",
        label: "延边朝鲜族自治州",
        children: [
          {
            code: "222401",
            value: "延吉市",
            label: "延吉市",
          },
          {
            code: "222402",
            value: "图们市",
            label: "图们市",
          },
          {
            code: "222403",
            value: "敦化市",
            label: "敦化市",
          },
          {
            code: "222404",
            value: "珲春市",
            label: "珲春市",
          },
          {
            code: "222405",
            value: "龙井市",
            label: "龙井市",
          },
          {
            code: "222406",
            value: "和龙市",
            label: "和龙市",
          },
          {
            code: "222424",
            value: "汪清县",
            label: "汪清县",
          },
          {
            code: "222426",
            value: "安图县",
            label: "安图县",
          },
        ],
      },
    ],
  },
  {
    code: "230000",
    value: "黑龙江省",
    label: "黑龙江省",
    children: [
      {
        code: "230100",
        value: "哈尔滨市",
        label: "哈尔滨市",
        children: [
          {
            code: "230102",
            value: "道里区",
            label: "道里区",
          },
          {
            code: "230103",
            value: "南岗区",
            label: "南岗区",
          },
          {
            code: "230104",
            value: "道外区",
            label: "道外区",
          },
          {
            code: "230108",
            value: "平房区",
            label: "平房区",
          },
          {
            code: "230109",
            value: "松北区",
            label: "松北区",
          },
          {
            code: "230110",
            value: "香坊区",
            label: "香坊区",
          },
          {
            code: "230111",
            value: "呼兰区",
            label: "呼兰区",
          },
          {
            code: "230112",
            value: "阿城区",
            label: "阿城区",
          },
          {
            code: "230113",
            value: "双城区",
            label: "双城区",
          },
          {
            code: "230123",
            value: "依兰县",
            label: "依兰县",
          },
          {
            code: "230124",
            value: "方正县",
            label: "方正县",
          },
          {
            code: "230125",
            value: "宾县",
            label: "宾县",
          },
          {
            code: "230126",
            value: "巴彦县",
            label: "巴彦县",
          },
          {
            code: "230127",
            value: "木兰县",
            label: "木兰县",
          },
          {
            code: "230128",
            value: "通河县",
            label: "通河县",
          },
          {
            code: "230129",
            value: "延寿县",
            label: "延寿县",
          },
          {
            code: "230183",
            value: "尚志市",
            label: "尚志市",
          },
          {
            code: "230184",
            value: "五常市",
            label: "五常市",
          },
        ],
      },
      {
        code: "230200",
        value: "齐齐哈尔市",
        label: "齐齐哈尔市",
        children: [
          {
            code: "230202",
            value: "龙沙区",
            label: "龙沙区",
          },
          {
            code: "230203",
            value: "建华区",
            label: "建华区",
          },
          {
            code: "230204",
            value: "铁锋区",
            label: "铁锋区",
          },
          {
            code: "230205",
            value: "昂昂溪区",
            label: "昂昂溪区",
          },
          {
            code: "230206",
            value: "富拉尔基区",
            label: "富拉尔基区",
          },
          {
            code: "230207",
            value: "碾子山区",
            label: "碾子山区",
          },
          {
            code: "230208",
            value: "梅里斯达斡尔族区",
            label: "梅里斯达斡尔族区",
          },
          {
            code: "230221",
            value: "龙江县",
            label: "龙江县",
          },
          {
            code: "230223",
            value: "依安县",
            label: "依安县",
          },
          {
            code: "230224",
            value: "泰来县",
            label: "泰来县",
          },
          {
            code: "230225",
            value: "甘南县",
            label: "甘南县",
          },
          {
            code: "230227",
            value: "富裕县",
            label: "富裕县",
          },
          {
            code: "230229",
            value: "克山县",
            label: "克山县",
          },
          {
            code: "230230",
            value: "克东县",
            label: "克东县",
          },
          {
            code: "230231",
            value: "拜泉县",
            label: "拜泉县",
          },
          {
            code: "230281",
            value: "讷河市",
            label: "讷河市",
          },
        ],
      },
      {
        code: "230300",
        value: "鸡西市",
        label: "鸡西市",
        children: [
          {
            code: "230302",
            value: "鸡冠区",
            label: "鸡冠区",
          },
          {
            code: "230303",
            value: "恒山区",
            label: "恒山区",
          },
          {
            code: "230304",
            value: "滴道区",
            label: "滴道区",
          },
          {
            code: "230305",
            value: "梨树区",
            label: "梨树区",
          },
          {
            code: "230306",
            value: "城子河区",
            label: "城子河区",
          },
          {
            code: "230307",
            value: "麻山区",
            label: "麻山区",
          },
          {
            code: "230321",
            value: "鸡东县",
            label: "鸡东县",
          },
          {
            code: "230381",
            value: "虎林市",
            label: "虎林市",
          },
          {
            code: "230382",
            value: "密山市",
            label: "密山市",
          },
        ],
      },
      {
        code: "230400",
        value: "鹤岗市",
        label: "鹤岗市",
        children: [
          {
            code: "230402",
            value: "向阳区",
            label: "向阳区",
          },
          {
            code: "230403",
            value: "工农区",
            label: "工农区",
          },
          {
            code: "230404",
            value: "南山区",
            label: "南山区",
          },
          {
            code: "230405",
            value: "兴安区",
            label: "兴安区",
          },
          {
            code: "230406",
            value: "东山区",
            label: "东山区",
          },
          {
            code: "230407",
            value: "兴山区",
            label: "兴山区",
          },
          {
            code: "230421",
            value: "萝北县",
            label: "萝北县",
          },
          {
            code: "230422",
            value: "绥滨县",
            label: "绥滨县",
          },
        ],
      },
      {
        code: "230500",
        value: "双鸭山市",
        label: "双鸭山市",
        children: [
          {
            code: "230502",
            value: "尖山区",
            label: "尖山区",
          },
          {
            code: "230503",
            value: "岭东区",
            label: "岭东区",
          },
          {
            code: "230505",
            value: "四方台区",
            label: "四方台区",
          },
          {
            code: "230506",
            value: "宝山区",
            label: "宝山区",
          },
          {
            code: "230521",
            value: "集贤县",
            label: "集贤县",
          },
          {
            code: "230522",
            value: "友谊县",
            label: "友谊县",
          },
          {
            code: "230523",
            value: "宝清县",
            label: "宝清县",
          },
          {
            code: "230524",
            value: "饶河县",
            label: "饶河县",
          },
        ],
      },
      {
        code: "230600",
        value: "大庆市",
        label: "大庆市",
        children: [
          {
            code: "230602",
            value: "萨尔图区",
            label: "萨尔图区",
          },
          {
            code: "230603",
            value: "龙凤区",
            label: "龙凤区",
          },
          {
            code: "230604",
            value: "让胡路区",
            label: "让胡路区",
          },
          {
            code: "230605",
            value: "红岗区",
            label: "红岗区",
          },
          {
            code: "230606",
            value: "大同区",
            label: "大同区",
          },
          {
            code: "230621",
            value: "肇州县",
            label: "肇州县",
          },
          {
            code: "230622",
            value: "肇源县",
            label: "肇源县",
          },
          {
            code: "230623",
            value: "林甸县",
            label: "林甸县",
          },
          {
            code: "230624",
            value: "杜尔伯特蒙古族自治县",
            label: "杜尔伯特蒙古族自治县",
          },
        ],
      },
      {
        code: "230700",
        value: "伊春市",
        label: "伊春市",
        children: [
          {
            code: "230717",
            value: "伊美区",
            label: "伊美区",
          },
          {
            code: "230718",
            value: "乌翠区",
            label: "乌翠区",
          },
          {
            code: "230719",
            value: "友好区",
            label: "友好区",
          },
          {
            code: "230722",
            value: "嘉荫县",
            label: "嘉荫县",
          },
          {
            code: "230723",
            value: "汤旺县",
            label: "汤旺县",
          },
          {
            code: "230724",
            value: "丰林县",
            label: "丰林县",
          },
          {
            code: "230725",
            value: "大箐山县",
            label: "大箐山县",
          },
          {
            code: "230726",
            value: "南岔县",
            label: "南岔县",
          },
          {
            code: "230751",
            value: "金林区",
            label: "金林区",
          },
          {
            code: "230781",
            value: "铁力市",
            label: "铁力市",
          },
        ],
      },
      {
        code: "230800",
        value: "佳木斯市",
        label: "佳木斯市",
        children: [
          {
            code: "230803",
            value: "向阳区",
            label: "向阳区",
          },
          {
            code: "230804",
            value: "前进区",
            label: "前进区",
          },
          {
            code: "230805",
            value: "东风区",
            label: "东风区",
          },
          {
            code: "230811",
            value: "郊区",
            label: "郊区",
          },
          {
            code: "230822",
            value: "桦南县",
            label: "桦南县",
          },
          {
            code: "230826",
            value: "桦川县",
            label: "桦川县",
          },
          {
            code: "230828",
            value: "汤原县",
            label: "汤原县",
          },
          {
            code: "230881",
            value: "同江市",
            label: "同江市",
          },
          {
            code: "230882",
            value: "富锦市",
            label: "富锦市",
          },
          {
            code: "230883",
            value: "抚远市",
            label: "抚远市",
          },
        ],
      },
      {
        code: "230900",
        value: "七台河市",
        label: "七台河市",
        children: [
          {
            code: "230902",
            value: "新兴区",
            label: "新兴区",
          },
          {
            code: "230903",
            value: "桃山区",
            label: "桃山区",
          },
          {
            code: "230904",
            value: "茄子河区",
            label: "茄子河区",
          },
          {
            code: "230921",
            value: "勃利县",
            label: "勃利县",
          },
        ],
      },
      {
        code: "231000",
        value: "牡丹江市",
        label: "牡丹江市",
        children: [
          {
            code: "231002",
            value: "东安区",
            label: "东安区",
          },
          {
            code: "231003",
            value: "阳明区",
            label: "阳明区",
          },
          {
            code: "231004",
            value: "爱民区",
            label: "爱民区",
          },
          {
            code: "231005",
            value: "西安区",
            label: "西安区",
          },
          {
            code: "231025",
            value: "林口县",
            label: "林口县",
          },
          {
            code: "231081",
            value: "绥芬河市",
            label: "绥芬河市",
          },
          {
            code: "231083",
            value: "海林市",
            label: "海林市",
          },
          {
            code: "231084",
            value: "宁安市",
            label: "宁安市",
          },
          {
            code: "231085",
            value: "穆棱市",
            label: "穆棱市",
          },
          {
            code: "231086",
            value: "东宁市",
            label: "东宁市",
          },
        ],
      },
      {
        code: "231100",
        value: "黑河市",
        label: "黑河市",
        children: [
          {
            code: "231102",
            value: "爱辉区",
            label: "爱辉区",
          },
          {
            code: "231123",
            value: "逊克县",
            label: "逊克县",
          },
          {
            code: "231124",
            value: "孙吴县",
            label: "孙吴县",
          },
          {
            code: "231181",
            value: "北安市",
            label: "北安市",
          },
          {
            code: "231182",
            value: "五大连池市",
            label: "五大连池市",
          },
          {
            code: "231183",
            value: "嫩江市",
            label: "嫩江市",
          },
        ],
      },
      {
        code: "231200",
        value: "绥化市",
        label: "绥化市",
        children: [
          {
            code: "231202",
            value: "北林区",
            label: "北林区",
          },
          {
            code: "231221",
            value: "望奎县",
            label: "望奎县",
          },
          {
            code: "231222",
            value: "兰西县",
            label: "兰西县",
          },
          {
            code: "231223",
            value: "青冈县",
            label: "青冈县",
          },
          {
            code: "231224",
            value: "庆安县",
            label: "庆安县",
          },
          {
            code: "231225",
            value: "明水县",
            label: "明水县",
          },
          {
            code: "231226",
            value: "绥棱县",
            label: "绥棱县",
          },
          {
            code: "231281",
            value: "安达市",
            label: "安达市",
          },
          {
            code: "231282",
            value: "肇东市",
            label: "肇东市",
          },
          {
            code: "231283",
            value: "海伦市",
            label: "海伦市",
          },
        ],
      },
      {
        code: "232700",
        value: "大兴安岭地区",
        label: "大兴安岭地区",
        children: [
          {
            code: "232701",
            value: "漠河市",
            label: "漠河市",
          },
          {
            code: "232721",
            value: "呼玛县",
            label: "呼玛县",
          },
          {
            code: "232722",
            value: "塔河县",
            label: "塔河县",
          },
        ],
      },
    ],
  },
  {
    code: "310000",
    value: "上海市",
    label: "上海市",
    children: [
      {
        code: "310101",
        value: "黄浦区",
        label: "黄浦区",
      },
      {
        code: "310104",
        value: "徐汇区",
        label: "徐汇区",
      },
      {
        code: "310105",
        value: "长宁区",
        label: "长宁区",
      },
      {
        code: "310106",
        value: "静安区",
        label: "静安区",
      },
      {
        code: "310107",
        value: "普陀区",
        label: "普陀区",
      },
      {
        code: "310109",
        value: "虹口区",
        label: "虹口区",
      },
      {
        code: "310110",
        value: "杨浦区",
        label: "杨浦区",
      },
      {
        code: "310112",
        value: "闵行区",
        label: "闵行区",
      },
      {
        code: "310113",
        value: "宝山区",
        label: "宝山区",
      },
      {
        code: "310114",
        value: "嘉定区",
        label: "嘉定区",
      },
      {
        code: "310115",
        value: "浦东新区",
        label: "浦东新区",
      },
      {
        code: "310116",
        value: "金山区",
        label: "金山区",
      },
      {
        code: "310117",
        value: "松江区",
        label: "松江区",
      },
      {
        code: "310118",
        value: "青浦区",
        label: "青浦区",
      },
      {
        code: "310120",
        value: "奉贤区",
        label: "奉贤区",
      },
      {
        code: "310151",
        value: "崇明区",
        label: "崇明区",
      },
    ],
  },
  {
    code: "320000",
    value: "江苏省",
    label: "江苏省",
    children: [
      {
        code: "320100",
        value: "南京市",
        label: "南京市",
        children: [
          {
            code: "320102",
            value: "玄武区",
            label: "玄武区",
          },
          {
            code: "320104",
            value: "秦淮区",
            label: "秦淮区",
          },
          {
            code: "320105",
            value: "建邺区",
            label: "建邺区",
          },
          {
            code: "320106",
            value: "鼓楼区",
            label: "鼓楼区",
          },
          {
            code: "320111",
            value: "浦口区",
            label: "浦口区",
          },
          {
            code: "320113",
            value: "栖霞区",
            label: "栖霞区",
          },
          {
            code: "320114",
            value: "雨花台区",
            label: "雨花台区",
          },
          {
            code: "320115",
            value: "江宁区",
            label: "江宁区",
          },
          {
            code: "320116",
            value: "六合区",
            label: "六合区",
          },
          {
            code: "320117",
            value: "溧水区",
            label: "溧水区",
          },
          {
            code: "320118",
            value: "高淳区",
            label: "高淳区",
          },
        ],
      },
      {
        code: "320200",
        value: "无锡市",
        label: "无锡市",
        children: [
          {
            code: "320205",
            value: "锡山区",
            label: "锡山区",
          },
          {
            code: "320206",
            value: "惠山区",
            label: "惠山区",
          },
          {
            code: "320211",
            value: "滨湖区",
            label: "滨湖区",
          },
          {
            code: "320213",
            value: "梁溪区",
            label: "梁溪区",
          },
          {
            code: "320214",
            value: "新吴区",
            label: "新吴区",
          },
          {
            code: "320281",
            value: "江阴市",
            label: "江阴市",
          },
          {
            code: "320282",
            value: "宜兴市",
            label: "宜兴市",
          },
        ],
      },
      {
        code: "320300",
        value: "徐州市",
        label: "徐州市",
        children: [
          {
            code: "320302",
            value: "鼓楼区",
            label: "鼓楼区",
          },
          {
            code: "320303",
            value: "云龙区",
            label: "云龙区",
          },
          {
            code: "320305",
            value: "贾汪区",
            label: "贾汪区",
          },
          {
            code: "320311",
            value: "泉山区",
            label: "泉山区",
          },
          {
            code: "320312",
            value: "铜山区",
            label: "铜山区",
          },
          {
            code: "320321",
            value: "丰县",
            label: "丰县",
          },
          {
            code: "320322",
            value: "沛县",
            label: "沛县",
          },
          {
            code: "320324",
            value: "睢宁县",
            label: "睢宁县",
          },
          {
            code: "320381",
            value: "新沂市",
            label: "新沂市",
          },
          {
            code: "320382",
            value: "邳州市",
            label: "邳州市",
          },
        ],
      },
      {
        code: "320400",
        value: "常州市",
        label: "常州市",
        children: [
          {
            code: "320402",
            value: "天宁区",
            label: "天宁区",
          },
          {
            code: "320404",
            value: "钟楼区",
            label: "钟楼区",
          },
          {
            code: "320411",
            value: "新北区",
            label: "新北区",
          },
          {
            code: "320412",
            value: "武进区",
            label: "武进区",
          },
          {
            code: "320413",
            value: "金坛区",
            label: "金坛区",
          },
          {
            code: "320481",
            value: "溧阳市",
            label: "溧阳市",
          },
        ],
      },
      {
        code: "320500",
        value: "苏州市",
        label: "苏州市",
        children: [
          {
            code: "320505",
            value: "虎丘区",
            label: "虎丘区",
          },
          {
            code: "320506",
            value: "吴中区",
            label: "吴中区",
          },
          {
            code: "320507",
            value: "相城区",
            label: "相城区",
          },
          {
            code: "320508",
            value: "姑苏区",
            label: "姑苏区",
          },
          {
            code: "320509",
            value: "吴江区",
            label: "吴江区",
          },
          {
            code: "320581",
            value: "常熟市",
            label: "常熟市",
          },
          {
            code: "320582",
            value: "张家港市",
            label: "张家港市",
          },
          {
            code: "320583",
            value: "昆山市",
            label: "昆山市",
          },
          {
            code: "320585",
            value: "太仓市",
            label: "太仓市",
          },
        ],
      },
      {
        code: "320600",
        value: "南通市",
        label: "南通市",
        children: [
          {
            code: "320612",
            value: "通州区",
            label: "通州区",
          },
          {
            code: "320613",
            value: "崇川区",
            label: "崇川区",
          },
          {
            code: "320614",
            value: "海门区",
            label: "海门区",
          },
          {
            code: "320623",
            value: "如东县",
            label: "如东县",
          },
          {
            code: "320681",
            value: "启东市",
            label: "启东市",
          },
          {
            code: "320682",
            value: "如皋市",
            label: "如皋市",
          },
          {
            code: "320685",
            value: "海安市",
            label: "海安市",
          },
        ],
      },
      {
        code: "320700",
        value: "连云港市",
        label: "连云港市",
        children: [
          {
            code: "320703",
            value: "连云区",
            label: "连云区",
          },
          {
            code: "320706",
            value: "海州区",
            label: "海州区",
          },
          {
            code: "320707",
            value: "赣榆区",
            label: "赣榆区",
          },
          {
            code: "320722",
            value: "东海县",
            label: "东海县",
          },
          {
            code: "320723",
            value: "灌云县",
            label: "灌云县",
          },
          {
            code: "320724",
            value: "灌南县",
            label: "灌南县",
          },
        ],
      },
      {
        code: "320800",
        value: "淮安市",
        label: "淮安市",
        children: [
          {
            code: "320803",
            value: "淮安区",
            label: "淮安区",
          },
          {
            code: "320804",
            value: "淮阴区",
            label: "淮阴区",
          },
          {
            code: "320812",
            value: "清江浦区",
            label: "清江浦区",
          },
          {
            code: "320813",
            value: "洪泽区",
            label: "洪泽区",
          },
          {
            code: "320826",
            value: "涟水县",
            label: "涟水县",
          },
          {
            code: "320830",
            value: "盱眙县",
            label: "盱眙县",
          },
          {
            code: "320831",
            value: "金湖县",
            label: "金湖县",
          },
        ],
      },
      {
        code: "320900",
        value: "盐城市",
        label: "盐城市",
        children: [
          {
            code: "320902",
            value: "亭湖区",
            label: "亭湖区",
          },
          {
            code: "320903",
            value: "盐都区",
            label: "盐都区",
          },
          {
            code: "320904",
            value: "大丰区",
            label: "大丰区",
          },
          {
            code: "320921",
            value: "响水县",
            label: "响水县",
          },
          {
            code: "320922",
            value: "滨海县",
            label: "滨海县",
          },
          {
            code: "320923",
            value: "阜宁县",
            label: "阜宁县",
          },
          {
            code: "320924",
            value: "射阳县",
            label: "射阳县",
          },
          {
            code: "320925",
            value: "建湖县",
            label: "建湖县",
          },
          {
            code: "320981",
            value: "东台市",
            label: "东台市",
          },
        ],
      },
      {
        code: "321000",
        value: "扬州市",
        label: "扬州市",
        children: [
          {
            code: "321002",
            value: "广陵区",
            label: "广陵区",
          },
          {
            code: "321003",
            value: "邗江区",
            label: "邗江区",
          },
          {
            code: "321012",
            value: "江都区",
            label: "江都区",
          },
          {
            code: "321023",
            value: "宝应县",
            label: "宝应县",
          },
          {
            code: "321081",
            value: "仪征市",
            label: "仪征市",
          },
          {
            code: "321084",
            value: "高邮市",
            label: "高邮市",
          },
        ],
      },
      {
        code: "321100",
        value: "镇江市",
        label: "镇江市",
        children: [
          {
            code: "321102",
            value: "京口区",
            label: "京口区",
          },
          {
            code: "321111",
            value: "润州区",
            label: "润州区",
          },
          {
            code: "321112",
            value: "丹徒区",
            label: "丹徒区",
          },
          {
            code: "321181",
            value: "丹阳市",
            label: "丹阳市",
          },
          {
            code: "321182",
            value: "扬中市",
            label: "扬中市",
          },
          {
            code: "321183",
            value: "句容市",
            label: "句容市",
          },
        ],
      },
      {
        code: "321200",
        value: "泰州市",
        label: "泰州市",
        children: [
          {
            code: "321202",
            value: "海陵区",
            label: "海陵区",
          },
          {
            code: "321203",
            value: "高港区",
            label: "高港区",
          },
          {
            code: "321204",
            value: "姜堰区",
            label: "姜堰区",
          },
          {
            code: "321281",
            value: "兴化市",
            label: "兴化市",
          },
          {
            code: "321282",
            value: "靖江市",
            label: "靖江市",
          },
          {
            code: "321283",
            value: "泰兴市",
            label: "泰兴市",
          },
        ],
      },
      {
        code: "321300",
        value: "宿迁市",
        label: "宿迁市",
        children: [
          {
            code: "321302",
            value: "宿城区",
            label: "宿城区",
          },
          {
            code: "321311",
            value: "宿豫区",
            label: "宿豫区",
          },
          {
            code: "321322",
            value: "沭阳县",
            label: "沭阳县",
          },
          {
            code: "321323",
            value: "泗阳县",
            label: "泗阳县",
          },
          {
            code: "321324",
            value: "泗洪县",
            label: "泗洪县",
          },
        ],
      },
    ],
  },
  {
    code: "330000",
    value: "浙江省",
    label: "浙江省",
    children: [
      {
        code: "330100",
        value: "杭州市",
        label: "杭州市",
        children: [
          {
            code: "330102",
            value: "上城区",
            label: "上城区",
          },
          {
            code: "330103",
            value: "下城区",
            label: "下城区",
          },
          {
            code: "330104",
            value: "江干区",
            label: "江干区",
          },
          {
            code: "330105",
            value: "拱墅区",
            label: "拱墅区",
          },
          {
            code: "330106",
            value: "西湖区",
            label: "西湖区",
          },
          {
            code: "330108",
            value: "滨江区",
            label: "滨江区",
          },
          {
            code: "330109",
            value: "萧山区",
            label: "萧山区",
          },
          {
            code: "330110",
            value: "余杭区",
            label: "余杭区",
          },
          {
            code: "330111",
            value: "富阳区",
            label: "富阳区",
          },
          {
            code: "330112",
            value: "临安区",
            label: "临安区",
          },
          {
            code: "330122",
            value: "桐庐县",
            label: "桐庐县",
          },
          {
            code: "330127",
            value: "淳安县",
            label: "淳安县",
          },
          {
            code: "330182",
            value: "建德市",
            label: "建德市",
          },
        ],
      },
      {
        code: "330200",
        value: "宁波市",
        label: "宁波市",
        children: [
          {
            code: "330203",
            value: "海曙区",
            label: "海曙区",
          },
          {
            code: "330205",
            value: "江北区",
            label: "江北区",
          },
          {
            code: "330206",
            value: "北仑区",
            label: "北仑区",
          },
          {
            code: "330211",
            value: "镇海区",
            label: "镇海区",
          },
          {
            code: "330212",
            value: "鄞州区",
            label: "鄞州区",
          },
          {
            code: "330213",
            value: "奉化区",
            label: "奉化区",
          },
          {
            code: "330225",
            value: "象山县",
            label: "象山县",
          },
          {
            code: "330226",
            value: "宁海县",
            label: "宁海县",
          },
          {
            code: "330281",
            value: "余姚市",
            label: "余姚市",
          },
          {
            code: "330282",
            value: "慈溪市",
            label: "慈溪市",
          },
        ],
      },
      {
        code: "330300",
        value: "温州市",
        label: "温州市",
        children: [
          {
            code: "330302",
            value: "鹿城区",
            label: "鹿城区",
          },
          {
            code: "330303",
            value: "龙湾区",
            label: "龙湾区",
          },
          {
            code: "330304",
            value: "瓯海区",
            label: "瓯海区",
          },
          {
            code: "330305",
            value: "洞头区",
            label: "洞头区",
          },
          {
            code: "330324",
            value: "永嘉县",
            label: "永嘉县",
          },
          {
            code: "330326",
            value: "平阳县",
            label: "平阳县",
          },
          {
            code: "330327",
            value: "苍南县",
            label: "苍南县",
          },
          {
            code: "330328",
            value: "文成县",
            label: "文成县",
          },
          {
            code: "330329",
            value: "泰顺县",
            label: "泰顺县",
          },
          {
            code: "330381",
            value: "瑞安市",
            label: "瑞安市",
          },
          {
            code: "330382",
            value: "乐清市",
            label: "乐清市",
          },
          {
            code: "330383",
            value: "龙港市",
            label: "龙港市",
          },
        ],
      },
      {
        code: "330400",
        value: "嘉兴市",
        label: "嘉兴市",
        children: [
          {
            code: "330402",
            value: "南湖区",
            label: "南湖区",
          },
          {
            code: "330411",
            value: "秀洲区",
            label: "秀洲区",
          },
          {
            code: "330421",
            value: "嘉善县",
            label: "嘉善县",
          },
          {
            code: "330424",
            value: "海盐县",
            label: "海盐县",
          },
          {
            code: "330481",
            value: "海宁市",
            label: "海宁市",
          },
          {
            code: "330482",
            value: "平湖市",
            label: "平湖市",
          },
          {
            code: "330483",
            value: "桐乡市",
            label: "桐乡市",
          },
        ],
      },
      {
        code: "330500",
        value: "湖州市",
        label: "湖州市",
        children: [
          {
            code: "330502",
            value: "吴兴区",
            label: "吴兴区",
          },
          {
            code: "330503",
            value: "南浔区",
            label: "南浔区",
          },
          {
            code: "330521",
            value: "德清县",
            label: "德清县",
          },
          {
            code: "330522",
            value: "长兴县",
            label: "长兴县",
          },
          {
            code: "330523",
            value: "安吉县",
            label: "安吉县",
          },
        ],
      },
      {
        code: "330600",
        value: "绍兴市",
        label: "绍兴市",
        children: [
          {
            code: "330602",
            value: "越城区",
            label: "越城区",
          },
          {
            code: "330603",
            value: "柯桥区",
            label: "柯桥区",
          },
          {
            code: "330604",
            value: "上虞区",
            label: "上虞区",
          },
          {
            code: "330624",
            value: "新昌县",
            label: "新昌县",
          },
          {
            code: "330681",
            value: "诸暨市",
            label: "诸暨市",
          },
          {
            code: "330683",
            value: "嵊州市",
            label: "嵊州市",
          },
        ],
      },
      {
        code: "330700",
        value: "金华市",
        label: "金华市",
        children: [
          {
            code: "330702",
            value: "婺城区",
            label: "婺城区",
          },
          {
            code: "330703",
            value: "金东区",
            label: "金东区",
          },
          {
            code: "330723",
            value: "武义县",
            label: "武义县",
          },
          {
            code: "330726",
            value: "浦江县",
            label: "浦江县",
          },
          {
            code: "330727",
            value: "磐安县",
            label: "磐安县",
          },
          {
            code: "330781",
            value: "兰溪市",
            label: "兰溪市",
          },
          {
            code: "330782",
            value: "义乌市",
            label: "义乌市",
          },
          {
            code: "330783",
            value: "东阳市",
            label: "东阳市",
          },
          {
            code: "330784",
            value: "永康市",
            label: "永康市",
          },
        ],
      },
      {
        code: "330800",
        value: "衢州市",
        label: "衢州市",
        children: [
          {
            code: "330802",
            value: "柯城区",
            label: "柯城区",
          },
          {
            code: "330803",
            value: "衢江区",
            label: "衢江区",
          },
          {
            code: "330822",
            value: "常山县",
            label: "常山县",
          },
          {
            code: "330824",
            value: "开化县",
            label: "开化县",
          },
          {
            code: "330825",
            value: "龙游县",
            label: "龙游县",
          },
          {
            code: "330881",
            value: "江山市",
            label: "江山市",
          },
        ],
      },
      {
        code: "330900",
        value: "舟山市",
        label: "舟山市",
        children: [
          {
            code: "330902",
            value: "定海区",
            label: "定海区",
          },
          {
            code: "330903",
            value: "普陀区",
            label: "普陀区",
          },
          {
            code: "330921",
            value: "岱山县",
            label: "岱山县",
          },
          {
            code: "330922",
            value: "嵊泗县",
            label: "嵊泗县",
          },
        ],
      },
      {
        code: "331000",
        value: "台州市",
        label: "台州市",
        children: [
          {
            code: "331002",
            value: "椒江区",
            label: "椒江区",
          },
          {
            code: "331003",
            value: "黄岩区",
            label: "黄岩区",
          },
          {
            code: "331004",
            value: "路桥区",
            label: "路桥区",
          },
          {
            code: "331022",
            value: "三门县",
            label: "三门县",
          },
          {
            code: "331023",
            value: "天台县",
            label: "天台县",
          },
          {
            code: "331024",
            value: "仙居县",
            label: "仙居县",
          },
          {
            code: "331081",
            value: "温岭市",
            label: "温岭市",
          },
          {
            code: "331082",
            value: "临海市",
            label: "临海市",
          },
          {
            code: "331083",
            value: "玉环市",
            label: "玉环市",
          },
        ],
      },
      {
        code: "331100",
        value: "丽水市",
        label: "丽水市",
        children: [
          {
            code: "331102",
            value: "莲都区",
            label: "莲都区",
          },
          {
            code: "331121",
            value: "青田县",
            label: "青田县",
          },
          {
            code: "331122",
            value: "缙云县",
            label: "缙云县",
          },
          {
            code: "331123",
            value: "遂昌县",
            label: "遂昌县",
          },
          {
            code: "331124",
            value: "松阳县",
            label: "松阳县",
          },
          {
            code: "331125",
            value: "云和县",
            label: "云和县",
          },
          {
            code: "331126",
            value: "庆元县",
            label: "庆元县",
          },
          {
            code: "331127",
            value: "景宁畲族自治县",
            label: "景宁畲族自治县",
          },
          {
            code: "331181",
            value: "龙泉市",
            label: "龙泉市",
          },
        ],
      },
    ],
  },
  {
    code: "340000",
    value: "安徽省",
    label: "安徽省",
    children: [
      {
        code: "340100",
        value: "合肥市",
        label: "合肥市",
        children: [
          {
            code: "340102",
            value: "瑶海区",
            label: "瑶海区",
          },
          {
            code: "340103",
            value: "庐阳区",
            label: "庐阳区",
          },
          {
            code: "340104",
            value: "蜀山区",
            label: "蜀山区",
          },
          {
            code: "340111",
            value: "包河区",
            label: "包河区",
          },
          {
            code: "340121",
            value: "长丰县",
            label: "长丰县",
          },
          {
            code: "340122",
            value: "肥东县",
            label: "肥东县",
          },
          {
            code: "340123",
            value: "肥西县",
            label: "肥西县",
          },
          {
            code: "340124",
            value: "庐江县",
            label: "庐江县",
          },
          {
            code: "340181",
            value: "巢湖市",
            label: "巢湖市",
          },
        ],
      },
      {
        code: "340200",
        value: "芜湖市",
        label: "芜湖市",
        children: [
          {
            code: "340202",
            value: "镜湖区",
            label: "镜湖区",
          },
          {
            code: "340207",
            value: "鸠江区",
            label: "鸠江区",
          },
          {
            code: "340209",
            value: "弋江区",
            label: "弋江区",
          },
          {
            code: "340210",
            value: "湾沚区",
            label: "湾沚区",
          },
          {
            code: "340212",
            value: "繁昌区",
            label: "繁昌区",
          },
          {
            code: "340223",
            value: "南陵县",
            label: "南陵县",
          },
          {
            code: "340281",
            value: "无为市",
            label: "无为市",
          },
        ],
      },
      {
        code: "340300",
        value: "蚌埠市",
        label: "蚌埠市",
        children: [
          {
            code: "340302",
            value: "龙子湖区",
            label: "龙子湖区",
          },
          {
            code: "340303",
            value: "蚌山区",
            label: "蚌山区",
          },
          {
            code: "340304",
            value: "禹会区",
            label: "禹会区",
          },
          {
            code: "340311",
            value: "淮上区",
            label: "淮上区",
          },
          {
            code: "340321",
            value: "怀远县",
            label: "怀远县",
          },
          {
            code: "340322",
            value: "五河县",
            label: "五河县",
          },
          {
            code: "340323",
            value: "固镇县",
            label: "固镇县",
          },
        ],
      },
      {
        code: "340400",
        value: "淮南市",
        label: "淮南市",
        children: [
          {
            code: "340402",
            value: "大通区",
            label: "大通区",
          },
          {
            code: "340403",
            value: "田家庵区",
            label: "田家庵区",
          },
          {
            code: "340404",
            value: "谢家集区",
            label: "谢家集区",
          },
          {
            code: "340405",
            value: "八公山区",
            label: "八公山区",
          },
          {
            code: "340406",
            value: "潘集区",
            label: "潘集区",
          },
          {
            code: "340421",
            value: "凤台县",
            label: "凤台县",
          },
          {
            code: "340422",
            value: "寿县",
            label: "寿县",
          },
        ],
      },
      {
        code: "340500",
        value: "马鞍山市",
        label: "马鞍山市",
        children: [
          {
            code: "340503",
            value: "花山区",
            label: "花山区",
          },
          {
            code: "340504",
            value: "雨山区",
            label: "雨山区",
          },
          {
            code: "340506",
            value: "博望区",
            label: "博望区",
          },
          {
            code: "340521",
            value: "当涂县",
            label: "当涂县",
          },
          {
            code: "340522",
            value: "含山县",
            label: "含山县",
          },
          {
            code: "340523",
            value: "和县",
            label: "和县",
          },
        ],
      },
      {
        code: "340600",
        value: "淮北市",
        label: "淮北市",
        children: [
          {
            code: "340602",
            value: "杜集区",
            label: "杜集区",
          },
          {
            code: "340603",
            value: "相山区",
            label: "相山区",
          },
          {
            code: "340604",
            value: "烈山区",
            label: "烈山区",
          },
          {
            code: "340621",
            value: "濉溪县",
            label: "濉溪县",
          },
        ],
      },
      {
        code: "340700",
        value: "铜陵市",
        label: "铜陵市",
        children: [
          {
            code: "340705",
            value: "铜官区",
            label: "铜官区",
          },
          {
            code: "340706",
            value: "义安区",
            label: "义安区",
          },
          {
            code: "340711",
            value: "郊区",
            label: "郊区",
          },
          {
            code: "340722",
            value: "枞阳县",
            label: "枞阳县",
          },
        ],
      },
      {
        code: "340800",
        value: "安庆市",
        label: "安庆市",
        children: [
          {
            code: "340802",
            value: "迎江区",
            label: "迎江区",
          },
          {
            code: "340803",
            value: "大观区",
            label: "大观区",
          },
          {
            code: "340811",
            value: "宜秀区",
            label: "宜秀区",
          },
          {
            code: "340822",
            value: "怀宁县",
            label: "怀宁县",
          },
          {
            code: "340825",
            value: "太湖县",
            label: "太湖县",
          },
          {
            code: "340826",
            value: "宿松县",
            label: "宿松县",
          },
          {
            code: "340827",
            value: "望江县",
            label: "望江县",
          },
          {
            code: "340828",
            value: "岳西县",
            label: "岳西县",
          },
          {
            code: "340881",
            value: "桐城市",
            label: "桐城市",
          },
          {
            code: "340882",
            value: "潜山市",
            label: "潜山市",
          },
        ],
      },
      {
        code: "341000",
        value: "黄山市",
        label: "黄山市",
        children: [
          {
            code: "341002",
            value: "屯溪区",
            label: "屯溪区",
          },
          {
            code: "341003",
            value: "黄山区",
            label: "黄山区",
          },
          {
            code: "341004",
            value: "徽州区",
            label: "徽州区",
          },
          {
            code: "341021",
            value: "歙县",
            label: "歙县",
          },
          {
            code: "341022",
            value: "休宁县",
            label: "休宁县",
          },
          {
            code: "341023",
            value: "黟县",
            label: "黟县",
          },
          {
            code: "341024",
            value: "祁门县",
            label: "祁门县",
          },
        ],
      },
      {
        code: "341100",
        value: "滁州市",
        label: "滁州市",
        children: [
          {
            code: "341102",
            value: "琅琊区",
            label: "琅琊区",
          },
          {
            code: "341103",
            value: "南谯区",
            label: "南谯区",
          },
          {
            code: "341122",
            value: "来安县",
            label: "来安县",
          },
          {
            code: "341124",
            value: "全椒县",
            label: "全椒县",
          },
          {
            code: "341125",
            value: "定远县",
            label: "定远县",
          },
          {
            code: "341126",
            value: "凤阳县",
            label: "凤阳县",
          },
          {
            code: "341181",
            value: "天长市",
            label: "天长市",
          },
          {
            code: "341182",
            value: "明光市",
            label: "明光市",
          },
        ],
      },
      {
        code: "341200",
        value: "阜阳市",
        label: "阜阳市",
        children: [
          {
            code: "341202",
            value: "颍州区",
            label: "颍州区",
          },
          {
            code: "341203",
            value: "颍东区",
            label: "颍东区",
          },
          {
            code: "341204",
            value: "颍泉区",
            label: "颍泉区",
          },
          {
            code: "341221",
            value: "临泉县",
            label: "临泉县",
          },
          {
            code: "341222",
            value: "太和县",
            label: "太和县",
          },
          {
            code: "341225",
            value: "阜南县",
            label: "阜南县",
          },
          {
            code: "341226",
            value: "颍上县",
            label: "颍上县",
          },
          {
            code: "341282",
            value: "界首市",
            label: "界首市",
          },
        ],
      },
      {
        code: "341300",
        value: "宿州市",
        label: "宿州市",
        children: [
          {
            code: "341302",
            value: "埇桥区",
            label: "埇桥区",
          },
          {
            code: "341321",
            value: "砀山县",
            label: "砀山县",
          },
          {
            code: "341322",
            value: "萧县",
            label: "萧县",
          },
          {
            code: "341323",
            value: "灵璧县",
            label: "灵璧县",
          },
          {
            code: "341324",
            value: "泗县",
            label: "泗县",
          },
        ],
      },
      {
        code: "341500",
        value: "六安市",
        label: "六安市",
        children: [
          {
            code: "341502",
            value: "金安区",
            label: "金安区",
          },
          {
            code: "341503",
            value: "裕安区",
            label: "裕安区",
          },
          {
            code: "341504",
            value: "叶集区",
            label: "叶集区",
          },
          {
            code: "341522",
            value: "霍邱县",
            label: "霍邱县",
          },
          {
            code: "341523",
            value: "舒城县",
            label: "舒城县",
          },
          {
            code: "341524",
            value: "金寨县",
            label: "金寨县",
          },
          {
            code: "341525",
            value: "霍山县",
            label: "霍山县",
          },
        ],
      },
      {
        code: "341600",
        value: "亳州市",
        label: "亳州市",
        children: [
          {
            code: "341602",
            value: "谯城区",
            label: "谯城区",
          },
          {
            code: "341621",
            value: "涡阳县",
            label: "涡阳县",
          },
          {
            code: "341622",
            value: "蒙城县",
            label: "蒙城县",
          },
          {
            code: "341623",
            value: "利辛县",
            label: "利辛县",
          },
        ],
      },
      {
        code: "341700",
        value: "池州市",
        label: "池州市",
        children: [
          {
            code: "341702",
            value: "贵池区",
            label: "贵池区",
          },
          {
            code: "341721",
            value: "东至县",
            label: "东至县",
          },
          {
            code: "341722",
            value: "石台县",
            label: "石台县",
          },
          {
            code: "341723",
            value: "青阳县",
            label: "青阳县",
          },
        ],
      },
      {
        code: "341800",
        value: "宣城市",
        label: "宣城市",
        children: [
          {
            code: "341802",
            value: "宣州区",
            label: "宣州区",
          },
          {
            code: "341821",
            value: "郎溪县",
            label: "郎溪县",
          },
          {
            code: "341823",
            value: "泾县",
            label: "泾县",
          },
          {
            code: "341824",
            value: "绩溪县",
            label: "绩溪县",
          },
          {
            code: "341825",
            value: "旌德县",
            label: "旌德县",
          },
          {
            code: "341881",
            value: "宁国市",
            label: "宁国市",
          },
          {
            code: "341882",
            value: "广德市",
            label: "广德市",
          },
        ],
      },
    ],
  },
  {
    code: "350000",
    value: "福建省",
    label: "福建省",
    children: [
      {
        code: "350100",
        value: "福州市",
        label: "福州市",
        children: [
          {
            code: "350102",
            value: "鼓楼区",
            label: "鼓楼区",
          },
          {
            code: "350103",
            value: "台江区",
            label: "台江区",
          },
          {
            code: "350104",
            value: "仓山区",
            label: "仓山区",
          },
          {
            code: "350105",
            value: "马尾区",
            label: "马尾区",
          },
          {
            code: "350111",
            value: "晋安区",
            label: "晋安区",
          },
          {
            code: "350112",
            value: "长乐区",
            label: "长乐区",
          },
          {
            code: "350121",
            value: "闽侯县",
            label: "闽侯县",
          },
          {
            code: "350122",
            value: "连江县",
            label: "连江县",
          },
          {
            code: "350123",
            value: "罗源县",
            label: "罗源县",
          },
          {
            code: "350124",
            value: "闽清县",
            label: "闽清县",
          },
          {
            code: "350125",
            value: "永泰县",
            label: "永泰县",
          },
          {
            code: "350128",
            value: "平潭县",
            label: "平潭县",
          },
          {
            code: "350181",
            value: "福清市",
            label: "福清市",
          },
        ],
      },
      {
        code: "350200",
        value: "厦门市",
        label: "厦门市",
        children: [
          {
            code: "350203",
            value: "思明区",
            label: "思明区",
          },
          {
            code: "350205",
            value: "海沧区",
            label: "海沧区",
          },
          {
            code: "350206",
            value: "湖里区",
            label: "湖里区",
          },
          {
            code: "350211",
            value: "集美区",
            label: "集美区",
          },
          {
            code: "350212",
            value: "同安区",
            label: "同安区",
          },
          {
            code: "350213",
            value: "翔安区",
            label: "翔安区",
          },
        ],
      },
      {
        code: "350300",
        value: "莆田市",
        label: "莆田市",
        children: [
          {
            code: "350302",
            value: "城厢区",
            label: "城厢区",
          },
          {
            code: "350303",
            value: "涵江区",
            label: "涵江区",
          },
          {
            code: "350304",
            value: "荔城区",
            label: "荔城区",
          },
          {
            code: "350305",
            value: "秀屿区",
            label: "秀屿区",
          },
          {
            code: "350322",
            value: "仙游县",
            label: "仙游县",
          },
        ],
      },
      {
        code: "350400",
        value: "三明市",
        label: "三明市",
        children: [
          {
            code: "350402",
            value: "梅列区",
            label: "梅列区",
          },
          {
            code: "350403",
            value: "三元区",
            label: "三元区",
          },
          {
            code: "350421",
            value: "明溪县",
            label: "明溪县",
          },
          {
            code: "350423",
            value: "清流县",
            label: "清流县",
          },
          {
            code: "350424",
            value: "宁化县",
            label: "宁化县",
          },
          {
            code: "350425",
            value: "大田县",
            label: "大田县",
          },
          {
            code: "350426",
            value: "尤溪县",
            label: "尤溪县",
          },
          {
            code: "350427",
            value: "沙县",
            label: "沙县",
          },
          {
            code: "350428",
            value: "将乐县",
            label: "将乐县",
          },
          {
            code: "350429",
            value: "泰宁县",
            label: "泰宁县",
          },
          {
            code: "350430",
            value: "建宁县",
            label: "建宁县",
          },
          {
            code: "350481",
            value: "永安市",
            label: "永安市",
          },
        ],
      },
      {
        code: "350500",
        value: "泉州市",
        label: "泉州市",
        children: [
          {
            code: "350502",
            value: "鲤城区",
            label: "鲤城区",
          },
          {
            code: "350503",
            value: "丰泽区",
            label: "丰泽区",
          },
          {
            code: "350504",
            value: "洛江区",
            label: "洛江区",
          },
          {
            code: "350505",
            value: "泉港区",
            label: "泉港区",
          },
          {
            code: "350521",
            value: "惠安县",
            label: "惠安县",
          },
          {
            code: "350524",
            value: "安溪县",
            label: "安溪县",
          },
          {
            code: "350525",
            value: "永春县",
            label: "永春县",
          },
          {
            code: "350526",
            value: "德化县",
            label: "德化县",
          },
          {
            code: "350527",
            value: "金门县",
            label: "金门县",
          },
          {
            code: "350581",
            value: "石狮市",
            label: "石狮市",
          },
          {
            code: "350582",
            value: "晋江市",
            label: "晋江市",
          },
          {
            code: "350583",
            value: "南安市",
            label: "南安市",
          },
        ],
      },
      {
        code: "350600",
        value: "漳州市",
        label: "漳州市",
        children: [
          {
            code: "350602",
            value: "芗城区",
            label: "芗城区",
          },
          {
            code: "350603",
            value: "龙文区",
            label: "龙文区",
          },
          {
            code: "350622",
            value: "云霄县",
            label: "云霄县",
          },
          {
            code: "350623",
            value: "漳浦县",
            label: "漳浦县",
          },
          {
            code: "350624",
            value: "诏安县",
            label: "诏安县",
          },
          {
            code: "350625",
            value: "长泰县",
            label: "长泰县",
          },
          {
            code: "350626",
            value: "东山县",
            label: "东山县",
          },
          {
            code: "350627",
            value: "南靖县",
            label: "南靖县",
          },
          {
            code: "350628",
            value: "平和县",
            label: "平和县",
          },
          {
            code: "350629",
            value: "华安县",
            label: "华安县",
          },
          {
            code: "350681",
            value: "龙海市",
            label: "龙海市",
          },
        ],
      },
      {
        code: "350700",
        value: "南平市",
        label: "南平市",
        children: [
          {
            code: "350702",
            value: "延平区",
            label: "延平区",
          },
          {
            code: "350703",
            value: "建阳区",
            label: "建阳区",
          },
          {
            code: "350721",
            value: "顺昌县",
            label: "顺昌县",
          },
          {
            code: "350722",
            value: "浦城县",
            label: "浦城县",
          },
          {
            code: "350723",
            value: "光泽县",
            label: "光泽县",
          },
          {
            code: "350724",
            value: "松溪县",
            label: "松溪县",
          },
          {
            code: "350725",
            value: "政和县",
            label: "政和县",
          },
          {
            code: "350781",
            value: "邵武市",
            label: "邵武市",
          },
          {
            code: "350782",
            value: "武夷山市",
            label: "武夷山市",
          },
          {
            code: "350783",
            value: "建瓯市",
            label: "建瓯市",
          },
        ],
      },
      {
        code: "350800",
        value: "龙岩市",
        label: "龙岩市",
        children: [
          {
            code: "350802",
            value: "新罗区",
            label: "新罗区",
          },
          {
            code: "350803",
            value: "永定区",
            label: "永定区",
          },
          {
            code: "350821",
            value: "长汀县",
            label: "长汀县",
          },
          {
            code: "350823",
            value: "上杭县",
            label: "上杭县",
          },
          {
            code: "350824",
            value: "武平县",
            label: "武平县",
          },
          {
            code: "350825",
            value: "连城县",
            label: "连城县",
          },
          {
            code: "350881",
            value: "漳平市",
            label: "漳平市",
          },
        ],
      },
      {
        code: "350900",
        value: "宁德市",
        label: "宁德市",
        children: [
          {
            code: "350902",
            value: "蕉城区",
            label: "蕉城区",
          },
          {
            code: "350921",
            value: "霞浦县",
            label: "霞浦县",
          },
          {
            code: "350922",
            value: "古田县",
            label: "古田县",
          },
          {
            code: "350923",
            value: "屏南县",
            label: "屏南县",
          },
          {
            code: "350924",
            value: "寿宁县",
            label: "寿宁县",
          },
          {
            code: "350925",
            value: "周宁县",
            label: "周宁县",
          },
          {
            code: "350926",
            value: "柘荣县",
            label: "柘荣县",
          },
          {
            code: "350981",
            value: "福安市",
            label: "福安市",
          },
          {
            code: "350982",
            value: "福鼎市",
            label: "福鼎市",
          },
        ],
      },
    ],
  },
  {
    code: "360000",
    value: "江西省",
    label: "江西省",
    children: [
      {
        code: "360100",
        value: "南昌市",
        label: "南昌市",
        children: [
          {
            code: "360102",
            value: "东湖区",
            label: "东湖区",
          },
          {
            code: "360103",
            value: "西湖区",
            label: "西湖区",
          },
          {
            code: "360104",
            value: "青云谱区",
            label: "青云谱区",
          },
          {
            code: "360111",
            value: "青山湖区",
            label: "青山湖区",
          },
          {
            code: "360112",
            value: "新建区",
            label: "新建区",
          },
          {
            code: "360113",
            value: "红谷滩区",
            label: "红谷滩区",
          },
          {
            code: "360121",
            value: "南昌县",
            label: "南昌县",
          },
          {
            code: "360123",
            value: "安义县",
            label: "安义县",
          },
          {
            code: "360124",
            value: "进贤县",
            label: "进贤县",
          },
        ],
      },
      {
        code: "360200",
        value: "景德镇市",
        label: "景德镇市",
        children: [
          {
            code: "360202",
            value: "昌江区",
            label: "昌江区",
          },
          {
            code: "360203",
            value: "珠山区",
            label: "珠山区",
          },
          {
            code: "360222",
            value: "浮梁县",
            label: "浮梁县",
          },
          {
            code: "360281",
            value: "乐平市",
            label: "乐平市",
          },
        ],
      },
      {
        code: "360300",
        value: "萍乡市",
        label: "萍乡市",
        children: [
          {
            code: "360302",
            value: "安源区",
            label: "安源区",
          },
          {
            code: "360313",
            value: "湘东区",
            label: "湘东区",
          },
          {
            code: "360321",
            value: "莲花县",
            label: "莲花县",
          },
          {
            code: "360322",
            value: "上栗县",
            label: "上栗县",
          },
          {
            code: "360323",
            value: "芦溪县",
            label: "芦溪县",
          },
        ],
      },
      {
        code: "360400",
        value: "九江市",
        label: "九江市",
        children: [
          {
            code: "360402",
            value: "濂溪区",
            label: "濂溪区",
          },
          {
            code: "360403",
            value: "浔阳区",
            label: "浔阳区",
          },
          {
            code: "360404",
            value: "柴桑区",
            label: "柴桑区",
          },
          {
            code: "360423",
            value: "武宁县",
            label: "武宁县",
          },
          {
            code: "360424",
            value: "修水县",
            label: "修水县",
          },
          {
            code: "360425",
            value: "永修县",
            label: "永修县",
          },
          {
            code: "360426",
            value: "德安县",
            label: "德安县",
          },
          {
            code: "360428",
            value: "都昌县",
            label: "都昌县",
          },
          {
            code: "360429",
            value: "湖口县",
            label: "湖口县",
          },
          {
            code: "360430",
            value: "彭泽县",
            label: "彭泽县",
          },
          {
            code: "360481",
            value: "瑞昌市",
            label: "瑞昌市",
          },
          {
            code: "360482",
            value: "共青城市",
            label: "共青城市",
          },
          {
            code: "360483",
            value: "庐山市",
            label: "庐山市",
          },
        ],
      },
      {
        code: "360500",
        value: "新余市",
        label: "新余市",
        children: [
          {
            code: "360502",
            value: "渝水区",
            label: "渝水区",
          },
          {
            code: "360521",
            value: "分宜县",
            label: "分宜县",
          },
        ],
      },
      {
        code: "360600",
        value: "鹰潭市",
        label: "鹰潭市",
        children: [
          {
            code: "360602",
            value: "月湖区",
            label: "月湖区",
          },
          {
            code: "360603",
            value: "余江区",
            label: "余江区",
          },
          {
            code: "360681",
            value: "贵溪市",
            label: "贵溪市",
          },
        ],
      },
      {
        code: "360700",
        value: "赣州市",
        label: "赣州市",
        children: [
          {
            code: "360702",
            value: "章贡区",
            label: "章贡区",
          },
          {
            code: "360703",
            value: "南康区",
            label: "南康区",
          },
          {
            code: "360704",
            value: "赣县区",
            label: "赣县区",
          },
          {
            code: "360722",
            value: "信丰县",
            label: "信丰县",
          },
          {
            code: "360723",
            value: "大余县",
            label: "大余县",
          },
          {
            code: "360724",
            value: "上犹县",
            label: "上犹县",
          },
          {
            code: "360725",
            value: "崇义县",
            label: "崇义县",
          },
          {
            code: "360726",
            value: "安远县",
            label: "安远县",
          },
          {
            code: "360728",
            value: "定南县",
            label: "定南县",
          },
          {
            code: "360729",
            value: "全南县",
            label: "全南县",
          },
          {
            code: "360730",
            value: "宁都县",
            label: "宁都县",
          },
          {
            code: "360731",
            value: "于都县",
            label: "于都县",
          },
          {
            code: "360732",
            value: "兴国县",
            label: "兴国县",
          },
          {
            code: "360733",
            value: "会昌县",
            label: "会昌县",
          },
          {
            code: "360734",
            value: "寻乌县",
            label: "寻乌县",
          },
          {
            code: "360735",
            value: "石城县",
            label: "石城县",
          },
          {
            code: "360781",
            value: "瑞金市",
            label: "瑞金市",
          },
          {
            code: "360783",
            value: "龙南市",
            label: "龙南市",
          },
        ],
      },
      {
        code: "360800",
        value: "吉安市",
        label: "吉安市",
        children: [
          {
            code: "360802",
            value: "吉州区",
            label: "吉州区",
          },
          {
            code: "360803",
            value: "青原区",
            label: "青原区",
          },
          {
            code: "360821",
            value: "吉安县",
            label: "吉安县",
          },
          {
            code: "360822",
            value: "吉水县",
            label: "吉水县",
          },
          {
            code: "360823",
            value: "峡江县",
            label: "峡江县",
          },
          {
            code: "360824",
            value: "新干县",
            label: "新干县",
          },
          {
            code: "360825",
            value: "永丰县",
            label: "永丰县",
          },
          {
            code: "360826",
            value: "泰和县",
            label: "泰和县",
          },
          {
            code: "360827",
            value: "遂川县",
            label: "遂川县",
          },
          {
            code: "360828",
            value: "万安县",
            label: "万安县",
          },
          {
            code: "360829",
            value: "安福县",
            label: "安福县",
          },
          {
            code: "360830",
            value: "永新县",
            label: "永新县",
          },
          {
            code: "360881",
            value: "井冈山市",
            label: "井冈山市",
          },
        ],
      },
      {
        code: "360900",
        value: "宜春市",
        label: "宜春市",
        children: [
          {
            code: "360902",
            value: "袁州区",
            label: "袁州区",
          },
          {
            code: "360921",
            value: "奉新县",
            label: "奉新县",
          },
          {
            code: "360922",
            value: "万载县",
            label: "万载县",
          },
          {
            code: "360923",
            value: "上高县",
            label: "上高县",
          },
          {
            code: "360924",
            value: "宜丰县",
            label: "宜丰县",
          },
          {
            code: "360925",
            value: "靖安县",
            label: "靖安县",
          },
          {
            code: "360926",
            value: "铜鼓县",
            label: "铜鼓县",
          },
          {
            code: "360981",
            value: "丰城市",
            label: "丰城市",
          },
          {
            code: "360982",
            value: "樟树市",
            label: "樟树市",
          },
          {
            code: "360983",
            value: "高安市",
            label: "高安市",
          },
        ],
      },
      {
        code: "361000",
        value: "抚州市",
        label: "抚州市",
        children: [
          {
            code: "361002",
            value: "临川区",
            label: "临川区",
          },
          {
            code: "361003",
            value: "东乡区",
            label: "东乡区",
          },
          {
            code: "361021",
            value: "南城县",
            label: "南城县",
          },
          {
            code: "361022",
            value: "黎川县",
            label: "黎川县",
          },
          {
            code: "361023",
            value: "南丰县",
            label: "南丰县",
          },
          {
            code: "361024",
            value: "崇仁县",
            label: "崇仁县",
          },
          {
            code: "361025",
            value: "乐安县",
            label: "乐安县",
          },
          {
            code: "361026",
            value: "宜黄县",
            label: "宜黄县",
          },
          {
            code: "361027",
            value: "金溪县",
            label: "金溪县",
          },
          {
            code: "361028",
            value: "资溪县",
            label: "资溪县",
          },
          {
            code: "361030",
            value: "广昌县",
            label: "广昌县",
          },
        ],
      },
      {
        code: "361100",
        value: "上饶市",
        label: "上饶市",
        children: [
          {
            code: "361102",
            value: "信州区",
            label: "信州区",
          },
          {
            code: "361103",
            value: "广丰区",
            label: "广丰区",
          },
          {
            code: "361104",
            value: "广信区",
            label: "广信区",
          },
          {
            code: "361123",
            value: "玉山县",
            label: "玉山县",
          },
          {
            code: "361124",
            value: "铅山县",
            label: "铅山县",
          },
          {
            code: "361125",
            value: "横峰县",
            label: "横峰县",
          },
          {
            code: "361126",
            value: "弋阳县",
            label: "弋阳县",
          },
          {
            code: "361127",
            value: "余干县",
            label: "余干县",
          },
          {
            code: "361128",
            value: "鄱阳县",
            label: "鄱阳县",
          },
          {
            code: "361129",
            value: "万年县",
            label: "万年县",
          },
          {
            code: "361130",
            value: "婺源县",
            label: "婺源县",
          },
          {
            code: "361181",
            value: "德兴市",
            label: "德兴市",
          },
        ],
      },
    ],
  },
  {
    code: "370000",
    value: "山东省",
    label: "山东省",
    children: [
      {
        code: "370100",
        value: "济南市",
        label: "济南市",
        children: [
          {
            code: "370102",
            value: "历下区",
            label: "历下区",
          },
          {
            code: "370103",
            value: "市中区",
            label: "市中区",
          },
          {
            code: "370104",
            value: "槐荫区",
            label: "槐荫区",
          },
          {
            code: "370105",
            value: "天桥区",
            label: "天桥区",
          },
          {
            code: "370112",
            value: "历城区",
            label: "历城区",
          },
          {
            code: "370113",
            value: "长清区",
            label: "长清区",
          },
          {
            code: "370114",
            value: "章丘区",
            label: "章丘区",
          },
          {
            code: "370115",
            value: "济阳区",
            label: "济阳区",
          },
          {
            code: "370116",
            value: "莱芜区",
            label: "莱芜区",
          },
          {
            code: "370117",
            value: "钢城区",
            label: "钢城区",
          },
          {
            code: "370124",
            value: "平阴县",
            label: "平阴县",
          },
          {
            code: "370126",
            value: "商河县",
            label: "商河县",
          },
        ],
      },
      {
        code: "370200",
        value: "青岛市",
        label: "青岛市",
        children: [
          {
            code: "370202",
            value: "市南区",
            label: "市南区",
          },
          {
            code: "370203",
            value: "市北区",
            label: "市北区",
          },
          {
            code: "370211",
            value: "黄岛区",
            label: "黄岛区",
          },
          {
            code: "370212",
            value: "崂山区",
            label: "崂山区",
          },
          {
            code: "370213",
            value: "李沧区",
            label: "李沧区",
          },
          {
            code: "370214",
            value: "城阳区",
            label: "城阳区",
          },
          {
            code: "370215",
            value: "即墨区",
            label: "即墨区",
          },
          {
            code: "370281",
            value: "胶州市",
            label: "胶州市",
          },
          {
            code: "370283",
            value: "平度市",
            label: "平度市",
          },
          {
            code: "370285",
            value: "莱西市",
            label: "莱西市",
          },
        ],
      },
      {
        code: "370300",
        value: "淄博市",
        label: "淄博市",
        children: [
          {
            code: "370302",
            value: "淄川区",
            label: "淄川区",
          },
          {
            code: "370303",
            value: "张店区",
            label: "张店区",
          },
          {
            code: "370304",
            value: "博山区",
            label: "博山区",
          },
          {
            code: "370305",
            value: "临淄区",
            label: "临淄区",
          },
          {
            code: "370306",
            value: "周村区",
            label: "周村区",
          },
          {
            code: "370321",
            value: "桓台县",
            label: "桓台县",
          },
          {
            code: "370322",
            value: "高青县",
            label: "高青县",
          },
          {
            code: "370323",
            value: "沂源县",
            label: "沂源县",
          },
        ],
      },
      {
        code: "370400",
        value: "枣庄市",
        label: "枣庄市",
        children: [
          {
            code: "370402",
            value: "市中区",
            label: "市中区",
          },
          {
            code: "370403",
            value: "薛城区",
            label: "薛城区",
          },
          {
            code: "370404",
            value: "峄城区",
            label: "峄城区",
          },
          {
            code: "370405",
            value: "台儿庄区",
            label: "台儿庄区",
          },
          {
            code: "370406",
            value: "山亭区",
            label: "山亭区",
          },
          {
            code: "370481",
            value: "滕州市",
            label: "滕州市",
          },
        ],
      },
      {
        code: "370500",
        value: "东营市",
        label: "东营市",
        children: [
          {
            code: "370502",
            value: "东营区",
            label: "东营区",
          },
          {
            code: "370503",
            value: "河口区",
            label: "河口区",
          },
          {
            code: "370505",
            value: "垦利区",
            label: "垦利区",
          },
          {
            code: "370522",
            value: "利津县",
            label: "利津县",
          },
          {
            code: "370523",
            value: "广饶县",
            label: "广饶县",
          },
        ],
      },
      {
        code: "370600",
        value: "烟台市",
        label: "烟台市",
        children: [
          {
            code: "370602",
            value: "芝罘区",
            label: "芝罘区",
          },
          {
            code: "370611",
            value: "福山区",
            label: "福山区",
          },
          {
            code: "370612",
            value: "牟平区",
            label: "牟平区",
          },
          {
            code: "370613",
            value: "莱山区",
            label: "莱山区",
          },
          {
            code: "370614",
            value: "蓬莱区",
            label: "蓬莱区",
          },
          {
            code: "370681",
            value: "龙口市",
            label: "龙口市",
          },
          {
            code: "370682",
            value: "莱阳市",
            label: "莱阳市",
          },
          {
            code: "370683",
            value: "莱州市",
            label: "莱州市",
          },
          {
            code: "370685",
            value: "招远市",
            label: "招远市",
          },
          {
            code: "370686",
            value: "栖霞市",
            label: "栖霞市",
          },
          {
            code: "370687",
            value: "海阳市",
            label: "海阳市",
          },
        ],
      },
      {
        code: "370700",
        value: "潍坊市",
        label: "潍坊市",
        children: [
          {
            code: "370702",
            value: "潍城区",
            label: "潍城区",
          },
          {
            code: "370703",
            value: "寒亭区",
            label: "寒亭区",
          },
          {
            code: "370704",
            value: "坊子区",
            label: "坊子区",
          },
          {
            code: "370705",
            value: "奎文区",
            label: "奎文区",
          },
          {
            code: "370724",
            value: "临朐县",
            label: "临朐县",
          },
          {
            code: "370725",
            value: "昌乐县",
            label: "昌乐县",
          },
          {
            code: "370781",
            value: "青州市",
            label: "青州市",
          },
          {
            code: "370782",
            value: "诸城市",
            label: "诸城市",
          },
          {
            code: "370783",
            value: "寿光市",
            label: "寿光市",
          },
          {
            code: "370784",
            value: "安丘市",
            label: "安丘市",
          },
          {
            code: "370785",
            value: "高密市",
            label: "高密市",
          },
          {
            code: "370786",
            value: "昌邑市",
            label: "昌邑市",
          },
        ],
      },
      {
        code: "370800",
        value: "济宁市",
        label: "济宁市",
        children: [
          {
            code: "370811",
            value: "任城区",
            label: "任城区",
          },
          {
            code: "370812",
            value: "兖州区",
            label: "兖州区",
          },
          {
            code: "370826",
            value: "微山县",
            label: "微山县",
          },
          {
            code: "370827",
            value: "鱼台县",
            label: "鱼台县",
          },
          {
            code: "370828",
            value: "金乡县",
            label: "金乡县",
          },
          {
            code: "370829",
            value: "嘉祥县",
            label: "嘉祥县",
          },
          {
            code: "370830",
            value: "汶上县",
            label: "汶上县",
          },
          {
            code: "370831",
            value: "泗水县",
            label: "泗水县",
          },
          {
            code: "370832",
            value: "梁山县",
            label: "梁山县",
          },
          {
            code: "370881",
            value: "曲阜市",
            label: "曲阜市",
          },
          {
            code: "370883",
            value: "邹城市",
            label: "邹城市",
          },
        ],
      },
      {
        code: "370900",
        value: "泰安市",
        label: "泰安市",
        children: [
          {
            code: "370902",
            value: "泰山区",
            label: "泰山区",
          },
          {
            code: "370911",
            value: "岱岳区",
            label: "岱岳区",
          },
          {
            code: "370921",
            value: "宁阳县",
            label: "宁阳县",
          },
          {
            code: "370923",
            value: "东平县",
            label: "东平县",
          },
          {
            code: "370982",
            value: "新泰市",
            label: "新泰市",
          },
          {
            code: "370983",
            value: "肥城市",
            label: "肥城市",
          },
        ],
      },
      {
        code: "371000",
        value: "威海市",
        label: "威海市",
        children: [
          {
            code: "371002",
            value: "环翠区",
            label: "环翠区",
          },
          {
            code: "371003",
            value: "文登区",
            label: "文登区",
          },
          {
            code: "371082",
            value: "荣成市",
            label: "荣成市",
          },
          {
            code: "371083",
            value: "乳山市",
            label: "乳山市",
          },
        ],
      },
      {
        code: "371100",
        value: "日照市",
        label: "日照市",
        children: [
          {
            code: "371102",
            value: "东港区",
            label: "东港区",
          },
          {
            code: "371103",
            value: "岚山区",
            label: "岚山区",
          },
          {
            code: "371121",
            value: "五莲县",
            label: "五莲县",
          },
          {
            code: "371122",
            value: "莒县",
            label: "莒县",
          },
        ],
      },
      {
        code: "371300",
        value: "临沂市",
        label: "临沂市",
        children: [
          {
            code: "371302",
            value: "兰山区",
            label: "兰山区",
          },
          {
            code: "371311",
            value: "罗庄区",
            label: "罗庄区",
          },
          {
            code: "371312",
            value: "河东区",
            label: "河东区",
          },
          {
            code: "371321",
            value: "沂南县",
            label: "沂南县",
          },
          {
            code: "371322",
            value: "郯城县",
            label: "郯城县",
          },
          {
            code: "371323",
            value: "沂水县",
            label: "沂水县",
          },
          {
            code: "371324",
            value: "兰陵县",
            label: "兰陵县",
          },
          {
            code: "371325",
            value: "费县",
            label: "费县",
          },
          {
            code: "371326",
            value: "平邑县",
            label: "平邑县",
          },
          {
            code: "371327",
            value: "莒南县",
            label: "莒南县",
          },
          {
            code: "371328",
            value: "蒙阴县",
            label: "蒙阴县",
          },
          {
            code: "371329",
            value: "临沭县",
            label: "临沭县",
          },
        ],
      },
      {
        code: "371400",
        value: "德州市",
        label: "德州市",
        children: [
          {
            code: "371402",
            value: "德城区",
            label: "德城区",
          },
          {
            code: "371403",
            value: "陵城区",
            label: "陵城区",
          },
          {
            code: "371422",
            value: "宁津县",
            label: "宁津县",
          },
          {
            code: "371423",
            value: "庆云县",
            label: "庆云县",
          },
          {
            code: "371424",
            value: "临邑县",
            label: "临邑县",
          },
          {
            code: "371425",
            value: "齐河县",
            label: "齐河县",
          },
          {
            code: "371426",
            value: "平原县",
            label: "平原县",
          },
          {
            code: "371427",
            value: "夏津县",
            label: "夏津县",
          },
          {
            code: "371428",
            value: "武城县",
            label: "武城县",
          },
          {
            code: "371481",
            value: "乐陵市",
            label: "乐陵市",
          },
          {
            code: "371482",
            value: "禹城市",
            label: "禹城市",
          },
        ],
      },
      {
        code: "371500",
        value: "聊城市",
        label: "聊城市",
        children: [
          {
            code: "371502",
            value: "东昌府区",
            label: "东昌府区",
          },
          {
            code: "371503",
            value: "茌平区",
            label: "茌平区",
          },
          {
            code: "371521",
            value: "阳谷县",
            label: "阳谷县",
          },
          {
            code: "371522",
            value: "莘县",
            label: "莘县",
          },
          {
            code: "371524",
            value: "东阿县",
            label: "东阿县",
          },
          {
            code: "371525",
            value: "冠县",
            label: "冠县",
          },
          {
            code: "371526",
            value: "高唐县",
            label: "高唐县",
          },
          {
            code: "371581",
            value: "临清市",
            label: "临清市",
          },
        ],
      },
      {
        code: "371600",
        value: "滨州市",
        label: "滨州市",
        children: [
          {
            code: "371602",
            value: "滨城区",
            label: "滨城区",
          },
          {
            code: "371603",
            value: "沾化区",
            label: "沾化区",
          },
          {
            code: "371621",
            value: "惠民县",
            label: "惠民县",
          },
          {
            code: "371622",
            value: "阳信县",
            label: "阳信县",
          },
          {
            code: "371623",
            value: "无棣县",
            label: "无棣县",
          },
          {
            code: "371625",
            value: "博兴县",
            label: "博兴县",
          },
          {
            code: "371681",
            value: "邹平市",
            label: "邹平市",
          },
        ],
      },
      {
        code: "371700",
        value: "菏泽市",
        label: "菏泽市",
        children: [
          {
            code: "371702",
            value: "牡丹区",
            label: "牡丹区",
          },
          {
            code: "371703",
            value: "定陶区",
            label: "定陶区",
          },
          {
            code: "371721",
            value: "曹县",
            label: "曹县",
          },
          {
            code: "371722",
            value: "单县",
            label: "单县",
          },
          {
            code: "371723",
            value: "成武县",
            label: "成武县",
          },
          {
            code: "371724",
            value: "巨野县",
            label: "巨野县",
          },
          {
            code: "371725",
            value: "郓城县",
            label: "郓城县",
          },
          {
            code: "371726",
            value: "鄄城县",
            label: "鄄城县",
          },
          {
            code: "371728",
            value: "东明县",
            label: "东明县",
          },
        ],
      },
    ],
  },
  {
    code: "410000",
    value: "河南省",
    label: "河南省",
    children: [
      {
        code: "410100",
        value: "郑州市",
        label: "郑州市",
        children: [
          {
            code: "410102",
            value: "中原区",
            label: "中原区",
          },
          {
            code: "410103",
            value: "二七区",
            label: "二七区",
          },
          {
            code: "410104",
            value: "管城回族区",
            label: "管城回族区",
          },
          {
            code: "410105",
            value: "金水区",
            label: "金水区",
          },
          {
            code: "410106",
            value: "上街区",
            label: "上街区",
          },
          {
            code: "410108",
            value: "惠济区",
            label: "惠济区",
          },
          {
            code: "410122",
            value: "中牟县",
            label: "中牟县",
          },
          {
            code: "410181",
            value: "巩义市",
            label: "巩义市",
          },
          {
            code: "410182",
            value: "荥阳市",
            label: "荥阳市",
          },
          {
            code: "410183",
            value: "新密市",
            label: "新密市",
          },
          {
            code: "410184",
            value: "新郑市",
            label: "新郑市",
          },
          {
            code: "410185",
            value: "登封市",
            label: "登封市",
          },
        ],
      },
      {
        code: "410200",
        value: "开封市",
        label: "开封市",
        children: [
          {
            code: "410202",
            value: "龙亭区",
            label: "龙亭区",
          },
          {
            code: "410203",
            value: "顺河回族区",
            label: "顺河回族区",
          },
          {
            code: "410204",
            value: "鼓楼区",
            label: "鼓楼区",
          },
          {
            code: "410205",
            value: "禹王台区",
            label: "禹王台区",
          },
          {
            code: "410212",
            value: "祥符区",
            label: "祥符区",
          },
          {
            code: "410221",
            value: "杞县",
            label: "杞县",
          },
          {
            code: "410222",
            value: "通许县",
            label: "通许县",
          },
          {
            code: "410223",
            value: "尉氏县",
            label: "尉氏县",
          },
          {
            code: "410225",
            value: "兰考县",
            label: "兰考县",
          },
        ],
      },
      {
        code: "410300",
        value: "洛阳市",
        label: "洛阳市",
        children: [
          {
            code: "410302",
            value: "老城区",
            label: "老城区",
          },
          {
            code: "410303",
            value: "西工区",
            label: "西工区",
          },
          {
            code: "410304",
            value: "瀍河回族区",
            label: "瀍河回族区",
          },
          {
            code: "410305",
            value: "涧西区",
            label: "涧西区",
          },
          {
            code: "410306",
            value: "吉利区",
            label: "吉利区",
          },
          {
            code: "410311",
            value: "洛龙区",
            label: "洛龙区",
          },
          {
            code: "410322",
            value: "孟津县",
            label: "孟津县",
          },
          {
            code: "410323",
            value: "新安县",
            label: "新安县",
          },
          {
            code: "410324",
            value: "栾川县",
            label: "栾川县",
          },
          {
            code: "410325",
            value: "嵩县",
            label: "嵩县",
          },
          {
            code: "410326",
            value: "汝阳县",
            label: "汝阳县",
          },
          {
            code: "410327",
            value: "宜阳县",
            label: "宜阳县",
          },
          {
            code: "410328",
            value: "洛宁县",
            label: "洛宁县",
          },
          {
            code: "410329",
            value: "伊川县",
            label: "伊川县",
          },
          {
            code: "410381",
            value: "偃师市",
            label: "偃师市",
          },
        ],
      },
      {
        code: "410400",
        value: "平顶山市",
        label: "平顶山市",
        children: [
          {
            code: "410402",
            value: "新华区",
            label: "新华区",
          },
          {
            code: "410403",
            value: "卫东区",
            label: "卫东区",
          },
          {
            code: "410404",
            value: "石龙区",
            label: "石龙区",
          },
          {
            code: "410411",
            value: "湛河区",
            label: "湛河区",
          },
          {
            code: "410421",
            value: "宝丰县",
            label: "宝丰县",
          },
          {
            code: "410422",
            value: "叶县",
            label: "叶县",
          },
          {
            code: "410423",
            value: "鲁山县",
            label: "鲁山县",
          },
          {
            code: "410425",
            value: "郏县",
            label: "郏县",
          },
          {
            code: "410481",
            value: "舞钢市",
            label: "舞钢市",
          },
          {
            code: "410482",
            value: "汝州市",
            label: "汝州市",
          },
        ],
      },
      {
        code: "410500",
        value: "安阳市",
        label: "安阳市",
        children: [
          {
            code: "410502",
            value: "文峰区",
            label: "文峰区",
          },
          {
            code: "410503",
            value: "北关区",
            label: "北关区",
          },
          {
            code: "410505",
            value: "殷都区",
            label: "殷都区",
          },
          {
            code: "410506",
            value: "龙安区",
            label: "龙安区",
          },
          {
            code: "410522",
            value: "安阳县",
            label: "安阳县",
          },
          {
            code: "410523",
            value: "汤阴县",
            label: "汤阴县",
          },
          {
            code: "410526",
            value: "滑县",
            label: "滑县",
          },
          {
            code: "410527",
            value: "内黄县",
            label: "内黄县",
          },
          {
            code: "410581",
            value: "林州市",
            label: "林州市",
          },
        ],
      },
      {
        code: "410600",
        value: "鹤壁市",
        label: "鹤壁市",
        children: [
          {
            code: "410602",
            value: "鹤山区",
            label: "鹤山区",
          },
          {
            code: "410603",
            value: "山城区",
            label: "山城区",
          },
          {
            code: "410611",
            value: "淇滨区",
            label: "淇滨区",
          },
          {
            code: "410621",
            value: "浚县",
            label: "浚县",
          },
          {
            code: "410622",
            value: "淇县",
            label: "淇县",
          },
        ],
      },
      {
        code: "410700",
        value: "新乡市",
        label: "新乡市",
        children: [
          {
            code: "410702",
            value: "红旗区",
            label: "红旗区",
          },
          {
            code: "410703",
            value: "卫滨区",
            label: "卫滨区",
          },
          {
            code: "410704",
            value: "凤泉区",
            label: "凤泉区",
          },
          {
            code: "410711",
            value: "牧野区",
            label: "牧野区",
          },
          {
            code: "410721",
            value: "新乡县",
            label: "新乡县",
          },
          {
            code: "410724",
            value: "获嘉县",
            label: "获嘉县",
          },
          {
            code: "410725",
            value: "原阳县",
            label: "原阳县",
          },
          {
            code: "410726",
            value: "延津县",
            label: "延津县",
          },
          {
            code: "410727",
            value: "封丘县",
            label: "封丘县",
          },
          {
            code: "410781",
            value: "卫辉市",
            label: "卫辉市",
          },
          {
            code: "410782",
            value: "辉县市",
            label: "辉县市",
          },
          {
            code: "410783",
            value: "长垣市",
            label: "长垣市",
          },
        ],
      },
      {
        code: "410800",
        value: "焦作市",
        label: "焦作市",
        children: [
          {
            code: "410802",
            value: "解放区",
            label: "解放区",
          },
          {
            code: "410803",
            value: "中站区",
            label: "中站区",
          },
          {
            code: "410804",
            value: "马村区",
            label: "马村区",
          },
          {
            code: "410811",
            value: "山阳区",
            label: "山阳区",
          },
          {
            code: "410821",
            value: "修武县",
            label: "修武县",
          },
          {
            code: "410822",
            value: "博爱县",
            label: "博爱县",
          },
          {
            code: "410823",
            value: "武陟县",
            label: "武陟县",
          },
          {
            code: "410825",
            value: "温县",
            label: "温县",
          },
          {
            code: "410882",
            value: "沁阳市",
            label: "沁阳市",
          },
          {
            code: "410883",
            value: "孟州市",
            label: "孟州市",
          },
        ],
      },
      {
        code: "410900",
        value: "濮阳市",
        label: "濮阳市",
        children: [
          {
            code: "410902",
            value: "华龙区",
            label: "华龙区",
          },
          {
            code: "410922",
            value: "清丰县",
            label: "清丰县",
          },
          {
            code: "410923",
            value: "南乐县",
            label: "南乐县",
          },
          {
            code: "410926",
            value: "范县",
            label: "范县",
          },
          {
            code: "410927",
            value: "台前县",
            label: "台前县",
          },
          {
            code: "410928",
            value: "濮阳县",
            label: "濮阳县",
          },
        ],
      },
      {
        code: "411000",
        value: "许昌市",
        label: "许昌市",
        children: [
          {
            code: "411002",
            value: "魏都区",
            label: "魏都区",
          },
          {
            code: "411003",
            value: "建安区",
            label: "建安区",
          },
          {
            code: "411024",
            value: "鄢陵县",
            label: "鄢陵县",
          },
          {
            code: "411025",
            value: "襄城县",
            label: "襄城县",
          },
          {
            code: "411081",
            value: "禹州市",
            label: "禹州市",
          },
          {
            code: "411082",
            value: "长葛市",
            label: "长葛市",
          },
        ],
      },
      {
        code: "411100",
        value: "漯河市",
        label: "漯河市",
        children: [
          {
            code: "411102",
            value: "源汇区",
            label: "源汇区",
          },
          {
            code: "411103",
            value: "郾城区",
            label: "郾城区",
          },
          {
            code: "411104",
            value: "召陵区",
            label: "召陵区",
          },
          {
            code: "411121",
            value: "舞阳县",
            label: "舞阳县",
          },
          {
            code: "411122",
            value: "临颍县",
            label: "临颍县",
          },
        ],
      },
      {
        code: "411200",
        value: "三门峡市",
        label: "三门峡市",
        children: [
          {
            code: "411202",
            value: "湖滨区",
            label: "湖滨区",
          },
          {
            code: "411203",
            value: "陕州区",
            label: "陕州区",
          },
          {
            code: "411221",
            value: "渑池县",
            label: "渑池县",
          },
          {
            code: "411224",
            value: "卢氏县",
            label: "卢氏县",
          },
          {
            code: "411281",
            value: "义马市",
            label: "义马市",
          },
          {
            code: "411282",
            value: "灵宝市",
            label: "灵宝市",
          },
        ],
      },
      {
        code: "411300",
        value: "南阳市",
        label: "南阳市",
        children: [
          {
            code: "411302",
            value: "宛城区",
            label: "宛城区",
          },
          {
            code: "411303",
            value: "卧龙区",
            label: "卧龙区",
          },
          {
            code: "411321",
            value: "南召县",
            label: "南召县",
          },
          {
            code: "411322",
            value: "方城县",
            label: "方城县",
          },
          {
            code: "411323",
            value: "西峡县",
            label: "西峡县",
          },
          {
            code: "411324",
            value: "镇平县",
            label: "镇平县",
          },
          {
            code: "411325",
            value: "内乡县",
            label: "内乡县",
          },
          {
            code: "411326",
            value: "淅川县",
            label: "淅川县",
          },
          {
            code: "411327",
            value: "社旗县",
            label: "社旗县",
          },
          {
            code: "411328",
            value: "唐河县",
            label: "唐河县",
          },
          {
            code: "411329",
            value: "新野县",
            label: "新野县",
          },
          {
            code: "411330",
            value: "桐柏县",
            label: "桐柏县",
          },
          {
            code: "411381",
            value: "邓州市",
            label: "邓州市",
          },
        ],
      },
      {
        code: "411400",
        value: "商丘市",
        label: "商丘市",
        children: [
          {
            code: "411402",
            value: "梁园区",
            label: "梁园区",
          },
          {
            code: "411403",
            value: "睢阳区",
            label: "睢阳区",
          },
          {
            code: "411421",
            value: "民权县",
            label: "民权县",
          },
          {
            code: "411422",
            value: "睢县",
            label: "睢县",
          },
          {
            code: "411423",
            value: "宁陵县",
            label: "宁陵县",
          },
          {
            code: "411424",
            value: "柘城县",
            label: "柘城县",
          },
          {
            code: "411425",
            value: "虞城县",
            label: "虞城县",
          },
          {
            code: "411426",
            value: "夏邑县",
            label: "夏邑县",
          },
          {
            code: "411481",
            value: "永城市",
            label: "永城市",
          },
        ],
      },
      {
        code: "411500",
        value: "信阳市",
        label: "信阳市",
        children: [
          {
            code: "411502",
            value: "浉河区",
            label: "浉河区",
          },
          {
            code: "411503",
            value: "平桥区",
            label: "平桥区",
          },
          {
            code: "411521",
            value: "罗山县",
            label: "罗山县",
          },
          {
            code: "411522",
            value: "光山县",
            label: "光山县",
          },
          {
            code: "411523",
            value: "新县",
            label: "新县",
          },
          {
            code: "411524",
            value: "商城县",
            label: "商城县",
          },
          {
            code: "411525",
            value: "固始县",
            label: "固始县",
          },
          {
            code: "411526",
            value: "潢川县",
            label: "潢川县",
          },
          {
            code: "411527",
            value: "淮滨县",
            label: "淮滨县",
          },
          {
            code: "411528",
            value: "息县",
            label: "息县",
          },
        ],
      },
      {
        code: "411600",
        value: "周口市",
        label: "周口市",
        children: [
          {
            code: "411602",
            value: "川汇区",
            label: "川汇区",
          },
          {
            code: "411603",
            value: "淮阳区",
            label: "淮阳区",
          },
          {
            code: "411621",
            value: "扶沟县",
            label: "扶沟县",
          },
          {
            code: "411622",
            value: "西华县",
            label: "西华县",
          },
          {
            code: "411623",
            value: "商水县",
            label: "商水县",
          },
          {
            code: "411624",
            value: "沈丘县",
            label: "沈丘县",
          },
          {
            code: "411625",
            value: "郸城县",
            label: "郸城县",
          },
          {
            code: "411627",
            value: "太康县",
            label: "太康县",
          },
          {
            code: "411628",
            value: "鹿邑县",
            label: "鹿邑县",
          },
          {
            code: "411681",
            value: "项城市",
            label: "项城市",
          },
        ],
      },
      {
        code: "411700",
        value: "驻马店市",
        label: "驻马店市",
        children: [
          {
            code: "411702",
            value: "驿城区",
            label: "驿城区",
          },
          {
            code: "411721",
            value: "西平县",
            label: "西平县",
          },
          {
            code: "411722",
            value: "上蔡县",
            label: "上蔡县",
          },
          {
            code: "411723",
            value: "平舆县",
            label: "平舆县",
          },
          {
            code: "411724",
            value: "正阳县",
            label: "正阳县",
          },
          {
            code: "411725",
            value: "确山县",
            label: "确山县",
          },
          {
            code: "411726",
            value: "泌阳县",
            label: "泌阳县",
          },
          {
            code: "411727",
            value: "汝南县",
            label: "汝南县",
          },
          {
            code: "411728",
            value: "遂平县",
            label: "遂平县",
          },
          {
            code: "411729",
            value: "新蔡县",
            label: "新蔡县",
          },
        ],
      },
    ],
  },
  {
    code: "420000",
    value: "湖北省",
    label: "湖北省",
    children: [
      {
        code: "420100",
        value: "武汉市",
        label: "武汉市",
        children: [
          {
            code: "420102",
            value: "江岸区",
            label: "江岸区",
          },
          {
            code: "420103",
            value: "江汉区",
            label: "江汉区",
          },
          {
            code: "420104",
            value: "硚口区",
            label: "硚口区",
          },
          {
            code: "420105",
            value: "汉阳区",
            label: "汉阳区",
          },
          {
            code: "420106",
            value: "武昌区",
            label: "武昌区",
          },
          {
            code: "420107",
            value: "青山区",
            label: "青山区",
          },
          {
            code: "420111",
            value: "洪山区",
            label: "洪山区",
          },
          {
            code: "420112",
            value: "东西湖区",
            label: "东西湖区",
          },
          {
            code: "420113",
            value: "汉南区",
            label: "汉南区",
          },
          {
            code: "420114",
            value: "蔡甸区",
            label: "蔡甸区",
          },
          {
            code: "420115",
            value: "江夏区",
            label: "江夏区",
          },
          {
            code: "420116",
            value: "黄陂区",
            label: "黄陂区",
          },
          {
            code: "420117",
            value: "新洲区",
            label: "新洲区",
          },
        ],
      },
      {
        code: "420200",
        value: "黄石市",
        label: "黄石市",
        children: [
          {
            code: "420202",
            value: "黄石港区",
            label: "黄石港区",
          },
          {
            code: "420203",
            value: "西塞山区",
            label: "西塞山区",
          },
          {
            code: "420204",
            value: "下陆区",
            label: "下陆区",
          },
          {
            code: "420205",
            value: "铁山区",
            label: "铁山区",
          },
          {
            code: "420222",
            value: "阳新县",
            label: "阳新县",
          },
          {
            code: "420281",
            value: "大冶市",
            label: "大冶市",
          },
        ],
      },
      {
        code: "420300",
        value: "十堰市",
        label: "十堰市",
        children: [
          {
            code: "420302",
            value: "茅箭区",
            label: "茅箭区",
          },
          {
            code: "420303",
            value: "张湾区",
            label: "张湾区",
          },
          {
            code: "420304",
            value: "郧阳区",
            label: "郧阳区",
          },
          {
            code: "420322",
            value: "郧西县",
            label: "郧西县",
          },
          {
            code: "420323",
            value: "竹山县",
            label: "竹山县",
          },
          {
            code: "420324",
            value: "竹溪县",
            label: "竹溪县",
          },
          {
            code: "420325",
            value: "房县",
            label: "房县",
          },
          {
            code: "420381",
            value: "丹江口市",
            label: "丹江口市",
          },
        ],
      },
      {
        code: "420500",
        value: "宜昌市",
        label: "宜昌市",
        children: [
          {
            code: "420502",
            value: "西陵区",
            label: "西陵区",
          },
          {
            code: "420503",
            value: "伍家岗区",
            label: "伍家岗区",
          },
          {
            code: "420504",
            value: "点军区",
            label: "点军区",
          },
          {
            code: "420505",
            value: "猇亭区",
            label: "猇亭区",
          },
          {
            code: "420506",
            value: "夷陵区",
            label: "夷陵区",
          },
          {
            code: "420525",
            value: "远安县",
            label: "远安县",
          },
          {
            code: "420526",
            value: "兴山县",
            label: "兴山县",
          },
          {
            code: "420527",
            value: "秭归县",
            label: "秭归县",
          },
          {
            code: "420528",
            value: "长阳土家族自治县",
            label: "长阳土家族自治县",
          },
          {
            code: "420529",
            value: "五峰土家族自治县",
            label: "五峰土家族自治县",
          },
          {
            code: "420581",
            value: "宜都市",
            label: "宜都市",
          },
          {
            code: "420582",
            value: "当阳市",
            label: "当阳市",
          },
          {
            code: "420583",
            value: "枝江市",
            label: "枝江市",
          },
        ],
      },
      {
        code: "420600",
        value: "襄阳市",
        label: "襄阳市",
        children: [
          {
            code: "420602",
            value: "襄城区",
            label: "襄城区",
          },
          {
            code: "420606",
            value: "樊城区",
            label: "樊城区",
          },
          {
            code: "420607",
            value: "襄州区",
            label: "襄州区",
          },
          {
            code: "420624",
            value: "南漳县",
            label: "南漳县",
          },
          {
            code: "420625",
            value: "谷城县",
            label: "谷城县",
          },
          {
            code: "420626",
            value: "保康县",
            label: "保康县",
          },
          {
            code: "420682",
            value: "老河口市",
            label: "老河口市",
          },
          {
            code: "420683",
            value: "枣阳市",
            label: "枣阳市",
          },
          {
            code: "420684",
            value: "宜城市",
            label: "宜城市",
          },
        ],
      },
      {
        code: "420700",
        value: "鄂州市",
        label: "鄂州市",
        children: [
          {
            code: "420702",
            value: "梁子湖区",
            label: "梁子湖区",
          },
          {
            code: "420703",
            value: "华容区",
            label: "华容区",
          },
          {
            code: "420704",
            value: "鄂城区",
            label: "鄂城区",
          },
        ],
      },
      {
        code: "420800",
        value: "荆门市",
        label: "荆门市",
        children: [
          {
            code: "420802",
            value: "东宝区",
            label: "东宝区",
          },
          {
            code: "420804",
            value: "掇刀区",
            label: "掇刀区",
          },
          {
            code: "420822",
            value: "沙洋县",
            label: "沙洋县",
          },
          {
            code: "420881",
            value: "钟祥市",
            label: "钟祥市",
          },
          {
            code: "420882",
            value: "京山市",
            label: "京山市",
          },
        ],
      },
      {
        code: "420900",
        value: "孝感市",
        label: "孝感市",
        children: [
          {
            code: "420902",
            value: "孝南区",
            label: "孝南区",
          },
          {
            code: "420921",
            value: "孝昌县",
            label: "孝昌县",
          },
          {
            code: "420922",
            value: "大悟县",
            label: "大悟县",
          },
          {
            code: "420923",
            value: "云梦县",
            label: "云梦县",
          },
          {
            code: "420981",
            value: "应城市",
            label: "应城市",
          },
          {
            code: "420982",
            value: "安陆市",
            label: "安陆市",
          },
          {
            code: "420984",
            value: "汉川市",
            label: "汉川市",
          },
        ],
      },
      {
        code: "421000",
        value: "荆州市",
        label: "荆州市",
        children: [
          {
            code: "421002",
            value: "沙市区",
            label: "沙市区",
          },
          {
            code: "421003",
            value: "荆州区",
            label: "荆州区",
          },
          {
            code: "421022",
            value: "公安县",
            label: "公安县",
          },
          {
            code: "421024",
            value: "江陵县",
            label: "江陵县",
          },
          {
            code: "421081",
            value: "石首市",
            label: "石首市",
          },
          {
            code: "421083",
            value: "洪湖市",
            label: "洪湖市",
          },
          {
            code: "421087",
            value: "松滋市",
            label: "松滋市",
          },
          {
            code: "421088",
            value: "监利市",
            label: "监利市",
          },
        ],
      },
      {
        code: "421100",
        value: "黄冈市",
        label: "黄冈市",
        children: [
          {
            code: "421102",
            value: "黄州区",
            label: "黄州区",
          },
          {
            code: "421121",
            value: "团风县",
            label: "团风县",
          },
          {
            code: "421122",
            value: "红安县",
            label: "红安县",
          },
          {
            code: "421123",
            value: "罗田县",
            label: "罗田县",
          },
          {
            code: "421124",
            value: "英山县",
            label: "英山县",
          },
          {
            code: "421125",
            value: "浠水县",
            label: "浠水县",
          },
          {
            code: "421126",
            value: "蕲春县",
            label: "蕲春县",
          },
          {
            code: "421127",
            value: "黄梅县",
            label: "黄梅县",
          },
          {
            code: "421181",
            value: "麻城市",
            label: "麻城市",
          },
          {
            code: "421182",
            value: "武穴市",
            label: "武穴市",
          },
        ],
      },
      {
        code: "421200",
        value: "咸宁市",
        label: "咸宁市",
        children: [
          {
            code: "421202",
            value: "咸安区",
            label: "咸安区",
          },
          {
            code: "421221",
            value: "嘉鱼县",
            label: "嘉鱼县",
          },
          {
            code: "421222",
            value: "通城县",
            label: "通城县",
          },
          {
            code: "421223",
            value: "崇阳县",
            label: "崇阳县",
          },
          {
            code: "421224",
            value: "通山县",
            label: "通山县",
          },
          {
            code: "421281",
            value: "赤壁市",
            label: "赤壁市",
          },
        ],
      },
      {
        code: "421300",
        value: "随州市",
        label: "随州市",
        children: [
          {
            code: "421303",
            value: "曾都区",
            label: "曾都区",
          },
          {
            code: "421321",
            value: "随县",
            label: "随县",
          },
          {
            code: "421381",
            value: "广水市",
            label: "广水市",
          },
        ],
      },
      {
        code: "422800",
        value: "恩施土家族苗族自治州",
        label: "恩施土家族苗族自治州",
        children: [
          {
            code: "422801",
            value: "恩施市",
            label: "恩施市",
          },
          {
            code: "422802",
            value: "利川市",
            label: "利川市",
          },
          {
            code: "422822",
            value: "建始县",
            label: "建始县",
          },
          {
            code: "422823",
            value: "巴东县",
            label: "巴东县",
          },
          {
            code: "422825",
            value: "宣恩县",
            label: "宣恩县",
          },
          {
            code: "422826",
            value: "咸丰县",
            label: "咸丰县",
          },
          {
            code: "422827",
            value: "来凤县",
            label: "来凤县",
          },
          {
            code: "422828",
            value: "鹤峰县",
            label: "鹤峰县",
          },
        ],
      },
    ],
  },
  {
    code: "430000",
    value: "湖南省",
    label: "湖南省",
    children: [
      {
        code: "430100",
        value: "长沙市",
        label: "长沙市",
        children: [
          {
            code: "430102",
            value: "芙蓉区",
            label: "芙蓉区",
          },
          {
            code: "430103",
            value: "天心区",
            label: "天心区",
          },
          {
            code: "430104",
            value: "岳麓区",
            label: "岳麓区",
          },
          {
            code: "430105",
            value: "开福区",
            label: "开福区",
          },
          {
            code: "430111",
            value: "雨花区",
            label: "雨花区",
          },
          {
            code: "430112",
            value: "望城区",
            label: "望城区",
          },
          {
            code: "430121",
            value: "长沙县",
            label: "长沙县",
          },
          {
            code: "430181",
            value: "浏阳市",
            label: "浏阳市",
          },
          {
            code: "430182",
            value: "宁乡市",
            label: "宁乡市",
          },
        ],
      },
      {
        code: "430200",
        value: "株洲市",
        label: "株洲市",
        children: [
          {
            code: "430202",
            value: "荷塘区",
            label: "荷塘区",
          },
          {
            code: "430203",
            value: "芦淞区",
            label: "芦淞区",
          },
          {
            code: "430204",
            value: "石峰区",
            label: "石峰区",
          },
          {
            code: "430211",
            value: "天元区",
            label: "天元区",
          },
          {
            code: "430212",
            value: "渌口区",
            label: "渌口区",
          },
          {
            code: "430223",
            value: "攸县",
            label: "攸县",
          },
          {
            code: "430224",
            value: "茶陵县",
            label: "茶陵县",
          },
          {
            code: "430225",
            value: "炎陵县",
            label: "炎陵县",
          },
          {
            code: "430281",
            value: "醴陵市",
            label: "醴陵市",
          },
        ],
      },
      {
        code: "430300",
        value: "湘潭市",
        label: "湘潭市",
        children: [
          {
            code: "430302",
            value: "雨湖区",
            label: "雨湖区",
          },
          {
            code: "430304",
            value: "岳塘区",
            label: "岳塘区",
          },
          {
            code: "430321",
            value: "湘潭县",
            label: "湘潭县",
          },
          {
            code: "430381",
            value: "湘乡市",
            label: "湘乡市",
          },
          {
            code: "430382",
            value: "韶山市",
            label: "韶山市",
          },
        ],
      },
      {
        code: "430400",
        value: "衡阳市",
        label: "衡阳市",
        children: [
          {
            code: "430405",
            value: "珠晖区",
            label: "珠晖区",
          },
          {
            code: "430406",
            value: "雁峰区",
            label: "雁峰区",
          },
          {
            code: "430407",
            value: "石鼓区",
            label: "石鼓区",
          },
          {
            code: "430408",
            value: "蒸湘区",
            label: "蒸湘区",
          },
          {
            code: "430412",
            value: "南岳区",
            label: "南岳区",
          },
          {
            code: "430421",
            value: "衡阳县",
            label: "衡阳县",
          },
          {
            code: "430422",
            value: "衡南县",
            label: "衡南县",
          },
          {
            code: "430423",
            value: "衡山县",
            label: "衡山县",
          },
          {
            code: "430424",
            value: "衡东县",
            label: "衡东县",
          },
          {
            code: "430426",
            value: "祁东县",
            label: "祁东县",
          },
          {
            code: "430481",
            value: "耒阳市",
            label: "耒阳市",
          },
          {
            code: "430482",
            value: "常宁市",
            label: "常宁市",
          },
        ],
      },
      {
        code: "430500",
        value: "邵阳市",
        label: "邵阳市",
        children: [
          {
            code: "430502",
            value: "双清区",
            label: "双清区",
          },
          {
            code: "430503",
            value: "大祥区",
            label: "大祥区",
          },
          {
            code: "430511",
            value: "北塔区",
            label: "北塔区",
          },
          {
            code: "430522",
            value: "新邵县",
            label: "新邵县",
          },
          {
            code: "430523",
            value: "邵阳县",
            label: "邵阳县",
          },
          {
            code: "430524",
            value: "隆回县",
            label: "隆回县",
          },
          {
            code: "430525",
            value: "洞口县",
            label: "洞口县",
          },
          {
            code: "430527",
            value: "绥宁县",
            label: "绥宁县",
          },
          {
            code: "430528",
            value: "新宁县",
            label: "新宁县",
          },
          {
            code: "430529",
            value: "城步苗族自治县",
            label: "城步苗族自治县",
          },
          {
            code: "430581",
            value: "武冈市",
            label: "武冈市",
          },
          {
            code: "430582",
            value: "邵东市",
            label: "邵东市",
          },
        ],
      },
      {
        code: "430600",
        value: "岳阳市",
        label: "岳阳市",
        children: [
          {
            code: "430602",
            value: "岳阳楼区",
            label: "岳阳楼区",
          },
          {
            code: "430603",
            value: "云溪区",
            label: "云溪区",
          },
          {
            code: "430611",
            value: "君山区",
            label: "君山区",
          },
          {
            code: "430621",
            value: "岳阳县",
            label: "岳阳县",
          },
          {
            code: "430623",
            value: "华容县",
            label: "华容县",
          },
          {
            code: "430624",
            value: "湘阴县",
            label: "湘阴县",
          },
          {
            code: "430626",
            value: "平江县",
            label: "平江县",
          },
          {
            code: "430681",
            value: "汨罗市",
            label: "汨罗市",
          },
          {
            code: "430682",
            value: "临湘市",
            label: "临湘市",
          },
        ],
      },
      {
        code: "430700",
        value: "常德市",
        label: "常德市",
        children: [
          {
            code: "430702",
            value: "武陵区",
            label: "武陵区",
          },
          {
            code: "430703",
            value: "鼎城区",
            label: "鼎城区",
          },
          {
            code: "430721",
            value: "安乡县",
            label: "安乡县",
          },
          {
            code: "430722",
            value: "汉寿县",
            label: "汉寿县",
          },
          {
            code: "430723",
            value: "澧县",
            label: "澧县",
          },
          {
            code: "430724",
            value: "临澧县",
            label: "临澧县",
          },
          {
            code: "430725",
            value: "桃源县",
            label: "桃源县",
          },
          {
            code: "430726",
            value: "石门县",
            label: "石门县",
          },
          {
            code: "430781",
            value: "津市市",
            label: "津市市",
          },
        ],
      },
      {
        code: "430800",
        value: "张家界市",
        label: "张家界市",
        children: [
          {
            code: "430802",
            value: "永定区",
            label: "永定区",
          },
          {
            code: "430811",
            value: "武陵源区",
            label: "武陵源区",
          },
          {
            code: "430821",
            value: "慈利县",
            label: "慈利县",
          },
          {
            code: "430822",
            value: "桑植县",
            label: "桑植县",
          },
        ],
      },
      {
        code: "430900",
        value: "益阳市",
        label: "益阳市",
        children: [
          {
            code: "430902",
            value: "资阳区",
            label: "资阳区",
          },
          {
            code: "430903",
            value: "赫山区",
            label: "赫山区",
          },
          {
            code: "430921",
            value: "南县",
            label: "南县",
          },
          {
            code: "430922",
            value: "桃江县",
            label: "桃江县",
          },
          {
            code: "430923",
            value: "安化县",
            label: "安化县",
          },
          {
            code: "430981",
            value: "沅江市",
            label: "沅江市",
          },
        ],
      },
      {
        code: "431000",
        value: "郴州市",
        label: "郴州市",
        children: [
          {
            code: "431002",
            value: "北湖区",
            label: "北湖区",
          },
          {
            code: "431003",
            value: "苏仙区",
            label: "苏仙区",
          },
          {
            code: "431021",
            value: "桂阳县",
            label: "桂阳县",
          },
          {
            code: "431022",
            value: "宜章县",
            label: "宜章县",
          },
          {
            code: "431023",
            value: "永兴县",
            label: "永兴县",
          },
          {
            code: "431024",
            value: "嘉禾县",
            label: "嘉禾县",
          },
          {
            code: "431025",
            value: "临武县",
            label: "临武县",
          },
          {
            code: "431026",
            value: "汝城县",
            label: "汝城县",
          },
          {
            code: "431027",
            value: "桂东县",
            label: "桂东县",
          },
          {
            code: "431028",
            value: "安仁县",
            label: "安仁县",
          },
          {
            code: "431081",
            value: "资兴市",
            label: "资兴市",
          },
        ],
      },
      {
        code: "431100",
        value: "永州市",
        label: "永州市",
        children: [
          {
            code: "431102",
            value: "零陵区",
            label: "零陵区",
          },
          {
            code: "431103",
            value: "冷水滩区",
            label: "冷水滩区",
          },
          {
            code: "431121",
            value: "祁阳县",
            label: "祁阳县",
          },
          {
            code: "431122",
            value: "东安县",
            label: "东安县",
          },
          {
            code: "431123",
            value: "双牌县",
            label: "双牌县",
          },
          {
            code: "431124",
            value: "道县",
            label: "道县",
          },
          {
            code: "431125",
            value: "江永县",
            label: "江永县",
          },
          {
            code: "431126",
            value: "宁远县",
            label: "宁远县",
          },
          {
            code: "431127",
            value: "蓝山县",
            label: "蓝山县",
          },
          {
            code: "431128",
            value: "新田县",
            label: "新田县",
          },
          {
            code: "431129",
            value: "江华瑶族自治县",
            label: "江华瑶族自治县",
          },
        ],
      },
      {
        code: "431200",
        value: "怀化市",
        label: "怀化市",
        children: [
          {
            code: "431202",
            value: "鹤城区",
            label: "鹤城区",
          },
          {
            code: "431221",
            value: "中方县",
            label: "中方县",
          },
          {
            code: "431222",
            value: "沅陵县",
            label: "沅陵县",
          },
          {
            code: "431223",
            value: "辰溪县",
            label: "辰溪县",
          },
          {
            code: "431224",
            value: "溆浦县",
            label: "溆浦县",
          },
          {
            code: "431225",
            value: "会同县",
            label: "会同县",
          },
          {
            code: "431226",
            value: "麻阳苗族自治县",
            label: "麻阳苗族自治县",
          },
          {
            code: "431227",
            value: "新晃侗族自治县",
            label: "新晃侗族自治县",
          },
          {
            code: "431228",
            value: "芷江侗族自治县",
            label: "芷江侗族自治县",
          },
          {
            code: "431229",
            value: "靖州苗族侗族自治县",
            label: "靖州苗族侗族自治县",
          },
          {
            code: "431230",
            value: "通道侗族自治县",
            label: "通道侗族自治县",
          },
          {
            code: "431281",
            value: "洪江市",
            label: "洪江市",
          },
        ],
      },
      {
        code: "431300",
        value: "娄底市",
        label: "娄底市",
        children: [
          {
            code: "431302",
            value: "娄星区",
            label: "娄星区",
          },
          {
            code: "431321",
            value: "双峰县",
            label: "双峰县",
          },
          {
            code: "431322",
            value: "新化县",
            label: "新化县",
          },
          {
            code: "431381",
            value: "冷水江市",
            label: "冷水江市",
          },
          {
            code: "431382",
            value: "涟源市",
            label: "涟源市",
          },
        ],
      },
      {
        code: "433100",
        value: "湘西土家族苗族自治州",
        label: "湘西土家族苗族自治州",
        children: [
          {
            code: "433101",
            value: "吉首市",
            label: "吉首市",
          },
          {
            code: "433122",
            value: "泸溪县",
            label: "泸溪县",
          },
          {
            code: "433123",
            value: "凤凰县",
            label: "凤凰县",
          },
          {
            code: "433124",
            value: "花垣县",
            label: "花垣县",
          },
          {
            code: "433125",
            value: "保靖县",
            label: "保靖县",
          },
          {
            code: "433126",
            value: "古丈县",
            label: "古丈县",
          },
          {
            code: "433127",
            value: "永顺县",
            label: "永顺县",
          },
          {
            code: "433130",
            value: "龙山县",
            label: "龙山县",
          },
        ],
      },
    ],
  },
  {
    code: "440000",
    value: "广东省",
    label: "广东省",
    children: [
      {
        code: "440100",
        value: "广州市",
        label: "广州市",
        children: [
          {
            code: "440103",
            value: "荔湾区",
            label: "荔湾区",
          },
          {
            code: "440104",
            value: "越秀区",
            label: "越秀区",
          },
          {
            code: "440105",
            value: "海珠区",
            label: "海珠区",
          },
          {
            code: "440106",
            value: "天河区",
            label: "天河区",
          },
          {
            code: "440111",
            value: "白云区",
            label: "白云区",
          },
          {
            code: "440112",
            value: "黄埔区",
            label: "黄埔区",
          },
          {
            code: "440113",
            value: "番禺区",
            label: "番禺区",
          },
          {
            code: "440114",
            value: "花都区",
            label: "花都区",
          },
          {
            code: "440115",
            value: "南沙区",
            label: "南沙区",
          },
          {
            code: "440117",
            value: "从化区",
            label: "从化区",
          },
          {
            code: "440118",
            value: "增城区",
            label: "增城区",
          },
        ],
      },
      {
        code: "440200",
        value: "韶关市",
        label: "韶关市",
        children: [
          {
            code: "440203",
            value: "武江区",
            label: "武江区",
          },
          {
            code: "440204",
            value: "浈江区",
            label: "浈江区",
          },
          {
            code: "440205",
            value: "曲江区",
            label: "曲江区",
          },
          {
            code: "440222",
            value: "始兴县",
            label: "始兴县",
          },
          {
            code: "440224",
            value: "仁化县",
            label: "仁化县",
          },
          {
            code: "440229",
            value: "翁源县",
            label: "翁源县",
          },
          {
            code: "440232",
            value: "乳源瑶族自治县",
            label: "乳源瑶族自治县",
          },
          {
            code: "440233",
            value: "新丰县",
            label: "新丰县",
          },
          {
            code: "440281",
            value: "乐昌市",
            label: "乐昌市",
          },
          {
            code: "440282",
            value: "南雄市",
            label: "南雄市",
          },
        ],
      },
      {
        code: "440300",
        value: "深圳市",
        label: "深圳市",
        children: [
          {
            code: "440303",
            value: "罗湖区",
            label: "罗湖区",
          },
          {
            code: "440304",
            value: "福田区",
            label: "福田区",
          },
          {
            code: "440305",
            value: "南山区",
            label: "南山区",
          },
          {
            code: "440306",
            value: "宝安区",
            label: "宝安区",
          },
          {
            code: "440307",
            value: "龙岗区",
            label: "龙岗区",
          },
          {
            code: "440308",
            value: "盐田区",
            label: "盐田区",
          },
          {
            code: "440309",
            value: "龙华区",
            label: "龙华区",
          },
          {
            code: "440310",
            value: "坪山区",
            label: "坪山区",
          },
          {
            code: "440311",
            value: "光明区",
            label: "光明区",
          },
        ],
      },
      {
        code: "440400",
        value: "珠海市",
        label: "珠海市",
        children: [
          {
            code: "440402",
            value: "香洲区",
            label: "香洲区",
          },
          {
            code: "440403",
            value: "斗门区",
            label: "斗门区",
          },
          {
            code: "440404",
            value: "金湾区",
            label: "金湾区",
          },
        ],
      },
      {
        code: "440500",
        value: "汕头市",
        label: "汕头市",
        children: [
          {
            code: "440507",
            value: "龙湖区",
            label: "龙湖区",
          },
          {
            code: "440511",
            value: "金平区",
            label: "金平区",
          },
          {
            code: "440512",
            value: "濠江区",
            label: "濠江区",
          },
          {
            code: "440513",
            value: "潮阳区",
            label: "潮阳区",
          },
          {
            code: "440514",
            value: "潮南区",
            label: "潮南区",
          },
          {
            code: "440515",
            value: "澄海区",
            label: "澄海区",
          },
          {
            code: "440523",
            value: "南澳县",
            label: "南澳县",
          },
        ],
      },
      {
        code: "440600",
        value: "佛山市",
        label: "佛山市",
        children: [
          {
            code: "440604",
            value: "禅城区",
            label: "禅城区",
          },
          {
            code: "440605",
            value: "南海区",
            label: "南海区",
          },
          {
            code: "440606",
            value: "顺德区",
            label: "顺德区",
          },
          {
            code: "440607",
            value: "三水区",
            label: "三水区",
          },
          {
            code: "440608",
            value: "高明区",
            label: "高明区",
          },
        ],
      },
      {
        code: "440700",
        value: "江门市",
        label: "江门市",
        children: [
          {
            code: "440703",
            value: "蓬江区",
            label: "蓬江区",
          },
          {
            code: "440704",
            value: "江海区",
            label: "江海区",
          },
          {
            code: "440705",
            value: "新会区",
            label: "新会区",
          },
          {
            code: "440781",
            value: "台山市",
            label: "台山市",
          },
          {
            code: "440783",
            value: "开平市",
            label: "开平市",
          },
          {
            code: "440784",
            value: "鹤山市",
            label: "鹤山市",
          },
          {
            code: "440785",
            value: "恩平市",
            label: "恩平市",
          },
        ],
      },
      {
        code: "440800",
        value: "湛江市",
        label: "湛江市",
        children: [
          {
            code: "440802",
            value: "赤坎区",
            label: "赤坎区",
          },
          {
            code: "440803",
            value: "霞山区",
            label: "霞山区",
          },
          {
            code: "440804",
            value: "坡头区",
            label: "坡头区",
          },
          {
            code: "440811",
            value: "麻章区",
            label: "麻章区",
          },
          {
            code: "440823",
            value: "遂溪县",
            label: "遂溪县",
          },
          {
            code: "440825",
            value: "徐闻县",
            label: "徐闻县",
          },
          {
            code: "440881",
            value: "廉江市",
            label: "廉江市",
          },
          {
            code: "440882",
            value: "雷州市",
            label: "雷州市",
          },
          {
            code: "440883",
            value: "吴川市",
            label: "吴川市",
          },
        ],
      },
      {
        code: "440900",
        value: "茂名市",
        label: "茂名市",
        children: [
          {
            code: "440902",
            value: "茂南区",
            label: "茂南区",
          },
          {
            code: "440904",
            value: "电白区",
            label: "电白区",
          },
          {
            code: "440981",
            value: "高州市",
            label: "高州市",
          },
          {
            code: "440982",
            value: "化州市",
            label: "化州市",
          },
          {
            code: "440983",
            value: "信宜市",
            label: "信宜市",
          },
        ],
      },
      {
        code: "441200",
        value: "肇庆市",
        label: "肇庆市",
        children: [
          {
            code: "441202",
            value: "端州区",
            label: "端州区",
          },
          {
            code: "441203",
            value: "鼎湖区",
            label: "鼎湖区",
          },
          {
            code: "441204",
            value: "高要区",
            label: "高要区",
          },
          {
            code: "441223",
            value: "广宁县",
            label: "广宁县",
          },
          {
            code: "441224",
            value: "怀集县",
            label: "怀集县",
          },
          {
            code: "441225",
            value: "封开县",
            label: "封开县",
          },
          {
            code: "441226",
            value: "德庆县",
            label: "德庆县",
          },
          {
            code: "441284",
            value: "四会市",
            label: "四会市",
          },
        ],
      },
      {
        code: "441300",
        value: "惠州市",
        label: "惠州市",
        children: [
          {
            code: "441302",
            value: "惠城区",
            label: "惠城区",
          },
          {
            code: "441303",
            value: "惠阳区",
            label: "惠阳区",
          },
          {
            code: "441322",
            value: "博罗县",
            label: "博罗县",
          },
          {
            code: "441323",
            value: "惠东县",
            label: "惠东县",
          },
          {
            code: "441324",
            value: "龙门县",
            label: "龙门县",
          },
        ],
      },
      {
        code: "441400",
        value: "梅州市",
        label: "梅州市",
        children: [
          {
            code: "441402",
            value: "梅江区",
            label: "梅江区",
          },
          {
            code: "441403",
            value: "梅县区",
            label: "梅县区",
          },
          {
            code: "441422",
            value: "大埔县",
            label: "大埔县",
          },
          {
            code: "441423",
            value: "丰顺县",
            label: "丰顺县",
          },
          {
            code: "441424",
            value: "五华县",
            label: "五华县",
          },
          {
            code: "441426",
            value: "平远县",
            label: "平远县",
          },
          {
            code: "441427",
            value: "蕉岭县",
            label: "蕉岭县",
          },
          {
            code: "441481",
            value: "兴宁市",
            label: "兴宁市",
          },
        ],
      },
      {
        code: "441500",
        value: "汕尾市",
        label: "汕尾市",
        children: [
          {
            code: "441502",
            value: "城区",
            label: "城区",
          },
          {
            code: "441521",
            value: "海丰县",
            label: "海丰县",
          },
          {
            code: "441523",
            value: "陆河县",
            label: "陆河县",
          },
          {
            code: "441581",
            value: "陆丰市",
            label: "陆丰市",
          },
        ],
      },
      {
        code: "441600",
        value: "河源市",
        label: "河源市",
        children: [
          {
            code: "441602",
            value: "源城区",
            label: "源城区",
          },
          {
            code: "441621",
            value: "紫金县",
            label: "紫金县",
          },
          {
            code: "441622",
            value: "龙川县",
            label: "龙川县",
          },
          {
            code: "441623",
            value: "连平县",
            label: "连平县",
          },
          {
            code: "441624",
            value: "和平县",
            label: "和平县",
          },
          {
            code: "441625",
            value: "东源县",
            label: "东源县",
          },
        ],
      },
      {
        code: "441700",
        value: "阳江市",
        label: "阳江市",
        children: [
          {
            code: "441702",
            value: "江城区",
            label: "江城区",
          },
          {
            code: "441704",
            value: "阳东区",
            label: "阳东区",
          },
          {
            code: "441721",
            value: "阳西县",
            label: "阳西县",
          },
          {
            code: "441781",
            value: "阳春市",
            label: "阳春市",
          },
        ],
      },
      {
        code: "441800",
        value: "清远市",
        label: "清远市",
        children: [
          {
            code: "441802",
            value: "清城区",
            label: "清城区",
          },
          {
            code: "441803",
            value: "清新区",
            label: "清新区",
          },
          {
            code: "441821",
            value: "佛冈县",
            label: "佛冈县",
          },
          {
            code: "441823",
            value: "阳山县",
            label: "阳山县",
          },
          {
            code: "441825",
            value: "连山壮族瑶族自治县",
            label: "连山壮族瑶族自治县",
          },
          {
            code: "441826",
            value: "连南瑶族自治县",
            label: "连南瑶族自治县",
          },
          {
            code: "441881",
            value: "英德市",
            label: "英德市",
          },
          {
            code: "441882",
            value: "连州市",
            label: "连州市",
          },
        ],
      },
      {
        code: "441900",
        value: "东莞市",
        label: "东莞市",
        children: [],
      },
      {
        code: "442000",
        value: "中山市",
        label: "中山市",
        children: [],
      },
      {
        code: "445100",
        value: "潮州市",
        label: "潮州市",
        children: [
          {
            code: "445102",
            value: "湘桥区",
            label: "湘桥区",
          },
          {
            code: "445103",
            value: "潮安区",
            label: "潮安区",
          },
          {
            code: "445122",
            value: "饶平县",
            label: "饶平县",
          },
        ],
      },
      {
        code: "445200",
        value: "揭阳市",
        label: "揭阳市",
        children: [
          {
            code: "445202",
            value: "榕城区",
            label: "榕城区",
          },
          {
            code: "445203",
            value: "揭东区",
            label: "揭东区",
          },
          {
            code: "445222",
            value: "揭西县",
            label: "揭西县",
          },
          {
            code: "445224",
            value: "惠来县",
            label: "惠来县",
          },
          {
            code: "445281",
            value: "普宁市",
            label: "普宁市",
          },
        ],
      },
      {
        code: "445300",
        value: "云浮市",
        label: "云浮市",
        children: [
          {
            code: "445302",
            value: "云城区",
            label: "云城区",
          },
          {
            code: "445303",
            value: "云安区",
            label: "云安区",
          },
          {
            code: "445321",
            value: "新兴县",
            label: "新兴县",
          },
          {
            code: "445322",
            value: "郁南县",
            label: "郁南县",
          },
          {
            code: "445381",
            value: "罗定市",
            label: "罗定市",
          },
        ],
      },
    ],
  },
  {
    code: "450000",
    value: "广西壮族自治区",
    label: "广西壮族自治区",
    children: [
      {
        code: "450100",
        value: "南宁市",
        label: "南宁市",
        children: [
          {
            code: "450102",
            value: "兴宁区",
            label: "兴宁区",
          },
          {
            code: "450103",
            value: "青秀区",
            label: "青秀区",
          },
          {
            code: "450105",
            value: "江南区",
            label: "江南区",
          },
          {
            code: "450107",
            value: "西乡塘区",
            label: "西乡塘区",
          },
          {
            code: "450108",
            value: "良庆区",
            label: "良庆区",
          },
          {
            code: "450109",
            value: "邕宁区",
            label: "邕宁区",
          },
          {
            code: "450110",
            value: "武鸣区",
            label: "武鸣区",
          },
          {
            code: "450123",
            value: "隆安县",
            label: "隆安县",
          },
          {
            code: "450124",
            value: "马山县",
            label: "马山县",
          },
          {
            code: "450125",
            value: "上林县",
            label: "上林县",
          },
          {
            code: "450126",
            value: "宾阳县",
            label: "宾阳县",
          },
          {
            code: "450127",
            value: "横县",
            label: "横县",
          },
        ],
      },
      {
        code: "450200",
        value: "柳州市",
        label: "柳州市",
        children: [
          {
            code: "450202",
            value: "城中区",
            label: "城中区",
          },
          {
            code: "450203",
            value: "鱼峰区",
            label: "鱼峰区",
          },
          {
            code: "450204",
            value: "柳南区",
            label: "柳南区",
          },
          {
            code: "450205",
            value: "柳北区",
            label: "柳北区",
          },
          {
            code: "450206",
            value: "柳江区",
            label: "柳江区",
          },
          {
            code: "450222",
            value: "柳城县",
            label: "柳城县",
          },
          {
            code: "450223",
            value: "鹿寨县",
            label: "鹿寨县",
          },
          {
            code: "450224",
            value: "融安县",
            label: "融安县",
          },
          {
            code: "450225",
            value: "融水苗族自治县",
            label: "融水苗族自治县",
          },
          {
            code: "450226",
            value: "三江侗族自治县",
            label: "三江侗族自治县",
          },
        ],
      },
      {
        code: "450300",
        value: "桂林市",
        label: "桂林市",
        children: [
          {
            code: "450302",
            value: "秀峰区",
            label: "秀峰区",
          },
          {
            code: "450303",
            value: "叠彩区",
            label: "叠彩区",
          },
          {
            code: "450304",
            value: "象山区",
            label: "象山区",
          },
          {
            code: "450305",
            value: "七星区",
            label: "七星区",
          },
          {
            code: "450311",
            value: "雁山区",
            label: "雁山区",
          },
          {
            code: "450312",
            value: "临桂区",
            label: "临桂区",
          },
          {
            code: "450321",
            value: "阳朔县",
            label: "阳朔县",
          },
          {
            code: "450323",
            value: "灵川县",
            label: "灵川县",
          },
          {
            code: "450324",
            value: "全州县",
            label: "全州县",
          },
          {
            code: "450325",
            value: "兴安县",
            label: "兴安县",
          },
          {
            code: "450326",
            value: "永福县",
            label: "永福县",
          },
          {
            code: "450327",
            value: "灌阳县",
            label: "灌阳县",
          },
          {
            code: "450328",
            value: "龙胜各族自治县",
            label: "龙胜各族自治县",
          },
          {
            code: "450329",
            value: "资源县",
            label: "资源县",
          },
          {
            code: "450330",
            value: "平乐县",
            label: "平乐县",
          },
          {
            code: "450332",
            value: "恭城瑶族自治县",
            label: "恭城瑶族自治县",
          },
          {
            code: "450381",
            value: "荔浦市",
            label: "荔浦市",
          },
        ],
      },
      {
        code: "450400",
        value: "梧州市",
        label: "梧州市",
        children: [
          {
            code: "450403",
            value: "万秀区",
            label: "万秀区",
          },
          {
            code: "450405",
            value: "长洲区",
            label: "长洲区",
          },
          {
            code: "450406",
            value: "龙圩区",
            label: "龙圩区",
          },
          {
            code: "450421",
            value: "苍梧县",
            label: "苍梧县",
          },
          {
            code: "450422",
            value: "藤县",
            label: "藤县",
          },
          {
            code: "450423",
            value: "蒙山县",
            label: "蒙山县",
          },
          {
            code: "450481",
            value: "岑溪市",
            label: "岑溪市",
          },
        ],
      },
      {
        code: "450500",
        value: "北海市",
        label: "北海市",
        children: [
          {
            code: "450502",
            value: "海城区",
            label: "海城区",
          },
          {
            code: "450503",
            value: "银海区",
            label: "银海区",
          },
          {
            code: "450512",
            value: "铁山港区",
            label: "铁山港区",
          },
          {
            code: "450521",
            value: "合浦县",
            label: "合浦县",
          },
        ],
      },
      {
        code: "450600",
        value: "防城港市",
        label: "防城港市",
        children: [
          {
            code: "450602",
            value: "港口区",
            label: "港口区",
          },
          {
            code: "450603",
            value: "防城区",
            label: "防城区",
          },
          {
            code: "450621",
            value: "上思县",
            label: "上思县",
          },
          {
            code: "450681",
            value: "东兴市",
            label: "东兴市",
          },
        ],
      },
      {
        code: "450700",
        value: "钦州市",
        label: "钦州市",
        children: [
          {
            code: "450702",
            value: "钦南区",
            label: "钦南区",
          },
          {
            code: "450703",
            value: "钦北区",
            label: "钦北区",
          },
          {
            code: "450721",
            value: "灵山县",
            label: "灵山县",
          },
          {
            code: "450722",
            value: "浦北县",
            label: "浦北县",
          },
        ],
      },
      {
        code: "450800",
        value: "贵港市",
        label: "贵港市",
        children: [
          {
            code: "450802",
            value: "港北区",
            label: "港北区",
          },
          {
            code: "450803",
            value: "港南区",
            label: "港南区",
          },
          {
            code: "450804",
            value: "覃塘区",
            label: "覃塘区",
          },
          {
            code: "450821",
            value: "平南县",
            label: "平南县",
          },
          {
            code: "450881",
            value: "桂平市",
            label: "桂平市",
          },
        ],
      },
      {
        code: "450900",
        value: "玉林市",
        label: "玉林市",
        children: [
          {
            code: "450902",
            value: "玉州区",
            label: "玉州区",
          },
          {
            code: "450903",
            value: "福绵区",
            label: "福绵区",
          },
          {
            code: "450921",
            value: "容县",
            label: "容县",
          },
          {
            code: "450922",
            value: "陆川县",
            label: "陆川县",
          },
          {
            code: "450923",
            value: "博白县",
            label: "博白县",
          },
          {
            code: "450924",
            value: "兴业县",
            label: "兴业县",
          },
          {
            code: "450981",
            value: "北流市",
            label: "北流市",
          },
        ],
      },
      {
        code: "451000",
        value: "百色市",
        label: "百色市",
        children: [
          {
            code: "451002",
            value: "右江区",
            label: "右江区",
          },
          {
            code: "451003",
            value: "田阳区",
            label: "田阳区",
          },
          {
            code: "451022",
            value: "田东县",
            label: "田东县",
          },
          {
            code: "451024",
            value: "德保县",
            label: "德保县",
          },
          {
            code: "451026",
            value: "那坡县",
            label: "那坡县",
          },
          {
            code: "451027",
            value: "凌云县",
            label: "凌云县",
          },
          {
            code: "451028",
            value: "乐业县",
            label: "乐业县",
          },
          {
            code: "451029",
            value: "田林县",
            label: "田林县",
          },
          {
            code: "451030",
            value: "西林县",
            label: "西林县",
          },
          {
            code: "451031",
            value: "隆林各族自治县",
            label: "隆林各族自治县",
          },
          {
            code: "451081",
            value: "靖西市",
            label: "靖西市",
          },
          {
            code: "451082",
            value: "平果市",
            label: "平果市",
          },
        ],
      },
      {
        code: "451100",
        value: "贺州市",
        label: "贺州市",
        children: [
          {
            code: "451102",
            value: "八步区",
            label: "八步区",
          },
          {
            code: "451103",
            value: "平桂区",
            label: "平桂区",
          },
          {
            code: "451121",
            value: "昭平县",
            label: "昭平县",
          },
          {
            code: "451122",
            value: "钟山县",
            label: "钟山县",
          },
          {
            code: "451123",
            value: "富川瑶族自治县",
            label: "富川瑶族自治县",
          },
        ],
      },
      {
        code: "451200",
        value: "河池市",
        label: "河池市",
        children: [
          {
            code: "451202",
            value: "金城江区",
            label: "金城江区",
          },
          {
            code: "451203",
            value: "宜州区",
            label: "宜州区",
          },
          {
            code: "451221",
            value: "南丹县",
            label: "南丹县",
          },
          {
            code: "451222",
            value: "天峨县",
            label: "天峨县",
          },
          {
            code: "451223",
            value: "凤山县",
            label: "凤山县",
          },
          {
            code: "451224",
            value: "东兰县",
            label: "东兰县",
          },
          {
            code: "451225",
            value: "罗城仫佬族自治县",
            label: "罗城仫佬族自治县",
          },
          {
            code: "451226",
            value: "环江毛南族自治县",
            label: "环江毛南族自治县",
          },
          {
            code: "451227",
            value: "巴马瑶族自治县",
            label: "巴马瑶族自治县",
          },
          {
            code: "451228",
            value: "都安瑶族自治县",
            label: "都安瑶族自治县",
          },
          {
            code: "451229",
            value: "大化瑶族自治县",
            label: "大化瑶族自治县",
          },
        ],
      },
      {
        code: "451300",
        value: "来宾市",
        label: "来宾市",
        children: [
          {
            code: "451302",
            value: "兴宾区",
            label: "兴宾区",
          },
          {
            code: "451321",
            value: "忻城县",
            label: "忻城县",
          },
          {
            code: "451322",
            value: "象州县",
            label: "象州县",
          },
          {
            code: "451323",
            value: "武宣县",
            label: "武宣县",
          },
          {
            code: "451324",
            value: "金秀瑶族自治县",
            label: "金秀瑶族自治县",
          },
          {
            code: "451381",
            value: "合山市",
            label: "合山市",
          },
        ],
      },
      {
        code: "451400",
        value: "崇左市",
        label: "崇左市",
        children: [
          {
            code: "451402",
            value: "江州区",
            label: "江州区",
          },
          {
            code: "451421",
            value: "扶绥县",
            label: "扶绥县",
          },
          {
            code: "451422",
            value: "宁明县",
            label: "宁明县",
          },
          {
            code: "451423",
            value: "龙州县",
            label: "龙州县",
          },
          {
            code: "451424",
            value: "大新县",
            label: "大新县",
          },
          {
            code: "451425",
            value: "天等县",
            label: "天等县",
          },
          {
            code: "451481",
            value: "凭祥市",
            label: "凭祥市",
          },
        ],
      },
    ],
  },
  {
    code: "460000",
    value: "海南省",
    label: "海南省",
    children: [
      {
        code: "460100",
        value: "海口市",
        label: "海口市",
        children: [
          {
            code: "460105",
            value: "秀英区",
            label: "秀英区",
          },
          {
            code: "460106",
            value: "龙华区",
            label: "龙华区",
          },
          {
            code: "460107",
            value: "琼山区",
            label: "琼山区",
          },
          {
            code: "460108",
            value: "美兰区",
            label: "美兰区",
          },
        ],
      },
      {
        code: "460200",
        value: "三亚市",
        label: "三亚市",
        children: [
          {
            code: "460202",
            value: "海棠区",
            label: "海棠区",
          },
          {
            code: "460203",
            value: "吉阳区",
            label: "吉阳区",
          },
          {
            code: "460204",
            value: "天涯区",
            label: "天涯区",
          },
          {
            code: "460205",
            value: "崖州区",
            label: "崖州区",
          },
        ],
      },
      {
        code: "460300",
        value: "三沙市",
        label: "三沙市",
        children: [],
      },
      {
        code: "460400",
        value: "儋州市",
        label: "儋州市",
        children: [],
      },
    ],
  },
  {
    code: "500000",
    value: "重庆市",
    label: "重庆市",
    children: [
      {
        code: "500101",
        value: "万州区",
        label: "万州区",
      },
      {
        code: "500102",
        value: "涪陵区",
        label: "涪陵区",
      },
      {
        code: "500103",
        value: "渝中区",
        label: "渝中区",
      },
      {
        code: "500104",
        value: "大渡口区",
        label: "大渡口区",
      },
      {
        code: "500105",
        value: "江北区",
        label: "江北区",
      },
      {
        code: "500106",
        value: "沙坪坝区",
        label: "沙坪坝区",
      },
      {
        code: "500107",
        value: "九龙坡区",
        label: "九龙坡区",
      },
      {
        code: "500108",
        value: "南岸区",
        label: "南岸区",
      },
      {
        code: "500109",
        value: "北碚区",
        label: "北碚区",
      },
      {
        code: "500110",
        value: "綦江区",
        label: "綦江区",
      },
      {
        code: "500111",
        value: "大足区",
        label: "大足区",
      },
      {
        code: "500112",
        value: "渝北区",
        label: "渝北区",
      },
      {
        code: "500113",
        value: "巴南区",
        label: "巴南区",
      },
      {
        code: "500114",
        value: "黔江区",
        label: "黔江区",
      },
      {
        code: "500115",
        value: "长寿区",
        label: "长寿区",
      },
      {
        code: "500116",
        value: "江津区",
        label: "江津区",
      },
      {
        code: "500117",
        value: "合川区",
        label: "合川区",
      },
      {
        code: "500118",
        value: "永川区",
        label: "永川区",
      },
      {
        code: "500119",
        value: "南川区",
        label: "南川区",
      },
      {
        code: "500120",
        value: "璧山区",
        label: "璧山区",
      },
      {
        code: "500151",
        value: "铜梁区",
        label: "铜梁区",
      },
      {
        code: "500152",
        value: "潼南区",
        label: "潼南区",
      },
      {
        code: "500153",
        value: "荣昌区",
        label: "荣昌区",
      },
      {
        code: "500154",
        value: "开州区",
        label: "开州区",
      },
      {
        code: "500155",
        value: "梁平区",
        label: "梁平区",
      },
      {
        code: "500156",
        value: "武隆区",
        label: "武隆区",
      },
      {
        code: "500229",
        value: "城口县",
        label: "城口县",
      },
      {
        code: "500230",
        value: "丰都县",
        label: "丰都县",
      },
      {
        code: "500231",
        value: "垫江县",
        label: "垫江县",
      },
      {
        code: "500233",
        value: "忠县",
        label: "忠县",
      },
      {
        code: "500235",
        value: "云阳县",
        label: "云阳县",
      },
      {
        code: "500236",
        value: "奉节县",
        label: "奉节县",
      },
      {
        code: "500237",
        value: "巫山县",
        label: "巫山县",
      },
      {
        code: "500238",
        value: "巫溪县",
        label: "巫溪县",
      },
      {
        code: "500240",
        value: "石柱土家族自治县",
        label: "石柱土家族自治县",
      },
      {
        code: "500241",
        value: "秀山土家族苗族自治县",
        label: "秀山土家族苗族自治县",
      },
      {
        code: "500242",
        value: "酉阳土家族苗族自治县",
        label: "酉阳土家族苗族自治县",
      },
      {
        code: "500243",
        value: "彭水苗族土家族自治县",
        label: "彭水苗族土家族自治县",
      },
    ],
  },
  {
    code: "510000",
    value: "四川省",
    label: "四川省",
    children: [
      {
        code: "510100",
        value: "成都市",
        label: "成都市",
        children: [
          {
            code: "510104",
            value: "锦江区",
            label: "锦江区",
          },
          {
            code: "510105",
            value: "青羊区",
            label: "青羊区",
          },
          {
            code: "510106",
            value: "金牛区",
            label: "金牛区",
          },
          {
            code: "510107",
            value: "武侯区",
            label: "武侯区",
          },
          {
            code: "510108",
            value: "成华区",
            label: "成华区",
          },
          {
            code: "510112",
            value: "龙泉驿区",
            label: "龙泉驿区",
          },
          {
            code: "510113",
            value: "青白江区",
            label: "青白江区",
          },
          {
            code: "510114",
            value: "新都区",
            label: "新都区",
          },
          {
            code: "510115",
            value: "温江区",
            label: "温江区",
          },
          {
            code: "510116",
            value: "双流区",
            label: "双流区",
          },
          {
            code: "510117",
            value: "郫都区",
            label: "郫都区",
          },
          {
            code: "510118",
            value: "新津区",
            label: "新津区",
          },
          {
            code: "510121",
            value: "金堂县",
            label: "金堂县",
          },
          {
            code: "510129",
            value: "大邑县",
            label: "大邑县",
          },
          {
            code: "510131",
            value: "蒲江县",
            label: "蒲江县",
          },
          {
            code: "510181",
            value: "都江堰市",
            label: "都江堰市",
          },
          {
            code: "510182",
            value: "彭州市",
            label: "彭州市",
          },
          {
            code: "510183",
            value: "邛崃市",
            label: "邛崃市",
          },
          {
            code: "510184",
            value: "崇州市",
            label: "崇州市",
          },
          {
            code: "510185",
            value: "简阳市",
            label: "简阳市",
          },
        ],
      },
      {
        code: "510300",
        value: "自贡市",
        label: "自贡市",
        children: [
          {
            code: "510302",
            value: "自流井区",
            label: "自流井区",
          },
          {
            code: "510303",
            value: "贡井区",
            label: "贡井区",
          },
          {
            code: "510304",
            value: "大安区",
            label: "大安区",
          },
          {
            code: "510311",
            value: "沿滩区",
            label: "沿滩区",
          },
          {
            code: "510321",
            value: "荣县",
            label: "荣县",
          },
          {
            code: "510322",
            value: "富顺县",
            label: "富顺县",
          },
        ],
      },
      {
        code: "510400",
        value: "攀枝花市",
        label: "攀枝花市",
        children: [
          {
            code: "510402",
            value: "东区",
            label: "东区",
          },
          {
            code: "510403",
            value: "西区",
            label: "西区",
          },
          {
            code: "510411",
            value: "仁和区",
            label: "仁和区",
          },
          {
            code: "510421",
            value: "米易县",
            label: "米易县",
          },
          {
            code: "510422",
            value: "盐边县",
            label: "盐边县",
          },
        ],
      },
      {
        code: "510500",
        value: "泸州市",
        label: "泸州市",
        children: [
          {
            code: "510502",
            value: "江阳区",
            label: "江阳区",
          },
          {
            code: "510503",
            value: "纳溪区",
            label: "纳溪区",
          },
          {
            code: "510504",
            value: "龙马潭区",
            label: "龙马潭区",
          },
          {
            code: "510521",
            value: "泸县",
            label: "泸县",
          },
          {
            code: "510522",
            value: "合江县",
            label: "合江县",
          },
          {
            code: "510524",
            value: "叙永县",
            label: "叙永县",
          },
          {
            code: "510525",
            value: "古蔺县",
            label: "古蔺县",
          },
        ],
      },
      {
        code: "510600",
        value: "德阳市",
        label: "德阳市",
        children: [
          {
            code: "510603",
            value: "旌阳区",
            label: "旌阳区",
          },
          {
            code: "510604",
            value: "罗江区",
            label: "罗江区",
          },
          {
            code: "510623",
            value: "中江县",
            label: "中江县",
          },
          {
            code: "510681",
            value: "广汉市",
            label: "广汉市",
          },
          {
            code: "510682",
            value: "什邡市",
            label: "什邡市",
          },
          {
            code: "510683",
            value: "绵竹市",
            label: "绵竹市",
          },
        ],
      },
      {
        code: "510700",
        value: "绵阳市",
        label: "绵阳市",
        children: [
          {
            code: "510703",
            value: "涪城区",
            label: "涪城区",
          },
          {
            code: "510704",
            value: "游仙区",
            label: "游仙区",
          },
          {
            code: "510705",
            value: "安州区",
            label: "安州区",
          },
          {
            code: "510722",
            value: "三台县",
            label: "三台县",
          },
          {
            code: "510723",
            value: "盐亭县",
            label: "盐亭县",
          },
          {
            code: "510725",
            value: "梓潼县",
            label: "梓潼县",
          },
          {
            code: "510726",
            value: "北川羌族自治县",
            label: "北川羌族自治县",
          },
          {
            code: "510727",
            value: "平武县",
            label: "平武县",
          },
          {
            code: "510781",
            value: "江油市",
            label: "江油市",
          },
        ],
      },
      {
        code: "510800",
        value: "广元市",
        label: "广元市",
        children: [
          {
            code: "510802",
            value: "利州区",
            label: "利州区",
          },
          {
            code: "510811",
            value: "昭化区",
            label: "昭化区",
          },
          {
            code: "510812",
            value: "朝天区",
            label: "朝天区",
          },
          {
            code: "510821",
            value: "旺苍县",
            label: "旺苍县",
          },
          {
            code: "510822",
            value: "青川县",
            label: "青川县",
          },
          {
            code: "510823",
            value: "剑阁县",
            label: "剑阁县",
          },
          {
            code: "510824",
            value: "苍溪县",
            label: "苍溪县",
          },
        ],
      },
      {
        code: "510900",
        value: "遂宁市",
        label: "遂宁市",
        children: [
          {
            code: "510903",
            value: "船山区",
            label: "船山区",
          },
          {
            code: "510904",
            value: "安居区",
            label: "安居区",
          },
          {
            code: "510921",
            value: "蓬溪县",
            label: "蓬溪县",
          },
          {
            code: "510923",
            value: "大英县",
            label: "大英县",
          },
          {
            code: "510981",
            value: "射洪市",
            label: "射洪市",
          },
        ],
      },
      {
        code: "511000",
        value: "内江市",
        label: "内江市",
        children: [
          {
            code: "511002",
            value: "市中区",
            label: "市中区",
          },
          {
            code: "511011",
            value: "东兴区",
            label: "东兴区",
          },
          {
            code: "511024",
            value: "威远县",
            label: "威远县",
          },
          {
            code: "511025",
            value: "资中县",
            label: "资中县",
          },
          {
            code: "511083",
            value: "隆昌市",
            label: "隆昌市",
          },
        ],
      },
      {
        code: "511100",
        value: "乐山市",
        label: "乐山市",
        children: [
          {
            code: "511102",
            value: "市中区",
            label: "市中区",
          },
          {
            code: "511111",
            value: "沙湾区",
            label: "沙湾区",
          },
          {
            code: "511112",
            value: "五通桥区",
            label: "五通桥区",
          },
          {
            code: "511113",
            value: "金口河区",
            label: "金口河区",
          },
          {
            code: "511123",
            value: "犍为县",
            label: "犍为县",
          },
          {
            code: "511124",
            value: "井研县",
            label: "井研县",
          },
          {
            code: "511126",
            value: "夹江县",
            label: "夹江县",
          },
          {
            code: "511129",
            value: "沐川县",
            label: "沐川县",
          },
          {
            code: "511132",
            value: "峨边彝族自治县",
            label: "峨边彝族自治县",
          },
          {
            code: "511133",
            value: "马边彝族自治县",
            label: "马边彝族自治县",
          },
          {
            code: "511181",
            value: "峨眉山市",
            label: "峨眉山市",
          },
        ],
      },
      {
        code: "511300",
        value: "南充市",
        label: "南充市",
        children: [
          {
            code: "511302",
            value: "顺庆区",
            label: "顺庆区",
          },
          {
            code: "511303",
            value: "高坪区",
            label: "高坪区",
          },
          {
            code: "511304",
            value: "嘉陵区",
            label: "嘉陵区",
          },
          {
            code: "511321",
            value: "南部县",
            label: "南部县",
          },
          {
            code: "511322",
            value: "营山县",
            label: "营山县",
          },
          {
            code: "511323",
            value: "蓬安县",
            label: "蓬安县",
          },
          {
            code: "511324",
            value: "仪陇县",
            label: "仪陇县",
          },
          {
            code: "511325",
            value: "西充县",
            label: "西充县",
          },
          {
            code: "511381",
            value: "阆中市",
            label: "阆中市",
          },
        ],
      },
      {
        code: "511400",
        value: "眉山市",
        label: "眉山市",
        children: [
          {
            code: "511402",
            value: "东坡区",
            label: "东坡区",
          },
          {
            code: "511403",
            value: "彭山区",
            label: "彭山区",
          },
          {
            code: "511421",
            value: "仁寿县",
            label: "仁寿县",
          },
          {
            code: "511423",
            value: "洪雅县",
            label: "洪雅县",
          },
          {
            code: "511424",
            value: "丹棱县",
            label: "丹棱县",
          },
          {
            code: "511425",
            value: "青神县",
            label: "青神县",
          },
        ],
      },
      {
        code: "511500",
        value: "宜宾市",
        label: "宜宾市",
        children: [
          {
            code: "511502",
            value: "翠屏区",
            label: "翠屏区",
          },
          {
            code: "511503",
            value: "南溪区",
            label: "南溪区",
          },
          {
            code: "511504",
            value: "叙州区",
            label: "叙州区",
          },
          {
            code: "511523",
            value: "江安县",
            label: "江安县",
          },
          {
            code: "511524",
            value: "长宁县",
            label: "长宁县",
          },
          {
            code: "511525",
            value: "高县",
            label: "高县",
          },
          {
            code: "511526",
            value: "珙县",
            label: "珙县",
          },
          {
            code: "511527",
            value: "筠连县",
            label: "筠连县",
          },
          {
            code: "511528",
            value: "兴文县",
            label: "兴文县",
          },
          {
            code: "511529",
            value: "屏山县",
            label: "屏山县",
          },
        ],
      },
      {
        code: "511600",
        value: "广安市",
        label: "广安市",
        children: [
          {
            code: "511602",
            value: "广安区",
            label: "广安区",
          },
          {
            code: "511603",
            value: "前锋区",
            label: "前锋区",
          },
          {
            code: "511621",
            value: "岳池县",
            label: "岳池县",
          },
          {
            code: "511622",
            value: "武胜县",
            label: "武胜县",
          },
          {
            code: "511623",
            value: "邻水县",
            label: "邻水县",
          },
          {
            code: "511681",
            value: "华蓥市",
            label: "华蓥市",
          },
        ],
      },
      {
        code: "511700",
        value: "达州市",
        label: "达州市",
        children: [
          {
            code: "511702",
            value: "通川区",
            label: "通川区",
          },
          {
            code: "511703",
            value: "达川区",
            label: "达川区",
          },
          {
            code: "511722",
            value: "宣汉县",
            label: "宣汉县",
          },
          {
            code: "511723",
            value: "开江县",
            label: "开江县",
          },
          {
            code: "511724",
            value: "大竹县",
            label: "大竹县",
          },
          {
            code: "511725",
            value: "渠县",
            label: "渠县",
          },
          {
            code: "511781",
            value: "万源市",
            label: "万源市",
          },
        ],
      },
      {
        code: "511800",
        value: "雅安市",
        label: "雅安市",
        children: [
          {
            code: "511802",
            value: "雨城区",
            label: "雨城区",
          },
          {
            code: "511803",
            value: "名山区",
            label: "名山区",
          },
          {
            code: "511822",
            value: "荥经县",
            label: "荥经县",
          },
          {
            code: "511823",
            value: "汉源县",
            label: "汉源县",
          },
          {
            code: "511824",
            value: "石棉县",
            label: "石棉县",
          },
          {
            code: "511825",
            value: "天全县",
            label: "天全县",
          },
          {
            code: "511826",
            value: "芦山县",
            label: "芦山县",
          },
          {
            code: "511827",
            value: "宝兴县",
            label: "宝兴县",
          },
        ],
      },
      {
        code: "511900",
        value: "巴中市",
        label: "巴中市",
        children: [
          {
            code: "511902",
            value: "巴州区",
            label: "巴州区",
          },
          {
            code: "511903",
            value: "恩阳区",
            label: "恩阳区",
          },
          {
            code: "511921",
            value: "通江县",
            label: "通江县",
          },
          {
            code: "511922",
            value: "南江县",
            label: "南江县",
          },
          {
            code: "511923",
            value: "平昌县",
            label: "平昌县",
          },
        ],
      },
      {
        code: "512000",
        value: "资阳市",
        label: "资阳市",
        children: [
          {
            code: "512002",
            value: "雁江区",
            label: "雁江区",
          },
          {
            code: "512021",
            value: "安岳县",
            label: "安岳县",
          },
          {
            code: "512022",
            value: "乐至县",
            label: "乐至县",
          },
        ],
      },
      {
        code: "513200",
        value: "阿坝藏族羌族自治州",
        label: "阿坝藏族羌族自治州",
        children: [
          {
            code: "513201",
            value: "马尔康市",
            label: "马尔康市",
          },
          {
            code: "513221",
            value: "汶川县",
            label: "汶川县",
          },
          {
            code: "513222",
            value: "理县",
            label: "理县",
          },
          {
            code: "513223",
            value: "茂县",
            label: "茂县",
          },
          {
            code: "513224",
            value: "松潘县",
            label: "松潘县",
          },
          {
            code: "513225",
            value: "九寨沟县",
            label: "九寨沟县",
          },
          {
            code: "513226",
            value: "金川县",
            label: "金川县",
          },
          {
            code: "513227",
            value: "小金县",
            label: "小金县",
          },
          {
            code: "513228",
            value: "黑水县",
            label: "黑水县",
          },
          {
            code: "513230",
            value: "壤塘县",
            label: "壤塘县",
          },
          {
            code: "513231",
            value: "阿坝县",
            label: "阿坝县",
          },
          {
            code: "513232",
            value: "若尔盖县",
            label: "若尔盖县",
          },
          {
            code: "513233",
            value: "红原县",
            label: "红原县",
          },
        ],
      },
      {
        code: "513300",
        value: "甘孜藏族自治州",
        label: "甘孜藏族自治州",
        children: [
          {
            code: "513301",
            value: "康定市",
            label: "康定市",
          },
          {
            code: "513322",
            value: "泸定县",
            label: "泸定县",
          },
          {
            code: "513323",
            value: "丹巴县",
            label: "丹巴县",
          },
          {
            code: "513324",
            value: "九龙县",
            label: "九龙县",
          },
          {
            code: "513325",
            value: "雅江县",
            label: "雅江县",
          },
          {
            code: "513326",
            value: "道孚县",
            label: "道孚县",
          },
          {
            code: "513327",
            value: "炉霍县",
            label: "炉霍县",
          },
          {
            code: "513328",
            value: "甘孜县",
            label: "甘孜县",
          },
          {
            code: "513329",
            value: "新龙县",
            label: "新龙县",
          },
          {
            code: "513330",
            value: "德格县",
            label: "德格县",
          },
          {
            code: "513331",
            value: "白玉县",
            label: "白玉县",
          },
          {
            code: "513332",
            value: "石渠县",
            label: "石渠县",
          },
          {
            code: "513333",
            value: "色达县",
            label: "色达县",
          },
          {
            code: "513334",
            value: "理塘县",
            label: "理塘县",
          },
          {
            code: "513335",
            value: "巴塘县",
            label: "巴塘县",
          },
          {
            code: "513336",
            value: "乡城县",
            label: "乡城县",
          },
          {
            code: "513337",
            value: "稻城县",
            label: "稻城县",
          },
          {
            code: "513338",
            value: "得荣县",
            label: "得荣县",
          },
        ],
      },
      {
        code: "513400",
        value: "凉山彝族自治州",
        label: "凉山彝族自治州",
        children: [
          {
            code: "513401",
            value: "西昌市",
            label: "西昌市",
          },
          {
            code: "513422",
            value: "木里藏族自治县",
            label: "木里藏族自治县",
          },
          {
            code: "513423",
            value: "盐源县",
            label: "盐源县",
          },
          {
            code: "513424",
            value: "德昌县",
            label: "德昌县",
          },
          {
            code: "513425",
            value: "会理县",
            label: "会理县",
          },
          {
            code: "513426",
            value: "会东县",
            label: "会东县",
          },
          {
            code: "513427",
            value: "宁南县",
            label: "宁南县",
          },
          {
            code: "513428",
            value: "普格县",
            label: "普格县",
          },
          {
            code: "513429",
            value: "布拖县",
            label: "布拖县",
          },
          {
            code: "513430",
            value: "金阳县",
            label: "金阳县",
          },
          {
            code: "513431",
            value: "昭觉县",
            label: "昭觉县",
          },
          {
            code: "513432",
            value: "喜德县",
            label: "喜德县",
          },
          {
            code: "513433",
            value: "冕宁县",
            label: "冕宁县",
          },
          {
            code: "513434",
            value: "越西县",
            label: "越西县",
          },
          {
            code: "513435",
            value: "甘洛县",
            label: "甘洛县",
          },
          {
            code: "513436",
            value: "美姑县",
            label: "美姑县",
          },
          {
            code: "513437",
            value: "雷波县",
            label: "雷波县",
          },
        ],
      },
    ],
  },
  {
    code: "520000",
    value: "贵州省",
    label: "贵州省",
    children: [
      {
        code: "520100",
        value: "贵阳市",
        label: "贵阳市",
        children: [
          {
            code: "520102",
            value: "南明区",
            label: "南明区",
          },
          {
            code: "520103",
            value: "云岩区",
            label: "云岩区",
          },
          {
            code: "520111",
            value: "花溪区",
            label: "花溪区",
          },
          {
            code: "520112",
            value: "乌当区",
            label: "乌当区",
          },
          {
            code: "520113",
            value: "白云区",
            label: "白云区",
          },
          {
            code: "520115",
            value: "观山湖区",
            label: "观山湖区",
          },
          {
            code: "520121",
            value: "开阳县",
            label: "开阳县",
          },
          {
            code: "520122",
            value: "息烽县",
            label: "息烽县",
          },
          {
            code: "520123",
            value: "修文县",
            label: "修文县",
          },
          {
            code: "520181",
            value: "清镇市",
            label: "清镇市",
          },
        ],
      },
      {
        code: "520200",
        value: "六盘水市",
        label: "六盘水市",
        children: [
          {
            code: "520201",
            value: "钟山区",
            label: "钟山区",
          },
          {
            code: "520203",
            value: "六枝特区",
            label: "六枝特区",
          },
          {
            code: "520204",
            value: "水城区",
            label: "水城区",
          },
          {
            code: "520281",
            value: "盘州市",
            label: "盘州市",
          },
        ],
      },
      {
        code: "520300",
        value: "遵义市",
        label: "遵义市",
        children: [
          {
            code: "520302",
            value: "红花岗区",
            label: "红花岗区",
          },
          {
            code: "520303",
            value: "汇川区",
            label: "汇川区",
          },
          {
            code: "520304",
            value: "播州区",
            label: "播州区",
          },
          {
            code: "520322",
            value: "桐梓县",
            label: "桐梓县",
          },
          {
            code: "520323",
            value: "绥阳县",
            label: "绥阳县",
          },
          {
            code: "520324",
            value: "正安县",
            label: "正安县",
          },
          {
            code: "520325",
            value: "道真仡佬族苗族自治县",
            label: "道真仡佬族苗族自治县",
          },
          {
            code: "520326",
            value: "务川仡佬族苗族自治县",
            label: "务川仡佬族苗族自治县",
          },
          {
            code: "520327",
            value: "凤冈县",
            label: "凤冈县",
          },
          {
            code: "520328",
            value: "湄潭县",
            label: "湄潭县",
          },
          {
            code: "520329",
            value: "余庆县",
            label: "余庆县",
          },
          {
            code: "520330",
            value: "习水县",
            label: "习水县",
          },
          {
            code: "520381",
            value: "赤水市",
            label: "赤水市",
          },
          {
            code: "520382",
            value: "仁怀市",
            label: "仁怀市",
          },
        ],
      },
      {
        code: "520400",
        value: "安顺市",
        label: "安顺市",
        children: [
          {
            code: "520402",
            value: "西秀区",
            label: "西秀区",
          },
          {
            code: "520403",
            value: "平坝区",
            label: "平坝区",
          },
          {
            code: "520422",
            value: "普定县",
            label: "普定县",
          },
          {
            code: "520423",
            value: "镇宁布依族苗族自治县",
            label: "镇宁布依族苗族自治县",
          },
          {
            code: "520424",
            value: "关岭布依族苗族自治县",
            label: "关岭布依族苗族自治县",
          },
          {
            code: "520425",
            value: "紫云苗族布依族自治县",
            label: "紫云苗族布依族自治县",
          },
        ],
      },
      {
        code: "520500",
        value: "毕节市",
        label: "毕节市",
        children: [
          {
            code: "520502",
            value: "七星关区",
            label: "七星关区",
          },
          {
            code: "520521",
            value: "大方县",
            label: "大方县",
          },
          {
            code: "520522",
            value: "黔西县",
            label: "黔西县",
          },
          {
            code: "520523",
            value: "金沙县",
            label: "金沙县",
          },
          {
            code: "520524",
            value: "织金县",
            label: "织金县",
          },
          {
            code: "520525",
            value: "纳雍县",
            label: "纳雍县",
          },
          {
            code: "520526",
            value: "威宁彝族回族苗族自治县",
            label: "威宁彝族回族苗族自治县",
          },
          {
            code: "520527",
            value: "赫章县",
            label: "赫章县",
          },
        ],
      },
      {
        code: "520600",
        value: "铜仁市",
        label: "铜仁市",
        children: [
          {
            code: "520602",
            value: "碧江区",
            label: "碧江区",
          },
          {
            code: "520603",
            value: "万山区",
            label: "万山区",
          },
          {
            code: "520621",
            value: "江口县",
            label: "江口县",
          },
          {
            code: "520622",
            value: "玉屏侗族自治县",
            label: "玉屏侗族自治县",
          },
          {
            code: "520623",
            value: "石阡县",
            label: "石阡县",
          },
          {
            code: "520624",
            value: "思南县",
            label: "思南县",
          },
          {
            code: "520625",
            value: "印江土家族苗族自治县",
            label: "印江土家族苗族自治县",
          },
          {
            code: "520626",
            value: "德江县",
            label: "德江县",
          },
          {
            code: "520627",
            value: "沿河土家族自治县",
            label: "沿河土家族自治县",
          },
          {
            code: "520628",
            value: "松桃苗族自治县",
            label: "松桃苗族自治县",
          },
        ],
      },
      {
        code: "522300",
        value: "黔西南布依族苗族自治州",
        label: "黔西南布依族苗族自治州",
        children: [
          {
            code: "522301",
            value: "兴义市",
            label: "兴义市",
          },
          {
            code: "522302",
            value: "兴仁市",
            label: "兴仁市",
          },
          {
            code: "522323",
            value: "普安县",
            label: "普安县",
          },
          {
            code: "522324",
            value: "晴隆县",
            label: "晴隆县",
          },
          {
            code: "522325",
            value: "贞丰县",
            label: "贞丰县",
          },
          {
            code: "522326",
            value: "望谟县",
            label: "望谟县",
          },
          {
            code: "522327",
            value: "册亨县",
            label: "册亨县",
          },
          {
            code: "522328",
            value: "安龙县",
            label: "安龙县",
          },
        ],
      },
      {
        code: "522600",
        value: "黔东南苗族侗族自治州",
        label: "黔东南苗族侗族自治州",
        children: [
          {
            code: "522601",
            value: "凯里市",
            label: "凯里市",
          },
          {
            code: "522622",
            value: "黄平县",
            label: "黄平县",
          },
          {
            code: "522623",
            value: "施秉县",
            label: "施秉县",
          },
          {
            code: "522624",
            value: "三穗县",
            label: "三穗县",
          },
          {
            code: "522625",
            value: "镇远县",
            label: "镇远县",
          },
          {
            code: "522626",
            value: "岑巩县",
            label: "岑巩县",
          },
          {
            code: "522627",
            value: "天柱县",
            label: "天柱县",
          },
          {
            code: "522628",
            value: "锦屏县",
            label: "锦屏县",
          },
          {
            code: "522629",
            value: "剑河县",
            label: "剑河县",
          },
          {
            code: "522630",
            value: "台江县",
            label: "台江县",
          },
          {
            code: "522631",
            value: "黎平县",
            label: "黎平县",
          },
          {
            code: "522632",
            value: "榕江县",
            label: "榕江县",
          },
          {
            code: "522633",
            value: "从江县",
            label: "从江县",
          },
          {
            code: "522634",
            value: "雷山县",
            label: "雷山县",
          },
          {
            code: "522635",
            value: "麻江县",
            label: "麻江县",
          },
          {
            code: "522636",
            value: "丹寨县",
            label: "丹寨县",
          },
        ],
      },
      {
        code: "522700",
        value: "黔南布依族苗族自治州",
        label: "黔南布依族苗族自治州",
        children: [
          {
            code: "522701",
            value: "都匀市",
            label: "都匀市",
          },
          {
            code: "522702",
            value: "福泉市",
            label: "福泉市",
          },
          {
            code: "522722",
            value: "荔波县",
            label: "荔波县",
          },
          {
            code: "522723",
            value: "贵定县",
            label: "贵定县",
          },
          {
            code: "522725",
            value: "瓮安县",
            label: "瓮安县",
          },
          {
            code: "522726",
            value: "独山县",
            label: "独山县",
          },
          {
            code: "522727",
            value: "平塘县",
            label: "平塘县",
          },
          {
            code: "522728",
            value: "罗甸县",
            label: "罗甸县",
          },
          {
            code: "522729",
            value: "长顺县",
            label: "长顺县",
          },
          {
            code: "522730",
            value: "龙里县",
            label: "龙里县",
          },
          {
            code: "522731",
            value: "惠水县",
            label: "惠水县",
          },
          {
            code: "522732",
            value: "三都水族自治县",
            label: "三都水族自治县",
          },
        ],
      },
    ],
  },
  {
    code: "530000",
    value: "云南省",
    label: "云南省",
    children: [
      {
        code: "530100",
        value: "昆明市",
        label: "昆明市",
        children: [
          {
            code: "530102",
            value: "五华区",
            label: "五华区",
          },
          {
            code: "530103",
            value: "盘龙区",
            label: "盘龙区",
          },
          {
            code: "530111",
            value: "官渡区",
            label: "官渡区",
          },
          {
            code: "530112",
            value: "西山区",
            label: "西山区",
          },
          {
            code: "530113",
            value: "东川区",
            label: "东川区",
          },
          {
            code: "530114",
            value: "呈贡区",
            label: "呈贡区",
          },
          {
            code: "530115",
            value: "晋宁区",
            label: "晋宁区",
          },
          {
            code: "530124",
            value: "富民县",
            label: "富民县",
          },
          {
            code: "530125",
            value: "宜良县",
            label: "宜良县",
          },
          {
            code: "530126",
            value: "石林彝族自治县",
            label: "石林彝族自治县",
          },
          {
            code: "530127",
            value: "嵩明县",
            label: "嵩明县",
          },
          {
            code: "530128",
            value: "禄劝彝族苗族自治县",
            label: "禄劝彝族苗族自治县",
          },
          {
            code: "530129",
            value: "寻甸回族彝族自治县",
            label: "寻甸回族彝族自治县",
          },
          {
            code: "530181",
            value: "安宁市",
            label: "安宁市",
          },
        ],
      },
      {
        code: "530300",
        value: "曲靖市",
        label: "曲靖市",
        children: [
          {
            code: "530302",
            value: "麒麟区",
            label: "麒麟区",
          },
          {
            code: "530303",
            value: "沾益区",
            label: "沾益区",
          },
          {
            code: "530304",
            value: "马龙区",
            label: "马龙区",
          },
          {
            code: "530322",
            value: "陆良县",
            label: "陆良县",
          },
          {
            code: "530323",
            value: "师宗县",
            label: "师宗县",
          },
          {
            code: "530324",
            value: "罗平县",
            label: "罗平县",
          },
          {
            code: "530325",
            value: "富源县",
            label: "富源县",
          },
          {
            code: "530326",
            value: "会泽县",
            label: "会泽县",
          },
          {
            code: "530381",
            value: "宣威市",
            label: "宣威市",
          },
        ],
      },
      {
        code: "530400",
        value: "玉溪市",
        label: "玉溪市",
        children: [
          {
            code: "530402",
            value: "红塔区",
            label: "红塔区",
          },
          {
            code: "530403",
            value: "江川区",
            label: "江川区",
          },
          {
            code: "530423",
            value: "通海县",
            label: "通海县",
          },
          {
            code: "530424",
            value: "华宁县",
            label: "华宁县",
          },
          {
            code: "530425",
            value: "易门县",
            label: "易门县",
          },
          {
            code: "530426",
            value: "峨山彝族自治县",
            label: "峨山彝族自治县",
          },
          {
            code: "530427",
            value: "新平彝族傣族自治县",
            label: "新平彝族傣族自治县",
          },
          {
            code: "530428",
            value: "元江哈尼族彝族傣族自治县",
            label: "元江哈尼族彝族傣族自治县",
          },
          {
            code: "530481",
            value: "澄江市",
            label: "澄江市",
          },
        ],
      },
      {
        code: "530500",
        value: "保山市",
        label: "保山市",
        children: [
          {
            code: "530502",
            value: "隆阳区",
            label: "隆阳区",
          },
          {
            code: "530521",
            value: "施甸县",
            label: "施甸县",
          },
          {
            code: "530523",
            value: "龙陵县",
            label: "龙陵县",
          },
          {
            code: "530524",
            value: "昌宁县",
            label: "昌宁县",
          },
          {
            code: "530581",
            value: "腾冲市",
            label: "腾冲市",
          },
        ],
      },
      {
        code: "530600",
        value: "昭通市",
        label: "昭通市",
        children: [
          {
            code: "530602",
            value: "昭阳区",
            label: "昭阳区",
          },
          {
            code: "530621",
            value: "鲁甸县",
            label: "鲁甸县",
          },
          {
            code: "530622",
            value: "巧家县",
            label: "巧家县",
          },
          {
            code: "530623",
            value: "盐津县",
            label: "盐津县",
          },
          {
            code: "530624",
            value: "大关县",
            label: "大关县",
          },
          {
            code: "530625",
            value: "永善县",
            label: "永善县",
          },
          {
            code: "530626",
            value: "绥江县",
            label: "绥江县",
          },
          {
            code: "530627",
            value: "镇雄县",
            label: "镇雄县",
          },
          {
            code: "530628",
            value: "彝良县",
            label: "彝良县",
          },
          {
            code: "530629",
            value: "威信县",
            label: "威信县",
          },
          {
            code: "530681",
            value: "水富市",
            label: "水富市",
          },
        ],
      },
      {
        code: "530700",
        value: "丽江市",
        label: "丽江市",
        children: [
          {
            code: "530702",
            value: "古城区",
            label: "古城区",
          },
          {
            code: "530721",
            value: "玉龙纳西族自治县",
            label: "玉龙纳西族自治县",
          },
          {
            code: "530722",
            value: "永胜县",
            label: "永胜县",
          },
          {
            code: "530723",
            value: "华坪县",
            label: "华坪县",
          },
          {
            code: "530724",
            value: "宁蒗彝族自治县",
            label: "宁蒗彝族自治县",
          },
        ],
      },
      {
        code: "530800",
        value: "普洱市",
        label: "普洱市",
        children: [
          {
            code: "530802",
            value: "思茅区",
            label: "思茅区",
          },
          {
            code: "530821",
            value: "宁洱哈尼族彝族自治县",
            label: "宁洱哈尼族彝族自治县",
          },
          {
            code: "530822",
            value: "墨江哈尼族自治县",
            label: "墨江哈尼族自治县",
          },
          {
            code: "530823",
            value: "景东彝族自治县",
            label: "景东彝族自治县",
          },
          {
            code: "530824",
            value: "景谷傣族彝族自治县",
            label: "景谷傣族彝族自治县",
          },
          {
            code: "530825",
            value: "镇沅彝族哈尼族拉祜族自治县",
            label: "镇沅彝族哈尼族拉祜族自治县",
          },
          {
            code: "530826",
            value: "江城哈尼族彝族自治县",
            label: "江城哈尼族彝族自治县",
          },
          {
            code: "530827",
            value: "孟连傣族拉祜族佤族自治县",
            label: "孟连傣族拉祜族佤族自治县",
          },
          {
            code: "530828",
            value: "澜沧拉祜族自治县",
            label: "澜沧拉祜族自治县",
          },
          {
            code: "530829",
            value: "西盟佤族自治县",
            label: "西盟佤族自治县",
          },
        ],
      },
      {
        code: "530900",
        value: "临沧市",
        label: "临沧市",
        children: [
          {
            code: "530902",
            value: "临翔区",
            label: "临翔区",
          },
          {
            code: "530921",
            value: "凤庆县",
            label: "凤庆县",
          },
          {
            code: "530922",
            value: "云县",
            label: "云县",
          },
          {
            code: "530923",
            value: "永德县",
            label: "永德县",
          },
          {
            code: "530924",
            value: "镇康县",
            label: "镇康县",
          },
          {
            code: "530925",
            value: "双江拉祜族佤族布朗族傣族自治县",
            label: "双江拉祜族佤族布朗族傣族自治县",
          },
          {
            code: "530926",
            value: "耿马傣族佤族自治县",
            label: "耿马傣族佤族自治县",
          },
          {
            code: "530927",
            value: "沧源佤族自治县",
            label: "沧源佤族自治县",
          },
        ],
      },
      {
        code: "532300",
        value: "楚雄彝族自治州",
        label: "楚雄彝族自治州",
        children: [
          {
            code: "532301",
            value: "楚雄市",
            label: "楚雄市",
          },
          {
            code: "532322",
            value: "双柏县",
            label: "双柏县",
          },
          {
            code: "532323",
            value: "牟定县",
            label: "牟定县",
          },
          {
            code: "532324",
            value: "南华县",
            label: "南华县",
          },
          {
            code: "532325",
            value: "姚安县",
            label: "姚安县",
          },
          {
            code: "532326",
            value: "大姚县",
            label: "大姚县",
          },
          {
            code: "532327",
            value: "永仁县",
            label: "永仁县",
          },
          {
            code: "532328",
            value: "元谋县",
            label: "元谋县",
          },
          {
            code: "532329",
            value: "武定县",
            label: "武定县",
          },
          {
            code: "532331",
            value: "禄丰县",
            label: "禄丰县",
          },
        ],
      },
      {
        code: "532500",
        value: "红河哈尼族彝族自治州",
        label: "红河哈尼族彝族自治州",
        children: [
          {
            code: "532501",
            value: "个旧市",
            label: "个旧市",
          },
          {
            code: "532502",
            value: "开远市",
            label: "开远市",
          },
          {
            code: "532503",
            value: "蒙自市",
            label: "蒙自市",
          },
          {
            code: "532504",
            value: "弥勒市",
            label: "弥勒市",
          },
          {
            code: "532523",
            value: "屏边苗族自治县",
            label: "屏边苗族自治县",
          },
          {
            code: "532524",
            value: "建水县",
            label: "建水县",
          },
          {
            code: "532525",
            value: "石屏县",
            label: "石屏县",
          },
          {
            code: "532527",
            value: "泸西县",
            label: "泸西县",
          },
          {
            code: "532528",
            value: "元阳县",
            label: "元阳县",
          },
          {
            code: "532529",
            value: "红河县",
            label: "红河县",
          },
          {
            code: "532530",
            value: "金平苗族瑶族傣族自治县",
            label: "金平苗族瑶族傣族自治县",
          },
          {
            code: "532531",
            value: "绿春县",
            label: "绿春县",
          },
          {
            code: "532532",
            value: "河口瑶族自治县",
            label: "河口瑶族自治县",
          },
        ],
      },
      {
        code: "532600",
        value: "文山壮族苗族自治州",
        label: "文山壮族苗族自治州",
        children: [
          {
            code: "532601",
            value: "文山市",
            label: "文山市",
          },
          {
            code: "532622",
            value: "砚山县",
            label: "砚山县",
          },
          {
            code: "532623",
            value: "西畴县",
            label: "西畴县",
          },
          {
            code: "532624",
            value: "麻栗坡县",
            label: "麻栗坡县",
          },
          {
            code: "532625",
            value: "马关县",
            label: "马关县",
          },
          {
            code: "532626",
            value: "丘北县",
            label: "丘北县",
          },
          {
            code: "532627",
            value: "广南县",
            label: "广南县",
          },
          {
            code: "532628",
            value: "富宁县",
            label: "富宁县",
          },
        ],
      },
      {
        code: "532800",
        value: "西双版纳傣族自治州",
        label: "西双版纳傣族自治州",
        children: [
          {
            code: "532801",
            value: "景洪市",
            label: "景洪市",
          },
          {
            code: "532822",
            value: "勐海县",
            label: "勐海县",
          },
          {
            code: "532823",
            value: "勐腊县",
            label: "勐腊县",
          },
        ],
      },
      {
        code: "532900",
        value: "大理白族自治州",
        label: "大理白族自治州",
        children: [
          {
            code: "532901",
            value: "大理市",
            label: "大理市",
          },
          {
            code: "532922",
            value: "漾濞彝族自治县",
            label: "漾濞彝族自治县",
          },
          {
            code: "532923",
            value: "祥云县",
            label: "祥云县",
          },
          {
            code: "532924",
            value: "宾川县",
            label: "宾川县",
          },
          {
            code: "532925",
            value: "弥渡县",
            label: "弥渡县",
          },
          {
            code: "532926",
            value: "南涧彝族自治县",
            label: "南涧彝族自治县",
          },
          {
            code: "532927",
            value: "巍山彝族回族自治县",
            label: "巍山彝族回族自治县",
          },
          {
            code: "532928",
            value: "永平县",
            label: "永平县",
          },
          {
            code: "532929",
            value: "云龙县",
            label: "云龙县",
          },
          {
            code: "532930",
            value: "洱源县",
            label: "洱源县",
          },
          {
            code: "532931",
            value: "剑川县",
            label: "剑川县",
          },
          {
            code: "532932",
            value: "鹤庆县",
            label: "鹤庆县",
          },
        ],
      },
      {
        code: "533100",
        value: "德宏傣族景颇族自治州",
        label: "德宏傣族景颇族自治州",
        children: [
          {
            code: "533102",
            value: "瑞丽市",
            label: "瑞丽市",
          },
          {
            code: "533103",
            value: "芒市",
            label: "芒市",
          },
          {
            code: "533122",
            value: "梁河县",
            label: "梁河县",
          },
          {
            code: "533123",
            value: "盈江县",
            label: "盈江县",
          },
          {
            code: "533124",
            value: "陇川县",
            label: "陇川县",
          },
        ],
      },
      {
        code: "533300",
        value: "怒江傈僳族自治州",
        label: "怒江傈僳族自治州",
        children: [
          {
            code: "533301",
            value: "泸水市",
            label: "泸水市",
          },
          {
            code: "533323",
            value: "福贡县",
            label: "福贡县",
          },
          {
            code: "533324",
            value: "贡山独龙族怒族自治县",
            label: "贡山独龙族怒族自治县",
          },
          {
            code: "533325",
            value: "兰坪白族普米族自治县",
            label: "兰坪白族普米族自治县",
          },
        ],
      },
      {
        code: "533400",
        value: "迪庆藏族自治州",
        label: "迪庆藏族自治州",
        children: [
          {
            code: "533401",
            value: "香格里拉市",
            label: "香格里拉市",
          },
          {
            code: "533422",
            value: "德钦县",
            label: "德钦县",
          },
          {
            code: "533423",
            value: "维西傈僳族自治县",
            label: "维西傈僳族自治县",
          },
        ],
      },
    ],
  },
  {
    code: "540000",
    value: "西藏自治区",
    label: "西藏自治区",
    children: [
      {
        code: "540100",
        value: "拉萨市",
        label: "拉萨市",
        children: [
          {
            code: "540102",
            value: "城关区",
            label: "城关区",
          },
          {
            code: "540103",
            value: "堆龙德庆区",
            label: "堆龙德庆区",
          },
          {
            code: "540104",
            value: "达孜区",
            label: "达孜区",
          },
          {
            code: "540121",
            value: "林周县",
            label: "林周县",
          },
          {
            code: "540122",
            value: "当雄县",
            label: "当雄县",
          },
          {
            code: "540123",
            value: "尼木县",
            label: "尼木县",
          },
          {
            code: "540124",
            value: "曲水县",
            label: "曲水县",
          },
          {
            code: "540127",
            value: "墨竹工卡县",
            label: "墨竹工卡县",
          },
        ],
      },
      {
        code: "540200",
        value: "日喀则市",
        label: "日喀则市",
        children: [
          {
            code: "540202",
            value: "桑珠孜区",
            label: "桑珠孜区",
          },
          {
            code: "540221",
            value: "南木林县",
            label: "南木林县",
          },
          {
            code: "540222",
            value: "江孜县",
            label: "江孜县",
          },
          {
            code: "540223",
            value: "定日县",
            label: "定日县",
          },
          {
            code: "540224",
            value: "萨迦县",
            label: "萨迦县",
          },
          {
            code: "540225",
            value: "拉孜县",
            label: "拉孜县",
          },
          {
            code: "540226",
            value: "昂仁县",
            label: "昂仁县",
          },
          {
            code: "540227",
            value: "谢通门县",
            label: "谢通门县",
          },
          {
            code: "540228",
            value: "白朗县",
            label: "白朗县",
          },
          {
            code: "540229",
            value: "仁布县",
            label: "仁布县",
          },
          {
            code: "540230",
            value: "康马县",
            label: "康马县",
          },
          {
            code: "540231",
            value: "定结县",
            label: "定结县",
          },
          {
            code: "540232",
            value: "仲巴县",
            label: "仲巴县",
          },
          {
            code: "540233",
            value: "亚东县",
            label: "亚东县",
          },
          {
            code: "540234",
            value: "吉隆县",
            label: "吉隆县",
          },
          {
            code: "540235",
            value: "聂拉木县",
            label: "聂拉木县",
          },
          {
            code: "540236",
            value: "萨嘎县",
            label: "萨嘎县",
          },
          {
            code: "540237",
            value: "岗巴县",
            label: "岗巴县",
          },
        ],
      },
      {
        code: "540300",
        value: "昌都市",
        label: "昌都市",
        children: [
          {
            code: "540302",
            value: "卡若区",
            label: "卡若区",
          },
          {
            code: "540321",
            value: "江达县",
            label: "江达县",
          },
          {
            code: "540322",
            value: "贡觉县",
            label: "贡觉县",
          },
          {
            code: "540323",
            value: "类乌齐县",
            label: "类乌齐县",
          },
          {
            code: "540324",
            value: "丁青县",
            label: "丁青县",
          },
          {
            code: "540325",
            value: "察雅县",
            label: "察雅县",
          },
          {
            code: "540326",
            value: "八宿县",
            label: "八宿县",
          },
          {
            code: "540327",
            value: "左贡县",
            label: "左贡县",
          },
          {
            code: "540328",
            value: "芒康县",
            label: "芒康县",
          },
          {
            code: "540329",
            value: "洛隆县",
            label: "洛隆县",
          },
          {
            code: "540330",
            value: "边坝县",
            label: "边坝县",
          },
        ],
      },
      {
        code: "540400",
        value: "林芝市",
        label: "林芝市",
        children: [
          {
            code: "540402",
            value: "巴宜区",
            label: "巴宜区",
          },
          {
            code: "540421",
            value: "工布江达县",
            label: "工布江达县",
          },
          {
            code: "540422",
            value: "米林县",
            label: "米林县",
          },
          {
            code: "540423",
            value: "墨脱县",
            label: "墨脱县",
          },
          {
            code: "540424",
            value: "波密县",
            label: "波密县",
          },
          {
            code: "540425",
            value: "察隅县",
            label: "察隅县",
          },
          {
            code: "540426",
            value: "朗县",
            label: "朗县",
          },
        ],
      },
      {
        code: "540500",
        value: "山南市",
        label: "山南市",
        children: [
          {
            code: "540502",
            value: "乃东区",
            label: "乃东区",
          },
          {
            code: "540521",
            value: "扎囊县",
            label: "扎囊县",
          },
          {
            code: "540522",
            value: "贡嘎县",
            label: "贡嘎县",
          },
          {
            code: "540523",
            value: "桑日县",
            label: "桑日县",
          },
          {
            code: "540524",
            value: "琼结县",
            label: "琼结县",
          },
          {
            code: "540525",
            value: "曲松县",
            label: "曲松县",
          },
          {
            code: "540526",
            value: "措美县",
            label: "措美县",
          },
          {
            code: "540527",
            value: "洛扎县",
            label: "洛扎县",
          },
          {
            code: "540528",
            value: "加查县",
            label: "加查县",
          },
          {
            code: "540529",
            value: "隆子县",
            label: "隆子县",
          },
          {
            code: "540530",
            value: "错那县",
            label: "错那县",
          },
          {
            code: "540531",
            value: "浪卡子县",
            label: "浪卡子县",
          },
        ],
      },
      {
        code: "540600",
        value: "那曲市",
        label: "那曲市",
        children: [
          {
            code: "540602",
            value: "色尼区",
            label: "色尼区",
          },
          {
            code: "540621",
            value: "嘉黎县",
            label: "嘉黎县",
          },
          {
            code: "540622",
            value: "比如县",
            label: "比如县",
          },
          {
            code: "540623",
            value: "聂荣县",
            label: "聂荣县",
          },
          {
            code: "540624",
            value: "安多县",
            label: "安多县",
          },
          {
            code: "540625",
            value: "申扎县",
            label: "申扎县",
          },
          {
            code: "540626",
            value: "索县",
            label: "索县",
          },
          {
            code: "540627",
            value: "班戈县",
            label: "班戈县",
          },
          {
            code: "540628",
            value: "巴青县",
            label: "巴青县",
          },
          {
            code: "540629",
            value: "尼玛县",
            label: "尼玛县",
          },
          {
            code: "540630",
            value: "双湖县",
            label: "双湖县",
          },
        ],
      },
      {
        code: "542500",
        value: "阿里地区",
        label: "阿里地区",
        children: [
          {
            code: "542521",
            value: "普兰县",
            label: "普兰县",
          },
          {
            code: "542522",
            value: "札达县",
            label: "札达县",
          },
          {
            code: "542523",
            value: "噶尔县",
            label: "噶尔县",
          },
          {
            code: "542524",
            value: "日土县",
            label: "日土县",
          },
          {
            code: "542525",
            value: "革吉县",
            label: "革吉县",
          },
          {
            code: "542526",
            value: "改则县",
            label: "改则县",
          },
          {
            code: "542527",
            value: "措勤县",
            label: "措勤县",
          },
        ],
      },
    ],
  },
  {
    code: "610000",
    value: "陕西省",
    label: "陕西省",
    children: [
      {
        code: "610100",
        value: "西安市",
        label: "西安市",
        children: [
          {
            code: "610102",
            value: "新城区",
            label: "新城区",
          },
          {
            code: "610103",
            value: "碑林区",
            label: "碑林区",
          },
          {
            code: "610104",
            value: "莲湖区",
            label: "莲湖区",
          },
          {
            code: "610111",
            value: "灞桥区",
            label: "灞桥区",
          },
          {
            code: "610112",
            value: "未央区",
            label: "未央区",
          },
          {
            code: "610113",
            value: "雁塔区",
            label: "雁塔区",
          },
          {
            code: "610114",
            value: "阎良区",
            label: "阎良区",
          },
          {
            code: "610115",
            value: "临潼区",
            label: "临潼区",
          },
          {
            code: "610116",
            value: "长安区",
            label: "长安区",
          },
          {
            code: "610117",
            value: "高陵区",
            label: "高陵区",
          },
          {
            code: "610118",
            value: "鄠邑区",
            label: "鄠邑区",
          },
          {
            code: "610122",
            value: "蓝田县",
            label: "蓝田县",
          },
          {
            code: "610124",
            value: "周至县",
            label: "周至县",
          },
        ],
      },
      {
        code: "610200",
        value: "铜川市",
        label: "铜川市",
        children: [
          {
            code: "610202",
            value: "王益区",
            label: "王益区",
          },
          {
            code: "610203",
            value: "印台区",
            label: "印台区",
          },
          {
            code: "610204",
            value: "耀州区",
            label: "耀州区",
          },
          {
            code: "610222",
            value: "宜君县",
            label: "宜君县",
          },
        ],
      },
      {
        code: "610300",
        value: "宝鸡市",
        label: "宝鸡市",
        children: [
          {
            code: "610302",
            value: "渭滨区",
            label: "渭滨区",
          },
          {
            code: "610303",
            value: "金台区",
            label: "金台区",
          },
          {
            code: "610304",
            value: "陈仓区",
            label: "陈仓区",
          },
          {
            code: "610322",
            value: "凤翔县",
            label: "凤翔县",
          },
          {
            code: "610323",
            value: "岐山县",
            label: "岐山县",
          },
          {
            code: "610324",
            value: "扶风县",
            label: "扶风县",
          },
          {
            code: "610326",
            value: "眉县",
            label: "眉县",
          },
          {
            code: "610327",
            value: "陇县",
            label: "陇县",
          },
          {
            code: "610328",
            value: "千阳县",
            label: "千阳县",
          },
          {
            code: "610329",
            value: "麟游县",
            label: "麟游县",
          },
          {
            code: "610330",
            value: "凤县",
            label: "凤县",
          },
          {
            code: "610331",
            value: "太白县",
            label: "太白县",
          },
        ],
      },
      {
        code: "610400",
        value: "咸阳市",
        label: "咸阳市",
        children: [
          {
            code: "610402",
            value: "秦都区",
            label: "秦都区",
          },
          {
            code: "610403",
            value: "杨陵区",
            label: "杨陵区",
          },
          {
            code: "610404",
            value: "渭城区",
            label: "渭城区",
          },
          {
            code: "610422",
            value: "三原县",
            label: "三原县",
          },
          {
            code: "610423",
            value: "泾阳县",
            label: "泾阳县",
          },
          {
            code: "610424",
            value: "乾县",
            label: "乾县",
          },
          {
            code: "610425",
            value: "礼泉县",
            label: "礼泉县",
          },
          {
            code: "610426",
            value: "永寿县",
            label: "永寿县",
          },
          {
            code: "610428",
            value: "长武县",
            label: "长武县",
          },
          {
            code: "610429",
            value: "旬邑县",
            label: "旬邑县",
          },
          {
            code: "610430",
            value: "淳化县",
            label: "淳化县",
          },
          {
            code: "610431",
            value: "武功县",
            label: "武功县",
          },
          {
            code: "610481",
            value: "兴平市",
            label: "兴平市",
          },
          {
            code: "610482",
            value: "彬州市",
            label: "彬州市",
          },
        ],
      },
      {
        code: "610500",
        value: "渭南市",
        label: "渭南市",
        children: [
          {
            code: "610502",
            value: "临渭区",
            label: "临渭区",
          },
          {
            code: "610503",
            value: "华州区",
            label: "华州区",
          },
          {
            code: "610522",
            value: "潼关县",
            label: "潼关县",
          },
          {
            code: "610523",
            value: "大荔县",
            label: "大荔县",
          },
          {
            code: "610524",
            value: "合阳县",
            label: "合阳县",
          },
          {
            code: "610525",
            value: "澄城县",
            label: "澄城县",
          },
          {
            code: "610526",
            value: "蒲城县",
            label: "蒲城县",
          },
          {
            code: "610527",
            value: "白水县",
            label: "白水县",
          },
          {
            code: "610528",
            value: "富平县",
            label: "富平县",
          },
          {
            code: "610581",
            value: "韩城市",
            label: "韩城市",
          },
          {
            code: "610582",
            value: "华阴市",
            label: "华阴市",
          },
        ],
      },
      {
        code: "610600",
        value: "延安市",
        label: "延安市",
        children: [
          {
            code: "610602",
            value: "宝塔区",
            label: "宝塔区",
          },
          {
            code: "610603",
            value: "安塞区",
            label: "安塞区",
          },
          {
            code: "610621",
            value: "延长县",
            label: "延长县",
          },
          {
            code: "610622",
            value: "延川县",
            label: "延川县",
          },
          {
            code: "610625",
            value: "志丹县",
            label: "志丹县",
          },
          {
            code: "610626",
            value: "吴起县",
            label: "吴起县",
          },
          {
            code: "610627",
            value: "甘泉县",
            label: "甘泉县",
          },
          {
            code: "610628",
            value: "富县",
            label: "富县",
          },
          {
            code: "610629",
            value: "洛川县",
            label: "洛川县",
          },
          {
            code: "610630",
            value: "宜川县",
            label: "宜川县",
          },
          {
            code: "610631",
            value: "黄龙县",
            label: "黄龙县",
          },
          {
            code: "610632",
            value: "黄陵县",
            label: "黄陵县",
          },
          {
            code: "610681",
            value: "子长市",
            label: "子长市",
          },
        ],
      },
      {
        code: "610700",
        value: "汉中市",
        label: "汉中市",
        children: [
          {
            code: "610702",
            value: "汉台区",
            label: "汉台区",
          },
          {
            code: "610703",
            value: "南郑区",
            label: "南郑区",
          },
          {
            code: "610722",
            value: "城固县",
            label: "城固县",
          },
          {
            code: "610723",
            value: "洋县",
            label: "洋县",
          },
          {
            code: "610724",
            value: "西乡县",
            label: "西乡县",
          },
          {
            code: "610725",
            value: "勉县",
            label: "勉县",
          },
          {
            code: "610726",
            value: "宁强县",
            label: "宁强县",
          },
          {
            code: "610727",
            value: "略阳县",
            label: "略阳县",
          },
          {
            code: "610728",
            value: "镇巴县",
            label: "镇巴县",
          },
          {
            code: "610729",
            value: "留坝县",
            label: "留坝县",
          },
          {
            code: "610730",
            value: "佛坪县",
            label: "佛坪县",
          },
        ],
      },
      {
        code: "610800",
        value: "榆林市",
        label: "榆林市",
        children: [
          {
            code: "610802",
            value: "榆阳区",
            label: "榆阳区",
          },
          {
            code: "610803",
            value: "横山区",
            label: "横山区",
          },
          {
            code: "610822",
            value: "府谷县",
            label: "府谷县",
          },
          {
            code: "610824",
            value: "靖边县",
            label: "靖边县",
          },
          {
            code: "610825",
            value: "定边县",
            label: "定边县",
          },
          {
            code: "610826",
            value: "绥德县",
            label: "绥德县",
          },
          {
            code: "610827",
            value: "米脂县",
            label: "米脂县",
          },
          {
            code: "610828",
            value: "佳县",
            label: "佳县",
          },
          {
            code: "610829",
            value: "吴堡县",
            label: "吴堡县",
          },
          {
            code: "610830",
            value: "清涧县",
            label: "清涧县",
          },
          {
            code: "610831",
            value: "子洲县",
            label: "子洲县",
          },
          {
            code: "610881",
            value: "神木市",
            label: "神木市",
          },
        ],
      },
      {
        code: "610900",
        value: "安康市",
        label: "安康市",
        children: [
          {
            code: "610902",
            value: "汉滨区",
            label: "汉滨区",
          },
          {
            code: "610921",
            value: "汉阴县",
            label: "汉阴县",
          },
          {
            code: "610922",
            value: "石泉县",
            label: "石泉县",
          },
          {
            code: "610923",
            value: "宁陕县",
            label: "宁陕县",
          },
          {
            code: "610924",
            value: "紫阳县",
            label: "紫阳县",
          },
          {
            code: "610925",
            value: "岚皋县",
            label: "岚皋县",
          },
          {
            code: "610926",
            value: "平利县",
            label: "平利县",
          },
          {
            code: "610927",
            value: "镇坪县",
            label: "镇坪县",
          },
          {
            code: "610928",
            value: "旬阳县",
            label: "旬阳县",
          },
          {
            code: "610929",
            value: "白河县",
            label: "白河县",
          },
        ],
      },
      {
        code: "611000",
        value: "商洛市",
        label: "商洛市",
        children: [
          {
            code: "611002",
            value: "商州区",
            label: "商州区",
          },
          {
            code: "611021",
            value: "洛南县",
            label: "洛南县",
          },
          {
            code: "611022",
            value: "丹凤县",
            label: "丹凤县",
          },
          {
            code: "611023",
            value: "商南县",
            label: "商南县",
          },
          {
            code: "611024",
            value: "山阳县",
            label: "山阳县",
          },
          {
            code: "611025",
            value: "镇安县",
            label: "镇安县",
          },
          {
            code: "611026",
            value: "柞水县",
            label: "柞水县",
          },
        ],
      },
    ],
  },
  {
    code: "620000",
    value: "甘肃省",
    label: "甘肃省",
    children: [
      {
        code: "620100",
        value: "兰州市",
        label: "兰州市",
        children: [
          {
            code: "620102",
            value: "城关区",
            label: "城关区",
          },
          {
            code: "620103",
            value: "七里河区",
            label: "七里河区",
          },
          {
            code: "620104",
            value: "西固区",
            label: "西固区",
          },
          {
            code: "620105",
            value: "安宁区",
            label: "安宁区",
          },
          {
            code: "620111",
            value: "红古区",
            label: "红古区",
          },
          {
            code: "620121",
            value: "永登县",
            label: "永登县",
          },
          {
            code: "620122",
            value: "皋兰县",
            label: "皋兰县",
          },
          {
            code: "620123",
            value: "榆中县",
            label: "榆中县",
          },
        ],
      },
      {
        code: "620200",
        value: "嘉峪关市",
        label: "嘉峪关市",
        children: [],
      },
      {
        code: "620300",
        value: "金昌市",
        label: "金昌市",
        children: [
          {
            code: "620302",
            value: "金川区",
            label: "金川区",
          },
          {
            code: "620321",
            value: "永昌县",
            label: "永昌县",
          },
        ],
      },
      {
        code: "620400",
        value: "白银市",
        label: "白银市",
        children: [
          {
            code: "620402",
            value: "白银区",
            label: "白银区",
          },
          {
            code: "620403",
            value: "平川区",
            label: "平川区",
          },
          {
            code: "620421",
            value: "靖远县",
            label: "靖远县",
          },
          {
            code: "620422",
            value: "会宁县",
            label: "会宁县",
          },
          {
            code: "620423",
            value: "景泰县",
            label: "景泰县",
          },
        ],
      },
      {
        code: "620500",
        value: "天水市",
        label: "天水市",
        children: [
          {
            code: "620502",
            value: "秦州区",
            label: "秦州区",
          },
          {
            code: "620503",
            value: "麦积区",
            label: "麦积区",
          },
          {
            code: "620521",
            value: "清水县",
            label: "清水县",
          },
          {
            code: "620522",
            value: "秦安县",
            label: "秦安县",
          },
          {
            code: "620523",
            value: "甘谷县",
            label: "甘谷县",
          },
          {
            code: "620524",
            value: "武山县",
            label: "武山县",
          },
          {
            code: "620525",
            value: "张家川回族自治县",
            label: "张家川回族自治县",
          },
        ],
      },
      {
        code: "620600",
        value: "武威市",
        label: "武威市",
        children: [
          {
            code: "620602",
            value: "凉州区",
            label: "凉州区",
          },
          {
            code: "620621",
            value: "民勤县",
            label: "民勤县",
          },
          {
            code: "620622",
            value: "古浪县",
            label: "古浪县",
          },
          {
            code: "620623",
            value: "天祝藏族自治县",
            label: "天祝藏族自治县",
          },
        ],
      },
      {
        code: "620700",
        value: "张掖市",
        label: "张掖市",
        children: [
          {
            code: "620702",
            value: "甘州区",
            label: "甘州区",
          },
          {
            code: "620721",
            value: "肃南裕固族自治县",
            label: "肃南裕固族自治县",
          },
          {
            code: "620722",
            value: "民乐县",
            label: "民乐县",
          },
          {
            code: "620723",
            value: "临泽县",
            label: "临泽县",
          },
          {
            code: "620724",
            value: "高台县",
            label: "高台县",
          },
          {
            code: "620725",
            value: "山丹县",
            label: "山丹县",
          },
        ],
      },
      {
        code: "620800",
        value: "平凉市",
        label: "平凉市",
        children: [
          {
            code: "620802",
            value: "崆峒区",
            label: "崆峒区",
          },
          {
            code: "620821",
            value: "泾川县",
            label: "泾川县",
          },
          {
            code: "620822",
            value: "灵台县",
            label: "灵台县",
          },
          {
            code: "620823",
            value: "崇信县",
            label: "崇信县",
          },
          {
            code: "620825",
            value: "庄浪县",
            label: "庄浪县",
          },
          {
            code: "620826",
            value: "静宁县",
            label: "静宁县",
          },
          {
            code: "620881",
            value: "华亭市",
            label: "华亭市",
          },
        ],
      },
      {
        code: "620900",
        value: "酒泉市",
        label: "酒泉市",
        children: [
          {
            code: "620902",
            value: "肃州区",
            label: "肃州区",
          },
          {
            code: "620921",
            value: "金塔县",
            label: "金塔县",
          },
          {
            code: "620922",
            value: "瓜州县",
            label: "瓜州县",
          },
          {
            code: "620923",
            value: "肃北蒙古族自治县",
            label: "肃北蒙古族自治县",
          },
          {
            code: "620924",
            value: "阿克塞哈萨克族自治县",
            label: "阿克塞哈萨克族自治县",
          },
          {
            code: "620981",
            value: "玉门市",
            label: "玉门市",
          },
          {
            code: "620982",
            value: "敦煌市",
            label: "敦煌市",
          },
        ],
      },
      {
        code: "621000",
        value: "庆阳市",
        label: "庆阳市",
        children: [
          {
            code: "621002",
            value: "西峰区",
            label: "西峰区",
          },
          {
            code: "621021",
            value: "庆城县",
            label: "庆城县",
          },
          {
            code: "621022",
            value: "环县",
            label: "环县",
          },
          {
            code: "621023",
            value: "华池县",
            label: "华池县",
          },
          {
            code: "621024",
            value: "合水县",
            label: "合水县",
          },
          {
            code: "621025",
            value: "正宁县",
            label: "正宁县",
          },
          {
            code: "621026",
            value: "宁县",
            label: "宁县",
          },
          {
            code: "621027",
            value: "镇原县",
            label: "镇原县",
          },
        ],
      },
      {
        code: "621100",
        value: "定西市",
        label: "定西市",
        children: [
          {
            code: "621102",
            value: "安定区",
            label: "安定区",
          },
          {
            code: "621121",
            value: "通渭县",
            label: "通渭县",
          },
          {
            code: "621122",
            value: "陇西县",
            label: "陇西县",
          },
          {
            code: "621123",
            value: "渭源县",
            label: "渭源县",
          },
          {
            code: "621124",
            value: "临洮县",
            label: "临洮县",
          },
          {
            code: "621125",
            value: "漳县",
            label: "漳县",
          },
          {
            code: "621126",
            value: "岷县",
            label: "岷县",
          },
        ],
      },
      {
        code: "621200",
        value: "陇南市",
        label: "陇南市",
        children: [
          {
            code: "621202",
            value: "武都区",
            label: "武都区",
          },
          {
            code: "621221",
            value: "成县",
            label: "成县",
          },
          {
            code: "621222",
            value: "文县",
            label: "文县",
          },
          {
            code: "621223",
            value: "宕昌县",
            label: "宕昌县",
          },
          {
            code: "621224",
            value: "康县",
            label: "康县",
          },
          {
            code: "621225",
            value: "西和县",
            label: "西和县",
          },
          {
            code: "621226",
            value: "礼县",
            label: "礼县",
          },
          {
            code: "621227",
            value: "徽县",
            label: "徽县",
          },
          {
            code: "621228",
            value: "两当县",
            label: "两当县",
          },
        ],
      },
      {
        code: "622900",
        value: "临夏回族自治州",
        label: "临夏回族自治州",
        children: [
          {
            code: "622901",
            value: "临夏市",
            label: "临夏市",
          },
          {
            code: "622921",
            value: "临夏县",
            label: "临夏县",
          },
          {
            code: "622922",
            value: "康乐县",
            label: "康乐县",
          },
          {
            code: "622923",
            value: "永靖县",
            label: "永靖县",
          },
          {
            code: "622924",
            value: "广河县",
            label: "广河县",
          },
          {
            code: "622925",
            value: "和政县",
            label: "和政县",
          },
          {
            code: "622926",
            value: "东乡族自治县",
            label: "东乡族自治县",
          },
          {
            code: "622927",
            value: "积石山保安族东乡族撒拉族自治县",
            label: "积石山保安族东乡族撒拉族自治县",
          },
        ],
      },
      {
        code: "623000",
        value: "甘南藏族自治州",
        label: "甘南藏族自治州",
        children: [
          {
            code: "623001",
            value: "合作市",
            label: "合作市",
          },
          {
            code: "623021",
            value: "临潭县",
            label: "临潭县",
          },
          {
            code: "623022",
            value: "卓尼县",
            label: "卓尼县",
          },
          {
            code: "623023",
            value: "舟曲县",
            label: "舟曲县",
          },
          {
            code: "623024",
            value: "迭部县",
            label: "迭部县",
          },
          {
            code: "623025",
            value: "玛曲县",
            label: "玛曲县",
          },
          {
            code: "623026",
            value: "碌曲县",
            label: "碌曲县",
          },
          {
            code: "623027",
            value: "夏河县",
            label: "夏河县",
          },
        ],
      },
    ],
  },
  {
    code: "630000",
    value: "青海省",
    label: "青海省",
    children: [
      {
        code: "630100",
        value: "西宁市",
        label: "西宁市",
        children: [
          {
            code: "630102",
            value: "城东区",
            label: "城东区",
          },
          {
            code: "630103",
            value: "城中区",
            label: "城中区",
          },
          {
            code: "630104",
            value: "城西区",
            label: "城西区",
          },
          {
            code: "630105",
            value: "城北区",
            label: "城北区",
          },
          {
            code: "630106",
            value: "湟中区",
            label: "湟中区",
          },
          {
            code: "630121",
            value: "大通回族土族自治县",
            label: "大通回族土族自治县",
          },
          {
            code: "630123",
            value: "湟源县",
            label: "湟源县",
          },
        ],
      },
      {
        code: "630200",
        value: "海东市",
        label: "海东市",
        children: [
          {
            code: "630202",
            value: "乐都区",
            label: "乐都区",
          },
          {
            code: "630203",
            value: "平安区",
            label: "平安区",
          },
          {
            code: "630222",
            value: "民和回族土族自治县",
            label: "民和回族土族自治县",
          },
          {
            code: "630223",
            value: "互助土族自治县",
            label: "互助土族自治县",
          },
          {
            code: "630224",
            value: "化隆回族自治县",
            label: "化隆回族自治县",
          },
          {
            code: "630225",
            value: "循化撒拉族自治县",
            label: "循化撒拉族自治县",
          },
        ],
      },
      {
        code: "632200",
        value: "海北藏族自治州",
        label: "海北藏族自治州",
        children: [
          {
            code: "632221",
            value: "门源回族自治县",
            label: "门源回族自治县",
          },
          {
            code: "632222",
            value: "祁连县",
            label: "祁连县",
          },
          {
            code: "632223",
            value: "海晏县",
            label: "海晏县",
          },
          {
            code: "632224",
            value: "刚察县",
            label: "刚察县",
          },
        ],
      },
      {
        code: "632300",
        value: "黄南藏族自治州",
        label: "黄南藏族自治州",
        children: [
          {
            code: "632301",
            value: "同仁市",
            label: "同仁市",
          },
          {
            code: "632322",
            value: "尖扎县",
            label: "尖扎县",
          },
          {
            code: "632323",
            value: "泽库县",
            label: "泽库县",
          },
          {
            code: "632324",
            value: "河南蒙古族自治县",
            label: "河南蒙古族自治县",
          },
        ],
      },
      {
        code: "632500",
        value: "海南藏族自治州",
        label: "海南藏族自治州",
        children: [
          {
            code: "632521",
            value: "共和县",
            label: "共和县",
          },
          {
            code: "632522",
            value: "同德县",
            label: "同德县",
          },
          {
            code: "632523",
            value: "贵德县",
            label: "贵德县",
          },
          {
            code: "632524",
            value: "兴海县",
            label: "兴海县",
          },
          {
            code: "632525",
            value: "贵南县",
            label: "贵南县",
          },
        ],
      },
      {
        code: "632600",
        value: "果洛藏族自治州",
        label: "果洛藏族自治州",
        children: [
          {
            code: "632621",
            value: "玛沁县",
            label: "玛沁县",
          },
          {
            code: "632622",
            value: "班玛县",
            label: "班玛县",
          },
          {
            code: "632623",
            value: "甘德县",
            label: "甘德县",
          },
          {
            code: "632624",
            value: "达日县",
            label: "达日县",
          },
          {
            code: "632625",
            value: "久治县",
            label: "久治县",
          },
          {
            code: "632626",
            value: "玛多县",
            label: "玛多县",
          },
        ],
      },
      {
        code: "632700",
        value: "玉树藏族自治州",
        label: "玉树藏族自治州",
        children: [
          {
            code: "632701",
            value: "玉树市",
            label: "玉树市",
          },
          {
            code: "632722",
            value: "杂多县",
            label: "杂多县",
          },
          {
            code: "632723",
            value: "称多县",
            label: "称多县",
          },
          {
            code: "632724",
            value: "治多县",
            label: "治多县",
          },
          {
            code: "632725",
            value: "囊谦县",
            label: "囊谦县",
          },
          {
            code: "632726",
            value: "曲麻莱县",
            label: "曲麻莱县",
          },
        ],
      },
      {
        code: "632800",
        value: "海西蒙古族藏族自治州",
        label: "海西蒙古族藏族自治州",
        children: [
          {
            code: "632801",
            value: "格尔木市",
            label: "格尔木市",
          },
          {
            code: "632802",
            value: "德令哈市",
            label: "德令哈市",
          },
          {
            code: "632803",
            value: "茫崖市",
            label: "茫崖市",
          },
          {
            code: "632821",
            value: "乌兰县",
            label: "乌兰县",
          },
          {
            code: "632822",
            value: "都兰县",
            label: "都兰县",
          },
          {
            code: "632823",
            value: "天峻县",
            label: "天峻县",
          },
        ],
      },
    ],
  },
  {
    code: "640000",
    value: "宁夏回族自治区",
    label: "宁夏回族自治区",
    children: [
      {
        code: "640100",
        value: "银川市",
        label: "银川市",
        children: [
          {
            code: "640104",
            value: "兴庆区",
            label: "兴庆区",
          },
          {
            code: "640105",
            value: "西夏区",
            label: "西夏区",
          },
          {
            code: "640106",
            value: "金凤区",
            label: "金凤区",
          },
          {
            code: "640121",
            value: "永宁县",
            label: "永宁县",
          },
          {
            code: "640122",
            value: "贺兰县",
            label: "贺兰县",
          },
          {
            code: "640181",
            value: "灵武市",
            label: "灵武市",
          },
        ],
      },
      {
        code: "640200",
        value: "石嘴山市",
        label: "石嘴山市",
        children: [
          {
            code: "640202",
            value: "大武口区",
            label: "大武口区",
          },
          {
            code: "640205",
            value: "惠农区",
            label: "惠农区",
          },
          {
            code: "640221",
            value: "平罗县",
            label: "平罗县",
          },
        ],
      },
      {
        code: "640300",
        value: "吴忠市",
        label: "吴忠市",
        children: [
          {
            code: "640302",
            value: "利通区",
            label: "利通区",
          },
          {
            code: "640303",
            value: "红寺堡区",
            label: "红寺堡区",
          },
          {
            code: "640323",
            value: "盐池县",
            label: "盐池县",
          },
          {
            code: "640324",
            value: "同心县",
            label: "同心县",
          },
          {
            code: "640381",
            value: "青铜峡市",
            label: "青铜峡市",
          },
        ],
      },
      {
        code: "640400",
        value: "固原市",
        label: "固原市",
        children: [
          {
            code: "640402",
            value: "原州区",
            label: "原州区",
          },
          {
            code: "640422",
            value: "西吉县",
            label: "西吉县",
          },
          {
            code: "640423",
            value: "隆德县",
            label: "隆德县",
          },
          {
            code: "640424",
            value: "泾源县",
            label: "泾源县",
          },
          {
            code: "640425",
            value: "彭阳县",
            label: "彭阳县",
          },
        ],
      },
      {
        code: "640500",
        value: "中卫市",
        label: "中卫市",
        children: [
          {
            code: "640502",
            value: "沙坡头区",
            label: "沙坡头区",
          },
          {
            code: "640521",
            value: "中宁县",
            label: "中宁县",
          },
          {
            code: "640522",
            value: "海原县",
            label: "海原县",
          },
        ],
      },
    ],
  },
  {
    code: "650000",
    value: "新疆维吾尔自治区",
    label: "新疆维吾尔自治区",
    children: [
      {
        code: "650100",
        value: "乌鲁木齐市",
        label: "乌鲁木齐市",
        children: [
          {
            code: "650102",
            value: "天山区",
            label: "天山区",
          },
          {
            code: "650103",
            value: "沙依巴克区",
            label: "沙依巴克区",
          },
          {
            code: "650104",
            value: "新市区",
            label: "新市区",
          },
          {
            code: "650105",
            value: "水磨沟区",
            label: "水磨沟区",
          },
          {
            code: "650106",
            value: "头屯河区",
            label: "头屯河区",
          },
          {
            code: "650107",
            value: "达坂城区",
            label: "达坂城区",
          },
          {
            code: "650109",
            value: "米东区",
            label: "米东区",
          },
          {
            code: "650121",
            value: "乌鲁木齐县",
            label: "乌鲁木齐县",
          },
        ],
      },
      {
        code: "650200",
        value: "克拉玛依市",
        label: "克拉玛依市",
        children: [
          {
            code: "650202",
            value: "独山子区",
            label: "独山子区",
          },
          {
            code: "650203",
            value: "克拉玛依区",
            label: "克拉玛依区",
          },
          {
            code: "650204",
            value: "白碱滩区",
            label: "白碱滩区",
          },
          {
            code: "650205",
            value: "乌尔禾区",
            label: "乌尔禾区",
          },
        ],
      },
      {
        code: "650400",
        value: "吐鲁番市",
        label: "吐鲁番市",
        children: [
          {
            code: "650402",
            value: "高昌区",
            label: "高昌区",
          },
          {
            code: "650421",
            value: "鄯善县",
            label: "鄯善县",
          },
          {
            code: "650422",
            value: "托克逊县",
            label: "托克逊县",
          },
        ],
      },
      {
        code: "650500",
        value: "哈密市",
        label: "哈密市",
        children: [
          {
            code: "650502",
            value: "伊州区",
            label: "伊州区",
          },
          {
            code: "650521",
            value: "巴里坤哈萨克自治县",
            label: "巴里坤哈萨克自治县",
          },
          {
            code: "650522",
            value: "伊吾县",
            label: "伊吾县",
          },
        ],
      },
      {
        code: "652300",
        value: "昌吉回族自治州",
        label: "昌吉回族自治州",
        children: [
          {
            code: "652301",
            value: "昌吉市",
            label: "昌吉市",
          },
          {
            code: "652302",
            value: "阜康市",
            label: "阜康市",
          },
          {
            code: "652323",
            value: "呼图壁县",
            label: "呼图壁县",
          },
          {
            code: "652324",
            value: "玛纳斯县",
            label: "玛纳斯县",
          },
          {
            code: "652325",
            value: "奇台县",
            label: "奇台县",
          },
          {
            code: "652327",
            value: "吉木萨尔县",
            label: "吉木萨尔县",
          },
          {
            code: "652328",
            value: "木垒哈萨克自治县",
            label: "木垒哈萨克自治县",
          },
        ],
      },
      {
        code: "652700",
        value: "博尔塔拉蒙古自治州",
        label: "博尔塔拉蒙古自治州",
        children: [
          {
            code: "652701",
            value: "博乐市",
            label: "博乐市",
          },
          {
            code: "652702",
            value: "阿拉山口市",
            label: "阿拉山口市",
          },
          {
            code: "652722",
            value: "精河县",
            label: "精河县",
          },
          {
            code: "652723",
            value: "温泉县",
            label: "温泉县",
          },
        ],
      },
      {
        code: "652800",
        value: "巴音郭楞蒙古自治州",
        label: "巴音郭楞蒙古自治州",
        children: [
          {
            code: "652801",
            value: "库尔勒市",
            label: "库尔勒市",
          },
          {
            code: "652822",
            value: "轮台县",
            label: "轮台县",
          },
          {
            code: "652823",
            value: "尉犁县",
            label: "尉犁县",
          },
          {
            code: "652824",
            value: "若羌县",
            label: "若羌县",
          },
          {
            code: "652825",
            value: "且末县",
            label: "且末县",
          },
          {
            code: "652826",
            value: "焉耆回族自治县",
            label: "焉耆回族自治县",
          },
          {
            code: "652827",
            value: "和静县",
            label: "和静县",
          },
          {
            code: "652828",
            value: "和硕县",
            label: "和硕县",
          },
          {
            code: "652829",
            value: "博湖县",
            label: "博湖县",
          },
        ],
      },
      {
        code: "652900",
        value: "阿克苏地区",
        label: "阿克苏地区",
        children: [
          {
            code: "652901",
            value: "阿克苏市",
            label: "阿克苏市",
          },
          {
            code: "652902",
            value: "库车市",
            label: "库车市",
          },
          {
            code: "652922",
            value: "温宿县",
            label: "温宿县",
          },
          {
            code: "652924",
            value: "沙雅县",
            label: "沙雅县",
          },
          {
            code: "652925",
            value: "新和县",
            label: "新和县",
          },
          {
            code: "652926",
            value: "拜城县",
            label: "拜城县",
          },
          {
            code: "652927",
            value: "乌什县",
            label: "乌什县",
          },
          {
            code: "652928",
            value: "阿瓦提县",
            label: "阿瓦提县",
          },
          {
            code: "652929",
            value: "柯坪县",
            label: "柯坪县",
          },
        ],
      },
      {
        code: "653000",
        value: "克孜勒苏柯尔克孜自治州",
        label: "克孜勒苏柯尔克孜自治州",
        children: [
          {
            code: "653001",
            value: "阿图什市",
            label: "阿图什市",
          },
          {
            code: "653022",
            value: "阿克陶县",
            label: "阿克陶县",
          },
          {
            code: "653023",
            value: "阿合奇县",
            label: "阿合奇县",
          },
          {
            code: "653024",
            value: "乌恰县",
            label: "乌恰县",
          },
        ],
      },
      {
        code: "653100",
        value: "喀什地区",
        label: "喀什地区",
        children: [
          {
            code: "653101",
            value: "喀什市",
            label: "喀什市",
          },
          {
            code: "653121",
            value: "疏附县",
            label: "疏附县",
          },
          {
            code: "653122",
            value: "疏勒县",
            label: "疏勒县",
          },
          {
            code: "653123",
            value: "英吉沙县",
            label: "英吉沙县",
          },
          {
            code: "653124",
            value: "泽普县",
            label: "泽普县",
          },
          {
            code: "653125",
            value: "莎车县",
            label: "莎车县",
          },
          {
            code: "653126",
            value: "叶城县",
            label: "叶城县",
          },
          {
            code: "653127",
            value: "麦盖提县",
            label: "麦盖提县",
          },
          {
            code: "653128",
            value: "岳普湖县",
            label: "岳普湖县",
          },
          {
            code: "653129",
            value: "伽师县",
            label: "伽师县",
          },
          {
            code: "653130",
            value: "巴楚县",
            label: "巴楚县",
          },
          {
            code: "653131",
            value: "塔什库尔干塔吉克自治县",
            label: "塔什库尔干塔吉克自治县",
          },
        ],
      },
      {
        code: "653200",
        value: "和田地区",
        label: "和田地区",
        children: [
          {
            code: "653201",
            value: "和田市",
            label: "和田市",
          },
          {
            code: "653221",
            value: "和田县",
            label: "和田县",
          },
          {
            code: "653222",
            value: "墨玉县",
            label: "墨玉县",
          },
          {
            code: "653223",
            value: "皮山县",
            label: "皮山县",
          },
          {
            code: "653224",
            value: "洛浦县",
            label: "洛浦县",
          },
          {
            code: "653225",
            value: "策勒县",
            label: "策勒县",
          },
          {
            code: "653226",
            value: "于田县",
            label: "于田县",
          },
          {
            code: "653227",
            value: "民丰县",
            label: "民丰县",
          },
        ],
      },
      {
        code: "654000",
        value: "伊犁哈萨克自治州",
        label: "伊犁哈萨克自治州",
        children: [
          {
            code: "654002",
            value: "伊宁市",
            label: "伊宁市",
          },
          {
            code: "654003",
            value: "奎屯市",
            label: "奎屯市",
          },
          {
            code: "654004",
            value: "霍尔果斯市",
            label: "霍尔果斯市",
          },
          {
            code: "654021",
            value: "伊宁县",
            label: "伊宁县",
          },
          {
            code: "654022",
            value: "察布查尔锡伯自治县",
            label: "察布查尔锡伯自治县",
          },
          {
            code: "654023",
            value: "霍城县",
            label: "霍城县",
          },
          {
            code: "654024",
            value: "巩留县",
            label: "巩留县",
          },
          {
            code: "654025",
            value: "新源县",
            label: "新源县",
          },
          {
            code: "654026",
            value: "昭苏县",
            label: "昭苏县",
          },
          {
            code: "654027",
            value: "特克斯县",
            label: "特克斯县",
          },
          {
            code: "654028",
            value: "尼勒克县",
            label: "尼勒克县",
          },
        ],
      },
      {
        code: "654200",
        value: "塔城地区",
        label: "塔城地区",
        children: [
          {
            code: "654201",
            value: "塔城市",
            label: "塔城市",
          },
          {
            code: "654202",
            value: "乌苏市",
            label: "乌苏市",
          },
          {
            code: "654221",
            value: "额敏县",
            label: "额敏县",
          },
          {
            code: "654223",
            value: "沙湾县",
            label: "沙湾县",
          },
          {
            code: "654224",
            value: "托里县",
            label: "托里县",
          },
          {
            code: "654225",
            value: "裕民县",
            label: "裕民县",
          },
          {
            code: "654226",
            value: "和布克赛尔蒙古自治县",
            label: "和布克赛尔蒙古自治县",
          },
        ],
      },
      {
        code: "654300",
        value: "阿勒泰地区",
        label: "阿勒泰地区",
        children: [
          {
            code: "654301",
            value: "阿勒泰市",
            label: "阿勒泰市",
          },
          {
            code: "654321",
            value: "布尔津县",
            label: "布尔津县",
          },
          {
            code: "654322",
            value: "富蕴县",
            label: "富蕴县",
          },
          {
            code: "654323",
            value: "福海县",
            label: "福海县",
          },
          {
            code: "654324",
            value: "哈巴河县",
            label: "哈巴河县",
          },
          {
            code: "654325",
            value: "青河县",
            label: "青河县",
          },
          {
            code: "654326",
            value: "吉木乃县",
            label: "吉木乃县",
          },
        ],
      },
    ],
  },
  {
    code: "710000",
    value: "台湾省",
    label: "台湾省",
    children: [],
  },
  {
    code: "810000",
    value: "香港特别行政区",
    label: "香港特别行政区",
    children: [],
  },
  {
    code: "820000",
    value: "澳门特别行政区",
    label: "澳门特别行政区",
    children: [],
  },
];

export default options;
