import React, { Component } from "react";
import { Table, Button, message, Select, Input, Modal } from "antd";
import Request from "../../../utils/client";
import { AQUIRE_MAC, MODIFY_MAC } from "../../../graphql/admin/mac";
import root from "../../../config/root";
import AddMac from "./components/AddMac";

import "./mac.scss";
const { Option } = Select;
export default class Mac extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: "序号",
        dataIndex: "id",
        fixed: "left",
        width: 100,
      },
      {
        title: "MAC地址",
        dataIndex: "mac",
        width: 150,
      },
      {
        title: "用户名称",
        dataIndex: "userName",
        width: 100,
      },
      {
        title: "启用状态",
        dataIndex: "status",
        width: 150,
      },
    ];
    let userInfo = localStorage.getItem("userInfo");

    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {item.isAble === false ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  启用
                </Button>
              ) : (
                ""
              )}
              {item.isAble === true ? (
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  停用
                </Button>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }

    this.state = {
      data: [],
      macData: [], // 存储获取的数据，用于用户搜索时
      columns,
      role,
      pathname,
      modalVisible: false,
      searchType: "address",
      modalVisible: false,
    };
  }
  state = {
    total: 0, // 数据总数
    current: 1, // 当前的页数
    pageSize: 20, // 每页的数据量
  };
  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    Request.client
      .query({
        query: AQUIRE_MAC,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("mac地址", result);
        let { getDeviceList } = result.data;
        getDeviceList.forEach((item, index) => {
          item.sort = index + 1;
          item.key = item.id;
          item.userName = item.userInfo.name;
          item.status = item.isAble ? "正常" : "已停用";
        });
        this.setState({ data: getDeviceList, macData: getDeviceList });
      })
      .catch((error) => {
        // this.props.history.push({ pathname: "/admin/error/systemerror" });
      });
  };

  // 修改mac状态
  modify = (item) => {
    Request.client
      .mutate({
        mutation: MODIFY_MAC, // 封装好的GraphQL,
        variables: {
          input: {
            id: item.id,
            isAble: !item.isAble,
          },
        },
      })
      .then((result) => {
        console.log(result);
        message.success("修改成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
        console.log(error);
      });
  };
  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { macData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    console.log(this.state.searchType);
    let result = [];
    if (searchType === "address") {
      result = macData.filter((obj) => {
        return obj.mac.indexOf(keyword) != -1;
      });
    } else if (searchType === "admin") {
      result = macData.filter((obj) => {
        return obj.userName.indexOf(keyword) != -1;
      });
    } else {
      let flag = false;
      if ("正常".indexOf(keyword) != -1) {
        flag = true;
      } else {
        flag = false;
      }
      console.log(flag);
      result = macData.filter((obj) => {
        return obj.isAble === flag;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "address",
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 表格分页
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    pageSize: 20, // 每页条数
    showQuickJumper: true, // 开启页码快速跳转
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };
  // 页面发生改变
  changePage = (page) => {
    // console.log(page);
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // pageSize发生改变时
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    // console.log(pageSize, current);
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.aquireData();
      }
    );
  };

  // 设置弹窗的显示和关闭
  setModalVisible(modalVisible) {
    this.setState({ modalVisible }, () => {
      // console.log(this.addMaintenanceCom);
      if (modalVisible) {
        this.addMac.getPersonal();
        this.addMac.handlerResetForm();
      }
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    // if (this.state.title == "添加维修厂") {
    //   this.addMac.addExpress();
    // } else {
    //   this.addMac.modifyProjectSubmit();
    // }
    this.addMac.addExpress();
  };

  // 关闭弹窗，由addMaintenanceCom组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  render() {
    let { data, columns, role, pathname } = this.state;
    return (
      <div className="mac_box">
        <div className="btn_box">
          <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div>
          <div>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="address">地址</Option>
              <Option value="status">状态</Option>
              <Option value="admin">所属用户</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={{ ...this.paginationProps, total: this.state.total }}
          />
        </div>
        <Modal
          title="添加手持机"
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={this.closeModal}
        >
          <AddMac
            ref={(addMac) => {
              this.addMac = addMac;
            }}
            closeModal={this.closeModal}
          ></AddMac>
        </Modal>
      </div>
    );
  }
}
