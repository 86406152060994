/**
 * 存储状态值
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  22: "待确认",
  100: "在库",
  102: "运送至维修厂途中",
  200: "待使用",
  201: "使用中",
  202: "异常",
  203: "闲置",
  204: "准备归库",
  205: "归库途中",
  300: "已确认",
  400: "丢失",
  500: "报废",
  600: "待收货",
  700: "已收货(部分)",
  800: "已收货全部",
  900: "已归库",
};
