import React, { Component } from "react";
import { Line } from "@ant-design/charts";
import { message, Empty } from "antd";
import axiosRequest from "../../../../utils/request";
import {
  indexRequestPath,
  AdminIndexRequest,
} from "../../../../request/requestPath";
import moment from "moment";
class DemoLine extends Component {
  state = {
    data: [],
    isNull: true,
  };
  config = {
    data: this.state.data,
    xField: "date",
    yField: "count",
    seriesField: "category",
    xAxis: {
      type: "time",
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) =>
          `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };
  componentDidMount() {
    // this.aquireData();
    // this.asyncFetch();
  }
  // asyncFetch() {
  //   fetch(
  //     "https://gw.alipayobjects.com/os/bmw-prod/55424a73-7cb8-4f79-b60d-3ab627ac5698.json"
  //   )
  //     .then((response) => response.json())
  //     .then((json) => {
  //       console.log("获取的数据", json);
  //       // this.setState({ data: json, isNull: false });
  //     })
  //     .catch((error) => {
  //       console.log("fetch data failed", error);
  //     });
  // }

  //  获取项目现场型钢数据
  aquireData(repositoryId, projectId) {
    // console.log("repositoryId", repositoryId, projectId);
    if (repositoryId && projectId) {
      axiosRequest
        .post(indexRequestPath.GetProjectSteelDateByCompany, {
          repositoryId,
          projectId,
          companyId: 0,
        })
        .then((res) => {
          if (res.code === 200) {
            this.dataDeal(res.data);
          } else {
            // message.error("获取项目现场数据错误");
            console.log("获取项目现场数据错误");
          }
        })
        .catch((error) => {
          console.log("获取现场型钢数据错误", error);
        });
    }
  }

  // 超级管理员获取项目型钢数据
  adminAquireData(companyId, projectId) {
    if ((companyId || companyId === 0) && projectId) {
      axiosRequest
        .post(AdminIndexRequest.GetProjectSteelDataByCompany, {
          companyId,
          projectId,
        })
        .then((res) => {
          // console.log("获取项目现场数据错误", res);
          if (res.code === 200) {
            this.dataDeal(res.data);
          } else {
            // message.error("获取项目现场数据错误");
            console.log("获取项目现场数据错误");
          }
        })
        .catch((error) => {
          console.log("超级管理员获取现场型钢数据错误", error);
        });
    }
  }
  // 数据处理
  dataDeal(data) {
    // console.log("获取的项目现场型钢数据", data);
    let { enter, out, statedAt, endedAt } = data;
    let enterObject = {};
    let outObject = {};
    let enterList = [];
    let outList = [];
    // eslint-disable-next-line no-unused-expressions
    enter
      ? enter.forEach((item) => {
          item.date = moment(item.EnterWorkShopAt).format("YYYY-MM-DD");
          // eslint-disable-next-line no-unused-expressions
          enterObject[item.date]
            ? (enterObject[item.date].EnterWeight =
                enterObject[item.date].EnterWeight + item.EnterWeight)
            : (enterObject[item.date] = item);
        })
      : "";

    // eslint-disable-next-line no-unused-expressions
    enterObject[statedAt]
      ? ""
      : (enterObject[statedAt] = {
          EnterWeight: 0,
          date: statedAt,
        });

    for (let key in enterObject) {
      enterObject[key].count = enterObject[key].EnterWeight;
      enterObject[key].category = "项目进场型钢量";
      enterList.push(enterObject[key]);
    }
    enterList.sort(function (a, b) {
      return (
        // eslint-disable-next-line no-self-compare
        new Date(b.EnterWorkShopAt).getTime() >
          new Date(a.EnterWorkShopAt).getTime()
          ? -1
          : 1
      );
    });
    // // eslint-disable-next-line no-unused-expressions
    // enter
    //   ? enter.forEach((item) => {
    //       item.count = item.enterWeightTotal;
    //       item.category = "项目进场型钢量";
    //     })
    //   : "";
    // eslint-disable-next-line no-unused-expressions
    out
      ? out.forEach((item) => {
          item.date = moment(item.OutWorkShopAt).format("YYYY-MM-DD");
          // eslint-disable-next-line no-unused-expressions
          outObject[item.date]
            ? (outObject[item.date].OutWeight =
                outObject[item.date].OutWeight + item.OutWeight)
            : (outObject[item.date] = item);
        })
      : "";
    // eslint-disable-next-line no-unused-expressions
    outObject[endedAt]
      ? ""
      : (outObject[endedAt] = {
          OutWeight: 0,
          date: endedAt,
        });

    for (let key in outObject) {
      outObject[key].count = outObject[key].OutWeight;
      outObject[key].category = "项目出场型钢量";
      outList.push(outObject[key]);
    }
    outList.sort(function (a, b) {
      return (
        // eslint-disable-next-line no-self-compare
        new Date(b.OutWorkShopAt).getTime() >
          new Date(a.OutWorkShopAt).getTime()
          ? -1
          : 1
      );
    });
    // // eslint-disable-next-line no-unused-expressions
    // out
    //   ? out.forEach((item) => {
    //       item.count = item.enterWeightTotal;
    //       item.category = "项目出场型钢量";
    //     })
    //   : "";

    this.setState({
      data: [...enterList, ...outList],
      isNull: false,
    });
  }

  render() {
    let { isNull } = this.state;
    // console.log(this.state.data);
    return (
      <div style={{ padding: "0 20px" }}>
        {isNull ? (
          <div style={{ paddingTop: 100, paddingBottom: 100 }}>
            <Empty />
          </div>
        ) : (
          <Line {...this.config} data={this.state.data} />
        )}
      </div>
    );
  }
}

export default DemoLine;
