import React, { Component } from "react";
import { Table, Button, Select, Radio, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
// import { DETAIL_DEMAND } from "../../../graphql/admin/demand";
import {
  // PROJECT_LIST,
  REPOSITORY_LIST,
  SIZE_LIST,
} from "../../../graphql/filter/filter";
import Request from "../../../utils/client";
import "./demandDetail.scss";

import axiosRequest from "../../../utils/request";
import {
  demandRequestPath,
  ProjectRequestPath,
} from "../../../request/requestPath";

const { Option } = Select;
export default class DemandDetail extends Component {
  state = {
    value: 2, // radio的值
    total: 0, // 数据总数
    current: 1, // 当前的页数
    pageSize: 20, // 每页的数据量
    projectChild: [], // 项目选项 select中的options
    projectSelect: "all", // 选择的项目，默认是全部
    reposityChildren: [], // 仓库选项 select中的options
    repositySelect: "all", // 选择的仓库，默认是全部
    sizeSelect: "all", // 选择的规格
    sizeChildren: [], // 规格选项 select中的options
    locationCode: "", // 安装码
    data: [],
    loading: true, //  table加载
    orderNo: "", // 订单号
    steelTotal: 0, //数量
    weight: 0, // 重量
  };
  componentDidMount() {
    this.aquireProject();
    this.aquireSize();
    this.aquireReposity();
    this.aquireData();
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };

  // 获取项目列表
  aquireProject = () => {
    axiosRequest
      .post(ProjectRequestPath.GetProjectList, {})
      .then((res) => {
        if (res.code === 200) {
          // console.log("获取项目", res.data);
          let children = [];
          children.push(<Option key="all">项目（全部）</Option>);
          res.data.forEach((item) => {
            children.push(
              <Option key={item.projectId}>{item.projectName}</Option>
            );
          });
          this.setState({
            projectChild: children,
          });
        }
      })
      .catch((error) => {
        console.log("获取项目报错", error);
      });
    // Request.client
    //   .query({
    //     query: PROJECT_LIST,
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     // console.log("获取项目列表", result);
    //     let { getProjectLis } = result.data;
    //     let children = [];
    //     // let projectArray = [];
    //     children.push(<Option key="all">项目（全部）</Option>);
    //     // { text: "Male", value: "male" }
    //     getProjectLis.forEach((item) => {
    //       children.push(<Option key={item.id}>{item.name}</Option>);
    //     });
    //     this.setState({
    //       projectChild: children,
    //     });
    //   })
    //   .catch((err) => {
    //     // console.log(err);
    //     message.error("无法从服务器中获取到项目数据");
    //   });
  };
  // 获取仓库列表
  aquireReposity = () => {
    Request.client
      .query({
        query: REPOSITORY_LIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取仓库列表", result);
        let { getRepositoryList } = result.data;
        let children = [];
        children.push(<Option key="all">仓库（全部）</Option>);
        getRepositoryList.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          reposityChildren: children,
          // reposityDefault: { id: "all", name: "全部" },
          repositySelect: "all",
        });
      })
      .catch((err) => {
        message.error("无法从服务器中获取到仓库数据");
      });
  };
  // 获取规格列表
  aquireSize = () => {
    Request.client
      .query({
        query: SIZE_LIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取规格列表", result);
        let { getSpecification } = result.data;
        let children = [];
        children.push(<Option key="all">规格尺寸（全部）</Option>);
        getSpecification.forEach((item) => {
          children.push(<Option key={item.id}>{item.specification}</Option>);
        });
        this.setState({
          sizeChildren: children,
          // sizeDefault: { id: "all", specification: "全部" },
          // sizeSelect: "all",
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("无法从服务器获取到规格尺寸数据");
      });
  };
  // 请求数据
  aquireData = () => {
    let input = {};
    this.state.value === 1
      ? (input.isShowAll = true)
      : (input.isShowAll = false);
    let { current, pageSize } = this.paginationProps;
    input.pageNo = current;
    input.pageSize = pageSize;
    if (this.state.locationCode !== "") {
      input.orderNo = this.state.locationCode;
    }
    if (this.state.projectSelect !== "all") {
      input.projectId = this.state.projectSelect;
    }
    if (this.state.repositySelect !== "all") {
      input.repositoryId = this.state.repositySelect;
    }
    if (this.state.sizeSelect !== "all") {
      input.specificationId = this.state.sizeSelect;
    }
    axiosRequest
      .post(demandRequestPath.GetOrderDetail, { ...input })
      .then((res) => {
        console.log("获取的需求单详情数据", res);
        if (res.code === 200) {
          if (res.message === "暂无记录") {
            this.setState({ loading: false });
            return;
          }
          res.data.array.forEach((item, index) => {
            item.key = index;
            // item.specification = item.specifications.join(",");
          });
          this.setState({
            data: res.data.array,
            loading: false,
            total: res.data.total,
            steelTotal: res.data.steelTotal,
            weight: Math.floor(res.data.steelWeight * 1000) / 1000,
          });
        } else {
          message.error("获取维修单错误");
          this.setState({ loading: false });
        }
      })
      .catch((error) => {
        console.log("获取需求单详情错误", error);
      });
    // Request.client
    //   .query({
    //     query: DETAIL_DEMAND,
    //     variables: {
    //       input,
    //     },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     let { getOrderDetailForBackEnd } = result.data;

    //     getOrderDetailForBackEnd.list.forEach((item, index) => {
    //       item.sort = index + 1;
    //       item.key = item.id;
    //       item.orderNo = item.order.orderNo;
    //       item.projectName = item.order.project.name;
    //       item.repositoryName = item.order.repository.name;
    //       // let arr = [];
    //       // item.orderSpecificationList.forEach((item2) => {
    //       //   arr.push(item2.specification);
    //       // });
    //       // item.specification = arr.join(",");
    //       item.weight = Math.floor(item.weight * 1000) / 1000;
    //     });

    //     this.setState({
    //       data: getOrderDetailForBackEnd.list,
    //       loading: false,
    //       total: getOrderDetailForBackEnd.total,
    //       weight: Math.floor(getOrderDetailForBackEnd.steelTotal * 1000) / 1000,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log("获取的需求单详情错误", err);
    //     this.setState({
    //       loading: false,
    //     });
    //     // this.props.history.push({ pathname: "/admin/error/systemerror" });
    //   });
  };

  // 表格分页
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    pageSize: 20, // 每页条数
    showQuickJumper: true, // 开启页码快速跳转
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };
  // 是否展示全部改变
  onChange = (e) => {
    // console.log("radio checked", e.target.value);
    this.paginationProps.current = 1;
    this.setState(
      {
        value: e.target.value,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 项目选项改变时
  handleChange = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        projectSelect: value + "",
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 规格列表选项改变
  handleChange2 = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        sizeSelect: value,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 仓库列表选项改变
  handleChange3 = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        repositySelect: value,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 页面发生改变
  changePage = (page) => {
    // console.log(page);
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 型钢编码输入框变化时
  inputChange = () => {
    // console.log("改变了",this.inputCode.state.value)
    this.setState(
      {
        locationCode: this.inputCode.state.value,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 输入安装码和订单号的防抖函数
  debounce = (fn, wait) => {
    let timeout;
    return function () {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(function () {
        fn.apply(this);
      }, wait);
    };
  };
  // pageSize发生改变时
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    // console.log(pageSize, current);
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 重置
  resetFunc = () => {
    this.inputCode.state.value = "";
    this.setState(
      {
        value: 2,
        locationCode: "",
        projectSelect: "all",
        sizeSelect: "all",
        repositySelect: "all",
      },
      () => {
        this.aquireData();
      }
    );
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="请输入订单编号"
          onChange={this.debounce(this.inputChange, 600)}
          defaultValue={this.state.locationCode}
          style={{ width: 200 }}
          ref={(inputCode) => {
            this.inputCode = inputCode;
          }}
        ></Input>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  getColumnFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          {dataIndex == "project" ? (
            <Select
              value={this.state.projectSelect}
              style={{ width: 200 }}
              onChange={this.handleChange}
            >
              {this.state.projectChild}
            </Select>
          ) : dataIndex == "size" ? (
            <Select
              value={this.state.sizeSelect}
              style={{ width: 270 }}
              onChange={this.handleChange2}
            >
              {this.state.sizeChildren}
            </Select>
          ) : (
            <Select
              value={this.state.repositySelect}
              style={{ width: 200 }}
              onChange={this.handleChange3}
            >
              {this.state.reposityChildren}
            </Select>
          )}
        </div>
      </div>
    ),
  });

  columns = [
    {
      title: "序号",
      dataIndex: "id",
      fixed: "left",
      width: 100,
    },
    {
      title: "需求单编号",
      dataIndex: "orderNo",
      width: 200,
      ...this.getColumnSearchProps("orderNo"),
    },
    {
      title: "项目名称",
      dataIndex: "projectName",
      width: 100,
      ...this.getColumnFilterProps("project"),
    },
    {
      title: "仓库",
      dataIndex: "repositoryName",
      width: 150,
      ...this.getColumnFilterProps("repository"),
    },
    {
      title: "规格尺寸",
      dataIndex: "specifications",
      width: 150,
      ...this.getColumnFilterProps("size"),
    },
    {
      title: "数量（根）",
      dataIndex: "steelTotal",
      width: 150,
    },
    {
      title: "重量（吨）",
      dataIndex: "steelWeight",
      width: 150,
    },
  ];

  render() {
    let {
      value,
      // projectSelect,
      // projectChild,
      // sizeSelect,
      // repositySelect,
      data,
      loading,
      // sizeChildren,
      // reposityChildren,
      // locationCode,
      // selectEWA,
      // selectOWA,
    } = this.state;
    return (
      <div className="demand_detail_box">
        <div className="btn_box">
          <div className="btn_right">
            <div>
              是否展示全部：
              <Radio.Group onChange={this.onChange} value={value}>
                <Radio value={1}>是</Radio>
                <Radio value={2}>否</Radio>
              </Radio.Group>
            </div>
            {/* <div>
              <div style={{ width: 100 }}>订单编号：</div>
              <Input
                placeholder="请输入订单编号"
                onChange={this.debounce(this.inputChange, 600)}
                defaultValue={locationCode}
                ref={(inputCode) => {
                  this.inputCode = inputCode;
                }}
              ></Input>
            </div> */}
            {/* <div>
              <div style={{ width: 80 }}>订单号：</div>
              <Input
                placeholder="请输入订单号"
                onChange={this.debounce(this.orderChange, 600)}
                defaultValue={orderNo}
                ref={(orderInput) => {
                  this.orderInput = orderInput;
                }}
              ></Input>
            </div> */}
            {/* <div>
              项目列表：
              <Select
                // defaultValue={statusDefault.desc}
                // key={statusDefault.desc}
                value={projectSelect}
                style={{ width: 200 }}
                onChange={this.handleChange}
              >
                {projectChild}
              </Select>
            </div>
            <div>
              规格列表：
              <Select
                value={sizeSelect}
                style={{ width: 270 }}
                onChange={this.handleChange2}
              >
                {sizeChildren}
              </Select>
            </div>
            <div>
              仓库列表：
              <Select
                value={repositySelect}
                style={{ width: 200 }}
                onChange={this.handleChange3}
              >
                {reposityChildren}
              </Select>
            </div> */}
            <div className="btn" style={{ marginLeft: 10 }}>
              <Button
                type="primary"
                style={{ background: "#7BAC1C", border: "none" }}
                onClick={this.resetFunc}
              >
                重置
              </Button>
            </div>
          </div>
        </div>
        <div className="table_box">
          {value == 1 ? (
            <Table
              columns={this.columns}
              dataSource={data}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
              loading={loading}
            />
          ) : (
            <Table
              columns={this.columns}
              dataSource={data}
              bordered
              scroll={{ x: 600 }}
              loading={loading}
              pagination={{ ...this.paginationProps, total: this.state.total }}
              onChange={this.handleTableChange}
            />
          )}
        </div>
        {/* <div className="tip">
          <div> 型钢总数(根)：{this.state.total}</div>
          <div> 型钢总重(吨)：{this.state.weight}</div>
        </div> */}
        {value === 2 && data.length > 0 ? (
          <div className="tip">
            <div> 型钢总数(根)：{this.state.steelTotal}</div>
            <div> 型钢总重(吨)：{this.state.weight}</div>
          </div>
        ) : (
          <div className="tip2">
            <div> 型钢总数(根)：{this.state.steelTotal}</div>
            <div> 型钢总重(吨)：{this.state.weight}</div>
          </div>
        )}
      </div>
    );
  }
}
