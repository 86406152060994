import React, { Component } from "react";
import { Table, Button, Select, Radio, DatePicker, Input, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { DETAIL_MAINTENANCE } from "../../../graphql/admin/maintenance";
import {
  STATE_MAINTENANCE,
  REPOSITORY_LIST,
  SIZE_LIST,
} from "../../../graphql/filter/filter";
import Request from "../../../utils/client";

import "./maintenanceDetail.scss";
const { Option } = Select;
function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}
export default class MaintenanceDetail extends Component {
  state = {
    data: [],
    value: 2, // radio的值
    total: 0, // 数据总数
    current: 1, // 当前的页数
    pageSize: 20, // 每页的数据量
    statusChildren: [], // 规格选项 select中的options
    statusSelect: "all", // 选择的规格，默认是全部
    reposityChildren: [], // 仓库选项 select中的options
    repositySelect: "all", // 选择的仓库，默认是全部
    sizeSelect: "all", // 选择的规格
    sizeChildren: [], // 规格选项 select中的options
    // outOfRepositoryAt: "", // 出库时间
    enteredWorkshopAt: "", // 入场时间
    // installationAt: "", // 安装时间
    // outOfRepositoryAt: "", // 出库时间
    outOfWorkshopAt: "", // 出场时间
    // selectDate: "", // 选择的日期，moment格式的
    selectEWA: "", // 选择的入场日期，moment格式的
    // selectIA: "", // 选择的安装日期，moment格式的
    // selectORA: "", // 选择的出库日期，moment格式的
    selectOWA: "", // 选择的出场日期，moment格式的
    locationCode: "", // 安装码
    loading: true, //  table加载
    orderNo: "", // 订单号
    weight: 0, // 重量
  };
  componentDidMount() {
    this.aquireStatus();
    this.aquireSize();
    this.aquireReposity();
    this.aquireData();
  }
  // 获取状态列表
  aquireStatus = () => {
    Request.client
      .query({
        query: STATE_MAINTENANCE,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取状态列表", result);
        let { getStateForMaintenance } = result.data;
        let children = [];
        children.push(<Option key="all">状态（全部）</Option>);
        getStateForMaintenance.forEach((item) => {
          children.push(<Option key={item.state}>{item.desc}</Option>);
        });
        this.setState({
          statusChildren: children,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("无法从服务器中获取到状态数据");
      });
  };
  // 获取仓库列表
  aquireReposity = () => {
    Request.client
      .query({
        query: REPOSITORY_LIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取仓库列表", result);
        let { getRepositoryList } = result.data;
        let children = [];
        children.push(<Option key="all">仓库（全部）</Option>);
        getRepositoryList.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          reposityChildren: children,
          // reposityDefault: { id: "all", name: "全部" },
          repositySelect: "all",
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("无法从服务器中获取到仓库数据");
      });
  };
  // 获取规格列表
  aquireSize = () => {
    Request.client
      .query({
        query: SIZE_LIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取规格列表", result);
        let { getSpecification } = result.data;
        let children = [];
        children.push(<Option key="all">规格尺寸（全部）</Option>);
        getSpecification.forEach((item) => {
          children.push(<Option key={item.id}>{item.specification}</Option>);
        });
        this.setState({
          sizeChildren: children,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("无法从服务器获取到规格尺寸数据");
      });
  };
  // 请求数据
  aquireData = () => {
    let input = {};
    this.state.value === 1
      ? (input.isShowAll = true)
      : (input.isShowAll = false);
    let { current, pageSize } = this.paginationProps;
    input.page = current;
    input.pageSize = pageSize;
    if (this.state.locationCode !== "") {
      input.code = this.state.locationCode;
    }
    if (this.state.statusSelect !== "all") {
      input.state = this.state.statusSelect;
    }
    if (this.state.repositySelect !== "all") {
      input.repositoryId = this.state.repositySelect;
    }
    if (this.state.sizeSelect !== "all") {
      input.specificationId = this.state.sizeSelect;
    }
    if (this.state.selectEWA !== "") {
      input.enteredMaintenanceAt = this.state.enteredWorkshopAt;
    }
    if (this.state.selectOWA !== "") {
      input.outMaintenanceAt = this.state.outOfWorkshopAt;
    }
    Request.client
      .query({
        query: DETAIL_MAINTENANCE,
        variables: {
          input,
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("详情", result);
        let { getMaintenanceDetail } = result.data;

        getMaintenanceDetail.list.forEach((item, index) => {
          item.sort = index + 1;
          item.key = item.id;
          item.code = item.steel.code;
          item.desc = item.stateInfo.desc;
          item.curretnStatus = item.steel.stateInfo.desc;
          item.enteredAt
            ? (item.enteredDate = moment(item.enteredAt).format("YYYY-MM-DD"))
            : (item.enteredDate = "");
          item.outedAt
            ? (item.outedDate = moment(item.outedAt).format("YYYY-MM-DD"))
            : (item.outedDate = "");
          item.specification = item.steel.specifcation.specification;
          item.repository = item.steel.repository.name;
        });

        this.setState({
          data: getMaintenanceDetail.list,
          loading: false,
          total: getMaintenanceDetail.total,
          weight: getMaintenanceDetail.weight.toFixed(2),
        });
      })
      .catch((err) => {
        // this.props.history.push({ pathname: "/admin/error/systemerror" });
        // message.error(err)
        console.log(err);
      });
  };
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };
  // 表格分页
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    showQuickJumper: true, // 开启页码快速跳转
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };
  // 是否展示全部改变
  onChange = (e) => {
    // console.log("radio checked", e.target.value);
    this.paginationProps.current = 1;
    this.setState(
      {
        value: e.target.value,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 状态选项改变
  handleChange = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        statusSelect: value + "",
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 规格列表选项改变
  handleChange2 = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        sizeSelect: value,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 仓库列表选项改变
  handleChange3 = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        repositySelect: value,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 出库时间发生改变
  // onDateChange = (e) => {
  //   console.log(e);
  //   let value = moment(e).format();
  //   this.setState(
  //     {
  //       outOfRepositoryAt: value,
  //       selectDate: e,
  //       current: 1,
  //     },
  //     () => {
  // this.aquireData();
  //     }
  //   );
  //   console.log(value);
  // };
  // 入库时间发生改变
  // onORAChange = (e) => {
  //   let value = moment(e).format();
  //   this.setState(
  //     {
  //       outOfRepositoryAt: value,
  //       selectORA: e,
  //       current: 1,
  //     },
  //     () => {
  // this.aquireData();
  //     }
  //   );
  // };
  // 安装时间发生改变
  // onIAChange = (e) => {
  //   let value = moment(e).format();
  //   this.setState(
  //     {
  //       installationAt: value,
  //       selectIA: e,
  //       current: 1,
  //     },
  //     () => {
  // this.aquireData();
  //     }
  //   );
  // };
  // 出场时间发生改变
  onOWAChange = (e) => {
    // console.log(e, e == null);
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        outOfWorkshopAt: value,
        selectOWA: e,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 入场发生改变
  onEWAChange = (e) => {
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        enteredWorkshopAt: value,
        selectEWA: e,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 页面发生改变
  changePage = (page) => {
    // console.log(page);
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 型钢编码输入框变化时
  inputChange = () => {
    this.setState(
      {
        locationCode: this.inputCode.state.value,
        current: 1,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 订单号输入框变化时
  // orderChange = () => {
  //   this.setState(
  //     {
  //       orderNo: this.orderInput.state.value,
  //       current: 1,
  //     },
  //     () => {
  // this.aquireData();
  //     }
  //   );
  // };
  // 输入安装码和订单号的防抖函数
  debounce = (fn, wait) => {
    let timeout;
    return function () {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(function () {
        fn.apply(this);
      }, wait);
    };
  };
  // pageSize发生改变时
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    // console.log(pageSize, current);
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 重置
  resetFunc = () => {
    this.inputCode.state.value = "";
    this.setState(
      {
        value: 2,
        locationCode: "",
        statusSelect: "all",
        sizeSelect: "all",
        repositySelect: "all",
        outOfWorkshopAt: null,
        enteredWorkshopAt: null,
        selectOWA: "",
        selectEWA: "",
      },
      () => {
        this.aquireData();
      }
    );
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="请输入型钢编码"
          onChange={this.debounce(this.inputChange, 600)}
          defaultValue={this.state.locationCode}
          ref={(inputCode) => {
            this.inputCode = inputCode;
          }}
        ></Input>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  getColumnFilterProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <div>
          {dataIndex == "status" ? (
            <Select
              // defaultValue={statusDefault.desc}
              // key={statusDefault.desc}
              value={this.state.statusSelect}
              style={{ width: 200 }}
              onChange={this.handleChange}
            >
              {this.state.statusChildren}
            </Select>
          ) : dataIndex == "size" ? (
            <Select
              value={this.state.sizeSelect}
              style={{ width: 270 }}
              onChange={this.handleChange2}
            >
              {this.state.sizeChildren}
            </Select>
          ) : dataIndex === "repository" ? (
            <Select
              value={this.state.repositySelect}
              style={{ width: 200 }}
              onChange={this.handleChange3}
            >
              {this.state.reposityChildren}
            </Select>
          ) : dataIndex === "owa" ? (
            <DatePicker
              showTime
              value={this.state.selectOWA}
              onChange={this.onOWAChange}
            />
          ) : (
            <DatePicker
              showTime
              value={this.state.selectEWA}
              onChange={this.onEWAChange}
            />
          )}
        </div>
      </div>
    ),
  });

  render() {
    let {
      value,
      statusSelect,
      statusChildren,
      sizeSelect,
      repositySelect,
      data,
      loading,
      sizeChildren,
      reposityChildren,
      locationCode,
      selectEWA,
      selectOWA,
    } = this.state;
    const columns = [
      {
        title: "序号",
        dataIndex: "id",
        fixed: "left",
        width: 100,
      },
      {
        title: "维修状态",
        dataIndex: "desc",
        width: 150,
        ...this.getColumnFilterProps("status"),
      },

      {
        title: "仓库名称",
        dataIndex: "repository",
        width: 150,
        ...this.getColumnFilterProps("repository"),
      },
      {
        title: "型钢编号",
        dataIndex: "code",
        width: 200,
        ...this.getColumnSearchProps("code"),
      },

      {
        title: "规格尺寸",
        dataIndex: "specification",
        width: 150,
        ...this.getColumnFilterProps("size"),
      },
      {
        title: "当前状态",
        dataIndex: "currentStatus",
        width: 150,
      },
      {
        title: "入厂时间",
        dataIndex: "enteredDate",
        width: 150,
        ...this.getColumnFilterProps("ewa"),
      },
      {
        title: "出厂时间",
        dataIndex: "outedDate",
        width: 150,
        ...this.getColumnFilterProps("owa"),
      },
      {
        title: "维修天数",
        dataIndex: "useDays",
        width: 150,
      },
    ];
    return (
      <div className="maintenance_detail_box">
        <div className="btn_box">
          <div className="btn_right">
            <div>
              是否展示全部：
              <Radio.Group onChange={this.onChange} value={value}>
                <Radio value={1}>是</Radio>
                <Radio value={2}>否</Radio>
              </Radio.Group>
            </div>
            {/* <div>
              <div style={{ width: 100 }}>型钢编码：</div>
              <Input
                placeholder="请输入型钢编码"
                onChange={this.debounce(this.inputChange, 600)}
                defaultValue={locationCode}
                ref={(inputCode) => {
                  this.inputCode = inputCode;
                }}
              ></Input>
            </div> */}
            {/* <div>
              <div style={{ width: 80 }}>订单号：</div>
              <Input
                placeholder="请输入订单号"
                onChange={this.debounce(this.orderChange, 600)}
                defaultValue={orderNo}
                ref={(orderInput) => {
                  this.orderInput = orderInput;
                }}
              ></Input>
            </div> */}

            {/* <div>
              出库时间：
              <DatePicker value={selectDate} onChange={this.onDateChange} />
            </div>
            <div>
              入库时间：
              <DatePicker value={selectORA} onChange={this.onORAChange} />
            </div>
            <div>
              安装时间：
              <DatePicker value={selectIA} onChange={this.onIAChange} />
            </div> */}
            {/* <div>维修状态：</div>
            <div>规格列表：</div>
            <div>仓库列表：</div>
            <div>出场时间：</div>
            <div>入场时间：</div> */}
            <div className="btn" style={{ marginLeft: 10 }}>
              <Button
                type="primary"
                style={{ background: "#7BAC1C", border: "none" }}
                onClick={this.resetFunc}
              >
                重置
              </Button>
            </div>
          </div>
        </div>
        <div className="table_box">
          {value == 1 ? (
            <Table
              onChange={onChange}
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
              loading={loading}
            />
          ) : (
            <Table
              onChange={onChange}
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 600 }}
              loading={loading}
              pagination={this.paginationProps}
            />
          )}
        </div>
        {value === 0 && data.length > 0 ? (
          <div className="tip">
            <div> 型钢总数(根)：{this.state.total}</div>
            <div> 型钢总重(吨)：{this.state.weight}</div>
          </div>
        ) : (
          <div className="tip2">
            <div> 型钢总数(根)：{this.state.total}</div>
            <div> 型钢总重(吨)：{this.state.weight}</div>
          </div>
        )}
      </div>
    );
  }
}
