// 判断字符串是否是非空
function isNull(str) {
  if (str === "") return true;
  var regu = "^[ ]+$";
  var re = new RegExp(regu);
  return re.test(str);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isNull,
};
