import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import {
  Form,
  Input,
  DatePicker,
  Select,
  message,
  Cascader,
  Button,
} from "antd";
import moment from "moment";
import cityArray from "../../../../utils/city";
import axiosRequest from "../../../../utils/request";
import {
  ProjectRequestPath,
  companyRequestPath,
} from "../../../../request/requestPath";
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};
let map = null;

function AddProject(props, ref) {
  let [children, setChildren] = useState([]);
  const [id, setId] = useState("");
  const [form] = Form.useForm();
  let [latitude, setLatitude] = useState(0);
  let [longitude, setLongitude] = useState(0);

  let [initFlag, setInitFlag] = useState(false);
  useEffect(() => {
    setInitFlag(true);
    return function cleanup() {
      map = null;
    };
  }, []);
  useImperativeHandle(ref, () => {
    return {
      // 获取管理员
      getCompanyUser() {
        axiosRequest
          .post(companyRequestPath.GetCompanyUser, {})
          .then((res) => {
            if (res.code === 200) {
              let children2 = [];
              res.data.forEach((item) => {
                if (item.roleName === "项目管理员") {
                  children2.push(
                    <Option key={item.userId}>{item.userName}</Option>
                  );
                }
              });
              setChildren(children2);
            }
          })
          .catch((error) => {
            console.log("获取项目管理员错误", error);
          });
      },
      // 添加（打开窗口）
      openAddWindows() {
        initMap(116.404, 39.915);
      },
      // 添加项目
      addProject() {
        form
          .validateFields()
          .then((res) => {
            // console.log(form.getFieldsValue());
            let { name, city, address, leaderIdS, remark, startAt } =
              form.getFieldsValue();
            let newStartAt = moment(startAt).format();
            let cityAddress = city.join("/");
            let leaderId = [];
            leaderIdS.forEach((item) => {
              leaderId.push(item * 1);
            });
            addressResolution();
            let input = {
              address,
              leaderIdS: leaderId,
              name,
              city: cityAddress,
              startAt: newStartAt,
              location: {
                latitude,
                longitude,
              },
            };
            if (remark !== "") {
              input.remark = remark;
            }

            axiosRequest
              .post(ProjectRequestPath.CreateProject, input)
              .then((res) => {
                console.log(res);
                if (res.code === 200) {
                  message.success("添加项目成功");
                  form.resetFields();
                  props.closeModal();
                  props.aquireData();
                } else {
                  message.error(res.message);
                }
              })
              .catch((error) => {
                console.log("添加项目错误", error);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 修改项目
      modifyProject(project) {
        console.log(project);
        let {
          address,
          leaderList,
          projectId,
          projectName,
          remark,
          city,
          startAt,
          latitude,
          longitude,
        } = project;
        setLatitude(latitude);
        setLongitude(longitude);
        initMap(longitude, latitude); // 创建点坐标
        setId(projectId);
        let leaderIdS = leaderList.map((item) => {
          return item.id + "";
        });
        let newStartAt = moment(startAt);
        form.setFieldsValue({
          address,
          leaderIdS,
          name: projectName,
          remark,
          city: city.split("/"),
          startAt: newStartAt,
        });
      },
      // 修改项目提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { name, city, address, leaderIdS, remark, startAt } =
              form.getFieldsValue();
            let newStartAt = moment(startAt).format();
            // console.log(newStartAt);
            // console.log("修改项目");
            let cityAddress = city.join("/");
            let leaderIdList = [];
            leaderIdS.forEach((item) => {
              leaderIdList.push(item * 1);
            });
            axiosRequest
              .post(ProjectRequestPath.EditProject, {
                id,
                name,
                address,
                city: cityAddress,
                leaderIdList,
                remark,
                startedAt: newStartAt,
                latitude,
                longitude,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("修改成功");
                  form.resetFields();
                  props.closeModal();
                  props.aquireData();
                }
              })
              .catch((error) => {
                console.log("修改项目信息错误", error);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handlerResetForm() {
        form.resetFields();
      },
    };
  });

  // 初始化地图
  const initMap = (longitude, latitude) => {
    let { BMapGL } = window;
    if (!initFlag) {
      map = new BMapGL.Map("container"); // 创建地图实例
      map.addEventListener("click", function (e) {
        let lng = e.latlng.lng;
        let lat = e.latlng.lat;
        map.clearOverlays();
        setLatitude(lat);
        setLongitude(lng);
        map.centerAndZoom(e.latlng, 20);
        map.addOverlay(new BMapGL.Marker(e.latlng, { title: "klk;lklklk" }));
        geocoder.getLocation(e.latlng, function (rs) {
          var addComp = rs.addressComponents;
          form.setFieldsValue({
            city:
              addComp.province === addComp.city
                ? [addComp.province, addComp.district]
                : [addComp.province, addComp.city, addComp.district],
            address: addComp.street + addComp.streetNumber,
          });
        });
      });
    }

    var point = new BMapGL.Point(longitude, latitude); // 创建点坐标
    map.clearOverlays();
    map.addOverlay(new BMapGL.Marker(point, { title: "klk;lklklk" }));
    setTimeout(() => {
      map.centerAndZoom(point, 20); // 初始化地图，设置中心点坐标和地图级别
    }, 100);
    map.enableScrollWheelZoom(true);
    var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
    map.addControl(zoomCtrl);

    var geocoder = new BMapGL.Geocoder();
  };

  const onChange = (value, selectedOptions) => {
    console.log(value, selectedOptions);
  };

  const filter = (inputValue, path) => {
    return path.some((option) => option.label.indexOf(inputValue) > -1);
  };
  // 地址解析
  const addressResolution = () => {
    let { address, city } = form.getFieldsValue();
    if (!city) {
      message.error("请输入地址");
    }
    let selecty = city.length === 3 ? city[1] : city[0];
    let cityDetail = "";
    city.forEach((item) => {
      cityDetail = cityDetail + item;
    });

    let { BMapGL } = window;

    let myGeo = new BMapGL.Geocoder();
    // 将地址解析结果显示在地图上，并调整地图视野
    myGeo.getPoint(
      cityDetail + address,
      function (point) {
        if (point) {
          if (latitude) {
            map.clearOverlays();
          }
          setLatitude(point.lat);
          setLongitude(point.lng);
          map.centerAndZoom(point, 16);
          map.addOverlay(new BMapGL.Marker(point, { title: "klk;lklklk" }));
          map.enableScrollWheelZoom(true);
        } else {
          message.error("您输入的地址无法解析出经纬度");
        }
      },
      selecty
    );
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="name"
          label="项目名称"
          rules={[{ required: true, message: "请输入项目名称" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        {/* <Form.Item
          name="city"
          label="城市"
          rules={[{ required: true, message: "请输入城市" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item> */}
        <Form.Item
          label="城市"
          name="city"
          rules={[{ required: true, message: "请选择城市" }]}
        >
          <Cascader
            options={cityArray}
            onChange={onChange}
            placeholder="请选择城市"
            showSearch={filter}
            style={{ width: 300 }}
          />
        </Form.Item>
        <Form.Item
          name="address"
          label="地址"
          // rules={[{ required: true, message: "请输入地址" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item label="地址解析">
          <Button
            type="primary"
            onClick={addressResolution}
            style={{ marginLeft: 10 }}
          >
            地址解析
          </Button>
          <div
            style={{
              fontSize: 12,
              width: 300,
              color: "rgba(0,0,0,.6)",
              marginTop: 10,
            }}
          >
            添加项目前请先进行地址解析，确保地址解析所获取的经纬度是正确的(可点击地图选取位置)
          </div>
          <div
            id="container"
            style={{ height: 200, width: 300, marginTop: 10 }}
          ></div>
        </Form.Item>
        <Form.Item
          name="leaderIdS"
          label="管理员"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择管理员",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: 300 }}
            placeholder="请选择管理员"
            onChange={handleChange}
            maxTagCount={3}
          >
            {children}
          </Select>
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          rules={[{ required: true, message: "请输入" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="startAt"
          label="开始时间"
          rules={[{ required: true, message: "请选择时间" }]}
        >
          <DatePicker style={{ width: 300 }} showTime />
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddProject);
