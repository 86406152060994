// 管理员类型
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  repository: 3,
  repositoryAdmin: 3,
  project: 4,
  projectAdmin: 4,
  maintenance: 5,
  maintenanceAdmin: 5,
};
