import Request from "../../utils/client";

// 获取日志类型
export const AQUIRE_LOG_TYPE = Request.gql`
    query {
        getLogTypeList {
            desc # 类型说明 
            flag   #类型标志
        }
    }
`;
// 获取日志列表
export const AQUIRE_LOG = Request.gql`
    query ($input: GetLogListInput!){
        getLogList(input: $input)  {
        list {
            id
            typeInfo {
                flag # 类型标志
                desc # 类型说明
            }
            content # 操作内容 
            user {
                id 
                name # 操作用户
            }
            createdAt # 添加时间
        }
        total # 数量 
        }
    }
`;
