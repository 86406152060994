import { Component } from "react";
import { Spin } from "antd";
import PubSub from "pubsub-js";

import "./loading.scss";

export default class LoadingData extends Component {
  state = {
    isloading: false,
  };
  componentDidMount() {
    PubSub.subscribe("loading", (_, flag) => {
      if (!flag) {
        setTimeout(() => {
          this.setState({ isloading: flag });
        }, 500);
      } else {
        this.setState({ isloading: flag });
      }
    }); //订阅
  }
  render() {
    let { tip = "数据加载中..." } = this.props;
    let { isloading } = this.state;
    return (
      <div
        className={isloading ? `showLoading loading` : `hiddenLoading loading`}
      >
        <Spin tip={tip} size="large" />
      </div>
    );
  }
}
