import React, { Component } from "react";
import { Table, message } from "antd";
// import Request from "../../../../../utils/client";
// import { GET_MAINTENANCE_STEEL } from "../../../../../graphql/admin/index/maintenance";
import moment from "moment";
import axiosRequest from "../../../../../utils/request";
import { maintenanceIndexRequest } from "../../../../../request/requestPath";
const columns = [
  {
    title: "序号",
    dataIndex: "morderId",
    fixed: "left",
    width: 100,
  },
  {
    title: "维修单号",
    dataIndex: "morderNo",
    width: 180,
  },
  {
    title: "仓库名称",
    dataIndex: "repositoryName",
    width: 150,
  },
  {
    title: "维修厂名称",
    dataIndex: "maintenanceName",
    width: 150,
  },
  {
    title: "维修状态",
    dataIndex: "status",
    width: 150,
  },
  {
    title: "维修时间",
    dataIndex: "maintenanceTime",
    width: 150,
  },
  {
    title: "预计修回时间",
    dataIndex: "expectTime",
    width: 150,
  },
  {
    title: "型钢清单",
    dataIndex: "steel",
    width: 200,
  },
  {
    title: "设备清单",
    dataIndex: "equipment",
    width: 200,
  },
  {
    title: "配件清单",
    dataIndex: "part",
    width: 200,
  },
  {
    title: "仓库管理员",
    dataIndex: "repositoryAdmin",
    width: 150,
  },
  {
    title: "维修管理员",
    dataIndex: "maintenanceAdmin",
    width: 150,
  },
  {
    title: "公司管理员",
    dataIndex: "companyAdmin",
    width: 150,
  },
  {
    title: "出库时间",
    dataIndex: "outTime",
    width: 150,
  },
  {
    title: "出库运输公司及单号",
    dataIndex: "outCompanyExpress",
    width: 180,
  },
  {
    title: "出厂运输公司及单号",
    dataIndex: "outMaintenanceExpress",
    width: 180,
  },
  {
    title: "备注",
    dataIndex: "remark",
    width: 150,
  },
];

export default class log extends Component {
  state = {
    data: [],
    loading: true,
    total: 0, // 数据总数
    current: 1, // 当前的页数
    pageSize: 20, // 每页的数据量
    statusObj: {
      200: "待确认",
      300: "已确认",
      500: "已发货",
      400: "已拒绝",
      700: "部分收货",
      800: "收货",
    },
  };
  projectId = ""; // 项目id
  repositoryId = ""; // 仓库id
  // 表格分页
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    showQuickJumper: true, // 开启页码快速跳转
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };
  componentDidMount() {}
  // 请求数据
  aquireData = (maintenanceId) => {
    // input.repositoryId = id;
    // let { current, pageSize } = this.paginationProps;
    // input.page = current;
    // input.pageSize = pageSize;
    // item.enterRepositoryDate = moment(item.enterRepositoryAt).format(
    //         "YYYY-MM-DD"
    axiosRequest
      .post(maintenanceIndexRequest.GetSteelByMorder, {
        maintenanceId: maintenanceId * 1,
      })
      .then((res) => {
        if (res.code === 200) {
          console.log("获取型钢维修动向", res);
          let data = res.data;
          if (!data) {
            this.setState({
              data: [],
              loading: false,
            });
            return;
          }
          data.forEach((item) => {
            item.key = item.morderNo;
            item.status = this.state.statusObj[item.state];

            let equipmentArray = [];
            item.equipments.forEach((child) => {
              equipmentArray.push(
                `${child.equipmentName}(数量：${child.total})`
              );
            });
            item.equipment = equipmentArray.join(",");
            let partsArray = [];
            item.parts.forEach((child) => {
              partsArray.push(`${child.partName}(数量：${child.partTotal})`);
            });
            item.part = partsArray.join(",");

            let steelArray = [];
            item.steels.forEach((child) => {
              steelArray.push(
                `${child.steelSpecification}(重量：${child.steelWeight})`
              );
            });
            item.steel = steelArray.join(",");

            item.maintenanceTime = moment(item.maintenanceTime).format(
              "YYYY-MM-DD"
            );
            item.expectTime = moment(item.expectedReturnAt).format(
              "YYYY-MM-DD"
            );
            item.outTime = moment(item.outRepositoryAt).format("YYYY-MM-DD");

            // item.repositoryLogistics = `${item.outRepository.outRepositoryExpressCompany}(单号：${item.outRepository.outRepositoryExpressId})`;
            // item.factoryLogistics = `${item.outRepository.outExpressCompany}(单号：${item.outRepository.outExpressId})`;
          });

          this.setState({
            data,
            loading: false,
          });
        } else {
          message.error("获取型钢维修动向错误");
        }
      })
      .catch((error) => {
        message.error("获取型钢维修动向错误");
      });
  };

  render() {
    let { loading, data } = this.state;
    return (
      <div>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{ x: 600 }}
          pagination={false}
          loading={loading}
        />
      </div>
    );
  }
}
