/**
 * 型钢历史信息
 */
import React, { Component } from "react";
import { Table, Button, Popconfirm, Modal, message, Select, Input } from "antd";
import Request from "../../../utils/client";
import root from "../../../config/root";
import AddWarehousel from "./components/AddWarehousel";
import {
  AQUIRE_WAREHOUSE,
  DELETE_WAREHOUSE,
  STEEL_HISTORY,
} from "../../../graphql/admin/warehouse";

import "./warehouse.scss";
const { Option } = Select;
export default class log extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: "序号",
        dataIndex: "id",
        fixed: "left",
        width: 100,
      },
      {
        title: "仓库名称",
        dataIndex: "name",
        width: 150,
      },
      {
        title: "仓库拼音简写",
        dataIndex: "pinYin",
        width: 150,
      },
      {
        title: "所在城市",
        dataIndex: "city",
        width: 150,
      },
      {
        title: "地址",
        dataIndex: "address",
        width: 150,
      },
      {
        title: "管理员",
        dataIndex: "leaderName",
        width: 200,
      },
      {
        title: "电话",
        dataIndex: "phone",
        width: 150,
      },

      {
        title: "微信",
        dataIndex: "wechat",
        width: 150,
      },
      {
        title: "数量（根）",
        dataIndex: "total",
        width: 120,
      },
      {
        title: "重量（吨）",
        dataIndex: "weight",
        width: 120,
      },
      {
        title: "备注",
        dataIndex: "remark",
        width: 150,
      },
      {
        title: "状态",
        dataIndex: "status",
        width: 150,
      },
    ];
    let userInfo = localStorage.getItem("userInfo");

    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify || root[role][pathname].delete) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {root[role][pathname].modify ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  编辑
                </Button>
              ) : (
                ""
              )}
              {root[role][pathname].delete ? (
                <Popconfirm
                  title="确定删除吗？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    this.deleteItem(item);
                  }}
                >
                  <Button type="danger" style={{ marginLeft: "20px" }}>
                    删除
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }

    this.state = {
      data: [],
      warehouseData: [], // 存储获取的数据，用于用户搜索时
      columns,
      role,
      pathname,
      modalVisible: false,
      title: "添加仓库",
      searchType: "name",
    };
  }

  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    Request.client
      .query({
        query: STEEL_HISTORY,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("型钢历史信息", result);
        // let { getRepositoryList } = result.data;
        // getRepositoryList.forEach((item, index) => {
        //   item.sort = index + 1;
        //   item.key = index;
        //   let nameList = [];
        //   let wechatList = [];
        //   let phoneList = [];
        //   item.leaders.forEach((item) => {
        //     nameList.push(item.name);
        //     wechatList.push(item.wechat);
        //     phoneList.push(item.phone);
        //   });
        //   item.city = item.city.split("/").join("");
        //   item.leaderName = nameList.length > 0 ? nameList.join(",") : "";
        //   item.wechat = wechatList.length > 0 ? wechatList.join(",") : "";
        //   item.phone = phoneList.length > 0 ? phoneList.join(",") : "";
        //   item.status = item.isAble ? "启用" : "禁用";
        // });
        // this.setState({
        //   data: getRepositoryList,
        //   warehouseData: getRepositoryList,
        // });
      })
      .catch((error) => {
        // this.props.history.push({ pathname: "/admin/error/systemerror" });
      });
  };
  deleteItem = (item) => {
    Request.client
      .mutate({
        mutation: DELETE_WAREHOUSE, // 封装好的GraphQL,
        variables: {
          id: item.id,
        },
      })
      .then((result) => {
        message.success("删除仓库成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  // 设置弹窗的显示和关闭
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加仓库" }, () => {
      if (modalVisible) {
        this.AddWarehousel.openAddWindows();
        this.AddWarehousel.getMaintenancePersonenance();
        this.AddWarehousel.handlerResetForm();
      }
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加仓库") {
      this.AddWarehousel.addExpress();
    } else {
      this.AddWarehousel.modifyProjectSubmit();
    }
  };

  // 关闭弹窗，由AddWarehousel组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  // 修改项目
  modify = (project) => {
    this.setState({ modalVisible: true, title: "编辑仓库" }, () => {
      this.AddWarehousel.getMaintenancePersonenance();
      this.AddWarehousel.modify(project);
    });
  };
  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { warehouseData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }

    let result = [];
    if (searchType === "name") {
      result = warehouseData.filter((obj) => {
        return obj.name.indexOf(keyword) !== -1;
      });
    } else if (searchType === "address") {
      result = warehouseData.filter((obj) => {
        return obj.address.indexOf(keyword) !== -1;
      });
    } else if (searchType === "admin") {
      result = warehouseData.filter((obj) => {
        return obj.leaderName.indexOf(keyword) !== -1;
      });
    } else {
      result = warehouseData.filter((obj) => {
        return obj.phone.indexOf(keyword) !== -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "name",
      },
      () => {
        this.aquireData();
      }
    );
  };
  render() {
    let { data, role, pathname, columns, title } = this.state;
    return (
      <div className="warehouse_box">
        <div className="btn_box" style={{ justifyContent: "flex-end" }}>
          {/* <Button
            type="primary"
            style={{ background: "#A06921", border: "none" }}
          >
            导出
          </Button> */}
          {/* <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div> */}
          <div>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="name">仓库名称</Option>
              <Option value="address">仓库地址</Option>
              <Option value="admin">管理员名称</Option>
              <Option value="phone">仓库电话</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={this.closeModal}
        >
          <AddWarehousel
            ref={(AddWarehousel) => {
              this.AddWarehousel = AddWarehousel;
            }}
            closeModal={this.closeModal}
          ></AddWarehousel>
        </Modal>
      </div>
    );
  }
}
