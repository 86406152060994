import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, Input, DatePicker, Select, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Request, { uploadClient } from "../../../../utils/client";
import { SINGLE_UPLOAD } from "../../../../graphql/upload/upload";
import { ADD_COMPANY, MODIFY_COMPANY } from "../../../../graphql/admin/company";
import axiosRequet from "../../../../utils/request";
import { companyRequestPath } from "../../../../request/requestPath";
import moment from "moment";
// 提交的方法
// const Demo = () => {
//   const onFinish = (values) => {
//     console.log("Received values of form: ", values);
//   };
// };
const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddCompany(props, ref) {
  // state = {
  //   loading: false,
  // };
  const [loading, setLoad] = useState(false);
  const [bgLoading, setbGLoad] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [background, setBackground] = useState("");
  const [avaUrl, setAvaImgUrl] = useState("");
  const [avaloading, setAvaLoad] = useState(false);
  const [logoFileId, setLogoFileId] = useState("");
  const [backgroundFileId, setBgFileId] = useState("");
  const [adminAvatarFileId, setAdminFileId] = useState("");
  let [id, setId] = useState("");
  const [form] = Form.useForm();
  const normFile = (e) => {
    // console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };
  // 自定义logo上传的方法
  let doImgUpload = (options) => {
    // const { onSuccess, onError, file, onProgress } = options;
    const { file } = options;
    setLoad(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("图片格式只能是JPG/PNG");
      setLoad(false);
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("图片大小不能超过2MB!");
      setLoad(false);
      return;
    }
    uploadClient
      .mutate({
        mutation: SINGLE_UPLOAD,
        variables: {
          file,
        },
      })
      .then((res) => {
        console.log(res);
        let { url, id } = res.data.singleUpload;
        let img = url;
        setLogoFileId(id);
        setImgUrl(img);
        message.success("图片上传成功");
        setLoad(false);
      })
      .catch((err) => {
        message.error(err);
        setLoad(false);
      });
  };

  // 自定义background上传的方法
  let backgroundImgUpload = (options) => {
    // const { onSuccess, onError, file, onProgress } = options;
    const { file } = options;
    setbGLoad(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("图片格式只能是JPG/PNG");
      setbGLoad(false);
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("图片大小不能超过2MB!");
      setbGLoad(false);
      return;
    }
    uploadClient
      .mutate({
        mutation: SINGLE_UPLOAD,
        variables: {
          file,
        },
      })
      .then((res) => {
        let { url, id } = res.data.singleUpload;
        let img = url;
        setBgFileId(id);
        setBackground(img);
        message.success("图片上传成功");
        setbGLoad(false);
      })
      .catch((err) => {
        message.error(err);
        setbGLoad(false);
      });
  };
  // 自定义管理员头像上传的方法
  let avatarImgUpload = (options) => {
    // const { onSuccess, onError, file, onProgress } = options;
    const { file } = options;
    setAvaLoad(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("图片格式只能是JPG/PNG");
      setAvaLoad(false);
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("图片大小不能超过2MB!");
      setAvaLoad(false);
      return;
    }
    uploadClient
      .mutate({
        mutation: SINGLE_UPLOAD,
        variables: {
          file,
        },
      })
      .then((res) => {
        let { url, id } = res.data.singleUpload;
        let img = url;
        setAdminFileId(id);
        setAvaImgUrl(img);
        message.success("图片上传成功");
        setAvaLoad(false);
      })
      .catch((err) => {
        message.error(err);
        setAvaLoad(false);
      });
  };
  // 暴露给父组件调用的方法
  useImperativeHandle(ref, () => {
    return {
      // 添加
      addCompany() {
        setId("");
        form
          .validateFields()
          .then((res) => {
            let {
              adminName,
              adminPassword,
              adminPhone,
              adminWechat,
              name,
              phone,
              pinYin,
              symbol,
              username,
              wechat,
              startedAt,
              endedAt,
              isAble,
            } = form.getFieldsValue();
            let newStartAt = moment(startedAt).format("YYYY-MM-DD");
            let newEndedAt = moment(endedAt).format("YYYY-MM-DD");
            let flag = isAble === "true" ? true : false;
            Request.client
              .mutate({
                mutation: ADD_COMPANY, // 封装好的GraphQL,
                variables: {
                  input: {
                    adminName,
                    adminPassword,
                    adminPhone,
                    adminWechat,
                    name,
                    phone,
                    pinYin,
                    symbol,
                    username,
                    wechat,
                    startedAt: newStartAt,
                    endedAt: newEndedAt,
                    logoFileId,
                    backgroundFileId,
                    adminAvatarFileId,
                    isAble: flag,
                  },
                },
              })
              .then((result) => {
                message.success("添加成功");
                form.resetFields();
                props.closeModal();
              })
              .catch((error) => {
                message.error(error.message);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 修改
      modify(obj) {
        let {
          adminName,
          adminAvatar,
          adminAvatarFileId,
          adminPassword,
          adminPhone,
          adminWechat,
          backgroundImg,
          backgroundFileId,
          logoImg,
          logoFileId,
          companyId,
          companyName,
          phone,
          companyPinYin,
          symbol,
          username,
          wechat,
          startedAt,
          ended_at,
          userIsAble,
        } = obj;
        setId(companyId);
        setLogoFileId(logoFileId);
        setBgFileId(backgroundFileId);
        setImgUrl(logoImg);
        setBackground(backgroundImg);
        setAdminFileId(adminAvatarFileId);
        setAvaImgUrl(adminAvatar);
        let newStartAt = moment(startedAt);
        let newEndedAt = moment(ended_at);
        let newIsAble = userIsAble ? "true" : "false";
        // console.log(newStartAt, newEndedAt);
        // return
        form.setFieldsValue({
          adminName,
          adminPassword,
          adminPhone,
          adminWechat,
          name: companyName,
          phone,
          pinYin: companyPinYin,
          symbol,
          username,
          wechat,
          startedAt: newStartAt,
          endedAt: newEndedAt,
          isAble: newIsAble,
          logo: backgroundImg,
          background: logoImg,
          adminAvatar: adminAvatar,
        });
      },
      // 修改公司时提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let {
              adminName,
              adminPhone,
              adminWechat,
              name,
              phone,
              pinYin,
              symbol,
              username,
              wechat,
              startedAt,
              endedAt,
              isAble,
              modifyAdminPassword,
            } = form.getFieldsValue();
            let newStartAt = moment(startedAt).format("YYYY-MM-DD");
            let newEndedAt = moment(endedAt).format("YYYY-MM-DD");
            // console.log(newStartAt, newEndedAt);
            let flag = isAble === "true" ? true : false;
            let input = {
              adminName,
              adminPhone,
              adminWechat,
              companyName: name,
              phone,
              companyPinYin: pinYin,
              symbol,
              username,
              wechat,
              startedAt: newStartAt,
              endedAt: newEndedAt,
              logoFileId,
              backgroundFileId,
              adminAvatarFileId,
              isAble: flag,
              companyId: id,
            };
            if (modifyAdminPassword && modifyAdminPassword !== "") {
              input.adminPassword = modifyAdminPassword;
            } else {
              input.adminPassword = "";
            }
            axiosRequet
              .post(companyRequestPath.EditCompany, {
                ...input,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("修改成功");
                  form.resetFields();
                  props.closeModal();
                } else {
                  message.error(res.message);
                }
              })
              .catch((eror) => {
                message.error("修改错误");
              });
            // Request.client
            //   .mutate({
            //     mutation: MODIFY_COMPANY, // 封装好的GraphQL,
            //     variables: {
            //       input,
            //     },
            //   })
            //   .then((result) => {
            //     // console.log(result);
            //     message.success("修改成功");
            //     form.resetFields();
            //     props.closeModal();
            //   })
            //   .catch((error) => {
            //     message.error(error.message);
            //     // console.log(error);
            //   });
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },
      handlerResetForm() {
        form.resetFields();
        setImgUrl("");
        setBackground("");
        setAvaImgUrl("");
      },
    };
  });

  const uploadButton = (
    <div>
      {bgLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const logoUploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const avaUploadButton = (
    <div>
      {avaloading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="name"
          label="公司名称"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="pinYin"
          label="公司名称拼音简写"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="电话"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="wechat"
          label="微信"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="symbol"
          label="APP企业宗旨"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="logo"
          label="APP企业logo"
          rules={[{ required: true, message: "不能为空" }]}
          valuePropName="logofileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="logo"
            customRequest={doImgUpload}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            maxCount={1}
            accept=".jpeg,.jpg,.png"
          >
            {imgUrl ? (
              <img src={imgUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              logoUploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="background"
          label="APP主页背景"
          rules={[{ required: true, message: "不能为空" }]}
          valuePropName="bgfileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="background"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={backgroundImgUpload}
            accept=".jpeg,.jpg,.png"
          >
            {background ? (
              <img src={background} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="startedAt"
          label="起始时间"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <DatePicker
            showTime
            style={{
              width: 300,
            }}
          />
        </Form.Item>
        <Form.Item
          name="endedAt"
          label="结束时间"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <DatePicker
            showTime
            style={{
              width: 300,
            }}
          />
        </Form.Item>
        <Form.Item
          name="isAble"
          label="状态"
          hasFeedback
          rules={[
            {
              required: true,
              message: "不能为空",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: 300 }}
            placeholder="是否默认"
            onChange={handleChange}
          >
            <Option value="true">正常</Option>
            <Option value="false">停用</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="adminName"
          label="管理员名称"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="adminPhone"
          label="管理员手机号码"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        {id === "" ? (
          <Form.Item
            name="adminPassword"
            label="管理员密码"
            rules={[{ required: true, message: "不能为空" }]}
          >
            <Input style={{ width: 300 }} placeholder="请输入" />
          </Form.Item>
        ) : (
          <Form.Item name="modifyAdminPassword" label="管理员密码">
            <Input style={{ width: 300 }} placeholder="请输入" />
          </Form.Item>
        )}
        <Form.Item
          name="adminWechat"
          label="管理员微信"
          rules={[{ required: true, message: "不能为空" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="adminAvatar"
          label="管理员头像"
          rules={[{ required: true, message: "不能为空" }]}
          valuePropName="adminfileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="adminAvatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={avatarImgUpload}
            accept=".jpeg,.jpg,.png"
          >
            {avaUrl ? (
              <img src={avaUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              avaUploadButton
            )}
          </Upload>
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddCompany);
