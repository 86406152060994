import React, { useState } from "react";
import { Input, Button, message, Form } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Request from "../../../utils/client";
import "./modify.scss";
import {MODIFY_PASSWORD} from "../../../graphql/admin/modify"

export default function Modify() {
  // let { newPassword, setNewPassword } = useState("");
  let newPassword = ""
  const [form] = Form.useForm();
  const modfiy=()=> {
    form
      .validateFields()
      .then((res) => {
        console.log(form.getFieldsValue());
        let { password, validation } = form.getFieldsValue();
        if (password !== validation) {
          message.error("两次密码不一致");
        } else {
          newPassword =  password
          submit();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const submit=()=> {
    Request.client
      .query({
        query: MODIFY_PASSWORD,
        variables: {
          input: {
            password: newPassword,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        console.log(result);
        message.success("密码修改成功");
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message)
      });
  }

  return (
    <div className="modify_box">
      <Form form={form}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "请输入密码!",
            },
          ]}
          style={{ width: 300 }}
          hasFeedback
        >
          <Input.Password
            placeholder="请输入新密码"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          name="validation"
          style={{ width: 300 }}
          rules={[
            {
              required: true,
              message: "请输入密码!",
            },
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder="确认密码"
            prefix={<LockOutlined className="site-form-item-icon" />}
          />
        </Form.Item>
      </Form>
      <Button type="primary" onClick={modfiy}>
        确认
      </Button>
    </div>
  );
}
