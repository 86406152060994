import React, { Component } from "react";
import {
  Table,
  Button,
  Modal,
  Input,
  Select,
  message,
  // Popconfirm
} from "antd";
// import { format } from "../../../utils/dateFormate";
import Request from "../../../utils/client";
import {
  // AQUIRE_DEMAND,
  DELETE_DEMAND,
} from "../../../graphql/admin/demand";
import AddDemand from "./components/AddDemand";
// import root from "../../../config/root";
import commonStatus from "../../../utils/status";
import util from "../../../utils/util";

import PubSub from "pubsub-js";

import axiosRequest from "../../../utils/request";
import { demandRequestPath } from "../../../request/requestPath";

import { connect } from "react-redux";
import { addDemandAction } from "../../../redux/actions/demand";
import { resetAccessoriesAction } from "../../../redux/actions/accessories";
import { resetSteelAction } from "../../../redux/actions/steel";
import { resetEquipmentAction } from "../../../redux/actions/equipment";

import { steelColumns, partColums, equipmentColums } from "./util/util";
import Draggable from "react-draggable";
import "./demand.scss";
import moment from "moment";
const { Option } = Select;
const { TextArea } = Input;

steelColumns.splice(2, 1);
partColums.splice(2, 1);
equipmentColums.splice(2, 1);

function onChange(pagination, filters, sorter, extra) {
  // console.log("params", pagination, filters, sorter, extra);
}
let columns = [
  {
    title: "序号",
    dataIndex: "orderId",
    fixed: "left",
    width: 100,
  },
  {
    title: "需求单提交时间",
    dataIndex: "createdDate",
    width: 120,
  },
  {
    title: "需求单号",
    dataIndex: "orderNo",
    width: 180,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 150,
  },
  {
    title: "计划使用时间(天)",
    dataIndex: "useTime",
    width: 130,
  },
  {
    title: "计划归还时间",
    dataIndex: "expectedDate",
    width: 130,
  },
  {
    title: "提交人",
    dataIndex: "createUser",
    width: 130,
  },
  {
    title: "型钢总重（t）",
    dataIndex: "steelWeight",
    width: 100,
  },
  {
    title: "配件总重（t）",
    dataIndex: "partWeight",
    width: 100,
  },
  {
    title: "设备个数（个）",
    dataIndex: "equipmentTotal",
    width: 100,
  },
];
class Admin extends Component {
  userInfo = localStorage.getItem("userInfo");
  role = JSON.parse(this.userInfo).role;
  pathname = window.location.pathname;
  state = {
    role: this.role,
    modalVisible: false, // 添加需求单的弹窗
    visible: false, // 拒绝的弹窗
    data: [], // 表格数据源
    demandData: [], // 存储获取的数据，用于用户搜索时
    title: "添加需求单",
    pathname: this.pathname,
    searchText: "",
    searchedColumn: "",
    filteredInfo: null,
    searchType: "order",
    loading: true, // 表格的loading
    refusedOrderId: "", // 拒绝的需求单id值
    demandType: "toBeConfirm", // 需求单类型  toBeConfirm:待确认  confirmOrder:已确认   StateRejected:已拒绝  200 待确认  300 已确认  400 已拒绝
    materialVisible: false, // 控制材料列表弹窗的变量
    steelData: [], // 型钢列表
    partData: [], // 配件列表
    equipmentData: [], // 设备列表
    total: 0,
    current: 1,
    pageSize: 20,
    columns,

    // 拖动窗口相关属性
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
  };

  draggleRef = React.createRef();

  constructor(props) {
    super(props);
    let newColumns = [
      {
        title: "材料清单",
        key: "material",
        width: 150,
        fixed: "right",
        render: (item) => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.showMaterial(item);
              }}
            >
              查看
            </Button>
          </div>
        ),
      },
    ];
    this.state.columns = [...columns, ...newColumns];
  }
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };

  componentDidMount() {
    this.getData();

    PubSub.unsubscribe("aquireDemand"); //销毁订阅 防止多次触发
    // 添加需求单成功后重新获取需求单（由AddDemand组件触发）
    PubSub.subscribe("aquireDemand", (_) => {
      this.getData();
    }); //订阅
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };
  // 页面发生改变
  changePage = (page) => {
    // console.log(page)
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.getData();
      }
    );
  };
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.getData();
      }
    );
  };

  // 拖动框
  onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = this.draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    this.setState({
      bounds: {
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      },
    });
  };

  // 获取需求单单列表
  getData = () => {
    axiosRequest
      .post(demandRequestPath.GetOrderListByWeb, {
        queryType: this.state.demandType,
        page: this.state.current,
        pageSize: this.state.pageSize,
      })
      .then((res) => {
        // console.log("res", res);
        if (res.code === 200) {
          let { Array, total } = res.data;
          // console.log(Array);
          // eslint-disable-next-line no-unused-expressions
          Array ? "" : (Array = []);
          Array.forEach((item) => {
            item.key = item.orderId;
            item.createdDate = item.createdAt
              ? moment(item.createdAt).format("YYYY-MM-DD")
              : "";
            item.expectedDate = item.expectedReturnAt
              ? moment(item.expectedReturnAt).format("YYYY-MM-DD")
              : "";
            item.senderTime = item.senderAt
              ? moment(item.senderAt).format("YYYY-MM-DD")
              : "";
            item.receiveTime = item.receiveAt
              ? moment(item.receiveAt).format("YYYY-MM-DD")
              : "";
            item.status = commonStatus[item.state];
            item.steelWeight = item.steel.weight;
            item.partWeight = item.part.weight;
            item.equipmentTotal = item.equipment.total;
            item.steelWeight = item.steel.weight;
            item.partWeight = item.part.weight;
            item.equipmentTotal = item.equipment.total;
            item.steels.forEach((child) => {
              child.createdDate = item.createdDate;
            });
            item.parts.forEach((child) => {
              child.createdDate = item.createdDate;
            });
            item.equipments.forEach((child) => {
              child.createdDate = item.createdDate;
            });
          });
          this.setState({
            data: Array,
            demandData: Array,
            loading: false,
            total,
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log("需求单列表", error);
      });
  };
  // 删除需求单
  deleteItem = (item) => {
    Request.client
      .mutate({
        mutation: DELETE_DEMAND, // 封装好的GraphQL,
        variables: {
          input: {
            id: item.orderId,
          },
        },
      })
      .then((result) => {
        message.success("删除成功");
        this.getData();
      })
      .catch((error) => {
        message.error(error.message);
        console.log(error);
      });
  };
  // 设置弹窗的显示和关闭
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加需求单" }, () => {
      if (modalVisible) {
        this.demand.handlerResetForm();
        this.demand.getProject();
        // this.demand.getReposity();
        this.demand.getSize();
        this.demand.getAccessories();
        this.demand.getEquipment();
      }
      // this.demand.getCompanyUser();
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加需求单") {
      this.demand.addDemand();
    } else {
      this.demand.modifyProjectSubmit();
    }
  };
  // 关闭弹窗，由demand组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  // 修改需求单
  modify = (project) => {
    this.setState({ modalVisible: true, title: "修改需求单" }, () => {
      this.demand.handlerResetForm();
      this.demand.getProject();
      this.demand.getReposity();
      this.demand.getSize();
      this.demand.modifyProject(project);
    });
  };

  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 需求单类型改变时
  demandTypeChange = (e) => {
    this.setState({
      loading: true,
    });
    this.setState(
      {
        demandType: e,
      },
      () => {
        this.getData();
      }
    );
  };
  // 搜索的方法
  searchFunc = () => {
    let { demandData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    let result = [];
    if (searchType === "order") {
      result = demandData.filter((obj) => {
        return obj.orderNo.indexOf(keyword) !== -1;
      });
    } else if (searchType === "name") {
      result = demandData.filter((obj) => {
        return obj.projectName.indexOf(keyword) !== -1;
      });
    } else if (searchType === "admin") {
      result = demandData.filter((obj) => {
        return obj.create.indexOf(keyword) !== -1;
      });
    } else if (searchType === "repository") {
      result = demandData.filter((obj) => {
        return obj.repositoryName.indexOf(keyword) !== -1;
      });
    } else {
      result = demandData.filter((obj) => {
        return obj.status.indexOf(keyword) !== -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "order",
      },
      () => {
        this.getData();
      }
    );
  };
  // 审核需求单（拒绝按钮触发的事件）
  refused = (item) => {
    this.setState({ refusedOrderId: item.orderId, visible: true });
  };
  // 输入拒绝理由后提交触发的事件
  refusedSubmit = () => {
    let reason = this.textarea.resizableTextArea.textArea.value;
    if (util.isNull(reason)) {
      message.error("请输入拒绝理由");
    } else {
      this.refusedRequst(reason);
    }
  };
  // 拒绝需求单的请求
  refusedRequst = (reason) => {
    axiosRequest
      .post(demandRequestPath.RefuseOrder, {
        id: this.state.refusedOrderId,
        isAccess: false,
        reason,
      })
      .then((res) => {
        if (res.code === 200) {
          message.success("操作成功");
          this.hideModal();
          this.getData();
        } else {
          message.error(res.message);
        }
        // let { data } = res;
      })
      .catch((error) => {
        console.log("审核需求单", error);
      });
  };
  // 隐藏拒绝需求单的弹窗
  hideModal = () => {
    this.setState({ visible: false });
  };
  // 点击分配按钮
  addDemand = (item) => {
    if (
      this.props.demand.length > 0 &&
      this.props.demand[0].orderId !== item.orderId
    ) {
      this.props.resetAccessoriesAction();
      this.props.resetSteelAction();
      this.props.resetEquipmentAction();
    }
    item.equipments.forEach((equipment, index) => {
      equipment.sort = index + 1;
      equipment.key = equipment.sort;
    });
    item.steels.forEach((steel, index) => {
      steel.sort = index + 1;
      steel.key = steel.sort;
    });
    item.parts.forEach((part, index) => {
      part.sort = index + 1;
      part.key = part.sort;
    });

    this.props.addDemandAction(item);
    this.props.history.push({ pathname: "/admin/cart" });
  };
  // 查看材料清单
  showMaterial = (item) => {
    // eslint-disable-next-line no-unused-expressions
    item.steels ? "" : (item.steels = []);
    // eslint-disable-next-line no-unused-expressions
    item.parts ? "" : (item.parts = []);
    // eslint-disable-next-line no-unused-expressions
    item.equipments ? "" : (item.equipments = []);
    item.steels.forEach((item, index) => {
      item.sort = index + 1;
      item.key = index;
    });
    item.parts.forEach((item, index) => {
      item.sort = index + 1;
      item.key = index;
    });
    item.equipments.forEach((item, index) => {
      item.sort = index + 1;
      item.key = index;
    });
    this.setState({
      steelData: item.steels,
      partData: item.parts,
      equipmentData: item.equipments,
      materialVisible: true,
    });
  };
  render() {
    let {
      columns,
      data,
      title,
      // role,
      // pathname,
      loading,
      steelData,
      partData,
      equipmentData,
      disabled,
      bounds,
    } = this.state;
    return (
      <div className="demand_box">
        <div className="btn_box">
          {/* <Button
            type="primary"
            style={{
              marginLeft: "20px",
              background: "#A06921",
              border: "none",
            }}
          >
            导出
          </Button> */}
          <div>
            {/* <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button> */}
          </div>
          <div>
            {/* <Select
              value={this.state.demandType}
              style={{ width: 120, marginRight: 10 }}
              onChange={this.demandTypeChange}
            >
              <Option value="toBeConfirm">待确认</Option>
              <Option value="confirmOrder">已确认</Option>
              <Option value="StateRejected">已拒绝</Option>
            </Select> */}
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="order">订单编号</Option>
              <Option value="name">项目名称</Option>
              <Option value="status">状态</Option>
              <Option value="admin">创建人</Option>
              <Option value="repository">仓库名称</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            onChange={onChange}
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            loading={loading}
            pagination={this.paginationProps}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={() => this.submit()}
          onCancel={() => this.setModalVisible(false)}
          width="80%"
        >
          <AddDemand
            ref={(demand) => {
              this.demand = demand;
            }}
            closeModal={this.closeModal}
          ></AddDemand>
        </Modal>
        <Modal
          title="拒绝信息"
          visible={this.state.visible}
          onOk={this.refusedSubmit}
          onCancel={this.hideModal}
          okText="确认"
          cancelText="取消"
        >
          <TextArea
            showCount
            maxLength={150}
            rows={4}
            resize="false"
            ref={(textarea) => (this.textarea = textarea)}
            placeholder="请输入拒绝理由"
          />
        </Modal>
        <Modal
          centered
          visible={this.state.materialVisible}
          onOk={() => this.setState({ materialVisible: false })}
          onCancel={() => this.setState({ materialVisible: false })}
          width="80%"
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  this.setState({
                    disabled: false,
                  });
                }
              }}
              onMouseOut={() => {
                this.setState({
                  disabled: true,
                });
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              需求单材料清单
            </div>
          }
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => this.onStart(event, uiData)}
            >
              <div ref={this.draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <div>
            <div style={{ fontWeight: "bold", fontSize: 16 }}>型钢</div>
            <Table
              onChange={onChange}
              columns={steelColumns}
              dataSource={steelData}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
            <div style={{ fontWeight: "bold", marginTop: 20, fontSize: 16 }}>
              配件
            </div>
            <Table
              onChange={onChange}
              columns={partColums}
              dataSource={partData}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
            <div style={{ fontWeight: "bold", marginTop: 20, fontSize: 16 }}>
              设备
            </div>
            <Table
              onChange={onChange}
              columns={equipmentColums}
              dataSource={equipmentData}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
export default connect((state) => ({ demand: state.demand }), {
  addDemandAction,
  resetAccessoriesAction,
  resetSteelAction,
  resetEquipmentAction,
})(Admin);
