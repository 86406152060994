import React, { Component } from "react";
import { Table, Modal, Button, message } from "antd";
import Request from "../../../../utils/client";
import { format } from "../../../../utils/dateFormate";
import { AQUIRE_STEEL } from "../../../../graphql/admin/steel";
import moment from "moment";

// const children = [];
// for (let i = 10; i < 36; i++) {
//   children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
// }

import axiosRequest from "../../../../utils/request";
import { indexRequestPath } from "../../../../request/requestPath";

export default class Steel extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    filteredInfo: null,
    total: 0, // 数据总数
    current: 1, // 当前的页数
    pageSize: 20, // 每页的数据量
    data: [],
    columns: [],
    expressColumns: [
      // 维修经历table的相关数据
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "维修厂",
        dataIndex: "name",
        width: 150,
      },
      // {
      //   title: "维修天数",
      //   dataIndex: "useDays",
      //   width: 150,
      // },
      {
        title: "入厂时间",
        dataIndex: "startDate",
        width: 150,
      },
      // {
      //   title: "出厂时间",
      //   dataIndex: "outDate",
      //   width: 150,
      // },
      // {
      //   title: "状态信息",
      //   dataIndex: "status",
      //   width: 150,
      // },
    ],
    expressData: [], // 维修经历
    steelProject: [], // 项目经历
    project: [
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "时间",
        dataIndex: "startDate",
      },
      {
        title: "项目名称",
        dataIndex: "name",
      },
    ],
    projectVisible: false,
    modalVisible: false,
  };

  // 表格分页
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    showQuickJumper: true, // 开启页码快速跳转
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };

  // 请求数据
  aquireData = (data) => {
    // let { current, pageSize } = this.paginationProps;
    // input.page = current;
    // input.pageSize = pageSize;
    //SteelQueryByCompany;
    this.setState({
      loading: true,
    });
    axiosRequest
      .post(indexRequestPath.GetSteelForBack, data)
      .then((res) => {
        // console.log("查询数据", res);
        res.data.Array.forEach((item, index) => {
          item.key = index;
          item.sort = index + 1;
          item.createdTime = item.enterTime
            ? moment(item.enterTime).format("YYYY-MM-DD")
            : "暂无";
          item.produceTime = item.produceDate
            ? moment(item.produceDate).format("YYYY-MM-DD")
            : "暂无";
        });
        this.setState({
          data: res.data.Array,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log("型钢查询报错", error);
      });
    // Request.client
    //   .query({
    //     query: AQUIRE_STEEL,
    //     variables: { input },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     // console.log(result);
    //     let { list, total } = result.data.getSteelList;

    //     list.forEach((item, index) => {
    //       item.manufacturerName = item.manufacturer.name;
    //       item.materialManufacturerName = item.materialManufacturer.name;
    //       item.repositoryName = item.repository.name;
    //       item.specifcationName = item.specifcation.specification;
    //       item.key = item.identifier;
    //       item.sort = index + 1;
    //       item.producedTime = format(item.producedDate);
    //       item.status = item.stateInfo.desc;
    //       item.createdTime = format(item.createdAt);
    //       item.usageYearRate = Math.floor(item.usageYearRate * 1000) / 1000;
    //       item.createUserName = item.createUser.name;
    //       item.totalUsageRate = Math.floor(item.totalUsageRate * 1000) / 1000;
    //     });

    //     this.setState({ data: list, loading: false, total });
    //   })
    //   .catch((error) => {
    //     this.setState({ loading: false });
    //     // message.error("无法从服务器查找到型钢数据");
    //   });
  };

  columns = [
    {
      title: "序号",
      dataIndex: "sort",
      fixed: "left",
      width: 100,
    },
    {
      title: "识别码",
      dataIndex: "identifier",
      width: 150,
      // ...this.getColumnSearchProps("identifier"),
    },
    {
      title: "型钢编码",
      dataIndex: "Code",
      width: 200,
      // ...this.getColumnSearchProps("code"),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
    },
    // { title: "仓库", dataIndex: "repositoryName", width: 150 },
    {
      title: "规格尺寸",
      dataIndex: "specification",
      width: 150,
      // ...this.getColumnFilterProps("spec"),
    },
    // {
    //   title: "维修经历",
    //   dataIndex: "maintenanceRecord",
    //   width: 150,
    //   render: (item) => (
    //     <div>
    //       {item.length > 0 ? (
    //         <Button
    //           type="primary"
    //           onClick={() => {
    //             this.toView(item);
    //           }}
    //         >
    //           查看
    //         </Button>
    //       ) : (
    //         ""
    //       )}
    //       {item.length === 0 ? <Button type="error">暂无</Button> : ""}
    //     </div>
    //   ),
    // },

    // {
    //   title: "项目经历",
    //   dataIndex: "projectRecord",
    //   width: 200,
    //   render: (item) => (
    //     <div>
    //       {item.length > 0 ? (
    //         <Button
    //           type="primary"
    //           onClick={() => {
    //             this.toViewProject(item);
    //           }}
    //         >
    //           查看
    //         </Button>
    //       ) : (
    //         ""
    //       )}
    //       {item.length === 0 ? <Button type="error">暂无</Button> : ""}
    //     </div>
    //   ),
    // },
    {
      title: "周转次数",
      dataIndex: "turnover",
      width: 100,
    },
    {
      title: "单根型钢累计使用天数",
      dataIndex: "useTime",
      width: 200,
    },
    {
      title: "单根型钢使用率",
      dataIndex: "useRate",
      width: 200,
    },

    {
      title: "材料厂商",
      dataIndex: "materialName",
      width: 200,
      // ...this.getColumnFilterProps("mat"),
    },
    {
      title: "制造厂商",
      dataIndex: "manufacturer",
      width: 150,
      // ...this.getColumnFilterProps("man"),
    },
    {
      title: "生产日期",
      dataIndex: "produceTime",
      width: 150,
      // ...this.getColumnFilterProps("date"),
    },
    {
      title: "入库用户",
      dataIndex: "enterUser",
      width: 150,
    },
    {
      title: "入库时间",
      dataIndex: "createdTime",
      width: 150,
      // ...this.getColumnFilterProps("ora"),
    },
    // {
    //   title: "状态",
    //   dataIndex: "status",
    //   width: 150,
    // },
    // {
  ];

  // 页面发生改变
  changePage = (page) => {
    // console.log(page);
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // pageSize发生改变时
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    // console.log(pageSize, current);
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 查看维修经历
  toView = (list) => {
    // console.log(list);
    list.forEach((item, index) => {
      // item.sort = index + 1;
      // item.enteredDate = moment(item.enteredAt).format("YYYY-MM-DD");
      // item.outDate = moment(item.outedAt).format("YYYY-MM-DD");
      // item.status = item.stateInfo.desc;
      // item.key = index;
      // item.maintenanceName = item.maintenance.name;
      item.startDate = moment(item.startAt).format("YYYY-MM-DD");
      item.key = index;
      item.sort = index + 1;
    });
    this.setState({ expressData: list, modalVisible: true });
  };
  toViewProject = (list) => {
    // console.log(list);
    list.forEach((item, index) => {
      item.sort = index + 1;
      item.startDate = moment(item.startAt).format("YYYY-MM-DD");
      // item.outDate = moment(item.outedAt).format("YYYY-MM-DD");
      // item.status = item.stateInfo.desc;
      item.key = item.index;
      // item.maintenanceName = item.maintenance.name;
    });
    this.setState({ steelProject: list, projectVisible: true });
  };
  render() {
    let { data, loading, expressColumns, expressData, project, steelProject } =
      this.state;
    // console.log(this.state.data);
    return (
      <div className="steel_com_box">
        <div className="table_box">
          <Table
            columns={this.columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            // pagination={this.paginationProps}
            loading={loading}
          />
        </div>
        <Modal
          title="维修经历"
          centered
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          footer={false}
          width={1000}
        >
          <Table
            columns={expressColumns}
            dataSource={expressData}
            bordered
            // pagination={false}
          />
        </Modal>
        <Modal
          title="项目经历"
          centered
          visible={this.state.projectVisible}
          onCancel={() => this.setState({ projectVisible: false })}
          footer={false}
          width={1000}
        >
          <Table
            columns={project}
            dataSource={steelProject}
            bordered
            pagination={false}
          />
        </Modal>
      </div>
    );
  }
}
