/**
 * 不同角色首页展示数据的配置文件
 */

import {
  ADMIN,
  COMPANY_ADMIN,
  REPOSITORY_ADMIN,
  PROJECT_ADMIN,
  MAINTENANCE_ADMIN,
} from "../../../../config/constant";
import imgageObject from "../image";
// eslint-disable-next-line import/no-anonymous-default-export
export default function (role) {
  let roleShow = {
    assets: true, // 资产栏
    warehouse: true, // 仓库栏
    project: true, // 项目栏
    maintenance: true, // 维修栏
    steel: true, // 型钢栏
    log: true, //日志栏
  };
  let roleText = {
    [ADMIN]: {
      situation: [
        {
          text: "型钢总量",
          icon: imgageObject.icon1,
          name: "totalWeight",
          sum: 0,
        },
        {
          text: "仓库中",
          icon: imgageObject.icon2,
          name: "inRepository",
          sum: 0,
        },
        {
          text: "项目中",
          icon: imgageObject.icon3,
          name: "inProject",
          sum: 0,
        },
        {
          text: "维修中",
          icon: imgageObject.icon4,
          name: "inRepair",
          sum: 0,
        },
      ],
      status: [
        {
          text: "新入库量",
          icon: imgageObject.icon5,
          name: "addQuantity",
          sum: 0,
        },
        {
          text: "租赁量",
          icon: imgageObject.icon6,
          name: "leaseQuantity",
          sum: 0,
        },
        {
          text: "维修量（吨）",
          icon: imgageObject.icon7,
          name: "inRepairQuantity",
          sum: 0,
        },
        {
          text: "报废量（吨）",
          icon: imgageObject.icon8,
          name: "scrapQuantity",
          sum: 0,
        },
      ],
    },
    [COMPANY_ADMIN]: {
      situation: [
        {
          text: "型钢总量",
          icon: imgageObject.icon1,
          name: "totalWeight",
          sum: 0,
        },
        {
          text: "仓库中",
          icon: imgageObject.icon2,
          name: "inRepository",
          sum: 0,
        },
        {
          text: "项目中",
          icon: imgageObject.icon3,
          name: "inProject",
          sum: 0,
        },
        {
          text: "维修中",
          icon: imgageObject.icon4,
          name: "inRepair",
          sum: 0,
        },
      ],
      status: [
        {
          text: "新入库量",
          icon: imgageObject.icon5,
          name: "addQuantity",
          sum: 0,
        },
        {
          text: "租赁量",
          icon: imgageObject.icon6,
          name: "leaseQuantity",
          sum: 0,
        },
        {
          text: "维修量（吨）",
          icon: imgageObject.icon7,
          name: "inRepair",
          sum: 0,
        },
        {
          text: "报废量（吨）",
          icon: imgageObject.icon8,
          name: "scrapQuantity",
          sum: 0,
        },
      ],
    },
    [REPOSITORY_ADMIN]: {
      situation: [
        {
          text: "型钢总量",
          icon: imgageObject.icon1,
          name: "totalWeight",
          sum: 0,
        },
        {
          text: "仓库中",
          icon: imgageObject.icon2,
          name: "inRepository",
          sum: 0,
        },
        {
          text: "项目中",
          icon: imgageObject.icon3,
          name: "inProject",
          sum: 0,
        },
        {
          text: "维修中",
          icon: imgageObject.icon4,
          name: "inRepair",
          sum: 0,
        },
      ],
      status: [
        {
          text: "新入库量",
          icon: imgageObject.icon5,
          name: "addQuantity",
          sum: 0,
        },
        {
          text: "租赁量",
          icon: imgageObject.icon6,
          name: "leaseQuantity",
          sum: 0,
        },
        {
          text: "维修量（吨）",
          icon: imgageObject.icon7,
          name: "inRepair",
          sum: 0,
        },
        {
          text: "报废量（吨）",
          icon: imgageObject.icon8,
          name: "scrapQuantity",
          sum: 0,
        },
      ],
    },
    [MAINTENANCE_ADMIN]: {
      // situation: [
      //   {
      //     text: "型钢维修总量",
      //     icon: imgageObject.icon1,
      //     name: "maintenanceSteelTotal",
      //     sum: 0,
      //   },
      //   {
      //     text: "千斤顶维修总量",
      //     icon: imgageObject.icon2,
      //     name: "maintenanceEquipmentTotal",
      //     sum: 0,
      //   },
      //   {
      //     text: "维修中型钢总量",
      //     icon: imgageObject.icon3,
      //     name: "maintenancingSteelTotal",
      //     sum: 0,
      //   },
      //   {
      //     text: "维修中千斤顶总量",
      //     icon: imgageObject.icon4,
      //     name: "maintenancingEquipmentTotal",
      //     sum: 0,
      //   },
      // ],
      situation: [
        {
          text: "型钢维修总量",
          icon: imgageObject.icon1,
          name: "total",
          sum: 0,
        },
        {
          text: "千斤顶维修总量",
          icon: imgageObject.icon2,
          name: "jack",
          sum: 0,
        },
        {
          text: "预计修回时间",
          icon: imgageObject.icon3,
          name: "expectedReturn",
          sum: 0,
        },
        {
          text: "维修中",
          icon: imgageObject.icon4,
          name: "repairing",
          sum: 0,
        },
      ],
      status: [
        {
          text: "型钢维修量",
          icon: imgageObject.icon5,
          name: "durationMaintenanceSteelTotal",
          sum: 0,
        },
        {
          text: "维修进厂量",
          icon: imgageObject.icon6,
          name: "durationSteelToMaintenanceTotal",
          sum: 0,
        },
        {
          text: "维修中量",
          icon: imgageObject.icon7,
          name: "durationSteelMaintenancingTotal",
          sum: 0,
        },
        {
          text: "维修-准备回库量",
          icon: imgageObject.icon8,
          name: "durationSteelToRepositoryTotal",
          sum: 0,
        },
      ],
    },
    [PROJECT_ADMIN]: {
      situation: [
        {
          text: "租赁型钢量（吨）",
          icon: imgageObject.icon5,
          name: "steelTotal",
          sum: 0,
        },
        {
          text: "千斤顶使用量（个）",
          icon: imgageObject.icon8,
          name: "equipmentTotal",
          sum: 0,
        },
        {
          text: "开始使用时间",
          icon: imgageObject.icon6,
          name: "startTime",
          sum: 0,
        },
        {
          text: "预计结束时间",
          icon: imgageObject.icon7,
          name: "endTime",
          sum: 0,
        },
      ],
      status: [
        {
          text: "待使用",
          icon: imgageObject.icon1,
          name: "enterSteelTotal",
          sum: 0,
        },
        {
          text: "使用中",
          icon: imgageObject.icon3,
          name: "inProjectTotal",
          sum: 0,
        },
        {
          text: "闲置（已拆除）",
          icon: imgageObject.icon4,
          name: "beLibraryTotal",
          sum: 0,
        },
        {
          text: "已归还",
          icon: imgageObject.icon2,
          name: "outSteelTotal",
          sum: 0,
        },
      ],
    },
  };
  switch (role) {
    case ADMIN:
    case COMPANY_ADMIN:
    case REPOSITORY_ADMIN:
      roleShow.maintenance = false;
      break;
    case MAINTENANCE_ADMIN:
      roleShow.warehouse = false;
      roleShow.project = false;
      roleShow.log = false;
      roleShow.steel = false;
      break;
    case PROJECT_ADMIN:
      roleShow.warehouse = false;
      roleShow.maintenance = false;
      roleShow.log = false;
      break;
    default:
      console.log("没有数据");
  }

  return { roleShow, roleText };
}
