import Request from "../../utils/client";

// 获取型钢列表
export const AQUIRE_STEEL = Request.gql`
query getSteelListQuery ($input: PaginationInput!){
    getSteelList(input: $input) {
        total
        weightTotal
      list {
        createUser{
          name
          id
        }
    repository {
      id
      name
    }
    id
    identifier # 识别码
    code  #型钢编码
    specifcation {
      specification #规格
    }
    stateInfo{
      state
      desc # 状态说明 
    }
    steelInProject {
      outRepositoryAt # 出库时间
      projectName  #项目名
    }
    steelInMaintenance {
      outRepositoryAt # 出库时间 
      maintenance {
        name # 维修厂名
      }
    }
    turnover # 周转次数
    usageYearRate # 年使用率
    totalUsageRate # 总使用率
    materialManufacturer {
      id
      name # 材料商家
    }
    manufacturer {
      id
      name #制作商
    }
    producedDate # 生产日期 
    createUser {
      name # 入库用户
    }
    createdAt # 入库时间
    steelInMaintenance{
     enteredAt #入厂时间
     enterRepositoryAt #入库时间
     id #序号
     maintenance{
      name
    } #维修厂
    outedAt #出厂时间
    outRepositoryAt #出库时间
    stateInfo{
      state
      desc
    }   #状态信息
    steel{
      code
    }#型钢
    useDays#维修天数
    }
  }
}
}
`;
