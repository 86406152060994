import React, { Component } from "react";
import { connect } from "react-redux";
import { message, Button, Table, Select, Modal } from "antd";
import Draggable from "react-draggable";
import {
  deleteSteelAction,
  resetSteelAction,
} from "../../../redux/actions/steel";
import {
  deleteEquipmentAction,
  resetEquipmentAction,
} from "../../../redux/actions/equipment";
import {
  deleteAccessoriesAction,
  resetAccessoriesAction,
} from "../../../redux/actions/accessories";
import { resetDemandAction } from "../../../redux/actions/demand";
import Request from "../../../utils/client";
import { AQUIRE_DEMAND } from "../../../graphql/admin/demand";

import axiosRequest from "../../../utils/request";
import { demandRequestPath } from "../../../request/requestPath";

import SelectAccessories from "../demand/SelectAccessories";
import SelectSteel from "../demand/SelectSteel";
import SelectEquipment from "../demand/SelectEquipment";
import {
  steelCloumn,
  accessoriesCloumn,
  equipmentCloumn,
  demandEquipmentCloumn,
  demandAccessoriesCloumn,
  demandSteelCloumn,
  statisticalSteel,
  statisticalAccessories,
  statisticalEquipment,
} from "./columns";

import "./cart.scss";
const { Option } = Select;
class Cart extends Component {
  state = {
    demandId: "",
    demandChildren: [],
    title: "",
    modelVisible: false,
    type: "",
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    submitVisible: false,
    disabled1: true,
    bounds1: { left: 0, top: 0, bottom: 0, right: 0 },
    steel_statistical: [], //统计的型钢
    acc_statistical: [], //统计的配件
    equ_statistical: [], //统计的设备
    isRequest: false,
  };
  draggleRef = React.createRef();
  draggleRef1 = React.createRef();
  componentDidMount() {
    console.log(this.props.demand);
    if (this.props.demand.length === 0) {
      this.props.history.push({ pathname: "/admin/demand/management" });
      return;
    }

    let addFlag = localStorage.getItem("addFlag") ? false : true;
    if (addFlag) {
      steelCloumn.push({
        title: "操作",
        key: "operation",
        width: 200,
        fixed: "right",
        align: "center",
        render: (item) => (
          <div>
            <Button
              type="text"
              onClick={() => {
                this.deleteSteelItem(item);
              }}
            >
              删除
            </Button>
          </div>
        ),
      });
      accessoriesCloumn.push({
        title: "操作",
        key: "operation",
        width: 200,
        fixed: "right",
        align: "center",
        render: (item) => (
          <div>
            <Button
              type="text"
              onClick={() => {
                this.deleteAccessoriesItem(item);
              }}
            >
              删除
            </Button>
          </div>
        ),
      });
      equipmentCloumn.push({
        title: "操作",
        key: "operation",
        width: 200,
        fixed: "right",
        align: "center",
        render: (item) => (
          <div>
            <Button
              type="text"
              onClick={() => {
                this.deleteEquipmentItem(item);
              }}
            >
              删除
            </Button>
          </div>
        ),
      });
      localStorage.setItem("addFlag", "添加的标识");
    }
  }

  // 获取需求单
  getDemand() {
    Request.client
      .query({
        query: AQUIRE_DEMAND,
        variables: {
          input: {
            queryType: "toBeConfirm",
            // queryType: "confirmOrder",
          },
        },
      })
      .then((result) => {
        let { getOrderList } = result.data;
        let children = [];
        getOrderList.forEach((item) => {
          children.push(<Option key={item.id}>{item.orderNo}</Option>);
        });
        this.setState({
          demandChildren: children,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // 确认需求单
  confirmDemand = () => {
    if (this.state.isRequest) {
      message.error("正在提交数据，请勿重复点击");
      return;
    }

    // let steels = [];
    // let equipments = [];
    // let parts = [];

    let orderObject = {};

    let allCount =
      this.props.accessories.length +
      this.props.equipment.length +
      this.props.steel.length;

    if (allCount === 0) {
      message.error("请选择材料");
      return;
    }
    // return;

    this.props.accessories.forEach((item) => {
      orderObject[item.repositoryId]
        ? orderObject[item.repositoryId].parts.push({
            id: item.partInfoId,
            total: item.selectNum * 1,
          })
        : (orderObject[item.repositoryId] = {
            repositoryId: item.repositoryId,
            steels: [],
            parts: [
              {
                id: item.partInfoId,
                total: item.selectNum * 1,
              },
            ],
            equipments: [],
          });
      // parts.push({
      //   id: item.partInfoId,
      //   total: item.selectNum * 1,
      //   repositoryId: item.repositoryId,
      // });
    });
    this.props.equipment.forEach((item) => {
      orderObject[item.repositoryId]
        ? orderObject[item.repositoryId].equipments.push({
            id: item.equipmentInfoId,
            total: item.selectNum * 1,
          })
        : (orderObject[item.repositoryId] = {
            repositoryId: item.repositoryId,
            steels: [],
            parts: [],
            equipments: [
              {
                id: item.equipmentInfoId,
                total: item.selectNum * 1,
              },
            ],
          });
      // equipments.push({
      //   id: item.equipmentInfoId,
      //   total: item.selectNum * 1,
      //   repositoryId: item.repositoryId,
      // });
    });
    this.props.steel.forEach((item) => {
      orderObject[item.repositoryId]
        ? orderObject[item.repositoryId].steels.push({
            id: item.specificationId,
            total: item.selectNum * 1,
          })
        : (orderObject[item.repositoryId] = {
            repositoryId: item.repositoryId,
            steels: [
              {
                id: item.specificationId,
                total: item.selectNum * 1,
              },
            ],
            parts: [],
            equipments: [],
          });
      // steels.push({
      //   id: item.specificationId,
      //   total: item.selectNum * 1,
      //   repositoryId: item.repositoryId,
      // });
    });

    let orderList = [];
    for (let key in orderObject) {
      orderList.push(orderObject[key]);
    }
    // return;
    let data = {
      id: this.props.demand[0].orderId * 1,

      // steels,
      // parts,
      // equipments,
      repositories: orderList,
    };
    console.log("orderObject", orderObject);
    console.log("orderList", orderList);
    // return;

    this.setState({
      isRequest: true,
    });

    axiosRequest
      .post(demandRequestPath.ConfirmOrder, data)
      .then((res) => {
        if (res.code === 200) {
          message.success("提交成功");
          this.setState({
            isRequest: false,
          });
          setTimeout(() => {
            this.props.history.push({ pathname: "/admin/demand/management" });
            this.props.resetSteelAction();
            this.props.resetEquipmentAction();
            this.props.resetAccessoriesAction();
            this.props.resetDemandAction();

            localStorage.removeItem("addFlag");
          }, 1000);
        }
      })
      .catch((error) => {
        this.setState({
          isRequest: false,
        });
        message.error("提交错误，请重试");
        console.log("确认需求单错误", error);
      });
  };
  // 删除选择的型钢
  deleteSteelItem = (item) => {
    this.props.deleteSteelAction(item);
  };
  // 删除选择的设备
  deleteEquipmentItem = (item) => {
    this.props.deleteEquipmentAction(item);
  };
  // 删除选择配件
  deleteAccessoriesItem = (item) => {
    this.props.deleteAccessoriesAction(item);
  };

  // 展示弹窗
  showPopup = (type) => {
    // switch (type) {
    //   case "steel":
    //     this.setState({
    //       title: "型钢选择",
    //     });
    //     break;
    //   case "equipment":
    //     this.setState({
    //       title: "设备选择",
    //     });
    //     break;
    //   default:
    //     this.setState({
    //       title: "配件选择",
    //     });
    // }
    this.setState({
      modelVisible: true,
      type: type,
    });
  };
  onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = this.draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    this.setState({
      bounds: {
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      },
    });
  };

  onStart1 = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = this.draggleRef1.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    this.setState({
      bounds1: {
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      },
    });
  };
  hideModal = () => {
    this.setState({ modelVisible: false });
  };
  // 点击提交按钮触发的事件
  submit = () => {
    let {
      steel,
      equipment,
      accessories,
      // demand
    } = this.props;

    let steelObject = {};
    steel.forEach((item) => {
      if (steelObject[item.specificationId]) {
        steelObject[item.specificationId].num =
          steelObject[item.specificationId].num * 1 + item.selectNum * 1;
        steelObject[item.specificationId].selectRepository.push(
          `${item.repositoryName}(数量：${item.selectNum})`
        );
      } else {
        steelObject[item.specificationId] = {
          num: item.selectNum,
          specification: item.specification,
          total: item.needNum,
          selectRepository: [`${item.repositoryName}(数量：${item.selectNum})`],
          key: item.specificationId,
          demandNum: item.demandNum,
        };
      }
    });
    let accessoriesObject = {};
    accessories.forEach((item) => {
      if (accessoriesObject[item.partInfoId]) {
        accessoriesObject[item.partInfoId].num =
          accessoriesObject[item.partInfoId].num * 1 + item.selectNum * 1;
        accessoriesObject[item.partInfoId].selectRepository.push(
          `${item.repositoryName}(数量：${item.selectNum})`
        );
      } else {
        accessoriesObject[item.partInfoId] = {
          name: item.partName,
          num: item.selectNum,
          specification: item.specification,
          total: item.needNum,
          selectRepository: [`${item.repositoryName}(数量：${item.selectNum})`],
          key: item.partInfoId,
          demandNum: item.demandNum,
        };
      }
    });

    let equipmentObject = {};
    equipment.forEach((item) => {
      if (equipmentObject[item.equipmentInfoId]) {
        equipmentObject[item.equipmentInfoId].num =
          equipmentObject[item.equipmentInfoId].num * 1 + item.selectNum * 1;
        equipmentObject[item.equipmentInfoId].selectRepository.push(
          `${item.repositoryName}(数量：${item.selectNum})`
        );
      } else {
        equipmentObject[item.equipmentInfoId] = {
          name: item.equipmentName,
          num: item.selectNum,
          specification: item.specification,
          total: item.needNum,
          selectRepository: [`${item.repositoryName}(数量：${item.selectNum})`],
          key: item.equipmentInfoId,
          demandNum: item.demandNum,
        };
      }
    });

    // console.log(steelObject, equipmentObject, accessoriesObject);

    let steelArray = [];
    for (let key in steelObject) {
      steelObject[key].selectInfo = steelObject[key].selectRepository.join(",");
      steelArray.push(steelObject[key]);
    }
    let accessoriesArray = [];
    for (let key in accessoriesObject) {
      accessoriesObject[key].selectInfo =
        accessoriesObject[key].selectRepository.join(",");
      accessoriesArray.push(accessoriesObject[key]);
    }
    let equipmentArray = [];
    for (let key in equipmentObject) {
      equipmentObject[key].selectInfo =
        equipmentObject[key].selectRepository.join(",");
      equipmentArray.push(equipmentObject[key]);
    }

    this.setState(
      {
        steel_statistical: steelArray,
        acc_statistical: accessoriesArray,
        equ_statistical: equipmentArray,
      },
      () => {
        console.log(
          this.state.steel_statistical,
          this.state.acc_statistical,
          this.state.equ_statistical
        );
      }
    );

    // return;
    this.setState({ submitVisible: true }, () => {
      console.log(this.state.submitVisible);
    });
  };
  // 确认框点击确定触发的事件
  handleOk = () => {
    this.confirmDemand();
  };
  // 确认框点击取消触发的事件
  handleCancel = () => {
    this.setState({ submitVisible: false });
    // message.warning("您执行了取消操作");
    // setTimeout(() => {
    //   this.props.history.push({ pathname: "/admin/demand/management" });
    //   this.props.resetSteelAction();
    //   this.props.resetEquipmentAction();
    //   this.props.resetAccessoriesAction();
    //   this.props.resetDemandAction();
    //   localStorage.removeItem("addFlag");
    // }, 1000);
  };
  render() {
    let { steel, equipment, accessories, demand } = this.props;
    let {
      type,
      submitVisible,
      steel_statistical,
      acc_statistical,
      equ_statistical,
      disabled,
      bounds,
      disabled1,
      bounds1,
    } = this.state;
    // let { demandChildren } = this.state;
    return (
      <div className="cart_box">
        <div>
          {/* <Select
            placeholder="选择需求单"
            style={{ width: 250 }}
            onChange={this.demandSelectChange}
          >
            {demandChildren}
          </Select> */}
          <div style={{ fontSize: 16 }}>
            需求单号：
            <span
              style={{
                color: "red",
                fontWeight: "bold",
              }}
            >
              {demand.length > 0 ? demand[0].orderNo : "未选择需求单"}
            </span>
          </div>
          <div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 20,
                marginBottom: 5,
                marginTop: 15,
              }}
            >
              型钢
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 15,
                marginBottom: 5,
                marginTop: 15,
              }}
            >
              所需
            </div>
            <Table
              columns={demandSteelCloumn}
              dataSource={demand.length > 0 ? demand[0].steels : []}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
            <div className="title_box">
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 15,
                  marginBottom: 5,
                  marginTop: 15,
                }}
              >
                已选
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    this.showPopup("steel");
                  }}
                >
                  型钢选择
                </Button>
              </div>
            </div>
            <Table
              columns={steelCloumn}
              dataSource={steel}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <div style={{ fontWeight: "bold", fontSize: 20, marginBottom: 5 }}>
              设备
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 15,
                marginBottom: 5,
                marginTop: 15,
              }}
            >
              所需
            </div>
            <Table
              columns={demandEquipmentCloumn}
              dataSource={demand.length > 0 ? demand[0].equipments : []}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
            <div className="title_box">
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 15,
                  marginBottom: 5,
                  marginTop: 15,
                }}
              >
                已选
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    this.showPopup("equipment");
                  }}
                >
                  设备选择
                </Button>
              </div>
            </div>
            <Table
              columns={equipmentCloumn}
              dataSource={equipment}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <div style={{ fontWeight: "bold", fontSize: 20, marginBottom: 5 }}>
              配件
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: 15,
                marginBottom: 5,
                marginTop: 15,
              }}
            >
              所需
            </div>
            <Table
              columns={demandAccessoriesCloumn}
              dataSource={demand.length > 0 ? demand[0].parts : []}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
            <div className="title_box">
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 15,
                  marginBottom: 5,
                  marginTop: 15,
                }}
              >
                已选
              </div>
              <div>
                <Button
                  type="primary"
                  onClick={() => {
                    this.showPopup("part");
                  }}
                >
                  配件选择
                </Button>
              </div>
            </div>
            <Table
              columns={accessoriesCloumn}
              bordered
              dataSource={accessories}
              scroll={{ x: 600 }}
              pagination={false}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button
              type="primary"
              size="middle"
              style={{ width: 100 }}
              onClick={this.submit}
            >
              提交
            </Button>
          </div>
        </div>
        {/* <Modal
          title={
            type === "steel"
              ? "型钢选择"
              : type === "part"
              ? "配件选择"
              : "设备选择"
          }
          visible={this.state.modelVisible}
          onCancel={this.hideModal}
          okText="确认"
          cancelText="取消"
          width="1000"
          footer={false}
        >
          {type === "steel" ? (
            <SelectSteel></SelectSteel>
          ) : type === "part" ? (
            <SelectAccessories></SelectAccessories>
          ) : (
            <SelectEquipment></SelectEquipment>
          )}
        </Modal> */}

        <Modal
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  this.setState({
                    disabled: false,
                  });
                }
              }}
              onMouseOut={() => {
                this.setState({
                  disabled: true,
                });
              }}
              // fix eslintjsx-a11y/mouse-events-have-key-events
              // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              {type === "steel"
                ? "型钢选择"
                : type === "part"
                ? "配件选择"
                : "设备选择"}
            </div>
          }
          visible={this.state.modelVisible}
          onCancel={this.hideModal}
          okText="确认"
          cancelText="取消"
          width="1000"
          footer={false}
          onOk={this.handleOk}
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => this.onStart(event, uiData)}
            >
              <div ref={this.draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          {type === "steel" ? (
            <SelectSteel></SelectSteel>
          ) : type === "part" ? (
            <SelectAccessories></SelectAccessories>
          ) : (
            <SelectEquipment></SelectEquipment>
          )}
        </Modal>
        <Modal
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled1) {
                  this.setState({
                    disabled1: false,
                  });
                }
              }}
              onMouseOut={() => {
                this.setState({
                  disabled1: true,
                });
              }}
              // fix eslintjsx-a11y/mouse-events-have-key-events
              // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
              onFocus={() => {}}
              onBlur={() => {}}
              // end
            >
              提交信息
            </div>
          }
          okText="确认"
          cancelText="取消"
          modalRender={(modal) => (
            <Draggable
              disabled={disabled1}
              bounds={bounds1}
              onStart={(event, uiData) => this.onStart1(event, uiData)}
            >
              <div ref={this.draggleRef1}>{modal}</div>
            </Draggable>
          )}
          visible={submitVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={1000}
        >
          <div style={{ fontWeight: "bold", fontSize: 20, marginBottom: 5 }}>
            型钢
          </div>
          <Table
            columns={statisticalSteel}
            bordered
            dataSource={steel_statistical}
            scroll={{ x: 600 }}
            pagination={false}
          />
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
              marginBottom: 5,
              marginTop: 20,
            }}
          >
            设备
          </div>
          <Table
            columns={statisticalEquipment}
            bordered
            dataSource={equ_statistical}
            scroll={{ x: 600 }}
            pagination={false}
          />
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
              marginBottom: 5,
              marginTop: 20,
            }}
          >
            配件
          </div>
          <Table
            columns={statisticalAccessories}
            bordered
            dataSource={acc_statistical}
            scroll={{ x: 600 }}
            pagination={false}
          />
        </Modal>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    steel: state.steel,
    accessories: state.accessories,
    equipment: state.equipment,
    demand: state.demand,
  }),
  {
    deleteSteelAction,
    deleteEquipmentAction,
    deleteAccessoriesAction,
    resetSteelAction,
    resetEquipmentAction,
    resetAccessoriesAction,
    resetDemandAction,
  }
)(Cart);
