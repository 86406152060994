import Request from "../../utils/client";

// 获取项目列表
export const AQUIRE_PROJECT = Request.gql`
  query {
    getProjectLis {
      id
      name
      address
      remark
      startedAt
      leaderList {
        id
        name
        phone
        wechat
      }
      city
      endedAt
      latitude
      longitude
    }
  }
`;
// 添加项目
export const ADD_PROJECT = Request.gql`
  mutation ($input: CreateProjectInput!) {
    createProject(input: $input) {
      id
      city
      company{id name}
      endedAt
      leaderList {
        id
        name
      }
      city
      name
      remark
      startedAt
      latitude
      longitude
    }
  }
`;
// 编辑项目
export const MODIFY_PROJECT = Request.gql`
  mutation ($input: SetProjectInput!) {
    setProject(input: $input) {
      id
    }
  }
`;

// 获取项目详情
export const PROJECT_DETAIL = Request.gql`
  query ($input: GetProjectDetailInput!){
    getProjectDetail(input: $input){
      list{
        id # 序号
        orderSpecification {
          order {
            id # 订单号
            project {
              id 
              name 
            }
          }
        }
        steel {
          repository {
            id 
            name # 出货仓库
          }
          code # 型钢编码
          specifcation {
            specification # 规格尺寸
          }
        }
        stateInfo {
          state 
          desc #状态说明 
        }
        outRepositoryAt # 出库时间
        enterRepositoryAt #归库时间
        enterWorkshopAt # 入场时间
        outWorkshopAt # 出场时间
        locationCode # 安装码
        installationAt # 安装时间
        useDays # 使用天数
      }
      total # 数量
      weight # 重量
    }
  } 
`;

// 删除项目
export const DELETE_PROJECT = Request.gql`
  mutation ($input: DeleteProjectInput!){
    deleteProject(input: $input)
  }
`;
