/**
 * 管理设备的 reducer
 * @param {*} preState
 * @param {*} actions
 */

import { DELETE_EQUIPMENT, ADD_EQUIPMENT, RESET_EQUIPMENT } from "../constant";
import { message } from "antd";

const initState = []; //初始化状态
export default function equipmentReducer(preState = initState, actions) {
  const { type, data } = actions;
  // eslint-disable-next-line default-case
  switch (type) {
    case DELETE_EQUIPMENT:
      let newState = preState.filter((item) => {
        return (
          item.repositoryId !== data.repositoryId ||
          item.equipmentInfoId !== data.equipmentInfoId
        );
      });
      return [...newState];
    case ADD_EQUIPMENT:
      let state = [];
      let index = preState.findIndex((item) => {
        return (
          item.equipmentInfoId === data.equipmentInfoId &&
          item.repositoryId === data.repositoryId
        );
      });
      if (index !== -1) {
        preState.splice(index, 1, data);
        state = preState;
        message.success("修改成功");
      } else {
        message.success("添加成功");
        state = [...preState, data];
      }
      return state;
    case RESET_EQUIPMENT:
      return [];
    default:
      return preState;
  }
}
