/**
 * 校验用户选择的配件、型钢、设备在需求单中是否存在
 * @type 类型：accessories(配件)  steel(型钢)  equipment(设备)
 * @demand  用户选择的需求单
 * @selectOject 用户选择的材料
 */

import store from "../store";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (type, demand, selectOject) {
  let checkArray = [];
  let attr = "specification";
  let selectArray = [];
  switch (type) {
    case "accessories": // 配件规格
      checkArray = demand[0].parts;
      // attr = "partSpecification";
      selectArray.push(...store.getState().accessories);

      break;
    case "steel": // 型钢规格
      checkArray = demand[0].steels;
      // attr = "specification";
      selectArray.push(...store.getState().steel);
      break;
    default:
      // 设备规格
      checkArray = demand[0].equipments;
      // attr = "equipmentSpecification";
      selectArray.push(...store.getState().equipment);
  }
  let checkIndex = checkArray.findIndex((item) => {
    return item[attr] === selectOject.specification;
  });
  if (checkIndex === -1) {
    // 校验规格在需求单中是否存在
    return { flag: false, msg: "需求单中不存在该规格材料，请选择正确的规格" };
  } else {
    let selectNumer = 0;
    selectArray.forEach((item) => {
      // eslint-disable-next-line no-unused-expressions
      item.specification === selectOject.specification
        ? (selectNumer = selectNumer * 1 + item.selectNum * 1)
        : "";
    });
    // 校验规格的数量
    if (
      checkArray[checkIndex].total <
      selectOject.selectNum * 1 + selectNumer * 1
    ) {
      return {
        flag: false,
        msg: `已选数量(${selectNumer}),添加的数量(${selectOject.selectNum}),超过所需的数量(${checkArray[checkIndex].total})，请调整添加的数量`,
      };
    } else {
      return { flag: true, msg: "" };
    }
  }
}
