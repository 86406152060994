import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Form, Input, Select, message } from "antd";
import Request from "../../../../utils/client";
import {
  ADD_MATERRIAL,
  MODIFY_MATERRIAL,
} from "../../../../graphql/admin/clock";
const { Option } = Select;
// 提交的方法
// const Demo = () => {
//   const onFinish = (values) => {
//     console.log("Received values of form: ", values);
//   };
// };
const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddCompany(props, ref) {
  let [id, setId] = useState("");
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => {
    return {
      // 添加
      addExpress() {
        form
          .validateFields()
          .then((res) => {
            let { isDefault, name, remark } = form.getFieldsValue();
            let newIsDefault = isDefault === "false" ? false : true;
            Request.client
              .mutate({
                mutation: ADD_MATERRIAL, // 封装好的GraphQL,
                variables: {
                  input: {
                    isDefault: newIsDefault,
                    name,
                    remark,
                  },
                },
              })
              .then((result) => {
                message.success("添加成功");
                form.resetFields();
                props.closeModal();
              })
              .catch((error) => {
                message.error(error.message);
                console.log(error);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 修改
      modify(obj) {
        // console.log(project);
        let { name, isDefault, remark, id } = obj;
        let newIsDefault = isDefault ? "是" : "否";
        setId(id);
        form.setFieldsValue({
          name,
          isDefault: newIsDefault,
          remark,
        });
      },
      // 修改项目提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { isDefault, name, remark } = form.getFieldsValue();
            let newIsDefault = isDefault === "否" ? false : true;
            Request.client
              .mutate({
                mutation: MODIFY_MATERRIAL, // 封装好的GraphQL,
                variables: {
                  input: {
                    isDefault: newIsDefault,
                    name,
                    remark,
                    id,
                  },
                },
              })
              .then((result) => {
                message.success("修改成功");
                form.resetFields();
                props.closeModal();
              })
              .catch((error) => {
                message.error(error.message);
                console.log(error);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handlerResetForm() {
        form.resetFields();
      },
    };
  });

  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="name"
          label="材料商名称"
          rules={[{ required: true, message: "请输入材料商名称" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入材料商名称" />
        </Form.Item>
        <Form.Item
          name="isDefault"
          label="是否默认"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择是否默认",
            },
          ]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="是否默认"
            onChange={handleChange}
          >
            <Option value="true">是</Option>
            <Option value="false">否</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="remark"
          label="备注"
          rules={[{ required: true, message: "请输入备注" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddCompany);
