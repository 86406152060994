// import basic from "../assets/images/menu/basic.png";
// import demand from "../assets/images/menu/demand.png";
// import log from "../assets/images/menu/log.png";
// import main from "../assets/images/menu/main.png";
// import project from "../assets/images/menu/project.png";
// import setting from "../assets/images/menu/setting.png";
// import warehouse from "../assets/images/menu/warehouse.png";
// import message from "../assets/images/menu/message.png";
// import steel from "../assets/images/menu/steel.png";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    title: "基础信息",
    key: "baseInfo",
    icon: "icon-caidan",
    level: 1,
    children: [
      // 子菜单列表
      {
        title: "公司管理",
        key: "/admin/company",
        icon: "bars",
      },
      {
        title: "地图检索",
        key: "/admin/rtrieve",
        icon: "bars",
      },
      {
        title: "人员管理",
        key: "/admin/personnel",
        icon: "tool",
      },
      {
        title: "手持MAC地址",
        key: "/admin/mac",
        icon: "tool",
      },
    ],
  },
  {
    title: "仓库管理",
    key: "steel",
    icon: "icon-cangkuxinxi",
    level: 1,
    children: [
      // 子菜单列表
      {
        title: "仓库管理",
        key: "/admin/warehouse",
        icon: "bars",
        level: 2,
      },
      {
        title: "仓库详情",
        key: "/admin/warehouse/detail",
        icon: "tool",
        level: 2,
      },

      {
        title: "型钢历史信息",
        key: "/admin/steel",
        icon: "tool",
        level: 2,
      },
      {
        title: "码表管理",
        key: "clock",
        icon: "appstore",
        level: 2,
        children: [
          // 子菜单列表
          {
            title: "型钢规格",
            key: "/admin/clock/size",
            icon: "bars",
            level: 3,
          },
          {
            title: "配件规格",
            key: "/admin/clock/accessories",
            icon: "bars",
            level: 3,
          },
          {
            title: "设备规格",
            key: "/admin/clock/equipment",
            icon: "bars",
            level: 3,
          },
          {
            title: "材料厂商",
            key: "/admin/clock/material",
            icon: "tool",
            level: 3,
          },
          {
            title: "制造厂商",
            key: "/admin/clock/manufacturing",
            icon: "tool",
            level: 3,
          },
          {
            title: "运输公司",
            key: "/admin/clock/transport",
            icon: "tool",
            level: 3,
          },
          {
            title: "型钢单价",
            key: "/admin/clock/price",
            icon: "tool",
            level: 3,
          },
        ],
      },
      // {
      //   title: "型钢历史信息",
      //   key: "/admin/warehouse/steelhistory",
      //   icon: "bars",
      //   level: 2,
      // },
      {
        title: "设备历史信息",
        key: "/admin/warehouse/equipmenthistory",
        icon: "tool",
        level: 2,
      },
    ],
  },

  {
    title: "项目管理",
    key: "project",
    icon: "icon-xiangmu",
    level: 1,
    children: [
      // 子菜单列表
      {
        title: "项目管理",
        key: "/admin/project",
        icon: "bars",
        level: 2,
      },
      // {
      //     title: '项目详情',
      //     key: '/admin/project/detail',
      //     icon: 'tool'
      // }
    ],
  },
  {
    title: "需求管理",
    key: "demand",
    icon: "icon-zhaoxuqiu",
    level: 1,
    children: [
      // 子菜单列表
      {
        title: "型钢选择",
        key: "/admin/demand/steel",
        icon: "tool",
        level: 2,
      },
      {
        title: "设备选择",
        key: "/admin/demand/equipemnt",
        icon: "tool",
        level: 2,
      },
      {
        title: "配件选择",
        key: "/admin/demand/accessories",
        icon: "tool",
        level: 2,
      },
      {
        title: "需求单管理",
        key: "/admin/demand/management",
        icon: "bars",
        level: 2,
      },
      {
        title: "需求单列表",
        key: "/admin/demand/list",
        icon: "bars",
        level: 2,
      },
      {
        title: "需求单详情",
        key: "/admin/demand/detail",
        icon: "tool",
        level: 2,
      },
    ],
  },
  {
    title: "维修厂管理",
    key: "maintenance",
    icon: "icon-weixiu1",
    level: 1,
    children: [
      // 子菜单列表
      {
        title: "维修厂管理",
        key: "/admin/maintenance",
        icon: "bars",
        level: 2,
      },
      {
        title: "维修厂详情",
        key: "/admin/maintenance/detail",
        icon: "tool",
        level: 2,
      },
    ],
  },
  {
    title: "维修单管理",
    key: "maintenanceorder",
    icon: "icon-weixiudan",
    level: 1,
    children: [
      {
        title: "维修单管理",
        key: "/admin/maintenanceMangement",
        icon: "tool",
        level: 2,
      },
      {
        title: "维修单列表",
        key: "/admin/maintenanceOrder",
        icon: "tool",
        level: 2,
      },
      // {
      //   title: "报废单列表",
      //   key: "/admin/scrapList",
      //   icon: "tool",
      //   level: 2,
      // },
    ],
  },
  {
    title: "报废单管理",
    key: "scrap",
    icon: "icon-baofei",
    level: 1,
    children: [
      {
        title: "报废单列表",
        key: "/admin/scrapList",
        icon: "tool",
        level: 2,
      },
    ],
  },
  {
    title: "日志管理",
    key: "log",
    icon: "icon-rizhi",
    level: 1,
    children: [
      // 子菜单列表
      {
        title: "日志列表",
        key: "/admin/log",
        level: 2,
      },
    ],
  },
  {
    title: "修改密码",
    key: "/admin/modify",
    icon: "icon-mima",
    level: 1,
  },
  {
    title: "短信设置",
    key: "/admin/message",
    icon: "icon-duanxin1",
    level: 1,
  },
];
