import React, { Component } from "react";
import {
  Table,
  Button,
  Input,
  Space,
  Select,
  DatePicker,
  Modal,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Request from "../../../utils/client";
import { format } from "../../../utils/dateFormate";
import { AQUIRE_STEEL } from "../../../graphql/admin/steel";
import axiosRequest from "../../../utils/request";
import { warehouseRequestPath } from "../../../request/requestPath";
import "./steel.scss";
import moment from "moment";
import {
  MANUFACTURERS,
  MATERIAL,
  STATELIST,
  GETSPECIFICATION,
  REPOSITORY,
} from "../../../graphql/admin/index";
const { Option } = Select;

export default class Steel extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    filteredInfo: null,
    weight: 0,
    total: 0, // 数据总数
    current: 1, // 当前的页数
    pageSize: 20, // 每页的数据量
    data: [],
    columns: [],
    children: [],
    defaultValue: [],
    loading: true,
    manChildren: [],
    manSelect: "all",
    matChildren: [],
    matSelect: "all",
    specChildren: [],
    specSelect: "all",
    stateChildren: [],
    stateSelect: "all",
    repositoryChild: [],
    repositorySelect: "all",
    proExp: "",
    proName: "",
    code: "",
    identifier: "",
    produceAt: "", // 生产时间
    enterRepositoryAt: "", // 入库时间
    selectDate: null, // 选择的生产日期，moment格式的
    selectORA: null, // 选择的入库日期，moment格式的
    modalVisible: false,
    expressColumns: [
      // 维修经历table的相关数据
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "维修厂",
        dataIndex: "name",
        width: 150,
      },
      // {
      //   title: "维修天数",
      //   dataIndex: "useDays",
      //   width: 150,
      // },
      {
        title: "入厂时间",
        dataIndex: "startDate",
        width: 150,
      },
      // {
      //   title: "出厂时间",
      //   dataIndex: "outDate",
      //   width: 150,
      // },
      // {
      //   title: "状态信息",
      //   dataIndex: "status",
      //   width: 150,
      // },
    ],
    expressData: [], // 维修经历
    steelProject: [], // 项目经历
    project: [
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "时间",
        dataIndex: "startDate",
      },
      {
        title: "项目名称",
        dataIndex: "name",
      },
    ],
    projectVisible: false,
  };

  // 表格分页
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    pageSize: 20, // 每页条数
    showQuickJumper: true, // 开启页码快速跳转
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };

  columnsObj = {
    identifier: "识别码",
    code: "型钢编码",
    specifcationName: "规格尺寸",
    steelInMaintenance: "维修经历",
    steelInProject: "项目经历",
    turnover: "周转次数",
    usageYearRate: "年使用率",
    totalUsageRate: "总使用率",
    materialManufacturerName: "材料厂商",
    manufacturerName: "制造厂商",
    producedTime: "生产时间",
    createUserName: "入库用户",
    createdTime: "入库时间",
    // status: "状态",
    // projectName: "项目名称",
    // repositoryName: "仓库",

    // specifcationName: "规格参数",
    // steelInProject: "项目经历",
    // steelInMaintenance: "维修经历",
    // producedTime: "生产时间",
    //
  };
  componentDidMount() {
    let children = [];
    let defaultValue = [];
    Object.keys(this.columnsObj).forEach((item, index) => {
      children.push(
        <Option key={this.columnsObj[item]}>{this.columnsObj[item]}</Option>
      );
      defaultValue.push(this.columnsObj[item] + "");
    });
    this.setState({
      children,
      defaultValue,
      columns: this.columnsList,
    });
    this.aquireMan();
    this.aquireMat();
    this.aquireSpec();
    this.aquireState();
    this.aquireResitory();
    // this.getHistorySteelList();
    this.getHistorySteelList();
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };
  // 获取制造商
  aquireMan = () => {
    Request.client
      .query({
        query: MANUFACTURERS,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("制造商", result);
        let { getManufacturers } = result.data;
        let children = [];
        children.push(<Option key="all">制造商（全部）</Option>);
        getManufacturers.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          manChildren: children,
        });
      })
      .catch((err) => {
        // console.log(err);
        message.error("无法从服务器获取到制造商数据");
      });
  };
  // 获取材料商
  aquireMat = () => {
    Request.client
      .query({
        query: MATERIAL,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("材料商", result);
        let { getMaterialManufacturers } = result.data;
        let children = [];
        children.push(<Option key="all">材料商（全部）</Option>);
        getMaterialManufacturers.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          matChildren: children,
        });
      })
      .catch((err) => {
        // console.log(err);
        message.error("无法从服务器获取到材料商数据");
      });
  };
  // 获取状态
  aquireState = () => {
    Request.client
      .query({
        query: STATELIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("状态", result);
        let { getAllStateList } = result.data;
        let children = [];
        children.push(<Option key="all">状态（全部）</Option>);
        getAllStateList.forEach((item) => {
          children.push(<Option key={item.state}>{item.desc}</Option>);
        });
        this.setState({
          stateChildren: children,
          // defaultValue: getAllStateList[0] ? getAllStateList[0] : {},
          // select: getAllStateList[0] ? getAllStateList[0].flag : "",
        });
      })
      .catch((err) => {
        // console.log(err);
        message.error("无法从服务器获取到状态数据");
      });
  };
  // 获取规格尺寸
  aquireSpec = () => {
    Request.client
      .query({
        query: GETSPECIFICATION,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("规格", result);
        let { getSpecification } = result.data;
        let children = [];
        children.push(<Option key="all">规格尺寸（全部）</Option>);
        getSpecification.forEach((item) => {
          children.push(<Option key={item.id}>{item.specification}</Option>);
        });
        this.setState({
          specChildren: children,
        });
      })
      .catch((err) => {
        // console.log(err);
        message.error("无法从服务器获取到规格尺寸数据");
      });
  };
  // 获取仓库
  aquireResitory() {
    Request.client
      .query({
        query: REPOSITORY,
        variables: {
          input: {},
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("仓库列表", result);
        let { getRepositoryListForDashboard } = result.data;
        let children = [];
        children.push(<Option key="all">仓库（全部）</Option>);
        getRepositoryListForDashboard.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          repositoryChild: children,
        });
        // this.setState({
        //   data1,
        //   data2,
        //   data3,
        // });
      })
      .catch((err) => {
        // console.log(err);
        message.error("无法从服务器获取到仓库数据");
      });
  }

  // 获取历史型钢数据
  getHistorySteelList() {
    let data = {};
    let { current, pageSize } = this.paginationProps;
    data.page = current;
    data.pageSize = pageSize;
    // data.state = "";
    // data.code = "";
    // data.identifier = "";
    // data.specification = "";
    // data.material = "";
    // data.manufacturer = "";
    if (this.state.code !== "") {
      data.code = this.state.code;
    }
    if (this.state.identifier !== "") {
      data.identifier = this.state.identifier;
    }
    if (this.state.manSelect !== "all") {
      data.manufacturerId = this.state.manSelect * 1;
    }
    if (this.state.matSelect !== "all") {
      data.materialManufacturerId = this.state.matSelect * 1;
    }
    if (this.state.specSelect !== "all") {
      data.specificationId = this.state.specSelect * 1;
    }
    if (this.state.stateSelect !== "all") {
      data.state = this.state.stateSelect;
    }
    if (this.state.produceAt !== "") {
      data.produceAt = this.state.produceAt;
    }
    if (this.state.enterRepositoryAt !== "") {
      data.createdAt = this.state.enterRepositoryAt;
    }
    if (this.state.repositorySelect !== "all") {
      data.repositoryId = this.state.repositorySelect;
    }
    axiosRequest
      .post(warehouseRequestPath.GetHistorySteelList, data)
      .then((res) => {
        console.log("获取历史型钢信息", res);
        if (res.code === 200) {
          let data = res.data.Array ? res.data.Array : [];
          data.forEach((item, index) => {
            item.key = index;
            item.sort = index + 1;
            item.createdTime = item.enterRepositoryAt
              ? moment(item.enterRepositoryAt).format("YYYY-MM-DD")
              : "暂无";
            item.produceTime = item.producedDate
              ? moment(item.producedDate).format("YYYY-MM-DD")
              : "暂无";
            // eslint-disable-next-line no-unused-expressions
            item.maintenanceRecord ? "" : (item.maintenanceRecord = []);

            // eslint-disable-next-line no-unused-expressions
            item.projectRecord ? "" : (item.projectRecord = []);
          });
          this.setState({
            data: data,
            loading: false,
            total: res.data.steelCount,
            weight: res.data.weight
              ? Math.floor(res.data.weight * 1000) / 1000
              : 0,
          });
        }
      })
      .catch((error) => {
        console.log("获取历史型钢信息报错", error);
      });
  }

  // 请求数据
  aquireData = () => {
    let input = {};
    let { current, pageSize } = this.paginationProps;
    input.page = current;
    input.pageSize = pageSize;
    if (this.state.code !== "") {
      input.code = this.state.code;
    }
    if (this.state.identifier !== "") {
      input.identifier = this.state.identifier;
    }
    if (this.state.manSelect !== "all") {
      input.manufacturerId = this.state.manSelect;
    }
    if (this.state.matSelect !== "all") {
      input.materialManufacturerId = this.state.matSelect;
    }
    if (this.state.specSelect !== "all") {
      input.specificationId = this.state.specSelect;
    }
    if (this.state.stateSelect !== "all") {
      input.state = this.state.stateSelect;
    }
    if (this.state.produceAt !== "") {
      input.produceAt = this.state.produceAt;
    }
    if (this.state.enterRepositoryAt !== "") {
      input.createdAt = this.state.enterRepositoryAt;
    }
    if (this.state.repositorySelect !== "all") {
      input.repositoryId = this.state.repositorySelect;
    }
    Request.client
      .query({
        query: AQUIRE_STEEL,
        variables: { input },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        let { list, total, weightTotal } = result.data.getSteelList;

        list.forEach((item, index) => {
          item.manufacturerName = item.manufacturer.name;
          item.materialManufacturerName = item.materialManufacturer.name;
          item.repositoryName = item.repository.name;
          item.specifcationName = item.specifcation.specification;
          item.key = item.identifier;
          item.sort = index + 1;
          item.producedTime = format(item.producedDate);
          item.status = item.stateInfo.desc;
          item.createdTime = format(item.createdAt);
          item.usageYearRate = Math.floor(item.usageYearRate * 1000) / 1000;
          item.createUserName = item.createUser.name;
          item.totalUsageRate = Math.floor(item.totalUsageRate * 1000) / 1000;

          // `<p><span>${children.outRepositoryAt}</span><span>${children.projectName}</span></p>`
          // if (item.steelInProject.length > 0) {
          //   let str = "";
          //   item.steelInProject.forEach((steel) => {
          //     let date = moment(steel.outRepositoryAt).format("YYYY-MM-DD");
          //     str =
          //       str +
          //       `<p><span>${date}</span><span>${steel.projectName}</span></p>`;
          //   });
          //   item.projectExpress = str;
          // }
        });
        // console.log("获取的型钢数据", list);
        this.setState({
          data: list,
          loading: false,
          total,
          weight: Math.floor(weightTotal * 1000) / 1000,
        });
      })
      .catch((error) => {
        console.log(error);
        // this.props.history.push({ pathname: "/admin/error/systemerror" });
      });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        {dataIndex === "code" ? (
          <Input
            placeholder="型钢编码"
            style={{ width: 200 }}
            defaultValue={this.state.code}
            onChange={this.debounce(this.codeChange, 600)}
            ref={(codeInput) => {
              this.codeInput = codeInput;
            }}
          />
        ) : (
          <Input
            placeholder="识别码"
            style={{ width: 200 }}
            defaultValue={this.state.identifier}
            onChange={this.debounce(this.identifierChange, 600)}
            ref={(identifierInput) => {
              this.identifierInput = identifierInput;
            }}
          />
        )}
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  getColumnFilterProps = (dataIndex) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <div>
          {dataIndex === "man" ? (
            <Select
              value={this.state.manSelect}
              style={{ width: 200 }}
              onChange={this.manHandleChange}
            >
              {this.state.manChildren}
            </Select>
          ) : dataIndex === "mat" ? (
            <Select
              value={this.state.matSelect}
              style={{ width: 200 }}
              onChange={this.matHandleChange}
            >
              {this.state.matChildren}
            </Select>
          ) : dataIndex === "spec" ? (
            <Select
              value={this.state.specSelect}
              style={{ width: 250 }}
              onChange={this.specHandleChange}
            >
              {this.state.specChildren}
            </Select>
          ) : dataIndex === "" ? (
            <Select
              value={this.state.stateSelect}
              style={{ width: 200 }}
              onChange={this.stateHandleChange}
            >
              {this.state.stateChildren}
            </Select>
          ) : dataIndex === "repository" ? (
            <Select
              value={this.state.repositorySelect}
              style={{ width: 200, marginBottom: 10 }}
              onChange={this.repositoryHandleChange}
            >
              {this.state.repositoryChild}
            </Select>
          ) : dataIndex === "ora" ? (
            <DatePicker
              style={{ width: 200 }}
              showTime
              value={this.state.selectORA}
              onChange={this.onORAChange}
              placeholder="入库时间"
            />
          ) : (
            <DatePicker
              style={{ width: 200 }}
              showTime
              value={this.state.selectDate}
              onChange={this.onDateChange}
              placeholder="生产时间"
            />
          )}
        </div>
      </div>
    ),
  });

  columnsList = [
    {
      title: "序号",
      dataIndex: "sort",
      fixed: "left",
      width: 100,
    },
    {
      title: "识别码",
      dataIndex: "identifier",
      width: 150,
      ...this.getColumnSearchProps("identifier"),
    },
    {
      title: "型钢编码",
      dataIndex: "code",
      width: 200,
      ...this.getColumnSearchProps("code"),
      // specify the condition of filtering result
      // here is that finding the name started with `value`
    },
    // { title: "仓库", dataIndex: "repositoryName", width: 150 },
    {
      title: "规格尺寸",
      dataIndex: "specification",
      width: 150,
      ...this.getColumnFilterProps("spec"),
    },
    {
      title: "维修经历",
      dataIndex: "maintenanceRecord",
      width: 150,
      render: (item) => (
        <div>
          {item.length > 0 ? (
            <Button
              type="primary"
              onClick={() => {
                this.toView(item);
              }}
            >
              查看
            </Button>
          ) : (
            ""
          )}
          {item.length === 0 ? <Button type="error">暂无</Button> : ""}
        </div>
      ),
    },

    {
      title: "项目经历",
      dataIndex: "projectRecord",
      width: 200,
      render: (item) => (
        <div>
          {item.length > 0 ? (
            <Button
              type="primary"
              onClick={() => {
                this.toViewProject(item);
              }}
            >
              查看
            </Button>
          ) : (
            ""
          )}
          {item.length === 0 ? <Button type="error">暂无</Button> : ""}
        </div>
      ),
    },
    {
      title: "周转次数",
      dataIndex: "turnover",
      width: 100,
    },
    {
      title: "单根型钢累计使用天数",
      dataIndex: "useTime",
      width: 200,
    },
    {
      title: "单根型钢使用率",
      dataIndex: "useRate",
      width: 200,
    },

    {
      title: "材料厂商",
      dataIndex: "materialName",
      width: 200,
      ...this.getColumnFilterProps("mat"),
    },
    {
      title: "制造厂商",
      dataIndex: "manufacturer",
      width: 150,
      ...this.getColumnFilterProps("man"),
    },
    {
      title: "生产日期",
      dataIndex: "produceTime",
      width: 150,
      ...this.getColumnFilterProps("date"),
    },

    {
      title: "入库用户",
      dataIndex: "enterRepositoryUsername",
      width: 150,
    },
    {
      title: "入库时间",
      dataIndex: "createdTime",
      width: 150,
      ...this.getColumnFilterProps("ora"),
    },
    // {
    //   title: "状态",
    //   dataIndex: "status",
    //   width: 150,
    // },
    // {
  ];

  // 输入安装码和订单号的防抖函数
  debounce = (fn, wait) => {
    let timeout;
    return function () {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(function () {
        fn.apply(this);
      }, wait);
    };
  };

  // select 变化执行的函数
  handleChange = (value) => {
    let list = [
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
    ];
    value.forEach((item) => {
      let arr = this.columnsList.filter((item2) => {
        return item2.title === item;
      });
      list.push(...arr);
    });
    this.setState({ columns: list, defaultValue: value });
  };

  // 页面发生改变
  changePage = (page) => {
    // console.log(page);
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  // pageSize发生改变时
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    this.paginationProps.pageSize = pageSize;
    this.setState(
      {
        pageSize,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  manHandleChange = (e) => {
    this.setState(
      {
        manSelect: e,
        current: 1,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  matHandleChange = (e) => {
    this.setState(
      {
        matSelect: e,
        current: 1,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  specHandleChange = (e) => {
    this.setState(
      {
        specSelect: e,
        current: 1,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  stateHandleChange = (e) => {
    this.setState(
      {
        stateSelect: e,
        current: 1,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  repositoryHandleChange = (e) => {
    this.setState(
      {
        repositorySelect: e,
        current: 1,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  // 重置
  resetFunc = () => {
    // this.peInput.state.value = "";
    // this.pnInput.state.value = "";
    if (this.codeInput) {
      this.codeInput.state.value = "";
    }
    if (this.identifierInput) {
      this.identifierInput.state.value = "";
    }
    let children = [];
    let defaultValue = [];
    Object.keys(this.columnsObj).forEach((item, index) => {
      children.push(
        <Option key={this.columnsObj[item]}>{this.columnsObj[item]}</Option>
      );
      defaultValue.push(this.columnsObj[item] + "");
    });
    this.setState(
      {
        children,
        defaultValue,
        columns: this.columnsList,
        manSelect: "all",
        matSelect: "all",
        specSelect: "all",
        stateSelect: "all",
        repositorySelect: "all",
        // proExp: "",
        // proName: "",
        code: "",
        identifier: "",
        produceAt: "",
        enterRepositoryAt: "",
        selectDate: null,
        selectORA: null,
        current: 1,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };

  codeChange = () => {
    this.setState({ code: this.codeInput.state.value, current: 1 }, () => {
      this.getHistorySteelList();
    });
  };
  identifierChange = () => {
    this.setState(
      { identifier: this.identifierInput.state.value, current: 1 },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  // 生产时间发生改变
  onDateChange = (e) => {
    // console.log(e);
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        produceAt: value,
        selectDate: e,
        current: 1,
      },
      () => {
        this.getHistorySteelList();
      }
    );
    // console.log(value);
  };
  // 入库时间发生改变
  onORAChange = (e) => {
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        enterRepositoryAt: value,
        selectORA: e,
        current: 1,
      },
      () => {
        this.getHistorySteelList();
      }
    );
  };
  // 查看维修经历
  toView = (list) => {
    console.log(list);
    list.forEach((item, index) => {
      // item.sort = index + 1;
      // item.enteredDate = moment(item.enteredAt).format("YYYY-MM-DD");
      // item.outDate = moment(item.outedAt).format("YYYY-MM-DD");
      // item.status = item.stateInfo.desc;
      // item.key = index;
      // item.maintenanceName = item.maintenance.name;
      item.startDate = moment(item.startAt).format("YYYY-MM-DD");
      item.key = index;
      item.sort = index + 1;
    });
    this.setState({ expressData: list, modalVisible: true });
  };
  toViewProject = (list) => {
    console.log(list);
    list.forEach((item, index) => {
      item.sort = index + 1;
      item.startDate = moment(item.startAt).format("YYYY-MM-DD");
      // item.outDate = moment(item.outedAt).format("YYYY-MM-DD");
      // item.status = item.stateInfo.desc;
      item.key = item.index;
      // item.maintenanceName = item.maintenance.name;
    });
    this.setState({ steelProject: list, projectVisible: true });
  };
  render() {
    let {
      // manChildren,
      // manSelect,
      // matChildren,
      // matSelect,
      // specChildren,
      // specSelect,
      // stateChildren,
      // stateSelect,
      // proExp,
      // proName,
      // code,
      // identifier,
      // selectDate,
      // selectORA,
      data,
      columns,
      children,
      defaultValue,
      loading,
      // repositorySelect,
      // repositoryChild,
      expressColumns,
      expressData,
      steelProject,
      project,
    } = this.state;

    return (
      <div className="steel_box">
        <div className="btn_box">
          <div className="btn_box_right">
            <div style={{ display: "flex" }}>
              <div className="right_title">选择展示列：</div>
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select"
                value={defaultValue}
                onChange={this.handleChange}
                style={{ minWidth: 250 }}
                maxTagCount="responsive"
              >
                {children}
              </Select>
            </div>
            {/* <Input
            className="seacrch_item"
            placeholder="项目经历"
            style={{ width: 200, marginBottom: 10 }}
            defaultValue={proExp}
            onChange={this.proExpChange}
            ref={(peInput) => {
              this.peInput = peInput;
            }}
          /> */}
            {/* <Input
            className="seacrch_item"
            placeholder="项目名称"
            style={{ width: 200, marginBottom: 10 }}
            defaultValue={proName}
            onChange={this.proNameChange}
            ref={(pnInput) => {
              this.pnInput = pnInput;
            }}
          /> */}

            {/* <div className="seacrch_item">
              <Input
                placeholder="型钢编码"
                style={{ width: 200, marginBottom: 10 }}
                defaultValue={code}
                onChange={this.debounce(this.codeChange, 600)}
                ref={(codeInput) => {
                  this.codeInput = codeInput;
                }}
              />
            </div>

            <div className="seacrch_item">
              <Input
                placeholder="识别码"
                style={{ width: 200, marginBottom: 10 }}
                defaultValue={identifier}
                onChange={this.debounce(this.identifierChange, 600)}
                ref={(identifierInput) => {
                  this.identifierInput = identifierInput;
                }}
              />
            </div>
            <div className="seacrch_item">
              <Select
                value={manSelect}
                style={{ width: 200, marginBottom: 10 }}
                onChange={this.manHandleChange}
              >
                {manChildren}
              </Select>
            </div>
            <div className="seacrch_item">
              <Select
                value={matSelect}
                style={{ width: 200, marginBottom: 10 }}
                onChange={this.matHandleChange}
              >
                {matChildren}
              </Select>
            </div>
            <div className="seacrch_item">
              <Select
                value={specSelect}
                style={{ width: 250, marginBottom: 10 }}
                onChange={this.specHandleChange}
              >
                {specChildren}
              </Select>
            </div>
            <div className="seacrch_item">
              <Select
                value={stateSelect}
                style={{ width: 200, marginBottom: 10 }}
                onChange={this.stateHandleChange}
              >
                {stateChildren}
              </Select>
            </div>
            <div className="seacrch_item">
              <Select
                value={repositorySelect}
                style={{ width: 200, marginBottom: 10 }}
                onChange={this.repositoryHandleChange}
              >
                {repositoryChild}
              </Select>
            </div>

            <div
              className="seacrch_item"
              style={{ width: 200, marginBottom: 10 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime
                value={selectDate}
                onChange={this.onDateChange}
                placeholder="生产时间"
              />
            </div>
            <div
              className="seacrch_item"
              style={{ width: 200, marginBottom: 10 }}
            >
              <DatePicker
                style={{ width: "100%" }}
                showTime
                value={selectORA}
                onChange={this.onORAChange}
                placeholder="入库时间"
              />
            </div> */}
            <div className="btn_box_left" style={{ marginLeft: 10 }}>
              <Button
                type="primary"
                style={{ background: "#7BAC1C", border: "none" }}
                onClick={this.resetFunc}
              >
                重置
              </Button>
            </div>
          </div>
        </div>
        <div className="table_box">
          <Table
            onChange={this.onChange}
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={{ ...this.paginationProps, total: this.state.total }}
            loading={loading}
          />
        </div>
        <Modal
          title="维修经历"
          centered
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          footer={false}
          width={1000}
        >
          <Table
            columns={expressColumns}
            dataSource={expressData}
            bordered
            pagination={false}
          />
        </Modal>
        <Modal
          title="项目经历"
          centered
          visible={this.state.projectVisible}
          onCancel={() => this.setState({ projectVisible: false })}
          footer={false}
          width={1000}
        >
          <Table
            columns={project}
            dataSource={steelProject}
            bordered
            pagination={false}
          />
        </Modal>
        <div className="tip">
          <div> 型钢总数(根)：{this.state.total}</div>
          <div> 型钢总重(吨)：{this.state.weight}</div>
        </div>
      </div>
    );
  }
}
