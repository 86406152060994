import React, {
  // Children,
  Component,
} from "react";
import { withRouter } from "react-router-dom";
import {
  // CloseOutlined,
  HomeOutlined,
  RightSquareOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import PubSub from "pubsub-js"; //引入

import menuConfig from "../../../../config/menusConfig";

import "./tabs.scss";

let UNLISTEN;
class Tabs extends Component {
  historyObj = {
    "/admin/company": "公司管理",
    "/admin/rtrieve": "地图检索",
    "/admin/personnel": "人员管理",
    "/admin/warehouse": "仓库管理",
    "/admin/warehouse/detail": "仓库详情",
    "/admin/warehouse/steelhistory": "型钢历史信息",
    "/admin/warehouse/equipmenthistory": "设备历史信息",
    "/admin/maintenance": "维修厂管理",
    "/admin/maintenance/detail": "维修厂详情",
    "/admin/maintenanceOrder": "维修单列表",
    "/admin/mac": "mac地址管理",
    "/admin/steel": "型钢管理",
    "/admin/project": "项目管理",
    "/admin/project/detail": "项目详情",
    "/admin/demand": "需求单管理",
    "/admin/demand/detail": "需求单详情",
    "/admin/demand/steel": "型钢选择",
    "/admin/demand/accessories": "配件选择",
    "/admin/demand/equipemnt": "设备选择",
    "/admin/clock/size": "型钢规格",
    "/admin/clock/accessories": "配件规格",
    "/admin/clock/equipment": "设备规格",
    "/admin/clock/material": "材料厂商",
    "/admin/clock/manufacturing": "制造厂商",
    "/admin/clock/transport": "运输公司",
    "/admin/clock/price": "型钢单价",
    "/admin/log": "日志管理",
    "/admin/modify": "修改密码",
    "/admin/message": "短信设置",
  };
  state = {
    pathList: [],
    activePath: "",
    showFlag: true,
    outHeight: 40,
    tabHeight: 0,
    collapsed: false,
    pathString: "", // 当前的路径
  };
  componentDidMount() {
    PubSub.subscribe("updateList", (_) => {
      //   console.log("数据更新");
      if (this.tabDiv && this.tabDiv.clientHeight) {
        this.setState({ tabHeight: this.tabDiv.clientHeight });
        setTimeout(() => {
          if (
            !this.state.showFlag &&
            this.outDiv.clientHeight < this.tabDiv.clientHeight
          ) {
            this.setState({ outHeight: this.tabDiv.clientHeight });
          }
        }, 400);
      }
    }); //订阅
    let activePath = localStorage.getItem("activePath");
    let pathname = window.location.pathname;
    if (
      pathname !== "/admin/index" &&
      pathname !== "/" &&
      pathname !== "/login" &&
      pathname !== "/admin/error/not" &&
      pathname !== "/admin/error/redirect" &&
      pathname !== "/admin/error/systemerror" &&
      pathname !== "/admin/cart" &&
      pathname.includes("/admin/orderDetail") === false
    ) {
      // eslint-disable-next-line no-unused-expressions
      activePath ? this.dealPath(activePath) : "";
    }
    // this.setState({ activePath });
    UNLISTEN = this.props.history.listen((route) => {
      //   console.log(route.pathname); // 这个route里面有当前路由的各个参数信息
      //   console.log(route.pathname == "/admin/index");

      localStorage.setItem("activePath", route.pathname);
      this.setState({ activePath: route.pathname });
      if (
        route.pathname === "/admin/index" ||
        route.pathname === "/" ||
        route.pathname === "/login" ||
        route.pathname === "/admin/error/not" ||
        route.pathname === "/admin/error/redirect" ||
        route.pathname === "/admin/error/systemerror" ||
        route.pathname === "/admin/cart" ||
        route.pathname.includes("/admin/orderDetail")
      ) {
        this.setState({
          pathString: "",
        });
        return;
      }

      //   console.log(this.historyObj[route.pathname]);
      // 以下代码是处理历史记录的
      // let { pathList } = this.state;
      // let index = pathList.findIndex((item) => {
      //   return item === route.pathname;
      // });
      // if (index === -1) {
      //   // pathList.push(route.pathname);
      //   pathList = [route.pathname, ...pathList];
      //   this.setState({ pathList });
      // }
      this.dealPath(route.pathname);
    });
    window.addEventListener("resize", this.listenerResize, false);
  }
  // 以下代码是处理路径信息   例：基础信息>公司管理
  dealPath = (pathname) => {
    let pathArray = [];
    menuConfig.some((item) => {
      if (item.children) {
        item.children.some((son) => {
          if (son.key === pathname) {
            pathArray.push(item.title);
            pathArray.push(son.title);
            return true;
          } else {
            if (son.children) {
              son.children.some((children) => {
                if (children.key === pathname) {
                  pathArray.push(item.title);
                  pathArray.push(son.title);
                  pathArray.push(children.title);
                  return true;
                } else {
                  return false;
                }
              });
            }
            return false;
          }
        });
      } else {
        // eslint-disable-next-line no-unused-expressions
        item.key === pathname ? pathArray.push(item.title) : "";
      }

      if (pathArray.length > 0) {
        return true;
      } else {
        return false;
      }
    });
    this.setState({
      pathString: pathArray.join(" > "),
    });
  };
  // 菜单栏的展开和隐藏
  toggle = () => {
    this.setState(
      {
        collapsed: !this.state.collapsed,
      },
      () => {
        PubSub.publish("collapsed", this.state.collapsed); //发布消息，触发Admin.jsx里订阅的事件
      }
    );
  };
  // 监听浏览器窗口大小
  listenerResize = () => {
    this.setState({});
    if (this.outDiv.clientHeight > this.tabDiv.clientHeight) {
      this.setState({ outHeight: this.tabDiv.clientHeight });
      if (this.tabDiv.clientHeight === 40) {
        let { showFlag } = this.state;
        this.setState({ showFlag: !showFlag });
      }
    }
    if (
      !this.state.showFlag &&
      this.outDiv.clientHeight < this.tabDiv.clientHeight
    ) {
      this.setState({ outHeight: this.tabDiv.clientHeight });
    }
    this.setState({ tabHeight: this.tabDiv.clientHeight });
  };
  // 移除历史记录
  removeHistory = (path) => {
    return () => {
      let { pathList, activePath } = this.state;
      let length = pathList.length;
      let index = pathList.findIndex((item) => {
        return item === path;
      });
      let newPathList = pathList;
      if (length > 1 && path === activePath) {
        let newActivePath = "";
        if (index === length - 1) {
          newActivePath = pathList[length - 2];
        } else {
          newActivePath = pathList[index + 1];
        }
        newPathList.splice(index, 1);
        this.setState(
          {
            activePath: newActivePath,
            pathList: newPathList,
          },
          () => {
            this.props.history.push({ pathname: newActivePath });
            PubSub.publish("switch", this.state.activePath); //发布消息
          }
        );
      } else if (length > 1) {
        newPathList.splice(index, 1);
        this.setState({
          pathList: newPathList,
        });
      } else {
        this.setState(
          {
            activePath: "",
            pathList: [],
          },
          () => {
            this.props.history.push({ pathname: "/admin/index" });
            PubSub.publish("switch", this.state.activePath); //发布消息
          }
        );
      }

      // let list = pathList.filter((item) => {
      //   return item !== path;
      // });
      setTimeout(() => {
        if (this.outDiv.clientHeight > this.tabDiv.clientHeight) {
          this.setState({
            outHeight: this.tabDiv.clientHeight,
            tabHeight: this.tabDiv.clientHeight,
          });
          if (this.tabDiv.clientHeight === 40) {
            let { showFlag } = this.state;
            this.setState({ showFlag: !showFlag });
          }
        }
      }, 100);
    };
  };
  // 页面跳转
  goPage = (path) => {
    return () => {
      this.props.history.push({ pathname: path });
      PubSub.publish("switch", path); //发布消息
    };
  };
  // 返回首页
  goHome = () => {
    // console.log("执行返回");
    this.props.history.push({ pathname: "/admin/index" });
  };
  // 展开
  showFunc = () => {
    let { showFlag } = this.state;
    this.setState({ showFlag: !showFlag });
    if (showFlag) {
      this.setState({ outHeight: this.tabDiv.clientHeight });
    } else {
      this.setState({ outHeight: 40 });
    }
  };
  componentWillUnmount() {
    UNLISTEN && UNLISTEN(); // 执行解绑this.props.history.listen
    window.removeEventListener("resize", this.listenerResize);
  }
  render() {
    let { pathString, outHeight, tabHeight, showFlag } = this.state;
    return (
      <div
        className="tab_out_box"
        ref={(outDiv) => (this.outDiv = outDiv)}
        style={{ height: outHeight }}
      >
        <div className="tabs_box" ref={(tabDiv) => (this.tabDiv = tabDiv)}>
          <div className="home_box" onClick={this.toggle}>
            {this.state.collapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: 18 }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: 18 }} />
            )}
          </div>
          <div
            onClick={this.goHome}
            className={
              pathString === "" || pathString === "/admin/index"
                ? `home_box home_null_box`
                : `home_box home_block_box`
            }
          >
            <HomeOutlined style={{ fontSize: 18 }} />
          </div>

          {/* 历史记录 */}
          {/* {pathList.map((item) => {
            return (
              <div
                className={item === activePath ? `tab_active tabs` : "tabs"}
                key={item}
              >
                <div
                  className={
                    item === activePath
                      ? `tabs_text_active tabs_text`
                      : "tab_custom"
                  }
                  onClick={this.goPage(item)}
                >
                  {this.historyObj[item]}
                </div>
                <CloseOutlined
                  onClick={this.removeHistory(item)}
                  style={{ fontSize: 12 }}
                  className={item === activePath ? `tabs_icon_active` : ""}
                />
              </div>
            );
          })} */}

          {pathString === "" ? (
            ""
          ) : (
            <div className="current_path">当前位置：{pathString}</div>
          )}
        </div>
        <div
          className={tabHeight > 40 ? `spread_icon show_spread` : `spread_icon`}
        >
          <RightSquareOutlined
            style={{ fontSize: 20 }}
            onClick={this.showFunc}
            className={
              showFlag
                ? `square_outlined show_right`
                : `square_outlined show_Down`
            }
          />
          {/* <DownSquareOutlined
            style={{ fontSize: 20 }}
            className= {showFlag ? `square_outlined show_Down` :`square_outlined`}
            onClick={() => {
              this.showFunc(false);
            }}
          /> */}
        </div>
      </div>
    );
  }
}

export default withRouter(Tabs);
