import Request from "../../utils/client";

// 获取公司（简略信息，用于select下拉框的数据）
export const AQUIRE_COMPANY_SIMPLE = Request.gql`
    query {
      getAllCompany{
          id
          name
      }
    }
`;

// 获取公司
export const AQUIRE_COMPANY = Request.gql`
    query {
    getAllCompany{
        adminAvatar {
            id
            url 
        }
        adminName
        adminPhone
        adminWechat
        backgroundFile{
            id
            url
        }
        createdAt
        endedAt
        id
        isAble    
        logoFile{
            id
            url
        }
        name
        phone
        pinYin
        startedAt
        symbol
        wechat
    }
    }
`;
// 删除公司
export const DELETE_COMPANY = Request.gql`
    mutation deleteCompanyMutation($id: Int!){
        deleteCompany(id: $id)
    }
`;
// 添加公司
export const ADD_COMPANY = Request.gql`
  mutation crateCompanyMutation($input: CreateCompanyInput!){
    createCompany(input: $input){
      id
      name
      pinYin
      symbol
      logoFile {
        id
        url
      }
      backgroundFile {
        id
        url
      }
      isAble
      phone
      wechat
      startedAt
      endedAt
      adminName
      adminPhone
      adminWechat
      adminAvatar{
        id
        url
      }
      createdAt
    }
  }
`;
// 修改公司
export const MODIFY_COMPANY = Request.gql`
  mutation editMutation($input: EditCompanyInput!) {
    editCompany(input: $input){
      id
      name
      pinYin
      symbol
      logoFile {id url}
      backgroundFile {id url}
      isAble
      phone
      wechat
      startedAt
      endedAt
      adminName
      createdAt
    }
  }
`;
