import React, { Component } from "react";
import { Pie } from "@ant-design/charts";
import { message, Empty } from "antd";
import { PROJECT_STEEL_STATE } from "../../../../graphql/admin/index/project";
import { GET_STEEL_STAT_OF_PROJECT } from "../../../../graphql/admin/index/company";
import Request from "../../../../utils/client";
import axiosRequest from "../../../../utils/request";
import { AdminIndexRequest } from "../../../../request/requestPath";
import {
  ADMIN,
  COMPANY_ADMIN,
  REPOSITORY_ADMIN,
  PROJECT_ADMIN,
  MAINTENANCE_ADMIN,
} from "../../../../config/constant";

class DemoPie extends Component {
  state = {
    data: [],
    isNull: true,
  };
  projectId = ""; // 项目id
  repositoryId = ""; // 仓库id
  config = {
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 0.7,
    label: {
      type: "outer",
      content: "{name}   {percentage}",
    },
    width: 350,
    height: 350,
    interactions: [{ type: "pie-legend-active" }, { type: "element-active" }],
    legend: {
      layout: "vertical",
      position: "left",
      offsetY: -120,
      marker: {
        symbol: "square",
      },
    },
  };

  // 获取项目型钢状态（废弃）
  aquireData(projectId, repositoryId) {
    if (projectId && repositoryId) {
      Request.client
        .query({
          query: GET_STEEL_STAT_OF_PROJECT,
          variables: {
            input: {
              projectId,
              repositoryId,
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          let { getSteelStateOfProject } = res.data;
          this.dataDeal(getSteelStateOfProject);
        })
        .catch((error) => {
          message.error("获取项目型钢状态错误");
        });
    }
  }

  // 项目管理员获取项目型钢状态（废弃）
  projectAquireData(projectId, repositoryId) {
    if (projectId && repositoryId) {
      // console.log(projectId, repositoryId);
      Request.client
        .query({
          query: PROJECT_STEEL_STATE,
          variables: {
            input: {
              projectId: projectId * 1,
              repositoryId,
            },
          },
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          // console.log("项目管理员获取项目型钢状态", res);
          let { getSteelStateOfProject } = res.data;
          this.dataDeal(getSteelStateOfProject);
        })
        .catch((error) => {
          message.error("项目管理员获取项目型钢状态错误");
        });
    }
  }

  // 获取项目型钢状态
  adminAquireData(body) {
    let { role, companyId, projectId, repositoryId } = body;
    let data = {};
    switch (role) {
      case ADMIN:
        if (companyId && projectId) {
          data = {
            companyId,
            projectId: projectId * 1,
          };
          this.getData(data);
        }
        break;
      case COMPANY_ADMIN:
      case REPOSITORY_ADMIN:
      case PROJECT_ADMIN:
        if (projectId && repositoryId) {
          data = {
            repositoryId,
            projectId: projectId * 1,
          };
          this.getData(data);
        }
        break;
      case MAINTENANCE_ADMIN:
        break;
      default:
        console.log("没有匹配到角色");
    }
  }
  // 发送请求，获取数据
  getData(data) {
    axiosRequest
      .post(AdminIndexRequest.GetProjectSteelStateBySuper, data)
      .then((res) => {
        // console.log("res", res);
        if (res.code === 200) {
          this.dataDeal(res.data);
        } else {
          message.error("项目管理员获取项目型钢状态错误");
        }
      })
      .catch((error) => {
        console.log("超级管理员获取项目型钢状态错误", error);
      });
  }

  // 数据处理
  dataDeal(requestData) {
    let {
      abnormalPercent,
      usingPercent,
      beUsePercent,
      toProjectPercent,
      toRepositoryPercent,
      readyToRepositoryPercent,
      idleWeightPercent,
    } = requestData;
    let totalWeight =
      abnormalPercent +
      usingPercent +
      beUsePercent +
      toProjectPercent +
      toRepositoryPercent +
      readyToRepositoryPercent +
      idleWeightPercent;
    // totalWeight 总量
    // abnormalPercent 异常
    // usingPercent 使用中
    // beUsePercent 待使用
    // toProjectPercent 送至项目途中
    // toRepositoryPercent 归途中
    // readyToRepositoryPercent 准备归库
    // idleWeightPercent 闲置
    let data = [
      {
        type: "异常",
        value: abnormalPercent,
      },
      {
        type: "使用中",
        value: usingPercent,
      },
      {
        type: "待使用",
        value: beUsePercent,
      },
      {
        type: "送至项目途中",
        value: toProjectPercent,
      },
      {
        type: "归途中",
        value: toRepositoryPercent,
      },
      {
        type: "准备归库",
        value: readyToRepositoryPercent,
      },
      {
        type: "闲置",
        value: idleWeightPercent,
      },
    ];

    this.setState({
      data,
      isNull: totalWeight > 0 ? false : true,
    });
  }

  render() {
    let { isNull } = this.state;
    return (
      <div style={{ padding: "0 20px 0 0" }}>
        {isNull ? (
          <div style={{ paddingTop: 100, paddingBottom: 100 }}>
            <Empty />
          </div>
        ) : (
          <Pie {...this.config} data={this.state.data} />
        )}
      </div>
    );
  }
}

export default DemoPie;
