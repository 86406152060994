import React, { Component } from "react";
import { Line } from "@ant-design/charts";
import { message, Empty } from "antd";
// import companyRequest from "../request/company";
import axiosRequest from "../../../../utils/request";
import {
  indexRequestPath,
  // AdminIndexRequest,
} from "../../../../request/requestPath";
import {
  ADMIN,
  // COMPANY_ADMIN,
  // REPOSITORY_ADMIN,
  // PROJECT_ADMIN,
  // MAINTENANCE_ADMIN,
} from "../../../../config/constant";
// import moment from "moment";

class DemoLine extends Component {
  state = {
    data: [],
    isNull: true,
  };
  config = {
    padding: "auto",
    xField: "yearMonthDay",
    yField: "steelAvgRate",
    xAxis: { tickCount: 5 },
    slider: {
      start: 0.1,
      end: 0.5,
    },
  };
  componentDidMount() {
    // this.asyncFetch();
  }
  asyncFetch() {
    fetch(
      "https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json"
    )
      .then((response) => response.json())
      .then((json) => {
        console.log("json", json);
        this.setState({ data: json });
      })
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  }

  // // 获取型钢使用率
  // getAverageUsage(id) {
  //   companyRequest
  //     .getSteelAverageUsage(id)
  //     .then((res) => {
  //       console.log("获取的型钢使用率", res);
  //     })
  //     .catch((result) => {
  //       console.log(result);
  //     });
  // }
  // 获取型钢使用率
  aquireData(body) {
    let { repositoryId, companyId, role } = body;
    let data = null;
    switch (role) {
      case ADMIN:
        data = {
          companyId,
          repositoryId: 0,
        };
        this.requsetFunc(data);
        break;
      default:
        data = {
          companyId: 0,
          repositoryId,
        };
        this.requsetFunc(data);
    }
  }
  // 获取型钢使用率的请求
  requsetFunc(data) {
    axiosRequest
      .post(indexRequestPath.GetSteelUsageByRepository, data)
      .then((res) => {
        if (res.code === 200) {
          // res.data.forEach((item) => {
          //   item.date = moment(item.year).format("YYYY-MM-DD");
          // });
          this.setState({
            data: res.data.reverse(),
            isNull: res.data && res.data.length > 0 ? false : true,
          });
        } else {
          message.error("获取型钢使用率错误");
        }
      })
      .catch((error) => {
        console.log("获取型钢使用率错误");
      });
  }
  // 超级管理员获取型钢使用率
  // adminAquireData(companyId) {
  //   axiosRequest
  //     .post(AdminIndexRequest.GetSteelUsageBySuper, {
  //       companyId,
  //     })
  //     .then((res) => {
  //       console.log("获取型钢使用率", res);
  //       if (res.code === 200) {
  //         res.data.forEach((item) => {
  //           item.date = moment(item.year).format("YYYY-MM-DD");
  //         });
  //         this.setState({
  //           data: res.data.reverse(),
  //           isNull: res.data && res.data.length > 0 ? false : true,
  //         });
  //       } else {
  //         message.error("获取型钢使用率错误");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("获取型钢使用率错误");
  //     });
  // }

  render() {
    let { isNull } = this.state;
    // console.log(this.state.data);
    return (
      <div style={{ padding: "0 20px" }}>
        {isNull ? (
          <div style={{ paddingTop: 100, paddingBottom: 100 }}>
            <Empty />{" "}
          </div>
        ) : (
          <Line {...this.config} data={this.state.data} />
        )}
      </div>
    );
  }
}

export default DemoLine;
