import React, { Component } from "react";
import { Form, Input, Button, Checkbox, Tag, message, Radio } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./loginFrom.scss";
// import icon from "../../assets/images/u3.png";
import logo from "../../assets/images/login/logo2.png";
import {
  ADMIN,
  COMPANY_ADMIN,
  REPOSITORY_ADMIN,
  PROJECT_ADMIN,
  MAINTENANCE_ADMIN,
} from "../../config/constant";
// import PubSub from "pubsub-js";

export default class LoginFrom extends Component {
  constructor(props) {
    super(props);
    let loginFrom = localStorage.getItem("loginFrom");
    console.log("loginFrom", JSON.parse(loginFrom));
    let data = null;
    if (loginFrom) {
      let { username, password, remember } = JSON.parse(loginFrom);
      data = {
        username,
        password,
        remember,
        role: "",
      };
    } else {
      data = {
        username: "",
        password: "",
        remember: false,
        role: "",
      };
    }
    data.loading = false;
    this.state = data;
  }

  // 登录角色
  roleList = [
    {
      name: "超级管理员",
      value: ADMIN,
    },
    {
      name: "公司管理员",
      value: COMPANY_ADMIN,
    },
    {
      name: "仓库管理员",
      value: REPOSITORY_ADMIN,
    },
    {
      name: "项目管理员",
      value: PROJECT_ADMIN,
    },
    {
      name: "维修管理员",
      value: MAINTENANCE_ADMIN,
    },
  ];

  onFinish = (values) => {
    // console.log("Received values of form: ", values);

    if (values.remember) {
      localStorage.setItem(
        "loginFrom",
        JSON.stringify({
          username: values.username,
          password: values.password,
          remember: values.remember,
          role: values.role,
        })
      );
    } else {
      localStorage.setItem(
        "loginFrom",
        JSON.stringify({
          username: values.username,
          password: "",
          remember: values.remember,
          role: values.role,
        })
      );
    }
    this.setState({
      loading: true,
    });

    this.props.goAdmin(values.username, values.password, values.role);
  };

  componentDidMount() {
    // console.log(this.state);
    let { remember, username, password, role } = this.state;
    let userInfo = localStorage.getItem("userInfo");
    let accessToken = "";
    if (userInfo) {
      let overDate = JSON.parse(userInfo).expired;
      accessToken = JSON.parse(userInfo).accessToken;
      if (overDate * 1000 - Date.now() < 1000) {
        message.error("登录凭证已过期，请重新登录");
        return;
      }
      if (remember && accessToken) {
        this.props.goAdmin(username, password, role);
      }
    }
  }
  changeLoaing = () => {
    this.setState({ loading: false });
  };
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    this.state.role = e.target.value;
  };

  render() {
    let { remember, username, password, loading, role } = this.state;
    return (
      <div className="loginfrom_page">
        <div className="inner_box">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "10px",
            }}
          >
            <img src={logo} alt="" style={{ width: "90px", height: "auto" }} />
          </div>
          <div
            className="loginfrom_page_title"
            style={{ marginBottom: "50px" }}
          >
            欢迎登录型钢管理系统
          </div>
          <Form
            name="normal_login"
            className="login-form"
            onFinish={this.onFinish}
            initialValues={{
              username,
              password,
              remember,
            }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "请输入用户名!" }]}
              key={username}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="用户名"
              />
            </Form.Item>
            {/* <Form.Item
                  name="password"
                  rules={[{ required: true, message: "请输入密码!" }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="密码"
                  />
                </Form.Item> */}
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "请输入密码!",
                },
              ]}
              hasFeedback
              key={password}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            {/* <Form.Item
              name="role"
              rules={[
                {
                  required: true,
                  message: "登录角色",
                },
              ]}
              hasFeedback
            >
              <Radio.Group onChange={this.onChange} value={role}>
                {this.roleList.map((item) => {
                  return (
                    <Radio value={item.value} key={item.value}>
                      {item.name}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item> */}

            <div>
              <Form.Item
                name="remember"
                valuePropName="checked"
                noStyle
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Checkbox>自动登录</Checkbox>
              </Form.Item>
              <div style={{ height: "30px", width: "100%" }}>
                {this.props.loginError !== "" ? (
                  <Tag color="red">{this.props.loginError}</Tag>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Form.Item style={{ marginTop: "10px" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
