import Request from "../../utils/client";

// 获取mac地址
export const AQUIRE_MAC = Request.gql`
  query getDeviceListQuery {
    getDeviceList{
    id
    userInfo{
      id
      name
    }
    mac
    isAble
    }
  }
`;

// 修改状态
export const MODIFY_MAC = Request.gql`
  mutation ($input: EditDeviceInput!){
    editDevice(input: $input)
  }
`;

// 添加设备
export const ADD_MAC = Request.gql`
  mutation ($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      id
    }
  }
`;

// 获取能登录设备的人员列表
export const AQUIRE_PESONAL = Request.gql`
  query {
    getUserListForDevice{
      id
      name
    }
  }
`;
