import React, { Component } from "react";
import { Column } from "@ant-design/charts";
import { message, Empty } from "antd";

import axiosRequest from "../../../../utils/request";
import {
  indexRequestPath,
  AdminIndexRequest,
} from "../../../../request/requestPath";
// import {
//   ADMIN,
//   COMPANY_ADMIN,
//   REPOSITORY_ADMIN,
//   PROJECT_ADMIN,
//   MAINTENANCE_ADMIN,
// } from "../../../../config/constant";
class DemoColumn extends Component {
  state = {
    data: [
      {
        name: "London",
        月份: "Jan.",
        月均降雨量: 18.9,
      },
      {
        name: "London",
        月份: "Feb.",
        月均降雨量: 28.8,
      },
      {
        name: "London",
        月份: "Mar.",
        月均降雨量: 39.3,
      },
      {
        name: "London",
        月份: "Apr.",
        月均降雨量: 81.4,
      },
      {
        name: "London",
        月份: "May",
        月均降雨量: 47,
      },
      {
        name: "London",
        月份: "Jun.",
        月均降雨量: 20.3,
      },
      {
        name: "London",
        月份: "Jul.",
        月均降雨量: 24,
      },
      {
        name: "London",
        月份: "Aug.",
        月均降雨量: 35.6,
      },
      {
        name: "Berlin",
        月份: "Jan.",
        月均降雨量: 12.4,
      },
      {
        name: "Berlin",
        月份: "Feb.",
        月均降雨量: 23.2,
      },
      {
        name: "Berlin",
        月份: "Mar.",
        月均降雨量: 34.5,
      },
      {
        name: "Berlin",
        月份: "Apr.",
        月均降雨量: 99.7,
      },
      {
        name: "Berlin",
        月份: "May",
        月均降雨量: 52.6,
      },
      {
        name: "Berlin",
        月份: "Jun.",
        月均降雨量: 35.5,
      },
      {
        name: "Berlin",
        月份: "Jul.",
        月均降雨量: 37.4,
      },
      {
        name: "Berlin",
        月份: "Aug.",
        月均降雨量: 42.4,
      },
    ],
    isNull: true,
  };
  config = {
    isGroup: true,
    xField: "year",
    yField: "number",
    seriesField: "type",
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };
  // role = "";
  componentDidMount() {
    // let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // this.role = userInfo.role;
  }
  // roleInit(id) {
  //   switch (this.role) {
  //     case ADMIN:
  //       this.adminAquireData(id);
  //       break;
  //     case COMPANY_ADMIN:
  //     case REPOSITORY_ADMIN:
  //     case MAINTENANCE_ADMIN:
  //       this.aquireData(id);
  //       break;
  //     case PROJECT_ADMIN:
  //       // this.maintemnanceAdminInit();
  //       break;

  //     default:
  //       console.log("没有匹配到角色");
  //   }
  // }
  aquireData(repositoryId) {
    axiosRequest
      .post(indexRequestPath.CompanySteelReserveAndLoss, {
        repositoryId,
      })
      .then((res) => {
        if (res.code === 200) {
          this.dataDeal(res.data);
        } else {
          message.error("获取型钢储备及损耗量错误");
        }
      })
      .catch((error) => {
        console.log("获取型钢储备及损耗量error", error);
      });
  }
  adminAquireData(companyId) {
    axiosRequest
      .post(AdminIndexRequest.SteelReserveAndLoss, {
        companyId: companyId * 1,
      })
      .then((res) => {
        if (res.code === 200) {
          this.dataDeal(res.data);
        } else {
          message.error("获取型钢储备及损耗量错误");
        }
      })
      .catch((error) => {
        console.log("超级管理员获取型钢储备及损耗量error", error);
      });
  }
  // 数据处理
  dataDeal(requestData) {
    let loss = [];
    let reserve = [];
    if (requestData) {
      requestData.forEach((item) => {
        loss.push({
          type: "损耗量",
          number: item.loss,
          year: item.year,
        });
        reserve.push({
          type: "储备量",
          number: item.reserve,
          year: item.year,
        });
      });
    }
    // console.log("data", data);
    this.setState({
      data: [...loss, ...reserve],
      isNull: requestData && requestData.length > 0 ? false : true,
    });
  }

  render() {
    let { isNull } = this.state;
    return (
      <div style={{ padding: "0 0 0 20px" }}>
        {isNull ? (
          <div style={{ paddingTop: 100, paddingBottom: 100 }}>
            <Empty />
          </div>
        ) : (
          <Column {...this.config} data={this.state.data} />
        )}
      </div>
    );
  }
}

export default DemoColumn;
