import React, { Component } from "react";
import { Table, Button, Modal, Popconfirm, Select, Input, message } from "antd";
import Request from "../../../utils/client";
import AddProject from "./components/AddProject";
import { format } from "../../../utils/dateFormate";
import root from "../../../config/root";
import {
  // AQUIRE_PROJECT,
  DELETE_PROJECT,
} from "../../../graphql/admin/project";
import axiosRequest from "../../../utils/request";
import { ProjectRequestPath } from "../../../request/requestPath";
import "./project.scss";
const { Option } = Select;
export default class Project extends Component {
  constructor(props) {
    super(props);

    let columns = [
      {
        title: "序号",
        dataIndex: "projectId",
        fixed: "left",
        width: 100,
      },
      {
        title: "项目名称",
        dataIndex: "projectName",
        width: 150,
      },
      {
        title: "所在城市",
        dataIndex: "city",
        width: 100,
      },
      {
        title: "地址",
        dataIndex: "address",
        width: 150,
      },
      {
        title: "负责人",
        dataIndex: "leader",
        width: 150,
      },
      {
        title: "电话",
        dataIndex: "phone",
        width: 150,
      },
      {
        title: "微信",
        dataIndex: "wechat",
        width: 150,
      },
      {
        title: "开始时间",
        dataIndex: "startedAt",
        width: 150,
      },
      {
        title: "结束时间",
        dataIndex: "endDate",
        width: 150,
      },
      {
        title: "备注",
        dataIndex: "remark",
        width: 100,
      },
    ];
    let userInfo = localStorage.getItem("userInfo");
    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify || root[role][pathname].delete) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 250,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {root[role][pathname].modify ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                  style={{ marginBottom: 10 }}
                >
                  编辑
                </Button>
              ) : (
                ""
              )}
              <Button
                type="primary"
                onClick={() => {
                  this.toView(item);
                }}
                style={{ marginLeft: "20px", marginBottom: 10 }}
              >
                查看
              </Button>
              {root[role][pathname].delete ? (
                <Popconfirm
                  title="确定删除吗？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    this.deleteItem(item);
                  }}
                >
                  <Button type="danger" style={{ marginLeft: "20px" }}>
                    删除
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }
    this.state = {
      role,
      modalVisible: false,
      data: [],
      projectData: [], // 存储获取的数据，用于用户搜索时
      title: "添加项目",
      pathname,
      columns,
      searchType: "name",
    };
  }
  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    axiosRequest
      .post(ProjectRequestPath.GetProjectList, {})
      .then((res) => {
        console.log("获取项目", res);
        if (res.code === 200) {
          let list = [];
          res.data.forEach((item, index) => {
            item.key = index + 1;
            item.sort = index + 1;
            let obj = Object.assign({}, item);
            let nameList = [];
            let phoneList = [];
            let wechatList = [];
            item.leaderList.forEach((item2) => {
              nameList.push(item2.name);
              phoneList.push(item2.phone);
              wechatList.push(item2.wechat);
            });
            obj.leader = nameList.join(",");
            obj.phone = phoneList.join(",");
            obj.wechat = wechatList.join(",");
            obj.startedAt = format(item.startedAt);
            obj.endDate = format(item.endedAt);
            obj.city = obj.city.split("/").join("");
            list.push(obj);
          });
          // console.log(list);
          this.setState({ data: list, projectData: list });
        }
      })
      .catch((error) => {
        console.log("获取项目报错");
      });
    // Request.client
    //   .query({
    //     query: AQUIRE_PROJECT,
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     console.log("项目", result);
    //     let list = [];
    //     result.data.getProjectLis.forEach((item, index) => {
    //       item.sort = index + 1;
    //       let obj = Object.assign({}, item);
    //       let nameList = [];
    //       let phoneList = [];
    //       let wechatList = [];
    //       item.leaderList.forEach((item2) => {
    //         nameList.push(item2.name);
    //         phoneList.push(item2.phone);
    //         wechatList.push(item2.wechat);
    //       });
    //       obj.leader = nameList.join(",");
    //       obj.phone = phoneList.join(",");
    //       obj.wechat = wechatList.join(",");
    //       obj.key = item.id;
    //       obj.startedAt = format(item.startedAt);
    //       obj.endDate = format(item.endedAt);
    //       obj.city = obj.city.split("/").join("");
    //       list.push(obj);
    //     });
    //     // console.log(list);
    //     this.setState({ data: list, projectData: list });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     // this.props.history.push({ pathname: "/admin/error/systemerror" });
    //   });
  };
  toView = (item) => {
    localStorage.setItem("projectId", item.projectId);
    this.props.history.push({ pathname: "/admin/project/detail" });
  };
  // 删除项目
  deleteItem = (item) => {
    // console.log(item);
    // return;
    Request.client
      .query({
        query: DELETE_PROJECT,
        variables: {
          input: {
            id: item.projectId,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        message.success("删除项目成功");
        this.aquireData();
      })
      .catch((error) => {
        // console.log(error);
        message.error(error.message);
      });
  };
  // 设置弹窗的显示和关闭
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加项目" }, () => {
      // console.log(this.addProject);
      if (modalVisible) {
        this.addProject.openAddWindows();
        this.addProject.getCompanyUser();
        this.addProject.handlerResetForm();
      }
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加项目") {
      this.addProject.addProject();
    } else {
      this.addProject.modifyProjectSubmit();
    }
  };
  // 关闭弹窗，由AddProject组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  // 修改项目
  modify = (project) => {
    this.setState({ modalVisible: true, title: "编辑项目" }, () => {
      this.addProject.getCompanyUser();
      this.addProject.modifyProject(project);
    });
  };
  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { projectData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    // console.log(this.state.searchType);
    let result = [];
    if (searchType === "name") {
      result = projectData.filter((obj) => {
        return obj.name.indexOf(keyword) !== -1;
      });
    } else if (searchType === "address") {
      result = projectData.filter((obj) => {
        return obj.address.indexOf(keyword) !== -1;
      });
    } else if (searchType === "admin") {
      result = projectData.filter((obj) => {
        return obj.leader.indexOf(keyword) !== -1;
      });
    } else {
      result = projectData.filter((obj) => {
        return obj.city.indexOf(keyword) !== -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "name",
      },
      () => {
        this.aquireData();
      }
    );
  };
  render() {
    let { title, pathname, role, columns, data } = this.state;
    return (
      <div className="project_box">
        <div className="btn_box">
          {/* <Button
            type="primary"
            style={{
              marginLeft: "20px",
              background: "#A06921",
              border: "none",
            }}
          >
            导出
          </Button> */}
          {/* {root[pathname]} */}
          <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div>
          <div>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="name">项目名称</Option>
              <Option value="city">所在城市</Option>
              <Option value="address">地址</Option>
              <Option value="admin">负责人</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={() => this.setModalVisible(false)}
        >
          <AddProject
            ref={(addProject) => {
              this.addProject = addProject;
            }}
            closeModal={this.closeModal}
            aquireData={this.aquireData}
          ></AddProject>
        </Modal>
      </div>
    );
  }
}
