/**
 * apollo对象
 */

import { createUploadLink } from "apollo-upload-client";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let userInfo = localStorage.getItem("userInfo");
  let token = "";
  if (userInfo) {
    token = JSON.parse(userInfo).accessToken;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const URI = "http://admin0502.42.huizhouyiren.com/api/query";
// const URI = "https://a0502.42.huizhouyiren.com/query";
const httpLink = createHttpLink({
  uri: URI,
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const uploadClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: createUploadLink({
    uri: URI,
  }),
});

// eslint-disable-next-line import/no-anonymous-default-export
export default { client, gql };
