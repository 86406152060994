import React, { Component } from "react";
import { Layout, Menu, Dropdown, Select, Popover, message } from "antd";
// Modal;
import { connect } from "react-redux";
import { addSteelAction } from "../../redux/actions/steel";
import { DownOutlined } from "@ant-design/icons";
import LeftMenu from "../../components/menu/LeftMenu";
import Router from "./components/router/Router";
import Tabs from "./components/tabs/Tabs";
import Loading from "../../components/loading/Loading";
import PubSub from "pubsub-js"; //引入
import "./admin.scss";
import Request from "../../utils/client";
import { AQUIRE_COMPANY_SIMPLE } from "../../graphql/admin/company";
import { ADMIN, PROJECT_ADMIN } from "../../config/constant";
import { REPOSITORY } from "../../graphql/admin/index";
// import List from "./components/list/List";
import CustomIcon from "../../components/customIcon/CustomIcon";

import logo from "../../assets/images/admin/admin_logo.png";

import { resetAccessoriesAction } from "../../redux/actions/accessories";
import { resetDemandAction } from "../../redux/actions/demand";
import { resetEquipmentAction } from "../../redux/actions/equipment";
import { resetSteelAction } from "../../redux/actions/steel";

import axiosRequest from "../../utils/request";
import {
  ProjectRequestPath,
  indexRequestPath,
} from "../../request/requestPath";

const { Header, Sider, Content } = Layout;
const { Option } = Select;
class admin extends Component {
  state = {
    collapsed: false,
    role: "",
    isloading: false,
    repositoryChild: [], // 仓库列表
    repositorySelect: "", // 选择的仓库
    companyChild: [], // 公司列表
    companySelect: "", //  选择的公司
    projectChild: [], // 项目列表
    projectSelect: "", // 选择的项目
    isModalVisible: false,
  };
  componentDidMount() {
    let userInfo = localStorage.getItem("userInfo");
    if (!userInfo) {
      this.props.history.push({ pathname: "/" });
    } else {
      let { role } = JSON.parse(userInfo);
      this.setState({ role });
      if (role === ADMIN) {
        this.aquireCompany();
      } else if (role === PROJECT_ADMIN) {
        this.getProject();
      } else {
        this.aquireResitory();
      }
    }
    PubSub.unsubscribe("collapsed"); //销毁订阅 防止多次触发
    // 菜单栏的显示和隐藏，由 Tabs.jsx 组件触发
    PubSub.subscribe("collapsed", (_, collapsed) => {
      this.setState({
        collapsed,
      });
    }); //订阅
  }

  componentWillUnmount() {
    localStorage.removeItem("refreshIndexData");
  }

  // 获取公司列表
  aquireCompany = () => {
    Request.client
      .query({
        query: AQUIRE_COMPANY_SIMPLE,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        let { getAllCompany } = result.data;
        let children = [];
        getAllCompany.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState(
          {
            companyChild: children,
            companySelect:
              getAllCompany.length > 0 ? getAllCompany[0].name : "",
          },
          () => {
            if (getAllCompany.length > 0) {
              this.refreshIndexData("company", getAllCompany[0].id);
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取仓库列表
  aquireResitory() {
    axiosRequest
      .post(indexRequestPath.GetRepository)
      .then((res) => {
        if (res.code === 200) {
          let data = res.data;
          let children = [];
          // children.push(<Option key="all">仓库（全部）</Option>);
          data.forEach((item) => {
            item.id = item.repositoryId;
            item.name = item.repositoryName;
            children.push(<Option key={item.id}>{item.name}</Option>);
          });
          this.setState(
            {
              repositoryChild: children,
              repositorySelect: data.length > 0 ? data[0].id + "" : "",
            },
            () => {
              // eslint-disable-next-line no-unused-expressions
              data.length > 0 ? this.handleChange(data[0].id + "") : "";
            }
          );
        } else {
          message.error(res.code);
        }
      })
      .catch((error) => {
        console.log("获取仓库失败", error);
      });
  }

  // 获取项目列表
  getProject() {
    axiosRequest
      .post(ProjectRequestPath.GetProjectList, {})
      .then((res) => {
        if (res.code === 200) {
          // console.log("获取项目", res.data);
          let children = [];
          res.data.forEach((item) => {
            children.push(
              <Option key={item.projectId}>{item.projectName}</Option>
            );
          });
          this.setState(
            {
              projectChild: children,
              projectSelect:
                res.data.length > 0 ? res.data[0].projectId + "" : "",
            },
            () => {
              // eslint-disable-next-line no-unused-expressions
              res.data.length > 0
                ? this.handleProjectChange(res.data[0].projectId + "")
                : "";
            }
          );
        }
      })
      .catch((error) => {
        console.log("获取项目报错", error);
      });
  }

  // 选择仓库触发的事件
  handleChange = (value) => {
    this.setState(
      {
        repositorySelect: value,
      },
      () => {
        // 当当前页面是index页面时，使用消息订阅的方式
        this.refreshIndexData("repository", value);
        localStorage.setItem(
          "refreshIndexData",
          JSON.stringify({
            type: "repository",
            value,
          })
        );
      }
    );
  };
  // 选择公司触发的事件
  handleChangeCompany = (value) => {
    this.setState(
      {
        companySelect: value,
      },
      () => {
        // 当当前页面是index页面时，使用消息订阅的方式
        this.refreshIndexData("company", value);
        // 当前页面不是index页面时
        localStorage.setItem(
          "refreshIndexData",
          JSON.stringify({
            type: "company",
            value,
          })
        );
        // if (this.props.location.pathname === "/admin/index") {
        // } else {
        //   this.props.history.push({ pathname: "/admin/index" });
        // }
      }
    );
  };
  // 选择项目触发的事件
  handleProjectChange = (value) => {
    this.setState(
      {
        projectSelect: value,
      },
      () => {
        this.refreshIndexData("project", value);
        localStorage.setItem(
          "refreshIndexData",
          JSON.stringify({
            type: "project",
            value,
          })
        );
        // if (this.props.location.pathname === "/admin/index") {

        // } else {

        //   this.props.history.push({ pathname: "/admin/index" });
        // }
      }
    );
  };

  refreshIndexData(type, value) {
    // 通知Index页面更新数据
    PubSub.publish("refreshData", {
      type,
      value,
    });
  }

  menu = (
    <Menu>
      <Menu.Item
        key="1"
        style={{ textAlign: "center", width: "100%", display: "inline-block" }}
        onClick={() => {
          this.modify();
        }}
      >
        修改密码
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="3"
        style={{ textAlign: "center", width: "100%", display: "inline-block" }}
        onClick={() => {
          this.loginOut();
        }}
      >
        退出
      </Menu.Item>
    </Menu>
  );
  content = (
    <div className="cart_icon">
      <p>
        需求单号：
        {this.props.demand.length > 0 ? this.props.demand[0].orderNo : ""}
      </p>
      <p>
        型钢：
        <span
          style={{
            color: "red",
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          {this.props.steel.length}
        </span>
      </p>
      <p>
        设备：
        <span
          style={{
            color: "red",
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          {this.props.equipment.length}
        </span>
      </p>
      <p>
        配件：
        <span
          style={{
            color: "red",
            fontSize: 15,
            fontWeight: "bold",
          }}
        >
          {this.props.accessories.length}
        </span>
      </p>
    </div>
  );

  // 退出
  loginOut = () => {
    this.props.resetSteelAction();
    this.props.resetEquipmentAction();
    this.props.resetAccessoriesAction();
    this.props.resetDemandAction();
    this.props.history.push({ pathname: "/" });
    localStorage.removeItem("userInfo");
  };

  goPage = (path) => {
    this.props.history.push({ pathname: path });
  };
  // 修改密码
  modify = () => {
    this.props.history.push({ pathname: "/admin/modify" });
  };
  // 显示确认需求单弹窗
  showModel = () => {
    this.setState(
      {
        isModalVisible: true,
      },
      () => {
        this.list.getDemand();
      }
    );
  };
  // 隐藏确认需求单弹窗
  hiddenModel = () => {
    this.setState({
      isModalVisible: false,
    });
  };
  // 点击确认需求单弹窗的确认按钮
  confirmDemand = () => {
    this.list.confirmDemand();
  };
  // 跳转到购物车页面
  goCartPage = () => {
    this.props.history.push({ pathname: "/admin/cart" });
  };

  render() {
    let {
      role,
      repositoryChild,
      repositorySelect,
      companyChild,
      companySelect,
      // isModalVisible,
      projectChild,
      projectSelect,
    } = this.state;
    let { steel, accessories, equipment, demand } = this.props;
    return (
      <div style={{ height: "100vh" }} className="admin_pages">
        <Layout style={{ height: "100vh" }}>
          <Header
            className="site-layout-background admin_header"
            style={{
              padding: 0,
              paddingLeft: "30px",
              paddingRigth: "30px",
            }}
          >
            <div className="admin_title">
              <img src={logo} alt="" style={{ width: "140px" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                flex: 1,
                padding: "0 20px",
              }}
            >
              {role === ADMIN ? (
                <Select
                  value={companySelect}
                  style={{ width: 200 }}
                  onChange={this.handleChangeCompany}
                >
                  {companyChild}
                </Select>
              ) : role === PROJECT_ADMIN ? (
                <Select
                  value={projectSelect}
                  style={{ width: 200 }}
                  onChange={this.handleProjectChange}
                >
                  {projectChild}
                </Select>
              ) : (
                <Select
                  value={repositorySelect}
                  style={{ width: 200 }}
                  onChange={this.handleChange}
                >
                  {repositoryChild}
                </Select>
              )}
            </div>
            <div
              className="avatars_box"
              style={{
                display: "flex",
                alignContent: "center",
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              {demand.length > 0 ||
              steel.length > 0 ||
              accessories.length > 0 ||
              equipment.length > 0 ? (
                <div
                  style={{
                    marginRight: 20,
                    display: "flex",
                    alignContent: "center",
                  }}
                  // onClick={this.showModel}
                  onClick={this.goCartPage}
                >
                  <Popover
                    content={
                      <div className="cart_icon">
                        <p>
                          需求单号：
                          {demand.length > 0 ? demand[0].orderNo : ""}
                        </p>
                        <p>
                          型钢：
                          <span
                            style={{
                              color: "red",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            {steel.length}
                          </span>
                        </p>
                        <p>
                          设备：
                          <span
                            style={{
                              color: "red",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            {equipment.length}
                          </span>
                        </p>
                        <p>
                          配件：
                          <span
                            style={{
                              color: "red",
                              fontSize: 15,
                              fontWeight: "bold",
                            }}
                          >
                            {this.props.accessories.length}
                          </span>
                        </p>
                      </div>
                    }
                    trigger="hover"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                        }}
                      >
                        <CustomIcon
                          icon="icon-gouwuchekong"
                          color="red"
                        ></CustomIcon>
                        <span
                          style={{
                            color: "red",
                            fontSize: 15,
                            fontWeight: "bold",
                          }}
                        >
                          (
                          {steel.length + accessories.length + equipment.length}
                          )
                        </span>
                      </div>
                    </div>
                  </Popover>
                </div>
              ) : (
                ""
              )}

              <Dropdown overlay={this.menu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  {role} <DownOutlined />
                </a>
              </Dropdown>
            </div>
          </Header>
          <Layout className="site-layout ">
            <Sider
              trigger={null}
              collapsible
              collapsed={this.state.collapsed}
              theme={"dark"}
            >
              {/* <div className="logo">
                <img src={icon1} alt="" />
              </div> */}
              <LeftMenu goPage={this.goPage}></LeftMenu>
            </Sider>

            <Content
              className="site-layout-background"
              style={{
                background: "none",
                minHeight: 280,
              }}
            >
              <div className="tabs_menu">
                <Tabs></Tabs>
              </div>
              <div className="content">
                <Router></Router>
                <Loading></Loading>
              </div>
            </Content>
          </Layout>
        </Layout>
        {/* <Modal
          title="需求单材料清单"
          visible={isModalVisible}
          onOk={this.confirmDemand}
          onCancel={this.hiddenModel}
          width="90%"
        >
          <List ref={(list) => (this.list = list)}></List>
        </Modal> */}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    steel: state.steel,
    accessories: state.accessories,
    equipment: state.equipment,
    demand: state.demand,
  }),
  {
    addSteelAction,
    resetSteelAction,
    resetEquipmentAction,
    resetAccessoriesAction,
    resetDemandAction,
  }
)(admin);
