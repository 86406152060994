import Request from "../../utils/client";

// 获取人员列表
export const AQUIRE_PERSONEL = Request.gql`
  query {
    getCompanyUser{
        id
        role{
          id
          name
          tag
        }
        name
        phone
        wechat
        avatar{
          id
          url
        }
        isAble
        company{
          id
          name
        }
    }
  }
`;
// 删除人员
export const DELETE_PERSONEL = Request.gql`
  mutation deleteCompanyUserMutation($uid: Int!){
    deleteCompanyUser(uid: $uid)
  }
`;
// 添加人员
export const ADD_PERSONEL = Request.gql`
  mutation createUserMutation($input: CreateCompanyUserInput!){
    createCompanyUser(input: $input){
      id
      role {
        id
        name
        tag
      }
      phone
      wechat
      avatar{
        id
        url
      }
      isAble
    }
  }
`;

// 修改人员
export const MODIFY_PERSONEL= Request.gql`
  mutation editCompanyUserMutaion ($input: EditCompanyUserInput!){
    editCompanyUser(input: $input) {
      id
      role {
        id
        name
        tag
      }
      phone
      wechat
      avatar {
        id
        url
      }
      isAble
    }
  }
`;
