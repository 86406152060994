import Request from "../../utils/client";

// 获取维修状态
export const STATE_MAINTENANCE = Request.gql`
  query {
    getStateForMaintenance {
      state
      desc
    }
  }
`;

// 获取状态列表
export const STATUS_LIST = Request.gql`
  query {
    getProjectSteelStateList{
      state
      desc
    }
  }
`;

// 获取规格列表
export const SIZE_LIST = Request.gql`
  query {
    getSpecification {
      id
      specification
    }
  }
`;

// 获取仓库列表
export const REPOSITORY_LIST = Request.gql`
  query {
    getRepositoryList {
      id 
      name
    }
  }
`;

// 获取项目列表
export const PROJECT_LIST = Request.gql`
  query {
    getProjectLis {
      id
      name
    }
  }
`;
