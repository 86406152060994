import Request from "../../utils/client";

// 获取管理员
export const AQUIRE_ADMINISTRATOR = Request.gql`
    query getCompanyUserQuery($input: GetCompanyUserInput!) {
    getCompanyUser(input: $input){
        id
        role {
        id
        tag
        name
        }
        name
        phone
        wechat
        avatar{
        id
        url
        }
        isAble
    }
    }
`;