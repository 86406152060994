import React, { Component } from "react";
import { Table, Button, Popconfirm, Modal, message, Select, Input } from "antd";
import Request from "../../../utils/client";
import {
  AQUIRE_PERSONEL,
  DELETE_PERSONEL,
} from "../../../graphql/admin/personal.js";
import root from "../../../config/root";
import AddPersonal from "./components/AddPersonal";
import axiosRequest from "../../../utils/request";
import { companyRequestPath } from "../../../request/requestPath";

import "./personnel.scss";

const { Option } = Select;
export default class Personnel extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: "序号",
        dataIndex: "userId",
        fixed: "left",
        width: 100,
      },
      {
        title: "人员名称",
        dataIndex: "userName",
        width: 150,
      },
      {
        title: "角色",
        dataIndex: "roleName",
        width: 150,
      },
      {
        title: "电话",
        dataIndex: "phone",
        width: 150,
      },
      {
        title: "微信",
        dataIndex: "wechat",
        width: 150,
      },
      {
        title: "头像",
        dataIndex: "avatar",
        width: 150,
        // eslint-disable-next-line jsx-a11y/alt-text
        render: (text) => <img src={text} style={{ width: "130px" }} />, //这里放后台返回的图片的路径或者整个<img/>
      },

      {
        title: "启用状态",
        dataIndex: "status",
        align: "center",
        width: 130,
      },
      // {
      //   title: "所属公司",
      //   dataIndex: "belongCompany",
      //   width: 150,
      // },
    ];
    let userInfo = localStorage.getItem("userInfo");

    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify || root[role][pathname].delete) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {root[role][pathname].modify ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  编辑
                </Button>
              ) : (
                ""
              )}
              {root[role][pathname].delete ? (
                <Popconfirm
                  title="确定删除吗？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    this.deleteItem(item);
                  }}
                >
                  <Button type="danger" style={{ marginLeft: "20px" }}>
                    删除
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }

    this.state = {
      data: [],
      userData: [],
      columns,
      role,
      pathname,
      modalVisible: false,
      title: "添加人员",
      searchType: "name",
    };
  }
  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    axiosRequest
      .post(companyRequestPath.GetCompanyUser, {})
      .then((res) => {
        if (res.code === 200) {
          let data = res.data ? res.data : [];
          data.forEach((item, index) => {
            item.key = index;
            item.sort = index + 1;
            item.status = item.isAble ? "正常" : "已停用";
          });
          this.setState({ data, userData: data });
        } else {
          message.error("获取公司人员失败");
        }
      })
      .catch((error) => {
        console.log("获取公司人员失败");
      });
    // return;

    // Request.client
    //   .query({
    //     query: AQUIRE_PERSONEL,
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     console.log("用户数据", result);
    //     let { getCompanyUser } = result.data;
    //     getCompanyUser.forEach((item, index) => {
    //       item.sort = index + 1;
    //       item.key = item.id;
    //       item.avatarPic = item.avatar.url;
    //       item.belongCompany = item.company.name;
    //       item.roleName = item.role.name;
    //       item.status = item.isAble ? "正常" : "已停用";
    //     });
    //     this.setState({ data: getCompanyUser, userData: getCompanyUser });
    //   })
    //   .catch((error) => {
    //     // this.props.history.push({ pathname: "/admin/error/systemerror" });
    //   });
  };
  deleteItem = (item) => {
    Request.client
      .mutate({
        mutation: DELETE_PERSONEL, // 封装好的GraphQL,
        variables: {
          uid: item.userId,
        },
      })
      .then((result) => {
        message.success("删除成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加人员", type: "add" }, () => {
      this.AddPersonal.handlerResetForm();
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加人员") {
      this.AddPersonal.addPersonal();
    } else {
      this.AddPersonal.modifyProjectSubmit();
    }
  };

  // 关闭弹窗，由AddPersonal组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  // 修改项目
  modify = (project) => {
    this.setState(
      { modalVisible: true, title: "编辑人员", type: "modify" },
      () => {
        this.AddPersonal.modify(project);
      }
    );
  };
  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { userData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    // console.log(this.state.searchType)
    let result = [];
    if (searchType === "name") {
      result = userData.filter((obj) => {
        return obj.userName.indexOf(keyword) !== -1;
      });
    } else if (searchType === "role") {
      result = userData.filter((obj) => {
        return obj.roleName.indexOf(keyword) !== -1;
      });
    } else {
      result = userData.filter((obj) => {
        return obj.phone.indexOf(keyword) !== -1;
      });
    }
    // else if (searchType === "company") {
    //   result = userData.filter((obj) => {
    //     return obj.company.name.indexOf(keyword) !== -1;
    //   });
    // }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "name",
      },
      () => {
        this.aquireData();
      }
    );
  };
  render() {
    let { data, role, pathname, columns, title } = this.state;
    return (
      <div className="personnel_box">
        <div className="btn_box">
          {/* <Button
            type="primary"
            style={{ background: "#A06921", border: "none" }}
          >
            导出
          </Button> */}
          <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div>
          <div>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="name">用户名称</Option>
              <Option value="role">用户角色</Option>
              {/* <Option value="compayn">所属公司</Option> */}
              <Option value="phone">用户电话</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={this.closeModal}
        >
          <AddPersonal
            ref={(AddPersonal) => {
              this.AddPersonal = AddPersonal;
            }}
            closeModal={this.closeModal}
            type={this.state.type}
          ></AddPersonal>
        </Modal>
      </div>
    );
  }
}
