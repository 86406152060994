import { createStore, combineReducers } from "redux";

import accessoriesReducer from "./reducers/accessories";
import equipmentReducer from "./reducers/equipment";
import steelReducer from "./reducers/steel";
import demandReducer from "./reducers/demand";
const allReducer = combineReducers({
  accessories: accessoriesReducer,
  equipment: equipmentReducer,
  steel: steelReducer,
  demand: demandReducer,
});

export default createStore(allReducer);
