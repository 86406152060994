/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { message } from "antd";
import Login from "./pages/login/Login";
import Admin from "./pages/admin/Admin";
// import PubSub from "pubsub-js";

import "./App.css";
function App() {
  // token be exitits
  localStorage.setItem("initFlag", "true"); // 修改Index页面订阅事件的标识

  let ticker;
  let timer;
  useEffect(() => {
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      let overDate = JSON.parse(userInfo).expired;
      if (overDate * 1000 - Date.now() > 10000) {
        ticker = setTimeout(() => {
          message.info("登录凭证已过期，请重新登录");
          timer = setTimeout(() => {
            window.location.href = "/login";
            localStorage.removeItem("userInfo");
            ticker && clearTimeout(ticker);
            timer && clearTimeout(timer);
          }, 2000);
        }, overDate * 1000 - Date.now() - 10000);
      }
    }

    return () => {
      // ticker && clearTimeout(ticker);
      // timer && clearTimeout(timer);
    };
  }, []);

  return (
    <div className="App">
      <Link to="/login"></Link>
      <Link to="/admin"></Link>

      <Switch>
        <Route path="/login" component={Login}></Route>
        <Route path="/admin" component={Admin}></Route>
        <Redirect to="/login"></Redirect>
      </Switch>
    </div>
  );
}

export default App;
