import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Form, Input, Select, message } from "antd";
// import Request from "../../../../utils/client";
// import { ADD_PART, MODIFY_SIZE } from "../../../../graphql/admin/clock";
import axiosRequest from "../../../../utils/request";
import { clockRequestPath } from "../../../../request/requestPath";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddAccessories(props, ref) {
  let [id, setId] = useState("");
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => {
    return {
      // 添加
      addExpress() {
        form
          .validateFields()
          .then((res) => {
            let { partName, specification, weight, isDefault, price } =
              form.getFieldsValue();
            let newIsDefault = isDefault === "false" ? false : true;
            axiosRequest
              .post(clockRequestPath.CreatePartInfo, {
                isDefault: newIsDefault,
                partName,
                specification,
                weight: parseFloat(weight),
                price: price ? price * 1 : 0,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("添加成功");
                  form.resetFields();
                  props.closeModal();
                  props.aquireData();
                } else {
                  message.error("添加失败");
                }
              })
              .catch((error) => {
                console.log("添加配件报错", res);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },

      // 修改
      modify(obj) {
        console.log(obj);
        let {
          part_name,
          // identification,
          specification,
          weight,
          isDefault,
          id,
          price,
        } = obj;
        let newIsDefault = isDefault ? "是" : "否";
        setId(id);
        form.setFieldsValue({
          partName: part_name,
          // identification,
          specification,
          weight,
          isDefault: newIsDefault,
          price,
        });
      },
      // 修改项目提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let {
              partName,
              // identification,
              specification,
              weight,
              isDefault,
              price,
            } = form.getFieldsValue();
            let newIsDefault = isDefault === "否" ? false : true;
            axiosRequest
              .post(clockRequestPath.EditPart, {
                isDefault: newIsDefault,
                partName,
                // identification,
                specification,
                weight: parseFloat(weight),
                id,
                price: price ? price * 1 : 0,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("修改成功");
                  form.resetFields();
                  props.closeModal();
                }
              })
              .catch((error) => {
                console.log("修改配件报错", error);
              });
            // Request.client
            //   .mutate({
            //     mutation: MODIFY_SIZE, // 封装好的GraphQL,
            //     variables: {
            //       input: {
            // isDefault: newIsDefault,
            // partName,
            // // identification,
            // specification,
            // weight: parseFloat(weight),
            // id,
            //       },
            //     },
            //   })
            //   .then((result) => {
            //     console.log(result);
            //     message.success("修改成功");
            //     form.resetFields();
            //     props.closeModal();
            //   })
            //   .catch((error) => {
            //     message.error(error.message);
            //     console.log(error);
            //   });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handlerResetForm() {
        form.resetFields();
      },
    };
  });

  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="partName"
          label="配件名称"
          rules={[{ required: true, message: "请输入配件名称" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        {/* <Form.Item
          name="identification"
          label="设备识别码"
          rules={[{ required: true, message: "请输入设备识别码" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item> */}
        <Form.Item
          name="specification"
          label="配件规格"
          rules={[{ required: true, message: "配件规格" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>

        <Form.Item
          name="weight"
          label="重量（t）"
          rules={[{ required: true, message: "请输入重量" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="price"
          label="价格（万元）"
          rules={[{ required: false, message: "请输入价格" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="isDefault"
          label="是否默认"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择是否默认",
            },
          ]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="是否默认"
            onChange={handleChange}
          >
            <Option value="true">是</Option>
            <Option value="false">否</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddAccessories);
