import React, { Component } from "react";
import { Table, Button, Popconfirm, Modal, message, Select, Input } from "antd";
import Request from "../../../utils/client";
import AddMaintenance from "./components/AddMaintenance";
import {
  // AQUIRE_MAINTENANCE,
  DELETE_MAINTENANCE,
} from "../../../graphql/admin/maintenance";
import axiosRequest from "../../../utils/request";
import { maintenanceRequestPath } from "../../../request/requestPath";
import root from "../../../config/root";
import "./maintenance.scss";
const { Option } = Select;
export default class log extends Component {
  constructor(props) {
    super(props);

    let columns = [
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "维修厂名称",
        dataIndex: "maintenanceName",
        width: 150,
      },
      {
        title: "地址",
        dataIndex: "address",
        width: 150,
      },
      {
        title: "管理员及联系方式",
        dataIndex: "adminStr",
        width: 150,
      },
      // {
      //   title: "电话",
      //   dataIndex: "phone",
      //   width: 150,
      // },
      // {
      //   title: "微信",
      //   dataIndex: "wechat",
      //   width: 150,
      // },
      // {
      //   title: "数量（根）",
      //   dataIndex: "total",
      //   width: 150,
      // },
      // {
      //   title: "重量（吨）",
      //   dataIndex: "weightTotal",
      //   width: 150,
      // },

      {
        title: "备注",
        dataIndex: "remark",
        width: 150,
      },
      {
        title: "状态",
        dataIndex: "status",
        width: 150,
      },
    ];
    let userInfo = localStorage.getItem("userInfo");
    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify || root[role][pathname].delete) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {root[role][pathname].modify ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  编辑
                </Button>
              ) : (
                ""
              )}
              {root[role][pathname].delete ? (
                <Popconfirm
                  title="确定删除吗？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    this.deleteItem(item);
                  }}
                >
                  <Button type="danger" style={{ marginLeft: "20px" }}>
                    删除
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }
    this.state = {
      role,
      modalVisible: false,
      data: [],
      mainData: [], // 存储获取的数据，用于用户搜索时
      title: "添加维修厂",
      pathname,
      columns,
      searchType: "name",
    };
  }
  componentDidMount() {
    // console.log("维修厂管理");
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    // console.log("维修厂管理请求");
    axiosRequest
      .post(maintenanceRequestPath.GetMaintenanceList)
      .then((res) => {
        // console.log("获取维修厂列表", res);
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.key = item.maintenanceId;
            item.sort = item.maintenanceId;
            item.status = item.isAble ? "正常" : "禁用";
            let adminArray = [];
            item.admin.forEach((admin) => {
              adminArray.push(admin.username + `(${admin.phone})`);
            });
            item.adminStr = adminArray.join(",");
          });
          this.setState({
            data: res.data,
            mainData: res.data,
          });
        }
      })
      .catch((error) => {
        console.log("获取维修厂报错");
      });
  };
  deleteItem = (item) => {
    // console.log(item);
    Request.client
      .mutate({
        mutation: DELETE_MAINTENANCE, // 封装好的GraphQL,
        variables: {
          input: {
            id: item.maintenanceId,
          },
        },
      })
      .then((result) => {
        message.success("删除成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  // 设置弹窗的显示和关闭
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加维修厂" }, () => {
      // console.log(this.addMaintenanceCom);
      if (modalVisible) {
        this.addMaintenanceCom.getMaintenancePersonenance();
        this.addMaintenanceCom.handlerResetForm();
      }
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title == "添加维修厂") {
      this.addMaintenanceCom.addExpress();
    } else {
      this.addMaintenanceCom.modifyProjectSubmit();
    }
  };

  // 关闭弹窗，由addMaintenanceCom组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  // 修改项目
  modify = (project) => {
    // console.log("编辑维修厂", project);
    this.setState({ modalVisible: true, title: "编辑维修厂" }, () => {
      this.addMaintenanceCom.getMaintenancePersonenance();
      this.addMaintenanceCom.modify(project);
    });
  };
  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { mainData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }

    // console.log(this.state.searchType);
    let result = [];
    if (searchType === "name") {
      result = mainData.filter((obj) => {
        return obj.name.indexOf(keyword) != -1;
      });
    } else if (searchType === "address") {
      result = mainData.filter((obj) => {
        return obj.address.indexOf(keyword) != -1;
      });
    } else if (searchType === "admin") {
      result = mainData.filter((obj) => {
        return obj.admin_name.indexOf(keyword) != -1;
      });
    } else if (searchType === "phone") {
      result = mainData.filter((obj) => {
        return obj.admin_phone.indexOf(keyword) != -1;
      });
    } else {
      result = mainData.filter((obj) => {
        return obj.status.indexOf(keyword) != -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "name",
      },
      () => {
        this.aquireData();
      }
    );
  };
  render() {
    let { title, pathname, role, columns, data } = this.state;
    return (
      <div className="maintenance_box">
        <div className="btn_box">
          {/* <Button
            type="primary"
            style={{ background: "#A06921", border: "none" }}
          >
            导出
          </Button> */}
          <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div>
          <div>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="name">维修厂名称</Option>
              <Option value="address">地址</Option>
              <Option value="admin">管理员名称</Option>
              <Option value="phone">电话</Option>
              <Option value="status">状态</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={this.closeModal}
        >
          <AddMaintenance
            ref={(addMaintenanceCom) => {
              this.addMaintenanceCom = addMaintenanceCom;
            }}
            closeModal={this.closeModal}
          ></AddMaintenance>
        </Modal>
      </div>
    );
  }
}
