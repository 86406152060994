import React, { Component } from "react";
import { Table, Button, Popconfirm, Modal, message } from "antd";
import Request from "../../../utils/client";
import { GET_MESSAGE } from "../../../graphql/admin/messgae.js";

import "./message.scss";
import ModifyMessage from "./components/ModifyMessage";

export default class Personnel extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: "accesskey",
        dataIndex: "accessKey",
        width: 150,
      },
      {
        title: " accessSecreptkey ",
        dataIndex: "accessSecretKey",
        width: 150,
      },
      {
        title: "签名",
        dataIndex: "sign",
        width: 150,
      },
      {
        title: "模板",
        dataIndex: "template",
        width: 150,
      },
    ];

    this.state = {
      data: [],
      columns,
      modalVisible: false,
      loading: true,
    };
  }
  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    Request.client
      .query({
        query: GET_MESSAGE,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        let arr = [];
        result.data.getSMSConfig.key = 1;
        arr.push(result.data.getSMSConfig);
        this.setState({ data: arr, loading: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加人员", type: "add" });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    this.modifyMessage.modifyProjectSubmit();
  };

  // 关闭弹窗，由modifyMessage组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  // 修改项目
  modify = (project) => {
    this.setState({ modalVisible: true }, () => {
      this.modifyMessage.modify(this.state.data[0]);
    });
  };

  render() {
    let { data, loading, columns } = this.state;
    return (
      <div className="message_box">
        <div className="btn_box">
          {/* <Button
            type="primary"
            style={{ background: "#A06921", border: "none" }}
          >
            导出
          </Button> */}
          <Button type="primary" onClick={this.modify}>
            修改
          </Button>
        </div>
        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
            loading={loading}
          />
        </div>
        <Modal
          title="修改短信配置"
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={this.closeModal}
        >
          <ModifyMessage
            ref={(modifyMessage) => {
              this.modifyMessage = modifyMessage;
            }}
            closeModal={this.closeModal}
            type={this.state.type}
          ></ModifyMessage>
        </Modal>
      </div>
    );
  }
}
