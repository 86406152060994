/**
 * 设备历史信息
 */
import React, { Component } from "react";
import { Table, Button, Popconfirm, Modal, message, Select, Input } from "antd";
import Request from "../../../utils/client";
import root from "../../../config/root";
import AddWarehousel from "./components/AddWarehousel";
import {
  AQUIRE_WAREHOUSE,
  DELETE_WAREHOUSE,
} from "../../../graphql/admin/warehouse";
import moment from "moment";
import axiosRequest from "../../../utils/request";
import { warehouseRequestPath } from "../../../request/requestPath";

import "./warehouse.scss";
const { Option } = Select;
export default class log extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "识别码",
        dataIndex: "identifier",
        width: 150,
        // ...this.getColumnSearchProps("identifier"),
      },
      {
        title: "型钢编码",
        dataIndex: "code",
        width: 200,
        // ...this.getColumnSearchProps("code"),
        // specify the condition of filtering result
        // here is that finding the name started with `value`
      },
      // { title: "仓库", dataIndex: "repositoryName", width: 150 },
      {
        title: "规格尺寸",
        dataIndex: "specification",
        width: 150,
        // ...this.getColumnFilterProps("spec"),
      },
      // {
      //   title: "维修经历",
      //   dataIndex: "steelInMaintenance",
      //   width: 150,
      //   render: (item) => (
      //     <div>
      //       {item.length > 0 ? (
      //         <Button
      //           type="primary"
      //           onClick={() => {
      //             this.toView(item);
      //           }}
      //         >
      //           查看
      //         </Button>
      //       ) : (
      //         ""
      //       )}
      //       {item.length === 0 ? <Button type="error">暂无</Button> : ""}
      //     </div>
      //   ),
      // },
      // {
      //   title: "项目经历",
      //   dataIndex: "steelInProject",
      //   width: 200,
      //   render: (steelInProject) => (
      //     <div>
      //       {steelInProject.length > 0 ? (
      //         <div>
      //           {steelInProject.map((item, index) => {
      //             return (
      //               <p key={index}>
      //                 <span>
      //                   {moment(item.outRepositoryAt).format("YYYY-MM-DD")}
      //                 </span>
      //                 <span>{item.projectName}</span>
      //               </p>
      //             );
      //           })}
      //         </div>
      //       ) : (
      //         <div>暂无数据</div>
      //       )}
      //     </div>
      //   ),
      // },
      // {
      //   title: "项目经历",
      //   dataIndex: "steelInProject",
      //   width: 200,
      //   render: (item) => (
      //     <div>
      //       {item.length > 0 ? (
      //         <Button
      //           type="primary"
      //           onClick={() => {
      //             this.toViewProject(item);
      //           }}
      //         >
      //           查看
      //         </Button>
      //       ) : (
      //         ""
      //       )}
      //       {item.length === 0 ? <Button type="error">暂无</Button> : ""}
      //     </div>
      //   ),
      // render: (steelInProject) => (
      //   <div>
      //     {steelInProject.length > 0 ? (
      //       <div>
      //         {steelInProject.map((item, index) => {
      //           return (
      //             <p key={index}>
      //               <span>
      //                 {moment(item.outRepositoryAt).format("YYYY-MM-DD")}
      //               </span>
      //               <span>{item.projectName}</span>
      //             </p>
      //           );
      //         })}
      //       </div>
      //     ) : (
      //       <div>暂无数据</div>
      //     )}
      //   </div>
      // ),
      // },
      {
        title: "周转次数",
        dataIndex: "turnover",
        width: 100,
      },
      {
        title: "设备累计使用天数",
        dataIndex: "useTime",
        width: 200,
      },
      {
        title: "设备使用率",
        dataIndex: "useRate",
        width: 200,
      },

      {
        title: "材料厂商",
        dataIndex: "materialName",
        width: 200,
        // ...this.getColumnFilterProps("mat"),
      },
      {
        title: "制造厂商",
        dataIndex: "manufacturer",
        width: 150,
        // ...this.getColumnFilterProps("man"),
      },
      {
        title: "生产日期",
        dataIndex: "produceTime",
        width: 150,
        // ...this.getColumnFilterProps("date"),
      },

      {
        title: "入库用户",
        dataIndex: "enterRepositoryUsername",
        width: 150,
      },
      {
        title: "入库时间",
        dataIndex: "createdTime",
        width: 150,
        // ...this.getColumnFilterProps("ora"),
      },
      // {
      //   title: "状态",
      //   dataIndex: "status",
      //   width: 150,
      // },
      // {
    ];
    let userInfo = localStorage.getItem("userInfo");

    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify || root[role][pathname].delete) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {root[role][pathname].modify ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  编辑
                </Button>
              ) : (
                ""
              )}
              {root[role][pathname].delete ? (
                <Popconfirm
                  title="确定删除吗？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    this.deleteItem(item);
                  }}
                >
                  <Button type="danger" style={{ marginLeft: "20px" }}>
                    删除
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }

    this.state = {
      data: [],
      warehouseData: [], // 存储获取的数据，用于用户搜索时
      columns,
      role,
      pathname,
      modalVisible: false,
      title: "添加仓库",
      searchType: "name",
    };
  }

  componentDidMount() {
    this.aquireData("");
  }
  // 请求数据
  aquireData = (identifier) => {
    axiosRequest
      .post(warehouseRequestPath.equipmenthistory, { identifier })
      .then((res) => {
        if (res.code === 200) {
          res.data.Array.forEach((item, index) => {
            item.key = index;
            item.sort = index + 1;
            item.createdTime = item.enterRepositoryAt
              ? moment(item.enterRepositoryAt).format("YYYY-MM-DD")
              : "暂无";
            item.produceTime = item.producedDate
              ? moment(item.producedDate).format("YYYY-MM-DD")
              : "暂无";
          });
          this.setState({
            data: res.data.Array,
            loading: false,
            total: res.data.steelCount,
            weight: Math.floor(res.data.weight * 1000) / 1000,
          });
        }
      })
      .catch((error) => {
        console.log("获取设备历史信息数据error", error);
      });
  };
  deleteItem = (item) => {
    Request.client
      .mutate({
        mutation: DELETE_WAREHOUSE, // 封装好的GraphQL,
        variables: {
          id: item.id,
        },
      })
      .then((result) => {
        message.success("删除仓库成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  // 设置弹窗的显示和关闭
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加仓库" }, () => {
      if (modalVisible) {
        this.AddWarehousel.openAddWindows();
        this.AddWarehousel.getMaintenancePersonenance();
        this.AddWarehousel.handlerResetForm();
      }
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加仓库") {
      this.AddWarehousel.addExpress();
    } else {
      this.AddWarehousel.modifyProjectSubmit();
    }
  };

  // 关闭弹窗，由AddWarehousel组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  // 修改项目
  modify = (project) => {
    this.setState({ modalVisible: true, title: "编辑仓库" }, () => {
      this.AddWarehousel.getMaintenancePersonenance();
      this.AddWarehousel.modify(project);
    });
  };
  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { warehouseData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }

    let result = [];
    if (searchType === "name") {
      result = warehouseData.filter((obj) => {
        return obj.name.indexOf(keyword) !== -1;
      });
    } else if (searchType === "address") {
      result = warehouseData.filter((obj) => {
        return obj.address.indexOf(keyword) !== -1;
      });
    } else if (searchType === "admin") {
      result = warehouseData.filter((obj) => {
        return obj.leaderName.indexOf(keyword) !== -1;
      });
    } else {
      result = warehouseData.filter((obj) => {
        return obj.phone.indexOf(keyword) !== -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "name",
      },
      () => {
        this.aquireData();
      }
    );
  };
  render() {
    let { data, role, pathname, columns, title } = this.state;
    return (
      <div className="warehouse_box">
        <div className="btn_box" style={{ justifyContent: "flex-end" }}>
          {/* <Button
            type="primary"
            style={{ background: "#A06921", border: "none" }}
          >
            导出
          </Button> */}
          {/* <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div> */}
          <div>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="name">仓库名称</Option>
              <Option value="address">仓库地址</Option>
              <Option value="admin">管理员名称</Option>
              <Option value="phone">仓库电话</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={this.closeModal}
        >
          <AddWarehousel
            ref={(AddWarehousel) => {
              this.AddWarehousel = AddWarehousel;
            }}
            closeModal={this.closeModal}
          ></AddWarehousel>
        </Modal>
      </div>
    );
  }
}
