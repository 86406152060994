import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, Input, Select, message } from "antd";
import Request from "../../../../utils/client";
import { ADD_MAC, AQUIRE_PESONAL } from "../../../../graphql/admin/mac";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddMaintenance(props, ref) {
  let [children, setChildren] = useState([]);
  let [id, setId] = useState([]);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => {
    return {
      // 获取管理员
      getPersonal() {
        Request.client
          .query({
            query: AQUIRE_PESONAL,
          })
          .then((result) => {
            console.log("获取人员", result);
            let arr = result.data.getUserListForDevice;
            let children2 = [];
            arr.forEach((item) => {
              children2.push(<Option key={item.id}>{item.name}</Option>);
            });
            setChildren(children2);
          })
          .catch((err) => {
            // this.props.history.push({ pathname: "/admin/error/systemerror" });
          });
      },
      // 添加
      addExpress() {
        form
          .validateFields()
          .then((res) => {
            // console.log(form.getFieldsValue());
            let { mac, uid, isAble } = form.getFieldsValue();
            // console.log(form.getFieldsValue());
            Request.client
              .mutate({
                mutation: ADD_MAC, // 封装好的GraphQL,
                variables: {
                  input: {
                    mac,
                    uid,
                    isAble: isAble == "true" ? true : false,
                  },
                },
              })
              .then((result) => {
                // console.log(result);
                message.success("添加成功");
                form.resetFields();
                props.closeModal();
              })
              .catch((error) => {
                message.error(error.message);
                console.log(error);
              });
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },

      handlerResetForm() {
        form.resetFields();
      },
    };
  });

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="mac"
          label="MAC地址"
          rules={[{ required: true, message: "请输入MAC地址" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入MAC地址" />
        </Form.Item>

        <Form.Item
          name="uid"
          label="用户名称"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择用户",
            },
          ]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="请选择用户"
            onChange={handleChange}
          >
            {children}
          </Select>
        </Form.Item>
        <Form.Item
          label="启用状态"
          name="isAble"
          rules={[{ required: true, message: "请选择启用状态" }]}
        >
          <Select
            style={{ width: 300 }}
            onChange={handleChange}
            placeholder="请选择启用状态"
          >
            <Option value="true">启用</Option>
            <Option value="false">禁用</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddMaintenance);
