import React, { Component } from "react";
import { Table, message } from "antd";
// import { STEEL_DASHBOARD } from "../../../../graphql/admin/index.js";
// import Request from "../../../../utils/client";
import moment from "moment";
// import PubSub from "pubsub-js";

import axiosRequest from "../../../../utils/request";
import {
  indexRequestPath,
  AdminIndexRequest,
  ProjectIndexRequest,
} from "../../../../request/requestPath.js";

const columns = [
  {
    title: "序号",
    dataIndex: "sort",
    fixed: "left",
    width: 100,
  },
  {
    title: "更新时间",
    dataIndex: "refreshDate",
    width: 150,
  },
  {
    title: "操作人",
    dataIndex: "operationUserName",
    width: 100,
  },
  {
    title: "需求单号",
    dataIndex: "orderNo",
    width: 150,
  },
  {
    title: "项目名称",
    dataIndex: "projectName",
    width: 150,
  },
  {
    title: "仓库",
    dataIndex: "repositoryName",
    width: 150,
  },
  {
    title: "型钢编码",
    dataIndex: "code",
    width: 150,
  },
  {
    title: "规格尺寸",
    dataIndex: "specification",
    width: 150,
  },
  {
    title: "当前状态",
    dataIndex: "currentStatus",
    width: 150,
  },
  {
    title: "使用状态",
    dataIndex: "useStatus",
    width: 200,
  },
  {
    title: "位置编码",
    dataIndex: "locationCode",
    width: 150,
  },
  {
    title: "出库时间",
    dataIndex: "outRepositoryDate",
    width: 150,
  },

  {
    title: "入场时间",
    dataIndex: "enteredDate",
    width: 150,
  },
  {
    title: "出场时间",
    dataIndex: "outedDate",
    width: 150,
  },
  {
    title: "归库时间",
    dataIndex: "enterRepositoryDate",
    width: 150,
  },
];

let statusOject = {
  100: "【仓库】在库",
  101: "【仓库】运送至项目途中",
  102: "【仓库】运送至维修厂途中",
  200: "【项目】待使用",
  201: "【项目】使用中",
  202: "【项目】异常",
  203: "【项目】闲置",
  204: "【项目】准备归库",
  205: "【项目】归库途中",

  300: "【维修】待维修",
  301: "【维修】维修中",
  302: "【维修】准备归库",
  303: "【维修】归库途中",

  400: "丢失",
  500: "报废",
  600: "租赁",
};
// 使用状态
let useStatusObject = {
  0: "未使用",
  1: "已使用",
};

export default class log extends Component {
  state = {
    data: [],
    loading: true,
    total: 0, // 数据总数
    current: 1, // 当前的页数
    pageSize: 20, // 每页的数据量
  };
  projectId = ""; // 项目id
  repositoryId = ""; // 仓库id
  // 表格分页
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    showQuickJumper: true, // 开启页码快速跳转
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };
  userInfo = null;
  componentDidMount() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));

    // PubSub.unsubscribe("receiveSteelStateParameters"); //销毁订阅 防止多次触发
    // PubSub.subscribe("receiveSteelStateParameters", (_, data) => {
    //   let { type, id } = data;
    //   type === "project" ? (this.projectId = id) : (this.repositoryId = id);
    //   if (this.projectId && this.repositoryId) {
    //     console.log("执行了");
    //     this.aquireData();
    //   }
    // });
    this.setState({
      loading: false,
    });
  }
  // 公司管理员获取最新使用动向
  commonInit(projectId, repositoryId) {
    if (
      (projectId || projectId === 0) &&
      (repositoryId || repositoryId === 0)
    ) {
      this.aquireData(indexRequestPath.GetNewState, {
        projectId,
        repositoryId,
      });
    }
  }
  // 超级管理员获取型钢最新使用动向
  adminInit(companyId, projectId, repositoryId) {
    if (companyId && projectId && repositoryId) {
      this.aquireData(AdminIndexRequest.GetNewStateBySuper, {
        companyId,
        projectId,
        repositoryId,
      });
    }
  }

  // 项目管理员获取型钢最新使用动向
  projectInit() {
    this.aquireData(ProjectIndexRequest.GetSteelNewState, {});
  }

  // 请求数据
  aquireData = (path, data) => {
    let { current, pageSize } = this.paginationProps;
    axiosRequest
      .post(path, {
        ...data,
        pageNo: current,
        pageSize,
      })
      .then((res) => {
        // console.log("获取最近型钢动态", res);
        if (res.code === 200) {
          let data = res.data ? res.data.steels : [];
          data.forEach((item, index) => {
            item.key = index;
            item.sort = index + 1;
            item.currentStatus = statusOject[item.state];
            item.useStatus = useStatusObject[item.useState];
            item.enterWorkShopAt
              ? (item.enteredDate = moment(item.enterWorkShopAt).format(
                  "YYYY-MM-DD"
                ))
              : (item.enteredDate = "");
            item.outWorkShopAt
              ? (item.outedDate = moment(item.outWorkShopAt).format(
                  "YYYY-MM-DD"
                ))
              : (item.outedDate = "");
            item.enterRepositoryAt
              ? (item.enterRepositoryDate = moment(
                  item.enterRepositoryAt
                ).format("YYYY-MM-DD"))
              : (item.enterRepositoryDate = "");

            item.outRepositoryAt
              ? (item.outRepositoryDate = moment(item.outRepositoryAt).format(
                  "YYYY-MM-DD"
                ))
              : (item.outRepositoryDate = "");
            item.refreshDate = moment(item.updateTime).format("YYYY-MM-DD");
          });

          this.setState({
            data,
            loading: false,
            total: res.totalNum ? res.totalNum : 0,
          });
        } else {
          message.error("获取最近型钢动态错误");
        }
      })
      .catch((error) => {
        console.log("获取最近型钢动态错误", error);
      });
  };

  // 页面发生改变
  changePage = (page) => {
    // console.log(page);
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.aquireData();
      }
    );
  };
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    // console.log(pageSize, current);
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.aquireData();
      }
    );
  };

  render() {
    let { loading, data } = this.state;
    return (
      <div>
        <Table
          columns={columns}
          dataSource={data}
          bordered
          scroll={{ x: 600 }}
          pagination={this.paginationProps}
          loading={loading}
        />
      </div>
    );
  }
}
