let commonColumns = [
  // {
  //   title: "序号",
  //   dataIndex: "id",
  //   fixed: "left",
  //   width: 100,
  // },
  {
    title: "仓库名称",
    dataIndex: "repositoryName",
    width: 150,
  },
  //   {
  //     title: "型钢状态",
  //     dataIndex: "status",
  //     width: 150,
  //   },
  {
    title: "规格尺寸",
    dataIndex: "specification",
    width: 150,
  },
  // {
  //   title: "剩余数量",
  //   dataIndex: "surplus",
  //   width: 100,
  // },
  {
    title: "已选数量",
    dataIndex: "selectNum",
    width: 100,
    align: "center",
  },
];
export const steelCloumn = [...commonColumns];
let oldAccessoriesCloumn = [...commonColumns];
oldAccessoriesCloumn.splice(2, 0, {
  title: "配件名称",
  dataIndex: "partName",
  width: 150,
});
export const accessoriesCloumn = oldAccessoriesCloumn;
export const oldEquipmentCloumn = [...commonColumns];
oldEquipmentCloumn.splice(2, 0, {
  title: "设备名称",
  dataIndex: "equipmentName",
  width: 150,
});
export const equipmentCloumn = oldEquipmentCloumn;
export const demandEquipmentCloumn = [
  // {
  //   title: "序号",
  //   dataIndex: "sort",
  //   fixed: "left",
  //   width: 100,
  // },
  {
    title: "设备名称",
    dataIndex: "name",
    width: 150,
  },
  {
    title: "规格尺寸",
    dataIndex: "specification",
    width: 150,
  },
  {
    title: "所需数量",
    dataIndex: "total",
    width: 150,
  },
  {
    title: "总需求量",
    dataIndex: "demandNum",
    width: 150,
  },
];
export const demandAccessoriesCloumn = [
  // {
  //   title: "序号",
  //   dataIndex: "sort",
  //   fixed: "left",
  //   width: 100,
  // },
  {
    title: "配件名称",
    dataIndex: "name",
    width: 150,
  },
  {
    title: "规格尺寸",
    dataIndex: "specification",
    width: 150,
  },
  {
    title: "所需数量",
    dataIndex: "total",
    width: 150,
  },
  {
    title: "总需求量",
    dataIndex: "demandNum",
    width: 150,
  },
];
export const demandSteelCloumn = [
  // {
  //   title: "序号",
  //   dataIndex: "sort",
  //   fixed: "left",
  //   width: 100,
  // },
  {
    title: "规格尺寸",
    dataIndex: "specification",
    width: 150,
  },
  {
    title: "所需数量",
    dataIndex: "total",
    width: 150,
  },
  {
    title: "总需求量",
    dataIndex: "demandNum",
    width: 150,
  },
];

export const statisticalSteel = [
  ...demandSteelCloumn,
  {
    title: "已选数量",
    dataIndex: "num",
    width: 150,
  },
  {
    title: "选择明细",
    dataIndex: "selectInfo",
    width: 250,
  },
];

export const statisticalAccessories = [
  ...demandAccessoriesCloumn,
  {
    title: "已选数量",
    dataIndex: "num",
    width: 150,
  },
  {
    title: "选择明细",
    dataIndex: "selectInfo",
    width: 250,
  },
];

export const statisticalEquipment = [
  ...demandEquipmentCloumn,
  {
    title: "已选数量",
    dataIndex: "num",
    width: 150,
  },
  {
    title: "选择明细",
    dataIndex: "selectInfo",
    width: 250,
  },
];
