import axios from "axios";
// import { Message } from "element-ui";
import { message } from "antd";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  // baseURL: "http://localhost:81",
  // baseURL: "http://192.168.0.164:8081",
  baseURL: "/api",
  timeout: 6000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  console.log(error);
  if (error.response) {
    // const data = error.response.data;

    if (error.response.status === 403) {
      message.error("服务器错误");
    }
    if (error.response.status === 401) {
      message.error("登录凭证失效，请重新登录");
      // Message.error("登录凭证失效，请重新登录");
      // localStorage.clear("LOGIN_INFO");
      // store.commit("updateLoginStatus", false);
      window.location.href = "/";
    }
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  let userInfo = localStorage.getItem("userInfo");
  let token = "";
  if (userInfo) {
    token = JSON.parse(userInfo).accessToken;
  }
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  config.headers["authorization"] = token ? `Bearer ${token}` : "";
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  return response.data;
}, errorHandler);

export default request;
