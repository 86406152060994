import Request from "../../utils/client";
// 获取概览（第一栏）
export const SUMMARY = Request.gql`
    query {
        getSummary {
        #### 资产概况1 ###
        feeTotal #总价值(万元)
        weightTotal #型钢总量(吨)
        yearFeeTotal # 今年新增价值(万元)
        yearWeightTotal # 今年新增型钢(吨)
        
        #### 资产概况2 ###
        idleWeightTotal #闲置量(吨)
        leaseWeightTotal #租赁数量(吨)
        maintenanceTotal #维修数量
        scrapWeightTotal # 报废量(吨)
    
        ### 最近最近 盘点 ###
        lossTotal #丢失数量
        maintenanceWeightTotal # 维修量(吨)
        projectTotal #项目总数
        weightTotal #总重量
        leaseTotal #总体租出  
        }
    }
`;

// 获取数据概览（饼图）
export const STEEL_SUMMARY = Request.gql`
    query ($input: GetSteelSummaryForDashboardInput!){
        getSteelSummaryForDashboard(input: $input){
        crappedPercent # 报废
        lostPercent # 丢失
        maintainingPercent # 维修中
        storedPercent # 在库
        UsingPercent # 项目中
        }
    }
`;

//获取型钢列表（第三栏第一列）
export const STEEL_DASHBOARD = Request.gql`
    query ($input: GetSteelForDashboardInput){
        getSteelForDashboard(input: $input) {
        list {
            id
            enterRepositoryUser{
                name
            }
            orderSpecification {
            order {
                orderNo # 订单号
                project {
                name # 项目名
                }
            }
            }
            steel {
            code
            repository {
                name # 仓库名
            }
            specifcation {
                specification # 规格
            }
            stateInfo {
                state 
                desc # 当前状态
            }
            }
            stateInfo { # 使用状态
            state 
            desc # 使用状态 
            }
            locationCode # 安装编码
            enterWorkshopAt # 入场时间
            outWorkshopAt # 出场时间
            enterRepositoryAt # 归库时间
            outRepositoryAt
            
        }
        total # 总量
        }
    }
`;

// 获取仓库列表
export const REPOSITORY = Request.gql`
    query {
        getRepositoryListForDashboard {
            id
            name
        } 
    }
`;

// 获取制造商家列表
export const MANUFACTURERS = Request.gql`
    query {
        getManufacturers {
        id
        name
        }
    }
`;

// 获取材料商家列表
export const MATERIAL = Request.gql`
    query {
        getMaterialManufacturers {
            id
            name
        }
    }
`;

// 获取状态列表
export const STATELIST = Request.gql`
    query {
        getAllStateList {
            state
            desc
        }
    }
`;

// 获取规格尺寸
export const GETSPECIFICATION = Request.gql`
    query {
        getSpecification {
            id
            specification
        }
    }
`;
