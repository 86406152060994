/**
 * 项目管理员的权限
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  baseInfo: {
    show: false,
  },
  "/admin/rtrieve": {
    show: false,
  },
  "/admin/company": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/personnel": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  steel: {
    show: false,
  },
  "/admin/warehouse": {
    show: false,
  },
  "/admin/warehouse/detail": {
    show: false,
  },
  "/admin/warehouse/steelhistory": { show: false },
  "/admin/warehouse/equipmenthistory": { show: false },
  "/admin/mac": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/steel": {
    show: false,
  },
  project: {
    show: false,
  },
  "/admin/project": {
    show: true,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/project/detail": {
    show: true,
  },
  demand: {
    show: true,
  },
  "/admin/demand/management": {
    show: true,
    add: true,
    delete: false,
    modify: false,
    return: false,
  },
  "/admin/demand/list": {
    show: true,
    add: true,
    delete: false,
    modify: false,
  },
  "/admin/demand/steel": {
    show: false,
  },
  "/admin/demand/equipemnt": {
    show: false,
  },
  "/admin/demand/accessories": {
    show: false,
  },
  "/admin/demand/detail": {
    show: false,
  },
  maintenance: {
    show: false,
  },
  "/admin/maintenance": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/maintenance/detail": {
    show: false,
  },
  scrap: {
    show: false,
  },
  "/admin/scrapList": {
    show: false,
    distribution: false,
  },
  maintenanceorder: {
    show: false,
  },
  "/admin/maintenanceOrder": {
    show: false,
  },
  "/admin/maintenanceMangement": {
    show: false,
  },
  clock: {
    show: false,
  },
  "/admin/clock/size": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/clock/accessories": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/clock/equipment": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/clock/material": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/clock/manufacturing": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/clock/transport": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  "/admin/clock/price": {
    show: false,
    add: false,
    delete: false,
    modify: false,
  },
  log: {
    show: true,
  },
  "/admin/log": {
    show: true,
  },
  "/admin/modify": {
    show: true,
  },
  "/admin/message": {
    show: false,
  },
};
