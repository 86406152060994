import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Form, Input, message } from "antd";
// import Request from "../../../../utils/client";
// import { MODIFY_PRICE } from "../../../../graphql/admin/clock";
import axiosRequest from "../../../../utils/request";
import { EditPrice } from "../../../../request/requestPath";
// const { Option } = Select;
// 提交的方法
// const Demo = () => {
//   const onFinish = (values) => {
//     console.log("Received values of form: ", values);
//   };
// };
const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddCompany(props, ref) {
  let [price, setPrice] = useState(0);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => {
    return {
      // 修改
      modify(price) {
        setPrice(price);
      },
      // 修改单价
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { price } = form.getFieldsValue();
            axiosRequest
              .post(EditPrice, {
                price,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("修改成功");
                  form.resetFields();
                  props.closeModal();
                }
              })
              .catch((error) => {
                console.log("修改价格报错", error);
              });
            // Request.client
            //   .mutate({
            //     mutation: MODIFY_PRICE, // 封装好的GraphQL,
            //     variables: {
            //       price: parseFloat(price),
            //     },
            //   })
            //   .then((result) => {
            //     message.success("修改成功");
            //     form.resetFields();
            //     props.closeModal();
            //   })
            //   .catch((error) => {
            //     message.error(error.message);
            //     console.log(error);
            //   });
          })
          .catch((err) => {
            console.log(err);
          });
      },
    };
  });

  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item name="currentPrice" label="当前价格">
          <Input style={{ width: 300 }} disabled placeholder={price} />
        </Form.Item>
        <Form.Item
          name="price"
          label="新价格"
          rules={[{ required: true, message: "请输入价格" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddCompany);
