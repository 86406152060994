import {
  DELETE_ACCESSORIES,
  ADD_ACCESSORIES,
  RESET_ACCESSORIES,
} from "../constant";

export const deleteAccessoriesAction = (data) => ({
  type: DELETE_ACCESSORIES,
  data,
});
export const addAccessoriesAction = (data) => ({ type: ADD_ACCESSORIES, data });
export const resetAccessoriesAction = (data) => ({
  type: RESET_ACCESSORIES,
  data,
});
