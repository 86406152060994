import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { Form, Input, Select, message, Cascader, Button } from "antd";
import Request from "../../../../utils/client";
import managementType from "../../../../utils/managementType";
// import {
//   ADD_WAREHOUSE,
//   MODIFY_WAREHOUSE,
// } from "../../../../graphql/admin/warehouse";
import { AQUIRE_ADMINISTRATOR } from "../../../../graphql/administrator/administrator";
import cityArray from "../../../../utils/city";
import axiosRequest from "../../../../utils/request";
import {
  RepositoryRequestPath,
  companyRequestPath,
} from "../../../../request/requestPath";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

let map = null;

function AddWarehousel(props, ref) {
  let [children, setChildren] = useState([]);
  let [id, setId] = useState("");
  let [latitude, setLatitude] = useState(0);
  let [longitude, setLongitude] = useState(0);

  let [initFlag, setInitFlag] = useState(false);
  // let [map, setMap] = useState({});
  // let [isAble, setIsable] = useState("true");
  const [form] = Form.useForm();

  useEffect(() => {
    setInitFlag(true);
    return function cleanup() {
      map = null;
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      // 获取管理员
      getMaintenancePersonenance() {
        setId("");
        Request.client
          .query({
            query: AQUIRE_ADMINISTRATOR,
            variables: {
              input: {
                roleId: managementType.repository,
              },
            },
          })
          .then((result) => {
            let arr = result.data.getCompanyUser;
            let children2 = [];
            arr.forEach((item) => {
              children2.push(<Option key={item.id}>{item.name}</Option>);
            });
            setChildren(children2);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 添加（打开窗口）
      openAddWindows() {
        initMap(116.404, 39.915);
      },
      // 添加（发送请求）
      addExpress() {
        form
          .validateFields()
          .then((res) => {
            let { name, address, repositoryAdminId, remark, pinYin, city } =
              form.getFieldsValue();

            let cityAddress = city.join("/");

            let adminId = [];
            repositoryAdminId.forEach((item) => {
              adminId.push(item * 1);
            });

            addressResolution();

            axiosRequest
              .post(companyRequestPath.CreateRepository, {
                name,
                address,
                repositoryAdminId: adminId,
                remark,
                pinYin,
                city: cityAddress,
                location: {
                  latitude,
                  longitude,
                },
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("添加成功");
                  form.resetFields();
                  props.closeModal();
                } else {
                  message.error(res.message);
                }
              })
              .catch((error) => {
                message.error(error.message);
              });

            //   Request.client
            //     .mutate({
            //       mutation: ADD_WAREHOUSE, // 封装好的GraphQL,
            //       variables: {
            //         input: {
            //           name,
            //           address,
            //           repositoryAdminId,
            //           remark,
            //           pinYin,
            //           city: cityAddress,
            //           location: {
            //             latitude,
            //             longitude,
            //           },
            //         },
            //       },
            //     })
            //     .then((result) => {
            //       // console.log(result);
            //       message.success("添加成功");
            //       form.resetFields();
            //       props.closeModal();
            //     })
            //     .catch((error) => {
            //       message.error(error.message);
            //       console.log(error);
            //     });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 修改
      modify(obj) {
        // console.log(obj);
        let {
          name,
          address,
          admin,
          remark,
          pinYin,
          id,
          isAble,
          city,
          latitude,
          longitude,
        } = obj;
        setLatitude(latitude);
        setLongitude(longitude);
        let uid = [];
        admin.forEach((item) => {
          uid.push(item.adminId + "");
        });
        setId(id);

        initMap(longitude, latitude); // 创建点坐标
        form.setFieldsValue({
          name,
          address,
          admin,
          remark,
          pinYin,
          repositoryAdminId: uid,
          isAble: isAble + "",
          city: city.split("/"),
        });
      },
      // 修改项目提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { name, address, repositoryAdminId, remark, isAble, city } =
              form.getFieldsValue();
            let newAble = true;
            isAble === "true" ? (newAble = true) : (newAble = false);
            let leaderIdList = [];
            repositoryAdminId.forEach((item) => {
              leaderIdList.push(item * 1);
            });
            let cityAddress = city.join("/");

            axiosRequest
              .post(RepositoryRequestPath.EditRepository, {
                id,
                name,
                address,
                remark,
                isAble: newAble,
                leaderIdList,
                city: cityAddress,
                latitude,
                longitude,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("修改成功");
                  form.resetFields();
                  props.closeModal();
                }
              })
              .catch((error) => {
                console.log("修改仓库错误", error);
              });

            // Request.client
            //   .mutate({
            //     mutation: MODIFY_WAREHOUSE, // 封装好的GraphQL,
            //     variables: {
            //       input: {
            //         name,
            //         address,
            //         leaderIdList,
            //         remark,
            //         id,
            //         isAble: newAble,
            //         city: cityAddress,
            //         location: {
            //           latitude,
            //           longitude,
            //         },
            //       },
            //     },
            //   })
            //   .then((result) => {
            //     message.success("修改成功");
            //     form.resetFields();
            //     props.closeModal();
            //   })
            //   .catch((error) => {
            //     message.error(error.message);
            //     console.log(error);
            //   });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 表单重置
      handlerResetForm() {
        form.resetFields();
      },
    };
  });

  // 初始化地图
  const initMap = (longitude, latitude) => {
    let { BMapGL } = window;
    if (!initFlag) {
      map = new BMapGL.Map("container"); // 创建地图实例
      map.addEventListener("click", function (e) {
        let lng = e.latlng.lng;
        let lat = e.latlng.lat;
        map.clearOverlays();
        setLatitude(lat);
        setLongitude(lng);
        map.centerAndZoom(e.latlng, 20);
        map.addOverlay(new BMapGL.Marker(e.latlng, { title: "klk;lklklk" }));
        geocoder.getLocation(e.latlng, function (rs) {
          var addComp = rs.addressComponents;
          form.setFieldsValue({
            city:
              addComp.province === addComp.city
                ? [addComp.province, addComp.district]
                : [addComp.province, addComp.city, addComp.district],
            address: addComp.street + addComp.streetNumber,
          });
        });
      });
    }

    var point = new BMapGL.Point(longitude, latitude); // 创建点坐标
    map.clearOverlays();
    map.addOverlay(new BMapGL.Marker(point, { title: "klk;lklklk" }));
    setTimeout(() => {
      map.centerAndZoom(point, 20); // 初始化地图，设置中心点坐标和地图级别
    }, 100);
    map.enableScrollWheelZoom(true);
    var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
    map.addControl(zoomCtrl);

    var geocoder = new BMapGL.Geocoder();
  };

  const onChange = (value, selectedOptions) => {
    console.log(value, selectedOptions);
  };

  const filter = (inputValue, path) => {
    return path.some((option) => option.label.indexOf(inputValue) > -1);
  };
  // 地址解析
  const addressResolution = () => {
    let { address, city } = form.getFieldsValue();
    if (!city) {
      message.error("请输入地址");
    }
    let selecty = city.length === 3 ? city[1] : city[0];
    let cityDetail = "";
    city.forEach((item) => {
      cityDetail = cityDetail + item;
    });

    let { BMapGL } = window;

    let myGeo = new BMapGL.Geocoder();
    // 将地址解析结果显示在地图上，并调整地图视野
    myGeo.getPoint(
      cityDetail + address,
      function (point) {
        if (point) {
          if (latitude) {
            map.clearOverlays();
          }
          setLatitude(point.lat);
          setLongitude(point.lng);
          map.centerAndZoom(point, 16);
          map.addOverlay(new BMapGL.Marker(point, { title: "klk;lklklk" }));
          map.enableScrollWheelZoom(true);
        } else {
          message.error("您输入的地址无法解析出经纬度");
        }
      },
      selecty
    );
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="name"
          label="仓库名称"
          rules={[{ required: true, message: "请输入仓库名称" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          label="仓库拼音简写"
          name="pinYin"
          rules={[{ required: true, message: "请输入仓库拼音简写" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        {/* <Form.Item
          label="城市"
          name="city"
          rules={[{ required: true, message: "请输入城市" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item> */}
        <Form.Item
          label="城市"
          name="city"
          rules={[{ required: true, message: "请选择城市" }]}
        >
          <Cascader
            options={cityArray}
            onChange={onChange}
            placeholder="请选择城市"
            showSearch={filter}
            style={{ width: 300 }}
          />
        </Form.Item>

        <Form.Item
          label="地址"
          name="address"
          // rules={[{ required: true, message: "请输入地址" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item label="地址解析">
          <Button
            type="primary"
            onClick={addressResolution}
            style={{ marginLeft: 10 }}
          >
            地址解析
          </Button>
          <div
            style={{
              fontSize: 12,
              width: 300,
              color: "rgba(0,0,0,.6)",
              marginTop: 10,
            }}
          >
            添加仓库前请先进行地址解析，确保地址解析所获取的经纬度是正确的(可点击地图选取位置)
          </div>
          <div
            id="container"
            style={{ height: 200, width: 300, marginTop: 10 }}
          ></div>
        </Form.Item>
        <Form.Item
          name="repositoryAdminId"
          label="仓库管理员"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择管理员",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: 300 }}
            placeholder="请选择管理员"
            onChange={handleChange}
            maxTagCount={3}
          >
            {children}
          </Select>
        </Form.Item>
        {id ? (
          <Form.Item
            name="isAble"
            label="是否默认"
            hasFeedback
            rules={[
              {
                required: true,
                message: "请选择",
              },
            ]}
          >
            <Select
              allowClear
              style={{ width: 300 }}
              placeholder="请选择"
              onChange={handleChange}
            >
              <Option key="true">是</Option>
              <Option key="false">否</Option>
            </Select>
          </Form.Item>
        ) : (
          ""
        )}
        <Form.Item label="备注" name="remark">
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddWarehousel);
