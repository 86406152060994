import React, { Component } from "react";
import { Table, Button, Modal, Input, Select, message, Popconfirm } from "antd";
import Request from "../../../utils/client";
import { DELETE_DEMAND } from "../../../graphql/admin/demand";
import AddDemand from "./components/AddDemand";
import root from "../../../config/root";
import commonStatus from "../../../utils/status";
import util from "../../../utils/util";

import PubSub from "pubsub-js";

import axiosRequest from "../../../utils/request";
import { demandRequestPath } from "../../../request/requestPath";

import { connect } from "react-redux";
import { addDemandAction } from "../../../redux/actions/demand";
import { resetAccessoriesAction } from "../../../redux/actions/accessories";
import { resetSteelAction } from "../../../redux/actions/steel";
import { resetEquipmentAction } from "../../../redux/actions/equipment";

import { steelColumns, partColums, equipmentColums } from "./util/util";
import {
  // MAINTENANCE_ADMIN,
  REPOSITORY_ADMIN,
} from "../../../config/constant";
import {
  awaitColumns,
  distributionColumns,
  infoColumns,
  refusedCloumns,
} from "./util/util";
import "./demand.scss";
import moment from "moment";
import Draggable from "react-draggable";
const { Option } = Select;
const { TextArea } = Input;
function onChange(pagination, filters, sorter, extra) {
  // console.log("params", pagination, filters, sorter, extra);
}

// 待分配表头
let awaitColumnsNew = [];

// 已分配表头
let distributionColumnsNew = [];

// 已拒绝表头
let stateRejectedColumns = [];

class Admin extends Component {
  userInfo = localStorage.getItem("userInfo");
  role = JSON.parse(this.userInfo).role;
  pathname = window.location.pathname;
  state = {
    role: this.role,
    modalVisible: false, // 添加需求单的弹窗
    visible: false, // 拒绝的弹窗
    data: [], // 表格数据源
    demandData: [], // 存储获取的数据，用于用户搜索时
    title: "添加需求单",
    pathname: this.pathname,
    searchText: "",
    searchedColumn: "",
    filteredInfo: null,
    searchType: "order",
    loading: true, // 表格的loading
    refusedOrderId: "", // 拒绝的需求单id值
    demandType: "toBeConfirm", // 需求单类型  toBeConfirm:待确认  confirmOrder:已确认  StateRejected:已拒绝  200 待确认  300 已确认  400 已拒绝
    materialVisible: false, // 控制材料列表弹窗的变量
    steelData: [], // 型钢列表
    partData: [], // 配件列表
    equipmentData: [], // 设备列表
    total: 0,
    current: 1,
    pageSize: 20,

    // 拖动窗口相关属性
    disabled: true,
    bounds: { left: 0, top: 0, bottom: 0, right: 0 },

    showType: "list", // 窗口展示的类型：list 材料清单   info:信息
    infoData: [], // 已分配点击状态的弹窗信息
    infoColumnsNews: [],
  };
  draggleRef = React.createRef();

  constructor(props) {
    super(props);
    // 待分配表头
    awaitColumnsNew = [
      ...awaitColumns,
      {
        title:
          this.role === REPOSITORY_ADMIN
            ? "待确定型钢总重（t）"
            : "待分配型钢总重（t）",
        dataIndex: "steelToBeWeight",
        width: 160,
      },
      {
        title:
          this.role === REPOSITORY_ADMIN
            ? "待确定配件总重（t）"
            : "待分配配件总重（t）",
        dataIndex: "partToBeWeight",
        width: 160,
      },
      {
        title:
          this.role === REPOSITORY_ADMIN
            ? "待确定设备个数（个）"
            : "待分配设备个数（个）",
        dataIndex: "equipmentToBeTotal",
        width: 160,
      },
      {
        title: "清单详细",
        key: "detail",
        width: 100,
        align: "center",
        render: (item) => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.showMaterial(item, "list");
              }}
            >
              查看
            </Button>
          </div>
        ),
      },
    ];
    let newColumns = [];
    let createObject = this.createHtml();
    // eslint-disable-next-line no-unused-expressions
    createObject ? newColumns.push(createObject) : "";
    this.state.columns = [...awaitColumnsNew, ...newColumns];
  }

  handleStateObject = {
    200: "未处理",
    300: "已确认",
    400: "已拒绝",
  };

  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };

  componentDidMount() {
    // 已分配表格表头
    let disstributionAdd = [
      {
        title: "清单详细",
        key: "detail",
        width: 100,
        align: "center",
        render: (item) => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.showMaterial(item, "list");
              }}
            >
              查看
            </Button>
          </div>
        ),
      },
      {
        title: "需求单状态",
        key: "detail",
        width: 120,
        align: "center",
        render: (item) => (
          <div
            style={{ cursor: "pointer" }}
            type="primary"
            onClick={() => {
              this.showMaterial(item, "info");
            }}
          >
            {item.handleStatus}
          </div>
        ),
      },
    ];
    distributionColumnsNew = [
      ...distributionColumns,
      {
        title:
          this.role === REPOSITORY_ADMIN
            ? "已确定型钢总重（t）"
            : "已分配型钢总重（t）",
        dataIndex: "steelToBeWeight",
        width: 160,
      },
      {
        title:
          this.role === REPOSITORY_ADMIN
            ? "已确定配件总重（t）"
            : "已分配配件总重（t）",
        dataIndex: "partToBeWeight",
        width: 160,
      },
      {
        title:
          this.role === REPOSITORY_ADMIN
            ? "已确定设备个数（个）"
            : "已分配设备个数（个）",
        dataIndex: "equipmentToBeTotal",
        width: 160,
      },
      ...disstributionAdd,
    ];
    distributionColumnsNew.splice(2, 1, {
      title: "需求单单号",
      dataIndex: "oriOrderNo",
      width: 160,
    });
    distributionColumnsNew.splice(3, 0, {
      title: "分配单号",
      dataIndex: "orderNo",
      width: 160,
    });

    // 已拒绝表头
    let stateRejectedAdd = [
      {
        title: "清单详细",
        key: "detail",
        width: 100,
        align: "center",
        render: (item) => (
          <div>
            <Button
              type="primary"
              onClick={() => {
                this.showMaterial(item, "list");
              }}
            >
              查看
            </Button>
          </div>
        ),
      },
      {
        title: "拒绝理由",
        dataIndex: "rejectReason",
        width: 200,
      },
    ];
    stateRejectedColumns = [...refusedCloumns, ...stateRejectedAdd];

    this.aquireOrderManagerByWeb();

    PubSub.unsubscribe("aquireDemand"); //销毁订阅 防止多次触发
    // 添加需求单成功后重新获取需求单（由AddDemand组件触发）
    PubSub.subscribe("aquireDemand", (_) => {
      this.aquireOrderManagerByWeb();
    }); //订阅
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };
  // 拖动框
  onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = this.draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    this.setState({
      bounds: {
        left: -targetRect.left + uiData.x,
        right: clientWidth - (targetRect.right - uiData.x),
        top: -targetRect.top + uiData.y,
        bottom: clientHeight - (targetRect.bottom - uiData.y),
      },
    });
  };

  // 页面发生改变
  changePage = (page) => {
    // console.log(page)
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.aquireOrderManagerByWeb();
      }
    );
  };
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.aquireOrderManagerByWeb();
      }
    );
  };
  // 创建元素
  createHtml = () => {
    if (this.state.demandType && this.state.demandType === "StateRejected") {
      return "";
    }
    if (
      (root[this.role][this.pathname].modify &&
        this.state.demandType === "toBeConfirm") ||
      (root[this.role][this.pathname].delete &&
        this.state.demandType === "toBeConfirm") ||
      (root[this.role][this.pathname].audit &&
        this.state.demandType === "toBeConfirm") ||
      (root[this.role][this.pathname].distribution &&
        this.state.demandType === "toBeConfirm") ||
      (root[this.role][this.pathname].re_refused &&
        this.state.demandType === "toBeConfirm") ||
      (root[this.role][this.pathname].re_confirm &&
        this.state.demandType === "toBeConfirm")
    ) {
      return {
        title: "操作",
        key: "operation",
        width: 200,
        fixed: "right",
        align: "center",
        render: (item) => (
          <div>
            {root[this.role][this.pathname].modify &&
            this.state.demandType === "toBeConfirm" ? (
              <Button
                type="primary"
                onClick={() => {
                  this.modify(item);
                }}
              >
                编辑
              </Button>
            ) : (
              ""
            )}
            {root[this.role][this.pathname].distribution &&
            this.state.demandType === "toBeConfirm" ? (
              <Button
                type="primary"
                onClick={() => {
                  this.addDemand(item);
                }}
              >
                分配
              </Button>
            ) : (
              ""
            )}
            {root[this.role][this.pathname].refused &&
            this.state.demandType === "toBeConfirm" ? (
              <Button
                type="danger"
                onClick={() => {
                  this.refused(item);
                }}
                style={{ marginLeft: "20px", marginTop: "10px" }}
              >
                拒绝
              </Button>
            ) : (
              ""
            )}
            {root[this.role][this.pathname].delete &&
            this.state.demandType === "toBeConfirm" ? (
              <Popconfirm
                title="确定删除吗？"
                okText="确定"
                cancelText="取消"
                onConfirm={() => {
                  this.deleteItem(item);
                }}
              >
                <Button
                  type="danger"
                  style={{ marginLeft: "20px", marginTop: "10px" }}
                >
                  删除
                </Button>
              </Popconfirm>
            ) : (
              ""
            )}
            {root[this.role][this.pathname].re_confirm &&
            this.state.demandType === "toBeConfirm" ? (
              <Button
                type="primary"
                onClick={() => {
                  this.repositoryOperation(true, item);
                }}
                style={{ marginTop: "10px" }}
              >
                确认
              </Button>
            ) : (
              ""
            )}
            {root[this.role][this.pathname].re_refused &&
            this.state.demandType === "toBeConfirm" ? (
              <Button
                type="danger"
                onClick={() => {
                  this.refused(item);
                }}
                style={{ marginLeft: "20px", marginTop: "10px" }}
              >
                拒绝
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      };
    } else {
      return "";
    }
  };

  // 获取需求单管理列表
  aquireOrderManagerByWeb() {
    axiosRequest
      .post(demandRequestPath.GetOrderManagerByWeb, {
        queryType: this.state.demandType,
        page: this.state.current,
        pageSize: this.state.pageSize,
      })
      .then((res) => {
        console.log("res", res);
        // return;
        if (res.code === 200) {
          let { Array, total } = res.data;
          // eslint-disable-next-line no-unused-expressions
          Array ? "" : (Array = []);
          Array.forEach((item) => {
            item.key = item.orderId;
            item.createdDate = moment(item.createdAt).format("YYYY-MM-DD");
            item.expectedDate = item.expectedReturnAt
              ? moment(item.expectedReturnAt).format("YYYY-MM-DD")
              : "";
            item.confirmedDate = item.confirmedAt
              ? moment(item.confirmedAt).format("YYYY-MM-DD")
              : "";
            item.senderTime = item.senderAt
              ? moment(item.senderAt).format("YYYY-MM-DD")
              : "";
            item.receiveTime = item.receiveAt
              ? moment(item.receiveAt).format("YYYY-MM-DD")
              : "";
            item.status = commonStatus[item.state];

            item.steelWeight = item.steel.weight;
            item.partWeight = item.part.weight;
            item.equipmentTotal = item.equipment.total;
            item.steelToBeWeight = item.steel.toBeWeight;
            item.partToBeWeight = item.part.toBeWeight;
            item.equipmentToBeTotal = item.equipment.toBeTotal;

            // if (this.state.demandType === "confirmOrder") {
            // }
            item.steels.forEach((child) => {
              child.createdDate = item.createdDate;
            });
            item.parts.forEach((child) => {
              child.createdDate = item.createdDate;
            });
            item.equipments.forEach((child) => {
              child.createdDate = item.createdDate;
            });
            item.handleStatus = this.handleStateObject[item.handleState];
          });
          this.setState({
            data: Array,
            demandData: Array,
            loading: false,
            total,
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log("需求单列表", error);
      });
  }
  // 删除需求单
  deleteItem = (item) => {
    Request.client
      .mutate({
        mutation: DELETE_DEMAND, // 封装好的GraphQL,
        variables: {
          input: {
            id: item.orderId,
          },
        },
      })
      .then((result) => {
        message.success("删除成功");
        this.aquireOrderManagerByWeb();
      })
      .catch((error) => {
        message.error(error.message);
        console.log(error);
      });
  };
  // 设置弹窗的显示和关闭
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加需求单" }, () => {
      if (modalVisible) {
        this.demand.handlerResetForm();
        this.demand.getProject();
        this.demand.getSize();
        this.demand.getAccessories();
        this.demand.getEquipment();
      }
      // this.demand.getCompanyUser();
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加需求单") {
      this.demand.addDemand();
    } else {
      this.demand.modifyProjectSubmit();
    }
  };
  // 关闭弹窗，由demand组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  // 修改需求单
  modify = (project) => {
    this.setState({ modalVisible: true, title: "修改需求单" }, () => {
      this.demand.handlerResetForm();
      this.demand.getProject();
      this.demand.getReposity();
      this.demand.getSize();
      this.demand.modifyProject(project);
    });
  };

  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e, current: 1 });
  };
  // 需求单类型改变时
  demandTypeChange = (e) => {
    this.setState(
      {
        loading: true,
        demandType: e,
      },
      () => {
        let newColumns = [];
        let createObject = this.createHtml();
        // eslint-disable-next-line no-unused-expressions
        createObject ? newColumns.push(createObject) : "";
        switch (e) {
          case "confirmOrder":
            this.setState(
              {
                columns: [...distributionColumnsNew, ...newColumns],
              },
              () => {
                this.aquireOrderManagerByWeb();
              }
            );
            break;
          case "StateRejected":
            this.setState(
              {
                columns: stateRejectedColumns,
              },
              () => {
                this.aquireOrderManagerByWeb();
              }
            );
            break;
          default:
            this.setState(
              {
                columns: [...awaitColumnsNew, ...newColumns],
              },
              () => {
                this.aquireOrderManagerByWeb();
              }
            );
        }
      }
    );
  };
  // 搜索的方法
  searchFunc = () => {
    let { demandData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    let result = [];
    if (searchType === "order") {
      result = demandData.filter((obj) => {
        return obj.orderNo.indexOf(keyword) !== -1;
      });
    } else if (searchType === "name") {
      result = demandData.filter((obj) => {
        return obj.projectName.indexOf(keyword) !== -1;
      });
    } else if (searchType === "admin") {
      result = demandData.filter((obj) => {
        return obj.create.indexOf(keyword) !== -1;
      });
    } else if (searchType === "repository") {
      result = demandData.filter((obj) => {
        return obj.repositoryName.indexOf(keyword) !== -1;
      });
    } else {
      result = demandData.filter((obj) => {
        return obj.status.indexOf(keyword) !== -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "order",
      },
      () => {
        this.aquireOrderManagerByWeb();
      }
    );
  };
  // 审核需求单（拒绝按钮触发的事件）
  refused = (item) => {
    this.setState({ refusedOrderId: item.orderId, visible: true });
  };
  // 输入拒绝理由后提交触发的事件
  refusedSubmit = () => {
    let reason = this.textarea.resizableTextArea.textArea.value;
    if (util.isNull(reason)) {
      message.error("请输入拒绝理由");
    } else {
      this.role === REPOSITORY_ADMIN
        ? this.repositoryOperation(
            false,
            { orderId: this.state.refusedOrderId },
            reason
          )
        : this.refusedRequst(reason);
    }
  };
  // 拒绝需求单的请求
  refusedRequst = (reason) => {
    axiosRequest
      .post(demandRequestPath.RefuseOrder, {
        id: this.state.refusedOrderId,
        isAccess: false,
        reason,
      })
      .then((res) => {
        if (res.code === 200) {
          message.success("操作成功");
          this.hideModal();
          this.aquireOrderManagerByWeb();
        } else {
          message.error(res.message);
        }
        // let { data } = res;
      })
      .catch((error) => {
        console.log("审核需求单", error);
      });
  };
  // 隐藏拒绝需求单的弹窗
  hideModal = () => {
    this.setState({ visible: false });
  };
  // 点击分配按钮
  addDemand = (item) => {
    if (
      this.props.demand.length > 0 &&
      this.props.demand[0].orderId === item.orderId
    ) {
      this.props.history.push({ pathname: "/admin/cart" });
      return;
    }
    if (
      this.props.demand.length > 0 &&
      this.props.demand[0].orderId !== item.orderId
    ) {
      this.props.resetAccessoriesAction();
      this.props.resetSteelAction();
      this.props.resetEquipmentAction();
    }
    item.equipments.forEach((equipment, index) => {
      equipment.sort = index + 1;
      equipment.key = equipment.sort;
    });
    item.steels.forEach((steel, index) => {
      steel.sort = index + 1;
      steel.key = steel.sort;
    });
    item.parts.forEach((part, index) => {
      part.sort = index + 1;
      part.key = part.sort;
    });
    localStorage.removeItem("addFlag");
    this.props.addDemandAction(item);
    this.props.history.push({ pathname: "/admin/cart" });
  };
  // 查看材料清单
  showMaterial = (item, type) => {
    if (type === "list") {
      item.steels.forEach((item, index) => {
        item.sort = index + 1;
        item.key = index;
      });
      item.parts.forEach((item, index) => {
        item.sort = index + 1;
        item.key = index;
      });
      item.equipments.forEach((item, index) => {
        item.sort = index + 1;
        item.key = index;
      });
      this.setState({
        showType: "list",
        steelData: item.steels,
        partData: item.parts,
        equipmentData: item.equipments,
        materialVisible: true,
      });
    } else {
      // eslint-disable-next-line no-unused-expressions
      item.confirmedDate = moment(item.confirmedAt).format("YYYY-MM-DD");
      switch (item.handleState) {
        case 400:
          this.setState({
            showType: "info",
            materialVisible: true,
            infoData: [item],
            infoColumnsNews: [
              ...infoColumns,
              {
                title: "拒绝理由",
                dataIndex: "rejectReason",
                width: 200,
              },
            ],
          });
          break;
        case 300:
          this.setState({
            showType: "info",
            materialVisible: true,
            infoData: [item],
            infoColumnsNews: [...infoColumns],
          });
          break;
        default:
      }
    }
  };
  // 确认归还
  returnSubmit = (item) => {
    axiosRequest
      .post(demandRequestPath.ConfirmReturn, {
        orderId: item.orderId,
      })
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          message.success("操作成功");
          this.aquireOrderManagerByWeb();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };
  // 仓库管理员操作
  repositoryOperation = (isAccess, item, reason) => {
    let data = {};
    data.orderId = item.orderId;
    data.isAccess = isAccess;
    // eslint-disable-next-line no-unused-expressions
    reason ? (data.reason = reason) : "";
    axiosRequest
      .post(demandRequestPath.RepositoryConfirm, data)
      .then((res) => {
        if (res.code === 200) {
          isAccess
            ? message.success("确认需求单成功")
            : message.success("拒绝需求单成功");
          this.hideModal();
          this.aquireOrderManagerByWeb();
        } else {
          message.success(res.message);
        }
      })
      .catch((error) => {
        isAccess
          ? message.error("确认需求单报错", error)
          : message.error("拒绝需求单报错", error);
      });
  };
  render() {
    let {
      columns,
      data,
      title,
      role,
      pathname,
      loading,
      steelData,
      partData,
      equipmentData,
      disabled,
      bounds,
      showType,
      infoData,
      infoColumnsNews,
    } = this.state;
    return (
      <div className="demand_box">
        <div className="btn_box">
          {/* <Button
            type="primary"
            style={{
              marginLeft: "20px",
              background: "#A06921",
              border: "none",
            }}
          >
            导出
          </Button> */}
          <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div>
          <div>
            <Select
              value={this.state.demandType}
              style={{ width: 120, marginRight: 10 }}
              onChange={this.demandTypeChange}
            >
              <Option value="toBeConfirm">
                {this.role === REPOSITORY_ADMIN ? "待确定" : "待分配"}
              </Option>
              <Option value="confirmOrder">
                {this.role === REPOSITORY_ADMIN ? "已确定" : "已分配"}
              </Option>
              <Option value="StateRejected">已拒绝</Option>
            </Select>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="order">订单编号</Option>
              <Option value="name">项目名称</Option>
              <Option value="status">状态</Option>
              <Option value="admin">创建人</Option>
              <Option value="repository">仓库名称</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            onChange={onChange}
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            loading={loading}
            pagination={this.paginationProps}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={() => this.submit()}
          onCancel={() => this.setModalVisible(false)}
          width="80%"
        >
          <AddDemand
            ref={(demand) => {
              this.demand = demand;
            }}
            closeModal={this.closeModal}
          ></AddDemand>
        </Modal>
        <Modal
          title="拒绝信息"
          visible={this.state.visible}
          onOk={this.refusedSubmit}
          onCancel={this.hideModal}
          okText="确认"
          cancelText="取消"
        >
          <TextArea
            showCount
            maxLength={150}
            rows={4}
            resize="false"
            ref={(textarea) => (this.textarea = textarea)}
            placeholder="请输入拒绝理由"
          />
        </Modal>
        <Modal
          centered
          visible={this.state.materialVisible}
          onOk={() => this.setState({ materialVisible: false })}
          onCancel={() => this.setState({ materialVisible: false })}
          width="80%"
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  this.setState({
                    disabled: false,
                  });
                }
              }}
              onMouseOut={() => {
                this.setState({
                  disabled: true,
                });
              }}
              onFocus={() => {}}
              onBlur={() => {}}
            >
              {showType === "list" ? "需求单材料清单" : "审批信息"}
            </div>
          }
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => this.onStart(event, uiData)}
            >
              <div ref={this.draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          {showType === "list" ? (
            <div>
              <div style={{ fontWeight: "bold", fontSize: 16 }}>型钢</div>
              <Table
                onChange={onChange}
                columns={steelColumns}
                dataSource={steelData}
                bordered
                scroll={{ x: 600 }}
                pagination={false}
              />
              <div style={{ fontWeight: "bold", marginTop: 20, fontSize: 16 }}>
                配件
              </div>
              <Table
                onChange={onChange}
                columns={partColums}
                dataSource={partData}
                bordered
                scroll={{ x: 600 }}
                pagination={false}
              />
              <div style={{ fontWeight: "bold", marginTop: 20, fontSize: 16 }}>
                设备
              </div>
              <Table
                onChange={onChange}
                columns={equipmentColums}
                dataSource={equipmentData}
                bordered
                scroll={{ x: 600 }}
                pagination={false}
              />
            </div>
          ) : (
            <div>
              <Table
                columns={infoColumnsNews}
                dataSource={infoData}
                bordered
                scroll={{ x: 600 }}
                pagination={false}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
export default connect((state) => ({ demand: state.demand }), {
  addDemandAction,
  resetAccessoriesAction,
  resetSteelAction,
  resetEquipmentAction,
})(Admin);
