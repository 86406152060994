import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, Input, Select, message } from "antd";
import Request from "../../../../utils/client";
import managementType from "../../../../utils/managementType";
import {
  ADD_MAINTENANCE,
  MODIFY_MAINTENANCE,
} from "../../../../graphql/admin/maintenance";
import { AQUIRE_ADMINISTRATOR } from "../../../../graphql/administrator/administrator";

import axiosRequest from "../../../../utils/request";
import { MaintenanceRequestPath } from "../../../../request/requestPath";

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddMaintenance(props, ref) {
  let [children, setChildren] = useState([]);
  let [id, setId] = useState([]);
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => {
    return {
      // 获取管理员
      getMaintenancePersonenance() {
        Request.client
          .query({
            query: AQUIRE_ADMINISTRATOR,
            variables: {
              input: {
                roleId: managementType.maintenance,
              },
            },
          })
          .then((result) => {
            let arr = result.data.getCompanyUser;
            let children2 = [];
            arr.forEach((item) => {
              children2.push(<Option key={item.id}>{item.name}</Option>);
            });
            setChildren(children2);
          })
          .catch((err) => {
            // this.props.history.push({ pathname: "/admin/error/systemerror" });
          });
      },
      // 添加
      addExpress() {
        form
          .validateFields()
          .then((res) => {
            // console.log(form.getFieldsValue());
            let { name, address, uid, remark } = form.getFieldsValue();
            let newUid = [];
            uid.forEach((item) => {
              newUid.push(item * 1);
            });
            axiosRequest
              .post(MaintenanceRequestPath.CreateMaintenance, {
                name,
                address,
                uid: newUid,
                remark,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("添加成功");
                  form.resetFields();
                  props.closeModal();
                } else {
                  message.success(res.message);
                }
              })
              .catch((error) => {
                //  message.error(error);
                console.log("添加维修厂报错", error);
              });
            // return;
            // Request.client
            //   .mutate({
            //     mutation: ADD_MAINTENANCE, // 封装好的GraphQL,
            //     variables: {
            //       input: {
            //         name,
            //         address,
            //         uid: newUid,
            //         remark,
            //       },
            //     },
            //   })
            //   .then((result) => {
            //     console.log(result);
            //     message.success("添加成功");
            //     form.resetFields();
            //     props.closeModal();
            //   })
            //   .catch((error) => {
            //     message.error(error.message);
            //     console.log(error);
            //   });
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },
      // 修改
      modify(obj) {
        console.log(obj);
        let { maintenanceName, address, admin, remark, maintenanceId, isAble } =
          obj;
        let uid = [];
        admin.forEach((item) => {
          uid.push(item.uid + "");
        });
        setId(maintenanceId);
        form.setFieldsValue({
          name: maintenanceName,
          address,
          remark,
          uid,
          isAble: isAble + "",
        });
      },
      // 修改维修厂提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { name, address, uid, remark, isAble } = form.getFieldsValue();
            let adminIdList = [];
            uid.forEach((item) => {
              adminIdList.push(item * 1);
            });

            axiosRequest
              .post(MaintenanceRequestPath.EditMaintenance, {
                name,
                address,
                adminIdList,
                remark,
                id,
                isAble: isAble === "true" ? true : false,
              })
              .then((res) => {
                if (res.code === 200) {
                  console.log(res);
                  message.success("修改成功");
                  form.resetFields();
                  props.closeModal();
                } else {
                  message.error(res.message);
                }
              })
              .catch((error) => {
                console.log("编辑维修厂错误", error);
              });

            //   Request.client
            //     .mutate({
            //       mutation: MODIFY_MAINTENANCE, // 封装好的GraphQL,
            //       variables: {
            //         input: {
            //           name,
            //           address,
            //           adminIdList: uid,
            //           remark,
            //           id,
            //         },
            //       },
            //     })
            //     .then((result) => {
            //       console.log(result);
            //       message.success("修改成功");
            //       form.resetFields();
            //       props.closeModal();
            //     })
            //     .catch((error) => {
            //       message.error(error.message);
            //       console.log(error);
            //     });
          })
          .catch((err) => {
            console.log(err);
            message.error(err.message);
          });
      },
      handlerResetForm() {
        form.resetFields();
      },
    };
  });

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="name"
          label="维修厂名称"
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>

        <Form.Item
          label="地址"
          name="address"
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="uid"
          label="管理员"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择管理员",
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: 300 }}
            placeholder="请选择管理员"
            onChange={handleChange}
            maxTagCount={3}
          >
            {children}
          </Select>
        </Form.Item>
        <Form.Item
          label="备注"
          name="remark"
          rules={[{ required: true, message: "Username is required" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="isAble"
          label="状态"
          hasFeedback
          rules={[
            {
              required: true,
              message: "不能为空",
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: 300 }}
            placeholder="请选择状态"
            onChange={handleChange}
          >
            <Option value="true">正常</Option>
            <Option value="false">停用</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddMaintenance);
