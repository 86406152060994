import Request from "../../utils/client";

// 获取需求单
export const AQUIRE_DEMAND = Request.gql`
   query ($input: GetOrderListInput!) {
  getOrderList(input: $input) {
    id
    state
    orderNo
    project {
      id
      name
    }
    repository {
      id
      name
    }
    state
    expectedReturnAt
    partList{
     partSpecification
      total
      partName
    }
    createdAt
    createUser {
      id
      name
    }
    confirmedUser {
      id
      name
    }
    confirmedAt
    expressList {
      id
      direction
      expressNo
      receiveAt
      receiver{ 
        isAble
      }
      sender {
        id
        name
      }
    }
    total
    weight  
    orderNo
    remark
    orderSpecificationList{
      specification
      total
      weight
    }
    equipmentList{
      equipmentName
      total
      equipmentSpecification
      weight
    }
  }
}
`;

export const DETAIL_DEMAND = Request.gql`
query ($input: GetOrderDetailForBackEndInput!) {
    getOrderDetailForBackEnd(input: $input){
      list {
        id
        order {
          orderNo
          project {
            id
            name
            
          }
          repository {
            id
            name
            
          }
          
        }
        specification
        total
        weight
       
      }
      total # 总的数据条数
      weight # 总的重量
      steelTotal # 型钢数量
      
    
   }
  }
`;
// 获取项目
export const DEMAND_PROJECT = Request.gql`
    query {
        getProjectLis {
        id
        name
        address
        remark
        startedAt
        leaderList {
            id
            name
        }
        city
        endedAt
        }
    }
`;
// 获取仓库
export const DEMAND_REPOSITY = Request.gql`
    query getRepositoryListQuery {
        getRepositoryList{
        id
        name
        leaders {
            name
            id
        }
        }
    }
`;
// 获取规格尺寸
export const DEMAND_SIZE = Request.gql`
    query {
        getSpecification {
        id
        specification 
        weight
        }
    }
`;

// 添加需求单
export const ADD_DEMAND = Request.gql`
    mutation ($input: CreateOrderInput!) {
  createOrder(input: $input) {
    id
    project{
      name
    }
    state
    usetime
    createUser{
      name
    }
    confirmedUser{
      name
    }
    expressList{
      expressNo
      expressCompany{
        name
      }
    }
    remark
    orderNo
    total
    weight
    repository{
    	name
    }
    equipmentList{
      equipmentName
      equipmentSpecification
      total
      id
    }
    partList{
      partName
      partSpecification
      total
    }
    orderSpecificationList{
      specificationInfo{id}
      total
      weight
    }
    createdAt
    confirmedAt
    expectedReturnAt
  }
}
  
`;

// 修改需求单
export const MODIFY_DEMAND = Request.gql`
    mutation ($input: EditOrderInput!) {
        editOrder(input: $input) {
        id
        }
    }
`;

// 删除需求单
export const DELETE_DEMAND = Request.gql`
    mutation ($input: DeleteOrderInput!){
        deleteOrder(input: $input) 
    }
`;

// 获取设备列表
export const AQUIRE_EQUIPMENT_LIST = Request.gql`
    query{
        getequipmentList{
            id
            weight
        equipmentName
        identification
        equipmentSpecification
        }
    }
`;
// 获取配件列表
export const AQUIRE_ACCESSORIES_LIST = Request.gql`
   query{
        getPartList{
            id
            weight
            PartName
            partSpecification
        }
    }
`;

// 审核需求单
export const AUDIT_DEMAND_ORDER = Request.gql`
  mutation ($input: ConfirmOrderInput!) {
    confirmOrRejectOrder(input: $input) {
      id
    }
  }
`;
