import React, { Component } from "react";
import { createFromIconfontCN } from "@ant-design/icons";
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_2969789_mk4c83ik6.js",
});

export default class MenuIcon extends Component {
  render() {
    let { icon, width, color } = this.props;
    // eslint-disable-next-line no-unused-expressions
    width ? "" : (width = 14);
    return (
      <span
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap !important",
        }}
      >
        {/* <img
          src={icon}
          alt=""
          style={{ width: width + "px", marginRight: "3px" }}
        /> */}
        {/* <span className="icon icon-weixiu" style={{ fontSize: "20rpx" }}></span> */}
        <IconFont
          type={icon}
          style={{
            color: color ? color : "white",
            fontSize: 16,
            marginRight: 5,
          }}
        />
      </span>
    );
  }
}
