/**
 * 设备选择
 */
import React, { Component } from "react";
import { Table, Button, Input, Select, message } from "antd";

import { connect } from "react-redux";
import { addEquipmentAction } from "../../../redux/actions/equipment";
import checkFunc from "../../../redux/util/check";

import axiosRequest from "../../../utils/request";

import commonStatus from "../../../utils/status";
import { REPOSITORY_LIST } from "../../../graphql/filter/filter";
import Request from "../../../utils/client";

import "./demand.scss";

const { Option } = Select;

function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}
class SelectEquipment extends Component {
  constructor(props) {
    super(props);
    let userInfo = localStorage.getItem("userInfo");
    let { role } = JSON.parse(userInfo);
    let pathname = window.location.pathname;

    let columns = [
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "仓库名称",
        dataIndex: "repositoryName",
        width: 150,
        // ...this.getColumnFilterProps("warehouse"),
      },
      {
        title: "设备名称",
        dataIndex: "equipmentName",
        width: 150,
        // ...this.getColumnFilterProps("equipment_name"),
      },
      // {
      //   title: "设备状态",
      //   dataIndex: "status",
      //   width: 150,
      //   // ...this.getColumnFilterProps("status"),
      // },
      {
        title: "规格尺寸",
        dataIndex: "specification",
        width: 150,
        // ...this.getColumnFilterProps("specification"),
      },
      {
        title: "库存数量",
        dataIndex: "Surplus",
        width: 150,
      },
      {
        title: "所需数量",
        dataIndex: "needNum",
        width: 150,
      },
      {
        title: "总需求量",
        dataIndex: "demandNum",
        width: 150,
      },
      {
        title: "添加数量",
        key: "addnum",
        width: 200,
        fixed: "right",
        align: "center",
        render: (item) => (
          <div>
            <Input
              placeholder="请输入数量"
              bordered={false}
              ref={(input) => {
                this[item.ref] = input;
              }}
              type="number"
              max={item.surplus}
              min={0}
            />
          </div>
        ),
      },
      {
        title: "操作",
        key: "operation",
        width: 200,
        fixed: "right",
        align: "center",
        render: (item) => (
          <div>
            <Button
              type="text"
              onClick={() => {
                this.add(item);
              }}
            >
              添加
            </Button>
          </div>
        ),
      },
    ];
    this.state = {
      role,
      modalVisible: false,
      data: [],
      demandData: [], // 存储获取的数据，用于用户搜索时
      pathname,
      columns,
      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      searchType: "order",
      loading: true,
      reposityChildren: [],
      repositySelect: "all",
    };
  }

  componentDidMount() {
    this.aquireData(0);
    this.aquireReposity();
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };

  aquireData = (repositoryId) => {
    this.setState({ loading: true });
    let data = {
      type: 2,
      repositoryId,
    };
    axiosRequest
      .post("/AddThirdSelectApi", data)
      .then((res) => {
        // console.log("获取的设备选择列表", res);
        // console.log("demand", this.props.demand);
        let { data } = res;
        data.forEach((item, index) => {
          item.status = commonStatus[item.state];
          item.sort = index + 1;
          item.key = index;
          item.ref = "input" + index;
          if (this.props.demand.length > 0) {
            let index = this.props.demand[0].equipments.findIndex(
              (equipment) => {
                return equipment.specification === item.specification;
              }
            );
            index > -1
              ? (item.needNum = this.props.demand[0].equipments[index].total)
              : (item.needNum = 0);
            index > -1
              ? (item.demandNum =
                  this.props.demand[0].equipments[index].demandNum)
              : (item.demandNum = 0);
          } else {
            item.needNum = "未选择需求单";
          }
        });
        this.setState({ data, loading: false });
      })
      .catch((error) => {
        console.log("获取的设备选择列表", error);
      });
  };

  // 获取仓库列表
  aquireReposity = () => {
    Request.client
      .query({
        query: REPOSITORY_LIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取仓库列表", result);
        let { getRepositoryList } = result.data;
        let children = [];
        children.push(<Option key="all">仓库（全部）</Option>);
        getRepositoryList.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          reposityChildren: children,
          repositySelect: "all",
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("无法从服务器中获取到仓库数据");
      });
  };

  // 仓库列表选项改变
  handleChange3 = (value) => {
    // console.log(`selected ${value}`);

    // this.paginationProps.current = 1;
    this.setState(
      {
        repositySelect: value,
        current: 1,
      },
      () => {
        value === "all" ? this.aquireData(0) : this.aquireData(value * 1);
      }
    );
  };

  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { demandData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    let result = [];
    if (searchType === "order") {
      result = demandData.filter((obj) => {
        return obj.orderNo.indexOf(keyword) !== -1;
      });
    } else if (searchType === "name") {
      result = demandData.filter((obj) => {
        return obj.projectName.indexOf(keyword) !== -1;
      });
    } else if (searchType === "admin") {
      result = demandData.filter((obj) => {
        return obj.create.indexOf(keyword) !== -1;
      });
    } else if (searchType === "repository") {
      result = demandData.filter((obj) => {
        return obj.repositoryName.indexOf(keyword) !== -1;
      });
    } else {
      result = demandData.filter((obj) => {
        return obj.status.indexOf(keyword) !== -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "order",
      },
      () => {
        this.aquireData();
      }
    );
  };
  //   添加
  add = (item) => {
    item.id = item.equipmentInfoId;
    item.surplus = item.Surplus;
    item.selectNum = this[item.ref].input.value;
    if (this.props.demand.length === 0) {
      message.error("请先选择需求单");
      return;
    }
    let { flag, msg } = checkFunc("equipment", this.props.demand, item);
    if (!flag) {
      message.error(msg);
      return;
    }
    if (item.Surplus === 0) {
      message.error("库存不足");
      return;
    }
    if (this[item.ref].input.value === "") {
      message.error("请输入数量");
      return;
    }
    if (this[item.ref].input.value > item.Surplus) {
      message.error("添加的数量不能超过剩余数量");
      return;
    }

    console.log("执行了");
    this.props.add(item);
  };
  // 取消
  canel = () => {};

  getColumnFilterProps = (dataIndex) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <div>
          {dataIndex === "status" ? (
            <Select
              // defaultValue={statusDefault.desc}
              // key={statusDefault.desc}
              value={this.state.statusSelect}
              style={{ width: 200 }}
              onChange={this.handleChange}
            >
              {this.state.statusChildren}
            </Select>
          ) : dataIndex === "size" ? (
            <Select
              value={this.state.sizeSelect}
              style={{ width: 270 }}
              onChange={this.handleChange2}
            >
              {this.state.sizeChildren}
            </Select>
          ) : (
            <Select
              value={this.state.repositySelect}
              style={{ width: 200 }}
              onChange={this.handleChange3}
            >
              {this.state.reposityChildren}
            </Select>
          )}
        </div>
      </div>
    ),
  });

  render() {
    let { columns, data, loading } = this.state;
    return (
      <div className="demand_box">
        {/* <div className="btn_box">
          <div style={{ fontWeight: "bold", fontSize: "18px" }}>设备选择</div>
        </div>
        <div className="table_box">
          <Table
            onChange={onChange}
            columns={columns}
            // dataSource={data}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
            loading={loading}
          />
        </div> */}
        <Table
          onChange={onChange}
          columns={columns}
          // dataSource={data}
          dataSource={data}
          bordered
          scroll={{ x: 600 }}
          pagination={false}
          loading={loading}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({ equipment: state.equipment, demand: state.demand }),
  {
    add: addEquipmentAction,
  }
)(SelectEquipment);
