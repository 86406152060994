import React, { Component } from "react";
import { Table, Button, Input, InputNumber, message, Select } from "antd";
// import Request from "../../../utils/client";

import "./detail.scss";
// import { DETAIL_WAREHOUSE } from "../../../graphql/admin/warehouse";

import axiosRequest from "../../../utils/request";
import { RepositoryRequestPath } from "../../../request/requestPath";

const { Option } = Select;
function onChange(pagination, filters, sorter, extra) {
  // console.log("params", pagination, filters, sorter, extra);
}
export default class log extends Component {
  state = {
    modalVisible: false,
    searchText: "",
    searchedColumn: "",
    filteredInfo: null,
    data: [],
    detailData: [], // 存储获取的数据，用于用户搜索时
    searchType: "name",
  };
  componentDidMount() {
    this.aquireData();
  }
  aquireData = () => {
    axiosRequest
      .post(RepositoryRequestPath.RepositoryDetail, {})
      .then((res) => {
        console.log("获取的仓库详情", res);
        if (res.code === 200) {
          res.data.forEach((item, index) => {
            item.key = index;
            item.asset = item.asset.toFixed(3);
            item.weight = item.weight.toFixed(3);
          });

          this.setState({
            data: res.data,
            detailData: res.data,
          });
        } else {
          message.error("获取仓库详情错误");
        }
      })
      .catch((error) => {
        console.log("获取仓库详情错误");
      });
  };

  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { detailData, searchType } = this.state;

    // console.log(this.state.searchType,)
    let result = [];
    if (searchType === "name") {
      let keyword = this.keywordInput.state.value;
      if (keyword === undefined) {
        message.error("请输入关键字搜索");
        return;
      }
      result = detailData.filter((obj) => {
        return obj.name.indexOf(keyword) !== -1;
      });
    } else {
      let numberKey = this.numberInput.value;
      if (numberKey === "") {
        message.error("请输入关键字搜索");
        return;
      }
      if (searchType === "free") {
        result = detailData.filter((obj) => {
          return parseInt(obj.asset) === parseInt(numberKey);
        });
      } else if (searchType === "total") {
        result = detailData.filter((obj) => {
          return parseInt(obj.total) === parseInt(numberKey);
        });
      } else {
        result = detailData.filter((obj) => {
          return parseInt(obj.weight) === parseInt(numberKey);
        });
      }
    }

    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    if (this.state.searchType === "name") {
      this.keywordInput.state.value = "";
    } else {
      this.numberInput.value = "";
    }

    this.setState(
      {
        searchType: "name",
      },
      () => {
        this.aquireData();
      }
    );
  };
  render() {
    const columns = [
      {
        title: "序号",
        dataIndex: "id",
        fixed: "left",
        width: 100,
      },
      {
        title: "仓库名称",
        dataIndex: "name",
        width: 150,
      },
      {
        title: "型钢规格",
        dataIndex: "specification",
        width: 150,
      },
      {
        title: "数量",
        dataIndex: "total",
        width: 150,
      },
      {
        title: "重量（吨）",
        dataIndex: "weight",
        width: 150,
      },
      {
        title: "资产价值（万元）",
        dataIndex: "asset",
        width: 150,
      },
    ];
    let { data } = this.state;
    return (
      <div className="detail_box">
        <div className="btn_box">
          <Select
            value={this.state.searchType}
            style={{ width: 120 }}
            onChange={this.handleChange}
          >
            <Option value="name">仓库名称</Option>
            <Option value="free">费用</Option>
            <Option value="total">数量</Option>
            <Option value="weight">重量</Option>
          </Select>
          {this.state.searchType === "name" ? (
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
          ) : (
            <InputNumber
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(numberInput) => {
                this.numberInput = numberInput;
              }}
            />
          )}

          <Button
            type="primary"
            onClick={this.searchFunc}
            style={{ marginLeft: 10 }}
          >
            搜索
          </Button>
          <Button
            type="primary"
            onClick={this.resetFunc}
            style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
          >
            重置
          </Button>
        </div>
        <div className="table_box">
          <Table
            onChange={onChange}
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}
