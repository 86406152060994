export const DELETE_STEEL = "deleteSteel"; // 删除
export const ADD_STEEL = "addSteel"; // 添加
export const RESET_STETL = "resetSteel"; //清空

export const DELETE_EQUIPMENT = "deleteEquipment"; // 删除
export const ADD_EQUIPMENT = "addEquipment"; // 添加
export const RESET_EQUIPMENT = "resetEquipment"; //清空

export const DELETE_ACCESSORIES = "deleteAccessories"; // 删除
export const ADD_ACCESSORIES = "addAccessories"; // 添加
export const RESET_ACCESSORIES = "resetAccessories"; //清空

export const ADD_DEMAND = "addDemand"; // 添加
export const RESET_DEMAND = "resetDemand";
