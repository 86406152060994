import React, { Component } from "react";
import { Table, Button, Select, Radio, message } from "antd";
import {
  AQUIRE_LOG_TYPE,
  // AQUIRE_LOG
} from "../../../graphql/admin/sysLog";
import Request from "../../../utils/client";
import axiosRequest from "../../../utils/request";
import { GetLogList } from "../../../request/requestPath";
import "./log.scss";
import moment from "moment";
const { Option } = Select;
export default class log extends Component {
  columns = [
    {
      title: "序号",
      dataIndex: "id",
      fixed: "left",
      width: 100,
    },
    {
      title: "操作类型",
      dataIndex: "type",
      width: 150,
    },
    {
      title: "操作内容",
      dataIndex: "content",
      width: 150,
    },
    {
      title: "操作用户",
      dataIndex: "userName",
      width: 150,
    },
    {
      title: "操作时间",
      dataIndex: "date",
      width: 100,
    },
  ];
  state = {
    data: [],
    modalVisible: false,
    children: [],
    defaultValue: {},
    value: 2,
    select: "all",
    total: 0,
    current: 1,
    pageSize: 20,
    loading: true,
  };
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };
  componentDidMount() {
    this.getLogType();
  }
  // 是否展示全部改变
  onChange = (e) => {
    // console.log("radio checked", e.target.value);
    this.paginationProps.current = 1;
    this.setState(
      {
        value: e.target.value,
        current: 1,
      },
      () => {
        this.getLogList();
      }
    );
  };

  // 日志类型改变
  handleChange = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        select: value,
        current: 1,
      },
      () => {
        this.getLogList();
      }
    );
  };
  // 页面发生改变
  changePage = (page) => {
    // console.log(page)
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.getLogList();
      }
    );
  };
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    console.log(pageSize, current);
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.getLogList();
      }
    );
  };

  // 获取日志类型
  getLogType = () => {
    Request.client
      .query({
        query: AQUIRE_LOG_TYPE,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取日志类型", result);
        let { getLogTypeList } = result.data;
        let children = [];
        children.push(<Option key="all">全部</Option>);
        getLogTypeList.forEach((item) => {
          children.push(<Option key={item.flag}>{item.desc}</Option>);
        });
        this.setState(
          {
            children,
            // defaultValue: getLogTypeList[0] ? getLogTypeList[0] : {},
            // select: getLogTypeList[0] ? getLogTypeList[0].flag : "",
          },
          () => {
            this.getLogList();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        message.error("无法获取从服务器中获取到日志类型数据");
      });
  };
  // 获取日志
  getLogList = () => {
    let input = {};
    let { current, pageSize } = this.state;
    this.state.value == 1
      ? (input.isShowAll = true)
      : (input.isShowAll = false);
    input.page = current;
    input.pageSize = pageSize;
    if (this.state.select !== "all") {
      input.type = this.state.select;
    }

    axiosRequest
      .post(GetLogList, { ...input })
      .then((res) => {
        if (res.code === 200) {
          let { list, total } = res.data;
          list.forEach((item, index) => {
            item.sort = index + 1;
            item.key = index + 1;
            item.date = moment(item.CreatedAt).format("YYYY-MM-DD");
          });
          this.paginationProps.total = total;
          // console.log(getLogList.list);
          this.setState({
            data: list,
            total: total,
            loading: false,
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log("获取日志列表错误", error);
      });

    // Request.client
    //   .query({
    //     query: AQUIRE_LOG,
    //     variables: {
    //       input,
    //     },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     // console.log("获取日志", result);
    //     let { getLogList } = result.data;
    //     getLogList.list.forEach((item, index) => {
    //       item.sort = index + 1;
    //       item.key = index + 1;
    //       item.date = moment(item.createdAt).format("YYYY-MM-DD");
    //       item.userName = item.user.name;
    //       item.type = item.typeInfo.desc;
    //     });
    //     this.paginationProps.total = getLogList.total;
    //     // console.log(getLogList.list);
    //     this.setState({
    //       data: getLogList.list,
    //       total: getLogList.total,
    //       loading: false,
    //     });
    //   })
    //   .catch((err) => {
    //     // this.props.history.push({ pathname: "/admin/error/systemerror" });
    //   });
  };

  // 重置
  resetFunc = () => {
    this.setState(
      {
        value: 2,
        select: "all",
      },
      () => {
        this.getLogList();
      }
    );
  };

  render() {
    let { children, select, value, data, loading } = this.state;
    return (
      <div className="log_box">
        <div className="btn_box">
          <div className="btn_right">
            <div>
              是否展示全部：
              <Radio.Group onChange={this.onChange} value={value}>
                <Radio value={1}>是</Radio>
                <Radio value={2}>否</Radio>
              </Radio.Group>
            </div>

            <div>
              日志类型：
              <Select
                value={select}
                style={{ width: 120 }}
                onChange={this.handleChange}
              >
                {children}
              </Select>
            </div>
          </div>
          <div className="btn" style={{ marginLeft: 10 }}>
            <Button
              type="primary"
              style={{ background: "#7BAC1C", border: "none" }}
              onClick={this.resetFunc}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          {value === 1 ? (
            <Table
              loading={loading}
              columns={this.columns}
              dataSource={data}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
            />
          ) : (
            <Table
              loading={loading}
              columns={this.columns}
              dataSource={data}
              bordered
              scroll={{ x: 600 }}
              pagination={this.paginationProps}
            />
          )}
        </div>
      </div>
    );
  }
}
