import Request from "../../utils/client";

// 获取短信配置
export const GET_MESSAGE = Request.gql`
    query {
        getSMSConfig {
        accessKey # 短信accesskey 
        accessSecretKey  # 短信accessSecreptkey 
        sign # 签名
        template # 模板
        }
    }
`;
// 修改短信配置
export const MODIFY_MESSAGE = Request.gql`
    mutation ($input: SetSMSConfigInput!) {
        setSMSConfig(input: $input) {
            accessKey # 
            accessSecretKey 
            sign
            template
        }
    }
`;
