import React, { Component } from "react";

import { format } from "../../../../utils/dateFormate";
// import { AQUIRE_PROJECT } from "../../../../graphql/admin/project";
// import Request from "../../../../utils/client";
import "./map.scss";

let map = null;

class DemoColumn extends Component {
  state = {
    projectData: [],
  };
  componentDidMount() {
    // let BMapGL = await BaiduMap();
    // console.log("BMapGL", BMapGL);
    let { BMapGL } = window;
    map = new BMapGL.Map("container"); // 创建地图实例
    var point = new BMapGL.Point(116.404, 39.915); // 创建点坐标
    map.centerAndZoom(point, 5); // 初始化地图，设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true);
    var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
    map.addControl(zoomCtrl);
  }
  markersEvents = {
    click: (MapsOption, marker) => {
      // console.log("MapsOptions:");
      // console.log(MapsOption);
      // console.log("marker:");
      // console.log(marker);
    },
  };
  clickMarker(e) {
    // console.log(e);
    // console.log(e.target._originOpts.title);
  }

  // 渲染项目数据
  initProjectMap = (projectList) => {
    let list = [];
    projectList.forEach((item, index) => {
      item.sort = index + 1;
      let obj = Object.assign({}, item);
      obj.startedAt = format(item.startedAt);
      list.push(obj);
    });
    // console.log(list);
    this.setState({ projectData: list }, () => {
      // console.log("list", list);
      let { BMapGL } = window;
      list.forEach((item) => {
        // 创建添加点标记
        var marker = new BMapGL.Marker(
          new BMapGL.Point(item.longitude, item.latitude)
        );
        map.addOverlay(marker);
        var opts = {
          width: 300, // 信息窗口宽度
          // height: 100, // 信息窗口高度
          title: item.projectName, // 信息窗口标题
          message: item.projectName,
        };
        // 创建图文信息窗口
        var sContent = `<div style='padding:0 10px 20px 10px;width:100%'>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              地址：${item.city.split("/").join("")}${item.address}
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              开始时间：${item.startedAt}
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              备注：${item.remark}
              </div>
            </div>`;
        var infoWindow = new BMapGL.InfoWindow(sContent, opts);
        // marker添加点击事件
        marker.addEventListener("click", function () {
          this.openInfoWindow(infoWindow);
        });
      });
    });
  };

  // 渲染仓库数据
  initWarehouse = (warehouseList) => {
    console.log("仓库", warehouseList);
    let list = [];
    warehouseList.forEach((item, index) => {
      item.sort = index + 1;
      let obj = Object.assign({}, item);
      obj.startedAt = format(item.startedAt);
      list.push(obj);
    });
    // console.log(list);
    this.setState({ projectData: list }, () => {
      console.log("list", list);
      let { BMapGL } = window;
      list.forEach((item) => {
        // 创建添加点标记
        var marker = new BMapGL.Marker(
          new BMapGL.Point(item.longitude, item.latitude)
        );
        map.addOverlay(marker);
        var opts = {
          width: 300, // 信息窗口宽度
          // height: 100, // 信息窗口高度
          title: item.name, // 信息窗口标题
          message: item.name,
        };
        // 创建图文信息窗口
        var sContent = `<div style='padding:0 10px 20px 10px;width:100%'>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              地址：${item.city.split("/").join("")}${item.address}
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              型钢总重量：${item.steelWeight}
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              型钢总数量：${item.steelTotal}
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              备注：${item.remark}
              </div>
            </div>`;
        var infoWindow = new BMapGL.InfoWindow(sContent, opts);
        // marker添加点击事件
        marker.addEventListener("click", function () {
          this.openInfoWindow(infoWindow);
        });
      });
    });
  };

  render() {
    // let {AMap}=this.state
    return (
      <div style={{ padding: "0 20px", height: "100%", width: "100%" }}>
        <div id="container" style={{ height: "100%", width: "100%" }}></div>
      </div>
    );
  }
}

export default DemoColumn;
