import icon1 from "../../../assets/images/index/u66.png";
import icon2 from "../../../assets/images/index/u69.png";
import icon3 from "../../../assets/images/index/u72.png";
import icon4 from "../../../assets/images/index/u75.png";
import icon5 from "../../../assets/images/index/u81.png";
import icon6 from "../../../assets/images/index/u84.png";
import icon7 from "../../../assets/images/index/u87.png";
import icon8 from "../../../assets/images/index/u90.png";
import icon9 from "../../../assets/images/index/u323.png";
import icon10 from "../../../assets/images/index/u328.png";
import icon11 from "../../../assets/images/index/u333.png";
import icon12 from "../../../assets/images/index/u338.png";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
  icon12,
};
