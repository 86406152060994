// 主页相关接口
export const indexRequestPath = {
  // 型钢储备及损耗量
  SteelReserveAndLoss: "/SteelReserveAndLoss",

  // 维修概况
  Maintenance: "/Maintenance",

  // 型钢使用情况
  SteelUsage: "/SteelUsage",

  // 型钢储备及损耗量
  CompanySteelReserveAndLoss: "/CompanySteelReserveAndLoss",

  // 型钢使用率
  GetSteelUsageByRepository: "/GetSteelUsageByRepository",

  // 型钢查询
  SteelQueryByCompany: "/SteelQueryByCompany",

  //  公司管理员型钢查询
  GetSteelForBack: "/GetSteelForBack",

  // 资产概况
  Assets: "/Assets",

  // 资产状态
  AssetState: "/AssetState",

  // 型钢最新使用动向
  GetNewState: "/GetNewState",

  //  获取项目现场型钢数据
  GetProjectSteelDateByCompany: "/GetProjectSteelDataByCompany",

  // 获取仓库
  GetRepository: "/GetRepository",
};

// 超级管理员主页相关接口
export const AdminIndexRequest = {
  // 资产概况
  SuperManAssets: "/SuperManAssets",

  // 资产状态
  SuperManAssetState: "/SuperManAssetState",

  // 型钢储备及损耗量
  SteelReserveAndLoss: "/SteelReserveAndLoss",

  // 型钢使用情况
  SteelUsageBySuper: "/SteelUsageBySuper",

  // 型钢使用率
  GetSteelUsageBySuper: "/GetSteelUsageBySuper",

  // 获取项目
  GetProjectListBySuper: "/GetProjectListBySuper",

  //  获取项目现场型钢数据
  GetProjectSteelDataByCompany: "/GetProjectSteelDataByCompany",

  // 获取项目型钢状态
  GetProjectSteelStateBySuper: "/GetProjectSteelStateBySuper",

  // 获取最新型钢动向
  GetNewStateBySuper: "/GetNewStateBySuper",
};

export const ProjectIndexRequest = {
  GetSteelNewState: "/GetSteelNewState",
};

// 维修管理员主页面相关接口
export const maintenanceIndexRequest = {
  // 获取维修概况
  Maintenance: "/Maintenance",

  // 维修率
  MaintenanceRate: "/MaintenanceRate",

  // 维修动向
  GetSteelByMorder: "/GetSteelByMorder",
};

// 需求单相关的接口
export const demandRequestPath = {
  // 选择型钢、配件、设备
  AddThirdSelect: "/AddThirdSelectApi",

  // 拒绝需求单（公司管理员）
  RefuseOrder: "/RefuseOrder",

  // 确认需求单（公司管理员）
  ConfirmOrder: "/ConfirmOrder",

  // 添加需求单
  CreateOrder: "/CreateOrder",

  // 获取需求单列表
  GetOrderList: "/GetOrderList",

  // 需求单详情
  GetOrderDetail: "/GetOrderDetail",

  // 归还
  ConfirmReturn: "/ConfirmReturn",

  // 仓库管理员审核需求单
  RepositoryConfirm: "/RepositoryConfirm",

  // 需求单管理
  GetOrderManagerByWeb: "/GetOrderManagerByWeb",

  // 需求单列表
  GetOrderListByWeb: "/GetOrderListByWeb",
};

// 仓库管理相关接口
export const warehouseRequestPath = {
  // 设备历史记录
  equipmenthistory: "/GetMultipleEquipmentDataDetail",

  // 型钢历史信息的接口
  GetHistorySteelList: "/GetHistorySteelList",
};

// 码表管理相关接口
export const clockRequestPath = {
  // 添加型钢规格
  CreateSpecification: "/CreateSpecification",

  // 获取型钢规格列表
  GetSpecification: "/GetSpecification",

  // 编辑型钢规格
  EditSpecification: "/EditSpecification",

  // 添加配件规格
  CreatePartInfo: "/CreatePartInfo",

  // 获取配件列表
  GetPartList: "/GetPartList",

  // 编辑配件
  EditPart: "/EditPart",

  // 添加设备规格
  CreateEquipment: "/CreateEquipment",

  // 获取设备列表
  GetEquipmentList: "/GetEquipmentList",

  // 编辑设备
  EditEquipment: "/EditEquipment",
};

// 维修单相关接口
export const maintenanceRequestPath = {
  GetMaintenanceList: "/GetMaintenanceList", // 维修厂列表

  GetMorderList: "/GetMorderList", // 维修单详情

  GetMaintenanceOrderList: "/GetMaintenanceOrderList", // 维修单列表

  // 审核维修单
  ConfirmOrRefuseMorder: "/ConfirmOrRefuseMorder",

  // 确认归还、确认维修
  ConfirmMorderReturn: "/ConfirmMorderReturn",

  // 报废单审核
  ConfirmScrapMorder: "/ConfirmScrapMorder",

  // 维修单管理
  GetMorderManage: "/GetMorderManage",
};

// 公司相关接口
export const companyRequestPath = {
  // 获取公司列表
  GetAllCompany: "/GetAllCompany",

  // 编辑公司
  EditCompany: "/EditCompany",

  // 获取人员列表
  GetCompanyUser: "/GetCompanyUser",

  // 编辑公司人员
  EditCompanyUser: "/EditCompanyUser",

  // 添加仓库
  CreateRepository: "/CreateRepository",
};

// 维修厂相关接口
export const MaintenanceRequestPath = {
  // 创建维修厂
  CreateMaintenance: "/CreateMaintenance",

  // 编辑维修厂
  EditMaintenance: "/EditMaintenance",
};

// 仓库相关接口
export const RepositoryRequestPath = {
  // 仓库列表
  RepositoryList: "/RepositoryDetail",

  // 仓库列表
  RepositoryDetail: "/RepositorySteelDetail",

  // 编辑仓库
  EditRepository: "/EditRepository",
};

// 项目相关接口
export const ProjectRequestPath = {
  // 编辑项目
  EditProject: "/EditProject",

  // 创建项目
  CreateProject: "/CreateProject",

  // 获取项目
  GetProjectList: "/GetProjectList",

  // 获取项目详情
  GetProjectDetail: "/GetProjectDetail",
};

// 登录
export const Login = "/Login";

// 修改单价
export const EditPrice = "/EditPrice";

// 获取日志列表
export const GetLogList = "/GetLogList";
