import React, { Component } from "react";
import { Button, Modal, message } from "antd";
import Request from "../../../utils/client";
import AddPrice from "./components/AddPrice";
import root from "../../../config/root";
import { AQUIRE_PRICE } from "../../../graphql/admin/clock";

import "./price.scss";

export default class log extends Component {
  constructor(props) {
    super(props);
    let userInfo = localStorage.getItem("userInfo");

    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
    }

    this.state = {
      role,
      pathname,
      price: 0,
    };
  }

  state = {
    modalVisible: false,
  };
  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    Request.client
      .query({
        query: AQUIRE_PRICE,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("价格",result)

        this.setState({ price: result.data.getPrice });
      })
      .catch((error) => {
        // this.props.history.push({ pathname: "/admin/error/systemerror" });
      });
  };

  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "修改价格" }, () => {
      this.addTrans.modify(this.state.price);
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    this.addTrans.modifyProjectSubmit();
  };

  // 关闭弹窗，由addTrans组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };

  render() {
    let { role, pathname, title, price } = this.state;
    return (
      <div className="price_box">
        <div className="btn_box">
          <Button
            type="primary"
            onClick={() => this.setModalVisible(true)}
            disabled={root[role][pathname].add ? false : true}
          >
            修改
          </Button>
        </div>

        <div className="table_box">
          <div className="inner_box">
            <div className="text_box">价格（万元/吨）</div>
            <div className="price_box">{price}</div>
          </div>
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={() => this.setModalVisible(false)}
        >
          <AddPrice
            ref={(addTrans) => {
              this.addTrans = addTrans;
            }}
            closeModal={this.closeModal}
          ></AddPrice>
        </Modal>
      </div>
    );
  }
}
