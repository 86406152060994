import Request from "../../utils/client";
// 获取制造商
export const AQUIRE = Request.gql`
  query getManufacturersQuery{
    getManufacturers{
      id
      isDefault
      name
      remark
    }
  }
`;
// 删除制造商
export const DELETE = Request.gql`
  mutation deleteManufacturerMutation($id: Int!) {
    deleteManufacturer(id: $id) 
  }
`;
// 添加制造商
export const ADD_MANU = Request.gql`
  mutation createManufacturerMutation($input: CreateManufacturerInput!) {
    createManufacturer(input: $input) {
      id
      name
      isDefault
    }
  }
`;
// 修改制造商
export const MODIFY_MANU = Request.gql`
  mutation editManufacturerManutation($input: EditManufacturerInput! ){
    editManufacturer(input: $input) {
      id
      name
      isDefault
      remark
    }
  }
`;

// 获取材料商
export const AQUIRE_MATERIAL = Request.gql`
query  getMaterialManufacturersQuery {
  getMaterialManufacturers{
    id
    name
    isDefault
    remark
  }
}
`;
// 删除材料商
export const DELETE_MATERRIAL = Request.gql`
mutation deleteMaterialManufacturer($deleteId: Int!){
  deleteMaterialManufacturer(id: $deleteId)
}
`;
// 添加材料商
export const ADD_MATERRIAL = Request.gql`
mutation createMaterialManufacturerMutation ($input: CreateMaterialManufacturerInput!){
  createMaterialManufacturer(input: $input) {
    id
    # name
    name
    # 1
    remark
    # 2
    isDefault
  }
} 
`;
// 修改材料商
export const MODIFY_MATERRIAL = Request.gql`
mutation editMaterialManufacturerMutation($input: EditMaterialManufacturerInput!) {
  editMaterialManufacturer(input: $input){
    id
    name
    remark
    isDefault
  }
}
`;

// 获取单价
export const AQUIRE_PRICE = Request.gql`
query getPriceQuery {
  getPrice
}
`;
// 修改单价
export const MODIFY_PRICE = Request.gql`
mutation editPriceMutation($price: Float!){
  editPrice(price: $price) 
}
`;

// 获取规格尺寸
export const AQUIRE_SIZE = Request.gql`
  query getSpecificationQuery {
    getSpecification {
      id
      type
      specification
      weight
      isDefault
      length
    }
  }
`;
// 删除规格尺寸
export const DELETE_SIZE = Request.gql`
  mutation deleteSpecifcation($id: Int!) {
    deleteSpecification(id: $id)
  }
`;
// 添加规格
export const ADD_SIZE = Request.gql`
  mutation createSpecificationInput($input:CreateSpecificationInput! ){
    createSpecification(input: $input) {
      id
      type
      length
      weight
      isDefault
      specification
    }
  }
`;
// 修改规格
export const MODIFY_SIZE = Request.gql`
  mutation editSpecificationMutation($input: EditSpecificationInput !) {
    editSpecification(input: $input) {
      id
      isDefault
      specification
      weight
      length
      type
    }
  }
`;

// 获取运输公司
export const AQUIRE_TRANSPORT = Request.gql`
  query {
    getExpressList {
      id
      name
      isDefault
      remark
    }
  }
`;
// 删除运输公司
export const DELETE_TRANSPORT = Request.gql`
  mutation ($id: Int!) {
    deleteExpress(id: $id)
  }
`;
// 添加运输公司
export const ADD_TRANSPORT = Request.gql`
  mutation ($input: CreateExpressInput!){
    createExpress(input: $input) {
      id
      # name
      name
      # 1
      remark
      # 2
      isDefault
    }
  } 
`;
// 删除运输公司
export const MODIFY_TRANSPORT = Request.gql`
  mutation ($input: EditExpressInput!){
    editExpress(input: $input) {
      id
      # name
      name
      # 1
      remark
      # 2
      isDefault
    }
  } 
`;

// 获取设备规格列表
export const GET_EQUIPEMENT_LIST = Request.gql`
  query{
    getequipmentList{
        id
        weight
      equipmentName
      identification
      equipmentSpecification
    }
  }
`;
// 添加设备规格
export const CREATE_EQUIPMENT = Request.gql`
  mutation($input: CreateEquipmentInput!) {
    createEquipment(input:$input) {
      id
      equipmentName
      weight
      identification
    }
  }
`;
// 删除设备
export const DELETE_EQUIPMENT = Request.gql`
  mutation($id: Int!){
    deleteEquipment(id:$id) 
  }
`;

// 获取配件列表
export const GET_PART_LIST = Request.gql`
  query{
    getPartList{
      id
      weight
    PartName
      partSpecification
    }
  }
`;
// 添加配件
export const ADD_PART = Request.gql`
  mutation($input: CreatePartInput!) {
    createPart(input:$input) {
      id
      PartName
      weight
      isDefault
    }
  }
`;
// 添加配件
export const DELETE_PART = Request.gql`
  mutation($id: Int!){
    deletePart(id:$id) 
  }
`;
