import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Form, Input, Select, message } from "antd";
import Request from "../../../../utils/client";
import { MODIFY_MESSAGE } from "../../../../graphql/admin/messgae";

const { Option } = Select;
// 提交的方法
// const Demo = () => {
//   const onFinish = (values) => {
//     console.log("Received values of form: ", values);
//   };
// };
const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function ModifyMessage(props, ref) {
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => {
    return {
      // 修改
      modify(obj) {
        console.log(obj);
        let { accessKey, accessSecretKey, sign, template } = obj;
        form.setFieldsValue({
          accessKey,
          accessSecretKey,
          sign,
          template,
        });
      },
      // 修改项目提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { accessKey, accessSecretKey, sign, template } =
              form.getFieldsValue();
            console.log(form.getFieldsValue());
            Request.client
              .mutate({
                mutation: MODIFY_MESSAGE, // 封装好的GraphQL,
                variables: {
                  input: {
                    accessKey,
                    accessSecretKey,
                    sign,
                    template,
                  },
                },
              })
              .then((result) => {
                console.log(result);
                message.success("修改成功");
                form.resetFields();
                props.closeModal();
              })
              .catch((error) => {
                message.error(error.message);
                console.log(error);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
    };
  });

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="accessKey"
          label="accessKey"
          rules={[{ required: true, message: "请输入" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="accessSecretKey"
          label="accessSecretKey"
          rules={[{ required: true, message: "请输入" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="sign"
          label="签名"
          rules={[{ required: true, message: "请输入" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="template"
          label="模板"
          rules={[{ required: true, message: "请输入" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(ModifyMessage);
