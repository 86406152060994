/**
 * 管理配件的 reducer
 * @param {*} preState
 * @param {*} actions
 */

import {
  DELETE_ACCESSORIES,
  ADD_ACCESSORIES,
  RESET_ACCESSORIES,
} from "../constant";
import { message } from "antd";

const initState = []; //初始化状态
export default function accessoriesReducer(preState = initState, actions) {
  const { type, data } = actions;
  // eslint-disable-next-line default-case
  switch (type) {
    case DELETE_ACCESSORIES:
      let newState = preState.filter((item) => {
        return (
          item.repositoryId !== data.repositoryId ||
          item.partInfoId !== data.partInfoId
        );
      });
      return [...newState];
    case ADD_ACCESSORIES:
      let state = [];
      let index = preState.findIndex((item) => {
        return (
          item.partInfoId === data.partInfoId &&
          item.repositoryId === data.repositoryId
        );
      });
      if (index !== -1) {
        preState.splice(index, 1, data);
        state = preState;
        message.success("修改成功");
      } else {
        message.success("添加成功");
        state = [...preState, data];
      }
      return state;
    case RESET_ACCESSORIES:
      return [];
    default:
      return preState;
  }
}
