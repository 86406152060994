import React, { Component } from "react";
import { Table, Button, Popconfirm, Modal, message, Input } from "antd";
import Request from "../../../utils/client";
import AddMaterial from "./components/AddMaterial";
import root from "../../../config/root";
import {
  AQUIRE_MATERIAL,
  DELETE_MATERRIAL,
} from "../../../graphql/admin/clock";

import "./material.scss";

export default class log extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: "序号",
        dataIndex: "sort",
        fixed: "left",
        width: 100,
      },
      {
        title: "厂商名称",
        dataIndex: "name",
      },
      {
        title: "是否设为默认值",
        dataIndex: "isDefaultName",
      },
      {
        title: "备注",
        dataIndex: "remark",
      },
    ];
    let userInfo = localStorage.getItem("userInfo");

    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify || root[role][pathname].delete) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {root[role][pathname].modify ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  编辑
                </Button>
              ) : (
                ""
              )}
              {root[role][pathname].delete ? (
                <Popconfirm
                  title="确定删除吗？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    this.deleteItem(item);
                  }}
                >
                  <Button type="danger" style={{ marginLeft: "20px" }}>
                    删除
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }

    this.state = {
      data: [],
      matData: [], // 存储获取的数据，用于用户搜索时
      columns,
      role,
      pathname,
    };
  }

  state = {
    modalVisible: false,
  };
  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    Request.client
      .query({
        query: AQUIRE_MATERIAL,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        let { getMaterialManufacturers } = result.data;
        getMaterialManufacturers.forEach((item, index) => {
          item.sort = index + 1;
          item.key = item.id;
          item.isDefaultName = item.isDefault ? "是" : "否";
        });
        this.setState({
          data: getMaterialManufacturers,
          matData: getMaterialManufacturers,
        });
      })
      .catch((err) => {
        // this.props.history.push({ pathname: "/admin/error/systemerror" });
      });
  };
  deleteItem = (item) => {
    // console.log(item);
    Request.client
      .mutate({
        mutation: DELETE_MATERRIAL, // 封装好的GraphQL,
        variables: {
          deleteId: item.id,
        },
      })
      .then((result) => {
        message.success("删除材料商成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加材料商" }, () => {
      this.addMat.handlerResetForm();
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title == "添加材料商") {
      this.addMat.addExpress();
    } else {
      this.addMat.modifyProjectSubmit();
    }
  };

  // 关闭弹窗，由addMat组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  // 修改项目
  modify = (project) => {
    this.setState({ modalVisible: true, title: "编辑材料商" }, () => {
      this.addMat.modify(project);
    });
  };
  // 搜索的方法
  searchFunc = () => {
    let { matData } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    // console.log(this.state.searchType)
    let result = [];
    result = matData.filter((obj) => {
      return obj.name.indexOf(keyword) != -1;
    });
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.aquireData();
  };
  render() {
    let { data, columns, role, pathname, title } = this.state;
    return (
      <div className="material_box">
        <div className="btn_box">
          <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div>
          <div>
            <Input
              placeholder="请输入材料商名称"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>

        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={() => this.setModalVisible(false)}
        >
          <AddMaterial
            ref={(addMat) => {
              this.addMat = addMat;
            }}
            closeModal={this.closeModal}
          ></AddMaterial>
        </Modal>
      </div>
    );
  }
}
