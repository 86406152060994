import React, { Component } from "react";
import { message } from "antd";
import LoginFrom from "./LoginFrom";
import Request from "../../utils/client";
import { LOGIN } from "../../graphql/login/login";
import axiosRequest from "../../utils/request";
import { Login } from "../../request/requestPath";

import "./login.scss";
import icon1 from "../../assets/images/u1.png";
// import logo from "../../assets/images/login/logo1.png";

export default class login extends Component {
  state = {
    loginError: "",
  };
  goAdmin = (phone, password, role) => {
    this.setState({
      loginError: "",
    });
    axiosRequest
      .post(Login, {
        phone,
        password,
      })
      .then((res) => {
        if (res.code === 200 && res.data) {
          let { accessToken, expired, role } = res.data;
          if (accessToken) {
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                accessToken,
                expired,
                role,
              })
            );
            message.success("登录成功");

            setTimeout(() => {
              this.loginCom.changeLoaing();
              this.props.history.push({ pathname: "/admin/index" });
            }, 1000);
          }
        } else {
          this.loginCom.changeLoaing();
          this.setState({
            loginError: res.message,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.loginCom.changeLoaing();
        this.setState({
          loginError: "服务器错误",
        });
      });
    // return;
    // Request.client
    //   .mutate({
    //     mutation: LOGIN, // 封装好的GraphQL,
    //     variables: {
    //       phone,
    //       password,
    //       mac: "",
    //       input: {
    //         // selectRole: role,
    //         selectRole: "abcd",
    //       },
    //     },
    //   })
    //   .then((result) => {
    //     // console.log("登录返回的结果", result);
    //     let { accessToken, expired, role } = result.data.login;
    //     if (accessToken) {
    //       localStorage.setItem(
    //         "userInfo",
    //         JSON.stringify({
    //           accessToken,
    //           expired,
    //           role,
    //         })
    //       );
    //       message.success("登录成功");

    //       setTimeout(() => {
    //         this.loginCom.changeLoaing();
    //         this.props.history.push({ pathname: "/admin/index" });
    //       }, 1000);
    //     }
    //   })
    //   .catch((error) => {
    //     this.loginCom.changeLoaing();
    //     this.setState({
    //       loginError: error.message,
    //     });
    //     // message.error(error.message);
    //   });
  };
  render() {
    return (
      <div
        className="pagebox"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className="left_box"
          style={{ width: "50%", height: "100%", position: "relative" }}
        >
          <div className="logo_img">
            <img src={icon1} alt="" />
          </div>
          <div className="footer">
            <div className="f_text">
              <div className="content">可信赖的智慧岩土解决方案专家</div>
              <div className="info">
                <div>
                  CopyRight©&nbsp; 2021, 北京中岩大地科技股份有限公司&nbsp;
                </div>
                <div>联系电话：010-57712508-8000&nbsp; &nbsp;</div>
                <div>地址：北京市石景山区苹果园路2号通景大厦12层</div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoginFrom
            loginError={this.state.loginError}
            goAdmin={this.goAdmin}
            ref={(loginCom) => {
              this.loginCom = loginCom;
            }}
          ></LoginFrom>
        </div>
      </div>
    );
  }
}
