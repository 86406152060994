/**
 * 管理型钢的 reducer
 * @param {*} preState
 * @param {*} actions
 */

import { DELETE_STEEL, ADD_STEEL, RESET_STETL } from "../constant";
import { message } from "antd";
const initState = []; //初始化状态
export default function steelReducer(preState = initState, actions) {
  const { type, data } = actions;
  // eslint-disable-next-line default-case
  switch (type) {
    case DELETE_STEEL:
      let newState = preState.filter((item) => {
        return (
          item.repositoryId !== data.repositoryId ||
          item.specificationId !== data.specificationId
        );
      });
      return [...newState];
    case ADD_STEEL:
      let state = [];
      let index = preState.findIndex((item) => {
        return (
          item.specificationId === data.specificationId &&
          item.repositoryId === data.repositoryId
        );
      });
      if (index !== -1) {
        preState.splice(index, 1, data);
        state = preState;
        message.success("修改成功");
      } else {
        message.success("添加成功");
        state = [...preState, data];
      }
      return state;
    case RESET_STETL:
      return [];
    default:
      return preState;
  }
}
