import React, { Component } from "react";
import { DualAxes } from "@ant-design/charts";
import { message, Empty } from "antd";
import axiosRequest from "../../../../../utils/request";
import { maintenanceIndexRequest } from "../../../../../request/requestPath";
class DemoPie extends Component {
  uvBillData = [
    {
      time: "2019-03",
      value: 350,
      type: "uv",
    },
    {
      time: "2019-04",
      value: 900,
      type: "uv",
    },
    {
      time: "2019-05",
      value: 300,
      type: "uv",
    },
    {
      time: "2019-06",
      value: 450,
      type: "uv",
    },
    {
      time: "2019-07",
      value: 470,
      type: "uv",
    },
    {
      time: "2019-03",
      value: 220,
      type: "bill",
    },
    {
      time: "2019-04",
      value: 300,
      type: "bill",
    },
    {
      time: "2019-05",
      value: 250,
      type: "bill",
    },
    {
      time: "2019-06",
      value: 220,
      type: "bill",
    },
    {
      time: "2019-07",
      value: 362,
      type: "bill",
    },
  ];
  transformData = [
    {
      time: "2019-03",
      count: 800,
    },
    {
      time: "2019-04",
      count: 600,
    },
    {
      time: "2019-05",
      count: 400,
    },
  ];

  state = {
    data: [[], []],
    isNull: true,
  };
  config = {
    xField: "time",
    yField: ["value", "count"],
    meta: {
      count: {
        alias: "维修率",
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        isGroup: true,
        seriesField: "type",
      },
      {
        geometry: "line",
        lineStyle: { lineWidth: 2 },
      },
    ],
  };

  componentDidMount() {
    this.aquireData();
  }
  aquireData() {
    axiosRequest
      .post(maintenanceIndexRequest.MaintenanceRate, {})
      .then((res) => {
        if (res.code === 200) {
          let uvBillData = [];
          let transformData = [];
          res.data.forEach((item) => {
            uvBillData.push({
              type: "维修总量",
              time: item.repositoryId + "",
              value: item.maintenanceTotal,
            });
            uvBillData.push({
              type: "型钢总量",
              time: item.repositoryId + "",
              value: item.steelTotal,
            });
            transformData.push({
              time: item.repositoryId + "",
              count: item.maintenanceRate,
              type: "维修率",
            });
          });
          this.setState({
            data: [uvBillData, transformData],
            isNull:
              uvBillData.length + transformData.length === 0 ? true : false,
          });
        } else {
          message.error("获取维修率错误");
        }
      })
      .catch((error) => {
        console.log("获取维修率报错", error);
      });
  }

  render() {
    let { isNull } = this.state;
    return (
      <div style={{ padding: "0 20px 0 0" }}>
        {isNull ? (
          <div style={{ paddingTop: 100, paddingBottom: 100 }}>
            <Empty />{" "}
          </div>
        ) : (
          <DualAxes {...this.config} data={this.state.data} />
        )}
      </div>
    );
  }
}

export default DemoPie;
