import React, { Component } from "react";
import { format } from "../../../utils/dateFormate";
import { AQUIRE_PROJECT } from "../../../graphql/admin/project";
import Request from "../../../utils/client";
import markerIcon from "../../../assets/images/icon/ware.png";
import proIcon from "../../../assets/images/icon/pro3.png";
let map = null;
export default class Retrieve extends Component {
  componentDidMount() {
    this.aquireData();

    let { BMapGL } = window;
    map = new BMapGL.Map("retrieve"); // 创建地图实例
    var point = new BMapGL.Point(116.404, 39.915); // 创建点坐标
    map.centerAndZoom(point, 8); // 初始化地图，设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true);
    var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
    map.addControl(zoomCtrl);
    var myIcon = new BMapGL.Icon(markerIcon, new BMapGL.Size(40, 40));
    var pt = new BMapGL.Point(116.417, 39.909);
    var marker = new BMapGL.Marker(pt, {
      icon: myIcon,
    });

    map.addOverlay(marker);
    var opts = {
      width: 300, // 信息窗口宽度
      // height: 100, // 信息窗口高度
      title: "仓库", // 信息窗口标题
      message: "次数",
    };
    // 创建图文信息窗口
    var sContent = `<div style='padding:0 10px 20px 10px;width:100%'>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              地址：测试
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              开始时间：测试3
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              备注：测试
              </div>
              <div style="display:flex;justify-content:center;align-item:center"><div id="infobtn" style="cursor:pointer;background:#527DF8;border-radius: 2px;width:80px;height:30px;line-height:30px;text-align:center;color:white">查看</div></div>
            </div>`;
    var infoWindow = new BMapGL.InfoWindow(sContent, opts);
    // marker添加点击事件
    marker.addEventListener("click", function () {
      this.openInfoWindow(infoWindow);
    });
    //判断窗口的打开状态
    if (!infoWindow.isOpen()) {
      //如果没有打开，则监听打开事件，获取按钮，添加事件
      infoWindow.addEventListener("open", () => {
        document.getElementById("infobtn").onclick = (e) => {
          this.props.history.push({ pathname: "/admin/index" });
        };
      });
    } else {
      //如果已经打开，直接获取按钮，添加事件
      document.getElementById("infobtn").onclick = (e) => {
        // alert(JSON.stringify(e));
        this.props.history.push({ pathname: "/admin/index" });
      };
    }
    var myIcon2 = new BMapGL.Icon(proIcon, new BMapGL.Size(30, 40));
    var pt2 = new BMapGL.Point(115.417, 39.909);
    var marker2 = new BMapGL.Marker(pt2, {
      icon: myIcon2,
    });
    map.addOverlay(marker2);
  }

  // 请求项目数据
  aquireData = () => {
    Request.client
      .query({
        query: AQUIRE_PROJECT,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        let list = [];
        result.data.getProjectLis.forEach((item, index) => {
          item.sort = index + 1;
          let obj = Object.assign({}, item);
          obj.startedAt = format(item.startedAt);
          list.push(obj);
        });
        // console.log(list);
        this.setState({ projectData: list }, () => {
          let { BMapGL } = window;
          list.forEach((item) => {
            // 创建添加点标记
            var marker = new BMapGL.Marker(
              new BMapGL.Point(item.longitude, item.latitude)
            );

            map.addOverlay(marker);
            var opts = {
              width: 300, // 信息窗口宽度
              // height: 100, // 信息窗口高度
              title: item.name, // 信息窗口标题
              message: item.name,
            };
            // 创建图文信息窗口
            var sContent = `<div style='padding:0 10px 20px 10px;width:100%'>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              地址：${item.city.split("/").join("")}${item.address}
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              开始时间：${item.startedAt}
              </div>
              <div style='margin:0;line-height:1.5;font-size:13px;word-wrap:break-word;width:100%'>
              备注：${item.remark}
              </div>
            </div>`;
            var infoWindow = new BMapGL.InfoWindow(sContent, opts);
            // marker添加点击事件
            marker.addEventListener("click", function () {
              this.openInfoWindow(infoWindow);
            });
          });
        });
      })
      .catch((error) => {
        console.log(error);
        // this.props.history.push({ pathname: "/admin/error/systemerror" });
      });
  };

  render() {
    return (
      <div
        className="retrieve"
        style={{
          background: "white",
          height: "calc(100vh - 150px)",
          width: "100%",
          padding: "10px",
        }}
      >
        <div id="retrieve" style={{ height: "100%", width: "100%" }}></div>
      </div>
    );
  }
}
