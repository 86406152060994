/**
 * 管理用户选择的需求单的reducer
 * @param {*} preState
 * @param {*} actions
 */

import { ADD_DEMAND, RESET_DEMAND } from "../constant";
import { message } from "antd";

const initState = []; //初始化状态
export default function equipmentReducer(preState = initState, actions) {
  const { type, data } = actions;
  // eslint-disable-next-line default-case
  switch (type) {
    case ADD_DEMAND:
      message.success("您成功选择了一个需求单，请添加相应的材料");
      return [data];
    case RESET_DEMAND:
      return [];
    default:
      return preState;
  }
}
