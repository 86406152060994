import Request from "../../utils/client";

// 获取仓库列表
export const AQUIRE_WAREHOUSE = Request.gql`
  query {
    getRepositoryList{
      address
      city
      id 
      isAble
      leaders{
        id 
        name
        wechat
        phone
      }
      name
      pinYin 
      remark
      total
      weight
      latitude
      longitude
    }
  }
`;
// 删除仓库
export const DELETE_WAREHOUSE = Request.gql`
  mutation deleteRepositoryMutation ($id: Int!) {
    deleteRepository(repositoryId: $id)
  }
`;
// 添加仓库
export const ADD_WAREHOUSE = Request.gql`
  mutation createRepository($input: CreateRepositoryInput!) {
    createRepository(input: $input) {
      id
      weight
      pinYin
      address
      total
      weight
      remark
      isAble
      leaders{
        id
        name
        wechat
        phone
      }
      total
      city
    }
  }
`;
// 修改仓库
export const MODIFY_WAREHOUSE = Request.gql`
  mutation ($input: SetRepositoryInput!){
    setRepository (input: $input){
      id
    }
  }
`;

// 仓库详情
export const DETAIL_WAREHOUSE = Request.gql`
  query ($input: GetRepositoryDetailInput!){
    getRepositoryDetail(input: $input) {
      id
      name # 仓库名
      total # 数量 
      weight # 重量
      fee # 费用
      specification
    }
  }
`;

// 型钢历史信息
export const STEEL_HISTORY = Request.gql`
query($input: GetMultipleSteelDetailInput){
  getMultipleSteelDetail(input: $input){
    id
    identifier
    createUser{
      name
    }
    state
    stateInfo{
      desc
    }
    companyId
    repository{
      name
    }
    materialManufacturer{
      name
    }
    manufacturer{
      name
    }
    turnover
    oneSteelUseDay
    oneSteelUsage
    producedDate
    specifcation{
      id
    }
    steelInProject{
      projectName
    }
    steelInMaintenance{
      maintenance{
        name
      }
    }
    code
    createdAt
  }
  
}
`;

// 设备历史信息
export const EQUIPMENT_HISTORY = Request.gql`
query($input: GetMultipleEquipmentDataDetailInput){
  getMultipleEquipmentDataDetail(input: $input) {
    id
    identifier
    createUser{
      name
    }
    state
    stateInfo{
      desc
    }
    companyId
    repository{
      name
      id
    }
    materialManufacturer{
      name
    }
    manufacturer{
      name
    }
    producedDate
    equipmentSpecification{
      equipmentName
      id
      identification
    }
    equipmentInProject{
      id
      projectName
    }
    equipmentInMaintenance{
      id
      maintenance{
        name
      }
    }
    code
    createdAt
  }
}
`;
