import React, { Component } from "react";
import { Pie } from "@ant-design/charts";
import { message, Empty } from "antd";
import Request from "../../../../../utils/client";
import { STEEL_SUMMARY } from "../../../../../graphql/admin/index";
import axiosRequest from "../../../../../utils/request";
import { maintenanceIndexRequest } from "../../../../../request/requestPath";
class DemoPie extends Component {
  state = {
    data: [],
    isNull: true,
  };
  config = {
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 0.7,
    label: {
      type: "outer",
      content: "{name}   {percentage}",
    },
    width: 350,
    height: 350,
    interactions: [{ type: "pie-legend-active" }, { type: "element-active" }],
    legend: {
      layout: "vertical",
      position: "left",
      offsetY: -120,
      marker: {
        symbol: "square",
      },
    },
  };
  // componentDidMount() {
  //   this.repairProfiles();
  // }
  // 获取维修概况
  repairProfiles(repositoryId) {
    axiosRequest
      .post(maintenanceIndexRequest.Maintenance, {
        repositoryId,
      })
      .then((res) => {
        console.log("获取维修概况", res);
        if (res.code === 200) {
          let { readyEnterRepository, repairing, toBeRepaired } = res.data;
          let count = readyEnterRepository + repairing + toBeRepaired;
          let data = [
            {
              type: `准备归库(${readyEnterRepository})`,
              value: readyEnterRepository,
            },
            {
              type: `待维修(${repairing})`,
              value: repairing,
            },
            {
              type: `维修中(${toBeRepaired})`,
              value: toBeRepaired,
            },
          ];
          this.setState({
            data,
            isNull: count > 0 ? false : true,
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log("获取维修概况error", error);
      });
  }

  render() {
    let { isNull } = this.state;
    return (
      <div style={{ padding: "0 20px 0 0" }}>
        {isNull ? (
          <div style={{ paddingTop: 100, paddingBottom: 100 }}>
            <Empty />{" "}
          </div>
        ) : (
          <Pie {...this.config} data={this.state.data} />
        )}
      </div>
    );
  }
}

export default DemoPie;
