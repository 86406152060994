/**
 * 维修管理员主页相关接口
 */
import Request from "../../../utils/client";

// 维修型钢动向
export const GET_MAINTENANCE_STEEL = Request.gql`
    query($input: GetMaintenanceRDateInput!) {
        getSteelMaintenanceNewTrand(input:$input){
            orderNo
            repositoryName
            maintenanceName
            state
            maintainTime
            returnTime
            steelList{
            name
            total
            }
            equipmentList{
            name
            total
            }
            partList{
            name
            total
            }
            repositoryLeader
            maintenance_main
            companyLeader
            outRepositoryTime
            outRepositoryExpressAndNo
            outFactoryTime
            outFactoryExpressAndNo
            remark
        }
    }
`;

// 维修响应数据
export const GET_MAINTENANCE_RDATE = Request.gql`
    query($input: GetMaintenanceRDateInput!) {
        getMaintenanceRDate(input:$input){
            maintenanceSteelTotal
            maintenanceEquipmentTotal
            maintenancingSteelTotal
            maintenancingEquipmentTotal
            durationMaintenanceSteelTotal
            durationSteelToMaintenanceTotal
            durationSteelMaintenancingTotal
            durationSteelToRepositoryTotal
            toBeMaintenancePercent
            maingenancingPercent
            toRepositoryPercent
            repairRate
        }
    }
`;
// 型钢查询
export const GET_STEEL_FOR_BACK = Request.gql`
   query($input: GetSteelForBackInput!) {
    getSteelForBack(input:$input){
        id
        identifier
        createUser{
        name
        }
        state
        stateInfo{
        desc
        }
        companyId
        repository{
        name
        }
        materialManufacturer{
        name
        }
        manufacturer{
        name
        }
        turnover
        oneSteelUseDay
        oneSteelUsage
        producedDate
        specifcation{
        specification
        }
        steelInProject{
        projectName
        }
        steelInMaintenance{
        maintenance{
            name
        }
        }
        code
        createdAt
    }
    }
`;
