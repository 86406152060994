import React, { Component } from "react";
import { Pie } from "@ant-design/charts";
import { message, Empty } from "antd";
import Request from "../../../../utils/client";
import { STEEL_SUMMARY } from "../../../../graphql/admin/index";

import axiosRequest from "../../../../utils/request";
import {
  indexRequestPath,
  AdminIndexRequest,
} from "../../../../request/requestPath";

class DemoPie extends Component {
  state = {
    data: [],
    isNull: true,
  };
  config = {
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 0.7,
    label: {
      type: "outer",
      content: "{name}   {percentage}",
    },
    width: 350,
    height: 350,
    interactions: [{ type: "pie-legend-active" }, { type: "element-active" }],
    legend: {
      layout: "vertical",
      position: "left",
      offsetY: -120,
      marker: {
        symbol: "square",
      },
    },
  };
  aquireData(repositoryId) {
    axiosRequest
      .post(indexRequestPath.SteelUsage, {
        repositoryId,
      })
      .then((res) => {
        if (res.code === 200) {
          // console.log("获取型钢使用情况", res);
          this.dataDeal(res.data);
        } else {
          message.error("获取型钢使用情况错误");
        }
      })
      .catch((error) => {
        console.log("获取型钢使用情况报错", error);
      });
  }

  adminAquireData(companyId) {
    axiosRequest
      .post(AdminIndexRequest.SteelUsageBySuper, {
        companyId,
      })
      .then((res) => {
        if (res.code === 200) {
          this.dataDeal(res.data);
        } else {
          message.error("获取型钢使用情况错误");
        }
      })
      .catch((error) => {
        console.log("获取型钢使用情况报错", error);
      });
  }
  // 数据处理
  dataDeal(requestData) {
    let {
      inProject,
      inRepair,
      inRepository,
      loss,
      scrap,
      inProjectPercent,
      inRepairPercent,
      inRepositoryPercent,
      lossPercent,
      scrapPercent,
    } = requestData;
    let count = inProject + inRepair + inRepository + loss + scrap;
    let data = [
      {
        type: `报废(${scrap})`,
        value: scrapPercent,
      },
      {
        type: `丢失(${loss})`,
        value: lossPercent,
      },
      {
        type: `维修中(${inRepair})`,
        value: inRepairPercent,
      },
      {
        type: `项目中(${inProject})`,
        value: inProjectPercent,
      },
      {
        type: `在库(${inRepository})`,
        value: inRepositoryPercent,
      },
    ];
    this.setState(
      {
        data,
        isNull: count > 0 ? false : true,
        // isNull: count > 0 ? true : false,
      },
      () => {
        // console.log(this.state.data);
      }
    );
  }

  render() {
    let { isNull } = this.state;
    return (
      <div style={{ padding: "0 20px 0 0" }}>
        {isNull ? (
          <div style={{ paddingTop: 100, paddingBottom: 100 }}>
            <Empty />{" "}
          </div>
        ) : (
          <Pie {...this.config} data={this.state.data} />
        )}
      </div>
    );
  }
}

export default DemoPie;
