/**
 * 角色路由权限，操作权限配置
 */
import {
  ADMIN,
  COMPANY_ADMIN,
  REPOSITORY_ADMIN,
  PROJECT_ADMIN,
  MAINTENANCE_ADMIN,
} from "./constant";
import admin from "./admin_root";
import companyAdmin from "./admin_company_root";
import maintenanceAdmin from "./admin_maintenance_root";
import projectAdmin from "./admin_project_root";
import repositoryAdmin from "./admin_repository_root";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [ADMIN]: admin,
  [COMPANY_ADMIN]: companyAdmin,
  [REPOSITORY_ADMIN]: repositoryAdmin,
  [PROJECT_ADMIN]: projectAdmin,
  [MAINTENANCE_ADMIN]: maintenanceAdmin,
};
