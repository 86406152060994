import React, { Component } from "react";
import { Menu } from "antd";
import PubSub from "pubsub-js"; //引入
// import { AppstoreOutlined } from "@ant-design/icons";
import CustomIcon from "../customIcon/CustomIcon";
import menuList from "../../config/menusConfig";
import root from "../../config/root";
import "./leftmenu.scss";
const { SubMenu } = Menu;

export default class menu extends Component {
  state = {
    selectedKeys: [],
    openKeys: [],
    menubar: [],
    role: "",
  };
  componentDidMount() {
    let userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      let { role } = JSON.parse(userInfo);
      this.setState(
        {
          role,
        },
        () => {
          const menubar = this.creatMenu(menuList);
          this.setState({
            menubar,
          });
        }
      );
    }

    PubSub.subscribe("switch", (_, path) => {
      this.setState({ selectedKeys: [path] });
      this.judgeSubmenu(path);
    }); //订阅
  }
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  goPage = (path) => {
    return () => {
      this.props.goPage(path);
    };
  };
  selectMenu = (obj) => {
    this.setState({ selectedKeys: [obj.key] });
    PubSub.publish("updateList"); //发布消息
  };
  openSubmenu = (obj) => {
    this.setState({ openKeys: obj });
  };
  judgeSubmenu(key) {
    menuList.forEach((item) => {
      if (item.key === key) {
        this.setState({ openKeys: [item.key] });
      } else if (item.children) {
        item.children.forEach((itemChild) => {
          if (itemChild.key === key) {
            this.setState({ openKeys: [item.key] });
          } else if (itemChild.children) {
            itemChild.children.forEach((itemChild2) => {
              if (itemChild2.key === key) {
                this.setState({ openKeys: [item.key, itemChild.key] });
              }
            });
          }
        });
      }
    });
  }
  // 生成menu
  creatMenu = (itemList) => {
    return itemList.map((item) => {
      if (this.judgement(item)) {
        if (item.children) {
          return (
            <SubMenu
              key={item.key}
              title={item.title}
              icon={
                item.level === 1 ? (
                  <CustomIcon icon={item.icon}></CustomIcon>
                ) : (
                  ""
                )
              }
              // icon={<AppstoreOutlined />}
            >
              {this.creatMenu(item.children)}
            </SubMenu>
          );
        } else {
          return (
            <Menu.Item
              key={item.key}
              icon={
                item.level === 1 ? (
                  <CustomIcon icon={item.icon}></CustomIcon>
                ) : (
                  ""
                )
              }
              onClick={this.goPage(item.key)}
            >
              {item.title}
            </Menu.Item>
          );
        }
      }
    });
  };
  judgement = (menu) => {
    let { role } = this.state;
    if (root[role][menu.key].show) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    let { selectedKeys, openKeys, menubar } = this.state;
    return (
      <div className="menu_box">
        {/* <Button
          type="primary"
          onClick={this.toggleCollapsed}
          style={{ marginBottom: 16 }}
        >
          {React.createElement(
            this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </Button> */}
        <Menu
          defaultSelectedKeys={["0"]}
          defaultOpenKeys={["sub0"]}
          mode="inline"
          theme="dark"
          selectedKeys={selectedKeys}
          onClick={this.selectMenu}
          onOpenChange={this.openSubmenu}
          openKeys={openKeys}
          style={{ color: "white" }}
        >
          {menubar}
        </Menu>
      </div>
    );
  }
}
