import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Form, Input, Select, message } from "antd";
import Request from "../../../../utils/client";
import { ADD_SIZE, MODIFY_SIZE } from "../../../../graphql/admin/clock";
import axiosRequest from "../../../../utils/request";
import { clockRequestPath } from "../../../../request/requestPath";
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 30,
    },
    sm: {
      span: 7,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

function AddCompany(props, ref) {
  let [id, setId] = useState("");
  const [form] = Form.useForm();
  useImperativeHandle(ref, () => {
    return {
      // 添加
      addExpress() {
        form
          .validateFields()
          .then((res) => {
            let { type, length, weight, isDefault, specification, price } =
              form.getFieldsValue();
            // console.log(" form.getFieldsValue()", form.getFieldsValue());
            // return;
            let newIsDefault = isDefault === "false" ? false : true;
            axiosRequest
              .post(clockRequestPath.CreateSpecification, {
                isDefault: newIsDefault,
                type,
                length: parseFloat(length),
                weight: parseFloat(weight),
                specification,
                price: price ? price * 1 : 0,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("添加型钢规格成功");
                  form.resetFields();
                  props.closeModal();
                  // props.aquireData();
                }
              })
              .catch((error) => {
                message.error(error.message);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 修改
      modify(obj) {
        // console.log(project);
        let { type, length, weight, isDefault, id, specification, price } = obj;
        let newIsDefault = isDefault ? "是" : "否";
        setId(id);
        form.setFieldsValue({
          type,
          length,
          weight,
          isDefault: newIsDefault,
          specification,
          price,
        });
      },
      // 修改型钢规格提交数据
      modifyProjectSubmit() {
        form
          .validateFields()
          .then((res) => {
            let { type, length, weight, isDefault, specification, price } =
              form.getFieldsValue();
            let newIsDefault = isDefault === "否" ? false : true;
            axiosRequest
              .post(clockRequestPath.EditSpecification, {
                isDefault: newIsDefault,
                type,
                length: parseFloat(length),
                weight: parseFloat(weight),
                id,
                specification,
                price: price ? price * 1 : 0,
              })
              .then((res) => {
                if (res.code === 200) {
                  message.success("修改型钢规格成功");
                  form.resetFields();
                  props.closeModal();
                }
              })
              .catch((error) => {
                message.error(error.message);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      handlerResetForm() {
        form.resetFields();
      },
    };
  });

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div>
      <Form {...formItemLayout} form={form}>
        <Form.Item
          name="type"
          label="类型"
          rules={[{ required: true, message: "请输入类型" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="length"
          label="长度（m）"
          rules={[{ required: true, message: "请输入长度" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="weight"
          label="重量（t）"
          rules={[{ required: true, message: "请输入重量" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="specification"
          label="规格"
          rules={[{ required: true, message: "请输入规格" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="price"
          label="价格（万元）"
          rules={[{ required: false, message: "请输入价格" }]}
        >
          <Input style={{ width: 300 }} placeholder="请输入" />
        </Form.Item>
        <Form.Item
          name="isDefault"
          label="是否默认"
          hasFeedback
          rules={[
            {
              required: true,
              message: "请选择是否默认",
            },
          ]}
        >
          <Select
            style={{ width: 300 }}
            placeholder="是否默认"
            onChange={handleChange}
          >
            <Option value="true">是</Option>
            <Option value="false">否</Option>
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
}
export default forwardRef(AddCompany);
