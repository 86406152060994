import React, { Component } from "react";
import { Table, message } from "antd";
// import { AQUIRE_LOG } from "../../../../graphql/admin/sysLog.js";
// import Request from "../../../../utils/client.js";
import moment from "moment";
import axiosRequest from "../../../../utils/request";
import { GetLogList } from "../../../../request/requestPath";

export default class log extends Component {
  state = {
    data: [],
    total: 0,
    current: 1,
    pageSize: 20,
    loading: true,
  };
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };
  columns = [
    {
      title: "序号",
      dataIndex: "id",
      fixed: "left",
      width: 80,
    },
    {
      title: "操作类型",
      dataIndex: "type",
      width: 100,
    },
    {
      title: "操作内容",
      dataIndex: "content",
      width: 200,
    },

    {
      title: "操作时间",
      dataIndex: "date",
      width: 200,
    },
    {
      title: "操作用户",
      dataIndex: "userName",
      width: 150,
    },
  ];
  componentDidMount() {
    this.getLogList();
  }
  // 页面发生改变
  changePage = (page) => {
    // console.log(page);
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.getLogList();
      }
    );
  };
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    // console.log(pageSize, current);
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.getLogList();
      }
    );
  };
  // 获取日志
  getLogList = () => {
    let input = {};
    let { current, pageSize } = this.state;
    input.isShowAll = false;
    input.page = current;
    input.pageSize = pageSize;

    axiosRequest
      .post(GetLogList, { ...input })
      .then((res) => {
        // console.log("获取的日志列表", res);
        if (res.code === 200) {
          let { list, total } = res.data;
          list.forEach((item, index) => {
            item.sort = index + 1;
            item.key = index + 1;
            item.date = moment(item.CreatedAt).format("YYYY-MM-DD");
          });
          this.paginationProps.total = total;
          // console.log(getLogList.list);
          this.setState({
            data: list,
            total: total,
            loading: false,
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log("获取日志列表错误", error);
      });

    // Request.client
    //   .query({
    //     query: AQUIRE_LOG,
    //     variables: {
    //       input,
    //     },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     // console.log("获取日志", result);
    //     let { getLogList } = result.data;
    //     getLogList.list.forEach((item, index) => {
    //       item.sort = index + 1;
    //       item.key = index + 1;
    //       item.date = moment(item.createdAt).format("YYYY-MM-DD");
    //       item.userName = item.user.name;
    //       item.type = item.typeInfo.desc;
    //     });
    //     this.paginationProps.total = getLogList.total;
    //     // console.log(getLogList.list);
    //     this.setState({
    //       data: getLogList.list,
    //       total: getLogList.total,
    //       loading: false,
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     message.error("无法从服务器获取到日志数据");
    //   });
  };
  render() {
    let { data, loading } = this.state;
    return (
      <div>
        <Table
          loading={loading}
          columns={this.columns}
          dataSource={data}
          bordered
          scroll={{ x: 600 }}
          pagination={this.paginationProps}
          bordered
        />
      </div>
    );
  }
}
