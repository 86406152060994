import React, { Component } from "react";
import { Table, Button, Select, Radio, DatePicker, Input, message } from "antd";
import Request from "../../../utils/client";
import moment from "moment";
import { PROJECT_DETAIL } from "../../../graphql/admin/project";
import {
  STATUS_LIST,
  SIZE_LIST,
  REPOSITORY_LIST,
} from "../../../graphql/filter/filter";
import "./projectDetail.scss";

import axiosRequest from "../../../utils/request";
import { ProjectRequestPath } from "../../../request/requestPath";

import stautsObject from "../../../utils/status";

const { Option } = Select;
function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}

export default class ProjectDetail extends Component {
  state = {
    value: 2,
    // statusDefault: {}, // 设置状态默认值
    // sizeDefault: {}, // 设置规格默认值
    // reposityDefault: {}, // 设置仓库默认值
    total: 0, // 数据总数
    current: 1, // 当前的页数
    pageSize: 20, // 每页的数据量
    children: [], // 状态选项 select中的options
    select: "all", // 选择的状态，默认是全部
    sizeChildren: [], // 规格选项 select中的options
    sizeSelect: "all", // 选择的规格，默认是全部
    reposityChildren: [], // 仓库选项 select中的options
    repositySelect: "all", // 选择的仓库，默认是全部
    outOfRepositoryAt: "", // 出库时间
    enteredWorkshopAt: "", // 入场时间
    installationAt: "", // 安装时间
    enterRepositoryAt: "", // 入库时间
    outOfWorkshopAt: "", // 出场时间
    selectDate: "", // 选择的日期，moment格式的
    selectEWA: "", // 选择的入场日期，moment格式的
    selectIA: "", // 选择的安装日期，moment格式的
    selectORA: "", // 选择的出库日期，moment格式的
    selectOWA: "", // 选择的出场日期，moment格式的
    locationCode: "", // 安装码
    data: [],
    loading: true, //  table加载
    orderNo: "", // 订单号
    total: 0,
    weight: 0,
  };
  componentDidMount() {
    this.aquireStatus();
    this.aquireSize();
    this.aquireReposity();
    this.aquireDetail();
  }
  // 获取状态列表
  aquireStatus = () => {
    Request.client
      .query({
        query: STATUS_LIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取状态列表", result);
        let { getProjectSteelStateList } = result.data;
        let children = [];
        children.push(<Option key="all">全部</Option>);
        getProjectSteelStateList.forEach((item) => {
          children.push(<Option key={item.state}>{item.desc}</Option>);
        });
        this.setState(
          {
            children,
            // statusDefault: { state: "all", desc: "全部" },
            select: "all",
          },
          () => {
            // this.aquireDetail()
          }
        );
      })
      .catch((err) => {
        console.log(err);
        message.error("无法从服务器获取到状态数据");
      });
  };
  // 获取规格列表
  aquireSize = () => {
    Request.client
      .query({
        query: SIZE_LIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取规格列表", result);
        let { getSpecification } = result.data;
        let children = [];
        children.push(<Option key="all">全部</Option>);
        getSpecification.forEach((item) => {
          children.push(<Option key={item.id}>{item.specification}</Option>);
        });
        this.setState(
          {
            sizeChildren: children,
            // sizeDefault: { id: "all", specification: "全部" },
            sizeSelect: "all",
          },
          () => {
            // this.aquireDetail()
          }
        );
      })
      .catch((err) => {
        console.log(err);
        message.error("无法从服务器获取到规格尺寸数据");
      });
  };
  // 获取仓库列表
  aquireReposity = () => {
    Request.client
      .query({
        query: REPOSITORY_LIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("获取仓库列表", result);
        let { getRepositoryList } = result.data;
        let children = [];
        children.push(<Option key="all">全部</Option>);
        getRepositoryList.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          reposityChildren: children,
          // reposityDefault: { id: "all", name: "全部" },
          repositySelect: "all",
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("无法从服务器获取到仓库数据");
      });
  };
  // 获取项目详情页面
  aquireDetail = () => {
    let input = {};
    input.projectId = localStorage.getItem("projectId") * 1;
    this.state.value === 1
      ? (input.isShowAll = true)
      : (input.isShowAll = false);

    if (this.state.select !== "all") {
      input.state = this.state.select;
    }
    if (this.state.sizeSelect !== "all") {
      input.specificationId = this.state.sizeSelect;
    }
    if (this.state.repositySelect !== "all") {
      input.repositoryId = this.state.repositySelect;
    }
    if (this.state.outOfRepositoryAt !== "") {
      input.outOfRepositoryAt = this.state.outOfRepositoryAt;
    }
    if (this.state.enteredWorkshopAt !== "") {
      input.enteredWorkshopAt = this.state.enteredWorkshopAt;
    }
    if (this.state.installationAt !== "") {
      input.installationAt = this.state.installationAt;
    }
    if (this.state.enterRepositoryAt !== "") {
      input.enterRepositoryAt = this.state.enterRepositoryAt;
    }
    if (this.state.outOfWorkshopAt !== "") {
      input.outOfWorkshopAt = this.state.outOfWorkshopAt;
    }

    input.page = this.state.current;
    input.pageSize = this.state.pageSize;
    if (this.state.locationCode !== "") {
      input.locationCode = this.state.locationCode;
    }
    if (this.state.orderNo !== "") {
      input.orderId = this.state.orderNo;
    }

    axiosRequest
      .post(ProjectRequestPath.GetProjectDetail, input)
      .then((res) => {
        if (res.code === 200) {
          // console.log("获取项目详情", res);
          let { List, Total, Weight } = res.data;
          List.forEach((item, index) => {
            item.sort = index + 1;
            item.key = index;
            // item.status = item.stateInfo.desc;
            item.status = stautsObject[item.state];
            item.installationAt
              ? (item.installationDate = moment(item.installationAt).format(
                  "YYYY-MM-DD"
                ))
              : (item.installationDate = "");
            item.outRepositoryAt
              ? (item.outRepositoryDate = moment(item.outRepositoryAt).format(
                  "YYYY-MM-DD"
                ))
              : (item.outRepositoryDate = "");
            item.enterRepositoryAt
              ? (item.enterRepositoryDate = moment(
                  item.enterRepositoryAt
                ).format("YYYY-MM-DD"))
              : (item.enterRepositoryDate = "");
            item.enterWorkshopAt
              ? (item.enterWorkshopDate = moment(item.enterWorkshopAt).format(
                  "YYYY-MM-DD"
                ))
              : (item.enterWorkshopDate = "");
            item.outWorkshopAt
              ? (item.outWorkshopDate = moment(item.outWorkshopAt).format(
                  "YYYY-MM-DD"
                ))
              : (item.outWorkshopDate = "");
            item.total = 1;
          });
          this.setState({
            loading: false,
            data: List,
            total: Total,
            weight: Weight.toFixed(2),
          });
        }
      })
      .catch((error) => {
        console.log("获取项目详情错误", error);
      });

    // return;
    // Request.client
    //   .query({
    //     query: PROJECT_DETAIL,
    //     variables: {
    //       input,
    //     },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     // console.log("获取项目详情", result);

    //     let { getProjectDetail } = result.data;
    //     getProjectDetail.list.forEach((item, index) => {
    //       item.sort = index + 1;
    //       item.key = index;
    //       item.order = item.orderSpecification.order.id;
    //       item.specification = item.steel.specifcation.specification;
    //       item.repository = item.steel.repository.name;
    //       item.code = item.steel.code;
    //       item.status = item.stateInfo.desc;
    //       item.installationAt
    //         ? (item.installationDate = moment(item.installationAt).format(
    //             "YYYY-MM-DD"
    //           ))
    //         : (item.installationDate = "");
    //       item.outRepositoryAt
    //         ? (item.outRepositoryDate = moment(item.outRepositoryAt).format(
    //             "YYYY-MM-DD"
    //           ))
    //         : (item.outRepositoryDate = "");
    //       item.enterRepositoryAt
    //         ? (item.enterRepositoryDate = moment(item.enterRepositoryAt).format(
    //             "YYYY-MM-DD"
    //           ))
    //         : (item.enterRepositoryDate = "");
    //       item.enterWorkshopAt
    //         ? (item.enterWorkshopDate = moment(item.enterWorkshopAt).format(
    //             "YYYY-MM-DD"
    //           ))
    //         : (item.enterWorkshopDate = "");
    //       item.outWorkshopAt
    //         ? (item.outWorkshopDate = moment(item.outWorkshopAt).format(
    //             "YYYY-MM-DD"
    //           ))
    //         : (item.outWorkshopDate = "");
    //       item.projectName = item.orderSpecification.order.project.name;
    //     });
    //     this.setState({
    //       loading: false,
    //       data: getProjectDetail.list,
    //       total: getProjectDetail.total,
    //       weight: getProjectDetail.weight.toFixed(2),
    //     });
    // })
    // .catch((err) => {
    //   console.log(err);
    //   // this.props.history.push({ pathname: "/admin/error/systemerror" });
    // });
  };
  // 表格分页
  paginationProps = {
    showSizeChanger: true,
    showQuickJumper: true,
    total: this.state.total, // 数据总数
    pageSize: 20, // 每页条数
    showQuickJumper: true, // 开启页码快速跳转
    current: parseInt(this.state.current), // 当前页码
    onChange: (current) => this.changePage(current),
    onShowSizeChange: (current, pageSize) =>
      this.changePageSize(pageSize, current),
    showTotal: (total) => {
      return `共 ${total} 条`;
    },
  };
  // 是否展示全部改变
  onChange = (e) => {
    // console.log("radio checked", e.target.value);
    this.paginationProps.current = 1;
    this.setState(
      {
        value: e.target.value,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 状态选项改变
  handleChange = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        select: value + "",
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 规格列表选项改变
  handleChange2 = (value) => {
    console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        sizeSelect: value,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 仓库列表选项改变
  handleChange3 = (value) => {
    // console.log(`selected ${value}`);
    this.paginationProps.current = 1;
    this.setState(
      {
        repositySelect: value,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 出库时间发生改变
  onDateChange = (e) => {
    // console.log(e);
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        outOfRepositoryAt: value,
        selectDate: e,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
    // console.log(value);
  };
  // 入库时间发生改变
  onORAChange = (e) => {
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        enterRepositoryAt: value,
        selectORA: e,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 安装时间发生改变
  onIAChange = (e) => {
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        installationAt: value,
        selectIA: e,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 出场时间发生改变
  onOWAChange = (e) => {
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        outOfWorkshopAt: value,
        selectOWA: e,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 入场发生改变
  onEWAChange = (e) => {
    let value = null;
    if (e !== null) {
      value = moment(e).format();
    }
    this.setState(
      {
        enteredWorkshopAt: value,
        selectEWA: e,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 页面发生改变
  changePage = (page) => {
    // console.log(page);
    this.paginationProps.current = page;
    this.setState(
      {
        current: page,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 安装码输入框变化时
  inputChange = () => {
    this.setState(
      {
        locationCode: this.inputCode.state.value,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 订单号输入框变化时
  orderChange = () => {
    this.setState(
      {
        orderNo: this.orderInput.state.value,
        current: 1,
      },
      () => {
        this.aquireDetail();
      }
    );
  };
  // 输入安装码和订单号的防抖函数
  debounce = (fn, wait) => {
    let timeout;
    return function () {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(function () {
        fn.apply(this);
      }, wait);
    };
  };
  // pageSize发生改变时
  changePageSize = (pageSize, current) => {
    // 将当前改变的每页条数存到state中
    // console.log(pageSize, current);
    this.paginationProps.pageSize = pageSize;

    this.setState(
      {
        pageSize,
      },
      () => {
        this.aquireDetail();
      }
    );
  };

  // 重置
  resetFunc = () => {
    this.inputCode.state.value = "";
    this.orderInput.state.value = "";
    this.setState(
      {
        orderNo: "",
        locationCode: "",
        value: 2,
        select: "all",
        sizeSelect: "all",
        repositySelect: "all",
        outOfRepositoryAt: "",
        selectDate: "",
        selectEWA: "",
        selectIA: "",
        selectORA: "",
        selectOWA: "",
        installationAt: "",
      },
      () => {
        this.aquireDetail();
      }
    );
  };

  render() {
    let {
      value,
      select,
      sizeSelect,
      repositySelect,
      data,
      loading,
      children,
      sizeChildren,
      reposityChildren,
      locationCode,
      orderNo,
      selectEWA,
      selectIA,
      selectORA,
      selectOWA,
      selectDate,
    } = this.state;
    const columns = [
      {
        title: "序号",
        dataIndex: "id",
        fixed: "left",
        width: 100,
      },
      {
        title: "订单号",
        dataIndex: "orderNo",
        width: 100,
      },
      {
        title: "型钢编号",
        dataIndex: "code",
        width: 200,
      },
      {
        title: "项目名称",
        dataIndex: "projectName",
        width: 200,
      },

      {
        title: "出货仓库",
        dataIndex: "repositoryName",
        width: 150,
      },

      {
        title: "规格尺寸",
        dataIndex: "specification",
        width: 150,
      },
      {
        title: "使用状态",
        dataIndex: "status",
        width: 100,
      },
      {
        title: "安装码",
        dataIndex: "locationCode",
        width: 150,
      },
      {
        title: "安装时间",
        dataIndex: "installationDate",
        width: 150,
      },

      {
        title: "出库时间",
        dataIndex: "outRepositoryDate",
        width: 150,
      },
      {
        title: "归库时间",
        dataIndex: "enterRepositoryDate",
        width: 150,
      },
      {
        title: "入场时间",
        dataIndex: "enterWorkshopDate",
        width: 150,
      },
      {
        title: "出厂时间",
        dataIndex: "outWorkshopDate",
        width: 150,
      },
      {
        title: "使用天数",
        dataIndex: "useTime",
        width: 150,
      },
      {
        title: "数量",
        dataIndex: "total",
        width: 150,
      },
      {
        title: "重量",
        dataIndex: "weight",
        width: 150,
      },
    ];
    return (
      <div className="project_detail_box">
        <div className="btn_box">
          <div className="btn_right">
            <div>
              是否展示全部：
              <Radio.Group onChange={this.onChange} value={value}>
                <Radio value={1}>是</Radio>
                <Radio value={2}>否</Radio>
              </Radio.Group>
            </div>
            <div>
              <div style={{ width: 80 }}>安装码：</div>
              <Input
                placeholder="请输入安装码"
                onChange={this.debounce(this.inputChange, 600)}
                defaultValue={locationCode}
                ref={(inputCode) => {
                  this.inputCode = inputCode;
                }}
              ></Input>
            </div>
            <div>
              <div style={{ width: 80 }}>订单号：</div>
              <Input
                placeholder="请输入订单号"
                onChange={this.debounce(this.orderChange, 600)}
                defaultValue={orderNo}
                ref={(orderInput) => {
                  this.orderInput = orderInput;
                }}
              ></Input>
            </div>
            <div>
              状态列表：
              <Select
                // defaultValue={statusDefault.desc}
                // key={statusDefault.desc}
                value={select}
                style={{ width: 200 }}
                onChange={this.handleChange}
              >
                {children}
              </Select>
            </div>
            <div>
              规格列表：
              <Select
                value={sizeSelect}
                style={{ width: 270 }}
                onChange={this.handleChange2}
              >
                {sizeChildren}
              </Select>
            </div>
            <div>
              仓库列表：
              <Select
                value={repositySelect}
                style={{ width: 200 }}
                onChange={this.handleChange3}
              >
                {reposityChildren}
              </Select>
            </div>
            <div>
              出库时间：
              <DatePicker
                showTime
                value={selectDate}
                onChange={this.onDateChange}
              />
            </div>
            <div>
              入库时间：
              <DatePicker
                showTime
                value={selectORA}
                onChange={this.onORAChange}
              />
            </div>
            <div>
              安装时间：
              <DatePicker
                showTime
                value={selectIA}
                onChange={this.onIAChange}
              />
            </div>
            <div>
              出场时间：
              <DatePicker
                showTime
                value={selectOWA}
                onChange={this.onOWAChange}
              />
            </div>
            <div>
              入场时间：
              <DatePicker
                showTime
                value={selectEWA}
                onChange={this.onEWAChange}
              />
            </div>
            <div className="btn" style={{ marginLeft: 10 }}>
              <Button
                type="primary"
                style={{ background: "#7BAC1C", border: "none" }}
                onClick={this.resetFunc}
              >
                重置
              </Button>
            </div>
          </div>
        </div>
        <div className="table_box">
          {value == 1 ? (
            <Table
              onChange={onChange}
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 600 }}
              pagination={false}
              loading={loading}
            />
          ) : (
            <Table
              onChange={onChange}
              columns={columns}
              dataSource={data}
              bordered
              scroll={{ x: 600 }}
              loading={loading}
              pagination={this.paginationProps}
            />
          )}
        </div>
        <div className="tip">
          <div> 型钢总数(根)：{this.state.total}</div>
          <div> 型钢总重(吨)：{this.state.weight}</div>
        </div>
      </div>
    );
  }
}
