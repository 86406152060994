import React, { Component } from "react";
import { Table, Button, Popconfirm, Modal, message, Select, Input } from "antd";
import Request from "../../../utils/client";
import { format } from "../../../utils/dateFormate";
import root from "../../../config/root";
import AddCompany from "./components/AddCompany";
import { AQUIRE_COMPANY, DELETE_COMPANY } from "../../../graphql/admin/company";
import axiosRequest from "../../../utils/request";
import { companyRequestPath } from "../../../request/requestPath";

import "./company.scss";
const { Option } = Select;
export default class log extends Component {
  constructor(props) {
    super(props);
    let columns = [
      {
        title: "序号",
        dataIndex: "companyId",
        fixed: "left",
        width: 100,
      },
      {
        title: "公司名称",
        dataIndex: "companyName",
        width: 150,
      },
      {
        title: "公司拼音简写",
        dataIndex: "companyPinYin",
        width: 150,
      },
      {
        title: "管理员名称",
        dataIndex: "adminName",
        width: 150,
      },
      {
        title: "电话",
        dataIndex: "phone",
        width: 150,
      },
      {
        title: "微信",
        dataIndex: "wechat",
        width: 150,
      },
      {
        title: "APP企业宗旨",
        dataIndex: "symbol",
        width: 150,
      },
      {
        title: "APP企业Logo",
        dataIndex: "logoImg",
        width: 150,
        align: "center", // 设置文本居中
        render: (text) => <img src={text} style={{ width: "130px" }} />, //这里放后台返回的图片的路径或者整个<img/>
      },
      {
        title: "APP主页背景",
        dataIndex: "backgroundImg",
        width: 150,
        align: "center", // 设置文本居中
        render: (text) => <img src={text} style={{ width: "130px" }} />, //这里放后台返回的图片的路径或者整个<img/>
      },
      {
        title: "起始时间",
        dataIndex: "startDate",
        width: 200,
      },
      {
        title: "结束时间",
        dataIndex: "endDate",
        width: 200,
      },
      {
        title: "账号状态",
        dataIndex: "status",
        width: 150,
      },
      // {
      //   title: "创建时间",
      //   dataIndex: "createdDate",
      //   width: 200,
      // },

      {
        title: "管理员手机",
        dataIndex: "adminPhone",
        width: 150,
      },
      {
        title: "管理员微信",
        dataIndex: "adminWechat",
        width: 150,
      },
      {
        title: "管理员头像",
        dataIndex: "adminAvatar",
        width: 150,
        align: "center", // 设置文本居中
        render: (text) => <img src={text} style={{ width: "130px" }} />, //这里放后台返回的图片的路径或者整个<img/>
      },
    ];
    let userInfo = localStorage.getItem("userInfo");

    let pathname = window.location.pathname;
    let role = "";
    if (userInfo) {
      role = JSON.parse(userInfo).role;
      if (root[role][pathname].modify || root[role][pathname].delete) {
        columns.push({
          title: "操作",
          key: "operation",
          width: 200,
          fixed: "right",
          align: "center",
          render: (item) => (
            <div>
              {root[role][pathname].modify ? (
                <Button
                  type="primary"
                  onClick={() => {
                    this.modify(item);
                  }}
                >
                  编辑
                </Button>
              ) : (
                ""
              )}
              {root[role][pathname].delete ? (
                <Popconfirm
                  title="确定删除吗？"
                  okText="确定"
                  cancelText="取消"
                  onConfirm={() => {
                    this.deleteItem(item);
                  }}
                >
                  <Button type="danger" style={{ marginLeft: "20px" }}>
                    删除
                  </Button>
                </Popconfirm>
              ) : (
                ""
              )}
            </div>
          ),
        });
      }
    }

    this.state = {
      data: [],
      companyData: [], // 存储获取的公司数据，用于用户搜索时
      columns,
      role,
      pathname,
      modalVisible: false,
      title: "添加公司",
      searchType: "company",
    };
  }
  componentDidMount() {
    this.aquireData();
  }
  // 请求数据
  aquireData = () => {
    // PubSub.publish("loading", true); //修改loading状态
    axiosRequest
      .post(companyRequestPath.GetAllCompany, {})
      .then((res) => {
        if (res.code === 200) {
          let data = res.data;
          data.forEach((item, index) => {
            item.sort = index + 1;
            item.key = index;
            // item.logoPicture = item.logoFile.url;
            // item.backgroundPicture = item.backgroundFile.url;
            // item.adminPicture = item.adminAvatar.url;
            item.startDate = item.startedAt ? format(item.startedAt) : "";
            item.endDate = item.ended_at ? format(item.ended_at) : "";
            // item.createdDate = item.createedAt ? format(item.createedAt) : "";
            item.status = item.userIsAble ? "正常" : "已停用";
          });
          this.setState({ data, companyData: data });
        } else {
          message.error("获取公司错误");
        }
      })
      .catch((error) => {
        console.log("获取公司报错", error);
      });

    // Request.client
    //   .query({
    //     query: AQUIRE_COMPANY,
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     // PubSub.publish("loading", false); //修改loading状态
    //     console.log("公司数据", result);
    //     let { getAllCompany } = result.data;
    //     getAllCompany.forEach((item, index) => {
    //       item.sort = index + 1;
    //       item.key = item.id;
    //       item.logoPicture = item.logoFile.url;
    //       item.backgroundPicture = item.backgroundFile.url;
    //       item.adminPicture = item.adminAvatar.url;
    //       item.startDate = item.startedAt ? format(item.startedAt) : "";
    //       item.endDate = item.endedAt ? format(item.endedAt) : "";
    //       item.createdDate = item.createedAt ? format(item.createedAt) : "";
    //       item.status = item.isAble ? "正常" : "已停用";
    //     });
    //     this.setState({ data: getAllCompany, companyData: getAllCompany });
    //   })
    //   .catch((err) => {
    //     // this.props.history.push({ pathname: "/admin/error/systemerror" });
    //     console.log(err);
    //     // PubSub.publish("loading", false); //修改loading状态
    //   });
  };
  // 删除
  deleteItem = (item) => {
    Request.client
      .mutate({
        mutation: DELETE_COMPANY, // 封装好的GraphQL,
        variables: {
          id: item.companyId,
        },
      })
      .then((result) => {
        message.success("删除公司成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加公司" }, () => {
      this.AddCompany.handlerResetForm();
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加公司") {
      this.AddCompany.addCompany();
    } else {
      this.AddCompany.modifyProjectSubmit();
    }
  };

  // 关闭弹窗，由AddCompany组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
    this.aquireData();
  };
  // 修改项目
  modify = (project) => {
    this.setState({ modalVisible: true, title: "编辑公司" }, () => {
      this.AddCompany.modify(project);
    });
  };
  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 搜索的方法
  searchFunc = () => {
    let { companyData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    // console.log(this.state.searchType,)
    let result = [];
    if (searchType === "company") {
      result = companyData.filter((obj) => {
        return obj.companyName.indexOf(keyword) !== -1;
      });
    } else if (searchType === "admin") {
      result = companyData.filter((obj) => {
        return obj.adminName.indexOf(keyword) !== -1;
      });
    } else {
      result = companyData.filter((obj) => {
        return obj.phone.indexOf(keyword) !== -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "company",
      },
      () => {
        this.aquireData();
      }
    );
  };
  render() {
    let { title, pathname, role, columns, data } = this.state;
    return (
      <div className="company_box">
        <div className="btn_box">
          {/* <Button
            type="primary"
            style={{ background: "#A06921", border: "none" }}
          >
            导出
          </Button> */}

          <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div>
          <div>
            <Select
              value={this.state.searchType}
              style={{ width: 120 }}
              onChange={this.handleChange}
            >
              <Option value="company">公司名称</Option>
              <Option value="admin">管理员名称</Option>
              <Option value="phone">公司电话</Option>
            </Select>
            <Input
              placeholder="请输入关键字"
              style={{ width: 200, marginLeft: 10 }}
              ref={(keywordInput) => {
                this.keywordInput = keywordInput;
              }}
            />
            <Button
              type="primary"
              onClick={this.searchFunc}
              style={{ marginLeft: 10 }}
            >
              搜索
            </Button>
            <Button
              type="primary"
              onClick={this.resetFunc}
              style={{ background: "#7BAC1C", border: "none", marginLeft: 10 }}
            >
              重置
            </Button>
          </div>
        </div>
        <div className="table_box">
          <Table
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
          />
        </div>
        <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={this.submit}
          onCancel={this.closeModal}
        >
          <AddCompany
            ref={(AddCompany) => {
              this.AddCompany = AddCompany;
            }}
            closeModal={this.closeModal}
          ></AddCompany>
        </Modal>
      </div>
    );
  }
}
