import React, { Component } from "react";
import { Card, Select, Modal, message, Empty, DatePicker } from "antd";
import Request from "../../../utils/client";
import { REPOSITORY } from "../../../graphql/admin/index";
// import { DEMAND_PROJECT } from "../../../graphql/admin/demand";
import { AQUIRE_PROJECT } from "../../../graphql/admin/project";
import { AQUIRE_SINPLE_MAINTENANCE } from "../../../graphql/admin/maintenance";
import {
  PROJECT_USING,
  PROJECT_SITUATION,
} from "../../../graphql/admin/index/project";
import { GET_MAINTENANCE_RDATE } from "../../../graphql/admin/index/maintenance";
import Search from "./components/Search";
import Log from "./components/Log";
import Trend from "./components/Trend";
import StatusPic from "./components/StatusPic";
import Pic from "./components/Pic";
import Line from "./components/Line";
import ProjectLine from "./components/ProjectLine";
import Cloumn from "./components/Cloumn";
import Map from "./components/Map";
import Steel from "./components/steel";
import MainPic from "./components/maintenance/MainPic";
import MainDualAxes from "./components/maintenance/MainDualAxes";
import MainTable from "./components/maintenance/MainTable";
import "./index.scss";
import imgageObject from "./image";
import configFunc from "./config/config";
import axiosRequest from "../../../utils/request";
import {
  indexRequestPath,
  AdminIndexRequest,
  maintenanceIndexRequest,
  RepositoryRequestPath,
  ProjectRequestPath,
} from "../../../request/requestPath";
import moment from "moment";

import {
  ADMIN,
  COMPANY_ADMIN,
  REPOSITORY_ADMIN,
  PROJECT_ADMIN,
  MAINTENANCE_ADMIN,
} from "../../../config/constant";

import PubSub from "pubsub-js";

// import companyRequest from "./request/company";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default class Index extends Component {
  state = {
    timer: null, // 型钢最近使用动态的定时器
    refreshType: "normal",
    mainrefreshType: "normal",
    mainTimer: null, // 型钢维修动向的定时器
    repositoryChild: [],
    pro_rep_Select: "", // 项目概览栏选择的仓库
    repositorySelect: "all",
    situationProjectChild: [],
    situationProjectSelect: "",
    projectChild: [],
    projectSelect: "",
    select: "all",
    modalVisible: false,
    repositoryId: "",
    mainChild: [], // 维修厂select组件数据
    mainSelect: "", // 选择的维修厂
    data1: [],
    data2: [],
    startDate: null, // 资产状态开始时间
    endDate: null, //资产状态结束时间
    startDateMoment: null, // moment格式的资产状态开始时间
    endDateMoment: null, // moment格式的资产状态结束时间
    data3: [
      {
        img: imgageObject.icon9,
        num: 0,
        txt: "项目总数",
        name: "projectTotal",
      },
      {
        img: imgageObject.icon10,
        num: 0,
        txt: "总体租出",
        name: "leaseTotal",
      },
      {
        img: imgageObject.icon11,
        num: 0,
        txt: "维修数量",
        name: "maintenanceWeightTotal",
      },
      { img: imgageObject.icon12, num: 20, txt: "丢失数量", name: "lossTotal" },
    ],
    roleShow: {},
    companyId: "", //公司id
    isAquireFlag: false,
  };
  role = "";
  roleText = null;
  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // 执行 config/config.js的函数，判断展示的栏目
    let { roleShow, roleText } = configFunc(userInfo.role);
    this.setState({
      roleShow,
    });
    this.role = userInfo.role;
    this.roleText = roleText;

    // 初始化资产状态的时间
    this.initDate();

    /**
     * 关于用户选择仓库后刷新index页面数据的处理
     */
    // 当用户从其它页面返回index页面时使用本地存储实现刷新数据
    let refreshIndexData = localStorage.getItem("refreshIndexData")
      ? localStorage.getItem("refreshIndexData")
      : "";
    if (refreshIndexData && !this.state.isAquireFlag) {
      this.initData(JSON.parse(refreshIndexData));
      this.setState({ isAquireFlag: true });
    }
    // 当用户在index页面选择仓库（项目，公司）时使用事件订阅刷新数据
    PubSub.unsubscribe("refreshData"); //销毁订阅 防止多次触发
    // 接收Admin.jsx中navbar区域选择仓库和选择公司时触发的事件
    PubSub.subscribe("refreshData", (_, data) => {
      this.initData(data);
      this.setState({ isAquireFlag: true });
    });

    // this.aquireResitory();
  }

  componentWillUnmount() {
    let { timer, mainTimer } = this.state;
    clearInterval(timer);
    clearInterval(mainTimer);
    this.setState({ timer: null, mainTimer: null });
  }

  // 数据初始化
  initData(data) {
    let { type, value } = data;
    switch (type) {
      case "repository":
        this.setState(
          {
            repositoryId: value * 1,
          },
          () => {
            this.roleInit();
          }
        );
        break;
      case "company":
        // return;
        this.setState(
          {
            companyId: value * 1,
          },
          () => {
            this.roleInit();
          }
        );
        break;
      default:
        this.setState(
          {
            projectId: value * 1,
          },
          () => {
            this.roleInit();
          }
        );
    }
  }

  // 初始化资产状态的时间
  initDate() {
    //  let newStartAt = moment(startAt);
    let dateNow = new Date();
    let yy = dateNow.getFullYear();
    let mm =
      dateNow.getMonth() + 1 < 10
        ? "0" + (dateNow.getMonth() + 1)
        : dateNow.getMonth() + 1;
    let dd =
      dateNow.getDate() < 10 ? "0" + dateNow.getDate() : dateNow.getDate();

    let endDate = yy + "-" + mm + "-" + dd;
    let dateStart = new Date(
      new Date(dateNow).getTime() - 7 * 24 * 60 * 60 * 1000
    );
    yy = dateStart.getFullYear();
    mm =
      dateStart.getMonth() + 1 < 10
        ? "0" + (dateStart.getMonth() + 1)
        : dateStart.getMonth() + 1;
    dd =
      dateStart.getDate() < 10
        ? "0" + dateStart.getDate()
        : dateStart.getDate();

    let startDate = yy + "-" + mm + "-" + dd;

    // startDate;
    // endDate;
    let startDateMoment = moment(startDate);
    let endDateMoment = moment(endDate);
    this.setState({
      startDate,
      endDate,
      startDateMoment,
      endDateMoment,
    });
  }

  roleInit() {
    switch (this.role) {
      case ADMIN:
        this.adminInit();
        break;
      case COMPANY_ADMIN:
      case REPOSITORY_ADMIN:
        this.commonAdminInit();
        break;
      case PROJECT_ADMIN:
        this.projectInit();
        break;
      case MAINTENANCE_ADMIN:
        this.maintemnanceAdminInit();
        break;
      default:
        console.log("没有匹配到角色");
    }
  }
  // 公共数据初始化
  commonAdminInit() {
    this.aquireProject(); //  获取项目
    this.aquireAssets(); // 获取资产概况
    this.aquireAssetState(); //  获取资产状态
    if (this.pic) {
      // 型钢使用情况
      this.pic.aquireData(this.state.repositoryId);
    }
    if (this.line) {
      // 型钢使用率
      this.line.aquireData({
        repositoryId: this.state.repositoryId,
        role: this.role,
      });
    }
    if (this.cloumn) {
      //型钢储备及损耗量
      this.cloumn.aquireData(this.state.repositoryId);
    }
    if (this.statusPic) {
      // 获取项目型钢状态
      this.statusPic.adminAquireData({
        role: this.role,
        projectId: this.state.situationProjectSelect,
        repositoryId: this.state.repositoryId,
      });
      this.aquireThrend();
    }
    // 将选择的项目id传递给 Trend.jsx （获取项目型钢动向）
    PubSub.publish("receiveSteelStateParameters", {
      type: "repository",
      id: this.state.repositoryId,
    });
  }

  // 超级管理员数据初始化
  adminInit() {
    this.adminAquireProject(); // 获取项目
    this.aquireResitory(); // 获取仓库
    this.superManAssets(); // 获取资产概况
    this.superManAssetState(); // 获取资产状态
    this.cloumn.adminAquireData(this.state.companyId); // 获取型钢储备及损耗量
    this.pic.adminAquireData(this.state.companyId); // 获取型钢使用情况
    this.line.aquireData({
      companyId: this.state.companyId,
      role: this.role,
    }); // 型钢使用率
    this.statusPic.adminAquireData(
      // 获取项目型钢状态
      {
        role: this.role,
        projectId: this.state.situationProjectSelect,
        companyId: this.state.companyId,
      }
    );
    this.aquireThrend();
  }

  // 项目管理员数据初始
  projectInit() {
    this.aquireResitory();
    this.projectAssets(); // 获取项目概况
    this.projectAssetState(); //  获取使用状态
    this.projectLine.aquireData(
      this.state.pro_rep_Select * 1,
      this.state.projectId * 1
    );
    this.statusPic.adminAquireData(
      // 获取项目型钢状态
      {
        role: this.role,
        projectId: this.state.projectId,
        repositoryId: this.state.pro_rep_Select,
      }
      // this.state.projectId,
      // this.state.pro_rep_Select
    );
    this.aquireThrend();
  }

  // 维修管理员初始化数据
  maintemnanceAdminInit() {
    this.aquireProject(); // 获取项目列表，搜索型钢的时候使用
    this.aquireMainData(); // 获取维修厂
    this.mainAssets(); // 获取维修响应数据，维修概况，维修状态
    this.mainPic.repairProfiles(this.state.repositoryId);
    this.aquireThrend();
  }

  // 获取资产概况
  aquireAssets() {
    axiosRequest
      .post(indexRequestPath.Assets, {
        repositoryId: this.state.repositoryId,
      })
      .then((res) => {
        this.roleText[this.role].situation.forEach((item) => {
          if (res.data[item.name]) {
            item.sum = res.data[item.name].toFixed(4) * 1;
          } else {
            item.sum = 0;
          }
        });
        this.setState({
          data1: this.roleText[this.role].situation,
        });
      });
  }

  // 超级管理员获取资产概况
  superManAssets() {
    axiosRequest
      .post(AdminIndexRequest.SuperManAssets, {
        companyId: this.state.companyId,
      })
      .then((res) => {
        this.roleText[this.role].situation.forEach((item) => {
          if (res.data[item.name]) {
            item.sum = res.data[item.name].toFixed(4) * 1;
          } else {
            item.sum = 0;
          }
        });
        this.setState({
          data1: this.roleText[this.role].situation,
        });
      });
  }
  // 超级管理员获取资产状态
  superManAssetState() {
    axiosRequest
      .post(AdminIndexRequest.SuperManAssetState, {
        companyId: this.state.companyId,
        startTime: this.state.startDate,
        endTime: this.state.endDate,
      })
      .then((res) => {
        this.roleText[this.role].status.forEach((item) => {
          if (res.data[item.name]) {
            item.sum = res.data[item.name].toFixed(4) * 1;
          } else {
            item.sum = 0;
          }
        });
        this.setState({
          data2: this.roleText[this.role].status,
        });
      });
  }
  // 超级管理员获取项目
  adminAquireProject = () => {
    axiosRequest
      .post(AdminIndexRequest.GetProjectListBySuper, {
        companyId: this.state.companyId,
      })
      .then((res) => {
        console.log("获取项目数据", res);
        if (res.code === 200) {
          if (res.data) {
            res.data.forEach((item) => {
              item.projectId = item.id + "";
              item.projectName = item.name;
            });
            res.data ? this.projectDeal(res.data) : this.projectDeal([]);
          } else {
            this.setState({
              projectChild: [],
              projectSelect: "",
              situationProjectSelect: "",
              situationProjectChild: [],
            });
          }
        }
      })
      .catch((error) => {
        console.log("超级管理员搜索获取项目错误", error);
      });
  };

  // 项目管理员获取项目概况
  projectAssets() {
    Request.client
      .query({
        query: PROJECT_SITUATION,
        variables: {
          input: {
            projectId: this.state.projectId,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let { getGeneralSituationOfProject } = res.data;
        this.roleText[this.role].situation.forEach((item) => {
          if (getGeneralSituationOfProject[item.name]) {
            item.name === "steelTotal" || item.name === "equipmentTotal"
              ? (item.sum =
                  getGeneralSituationOfProject[item.name].toFixed(4) * 1)
              : (item.sum = moment(
                  getGeneralSituationOfProject[item.name]
                ).format("YYYY-MM-DD"));
          } else {
            item.sum = 0;
          }
        });

        this.setState({
          data1: this.roleText[this.role].situation,
        });
      })
      .catch((error) => {
        console.log("项目管理员获取项目概况", error);
      });
  }

  // 项目管理员获取使用状态
  projectAssetState() {
    Request.client
      .query({
        query: PROJECT_USING,
        variables: {
          input: {
            projectId: this.state.projectId,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let { getProjectSteelUsingDynamic } = res.data;
        this.roleText[this.role].status.forEach((item) => {
          if (getProjectSteelUsingDynamic[item.name]) {
            item.sum = getProjectSteelUsingDynamic[item.name].toFixed(4) * 1;
          } else {
            item.sum = 0;
          }
        });
        this.setState({
          data2: this.roleText[this.role].status,
        });
      })
      .catch((error) => {
        console.log("项目管理员获取使用状态错误", error);
      });
  }

  // 维修管理员获取响应数据(维修概况，维修状态)
  mainAssets() {
    axiosRequest
      .post(maintenanceIndexRequest.Maintenance, {
        repositoryId: this.state.repositoryId,
      })
      .then((res) => {
        if (res.code === 200) {
          this.roleText[this.role].situation.forEach((item) => {
            if (res.data[item.name]) {
              item.name === "expectedReturn"
                ? (item.sum = moment(res.data[item.name]).format("YYYY-MM-DD"))
                : (item.sum = res.data[item.name].toFixed(4) * 1);
            } else {
              item.sum = 0;
            }
          });
          this.setState({
            data1: this.roleText[this.role].situation,
          });
        }
      })
      .catch((error) => {
        console.log("获取维修概览报错");
      });
    Request.client
      .query({
        query: GET_MAINTENANCE_RDATE,
        variables: {
          input: {
            repositoryId: this.state.repositoryId,
          },
        },
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let { getMaintenanceRDate } = res.data;
        // this.roleText[this.role].situation.forEach((item) => {
        //   if (getMaintenanceRDate[item.name]) {
        //     item.sum = getMaintenanceRDate[item.name].toFixed(4) * 1;
        //   } else {
        //     item.sum = 0;
        //   }
        // });
        this.roleText[this.role].status.forEach((item) => {
          if (getMaintenanceRDate[item.name]) {
            item.sum = getMaintenanceRDate[item.name].toFixed(4) * 1;
          } else {
            item.sum = 0;
          }
        });
        this.setState({
          // data1: this.roleText[this.role].situation,
          data2: this.roleText[this.role].status,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 获取资产状态
  aquireAssetState() {
    axiosRequest
      .post(indexRequestPath.AssetState, {
        repositoryId: this.state.repositoryId,
        startTime: this.state.startDate,
        endTime: this.state.endDate,
      })
      .then((res) => {
        this.roleText[this.role].status.forEach((item) => {
          if (res.data[item.name]) {
            item.sum = res.data[item.name].toFixed(4) * 1;
          } else {
            item.sum = 0;
          }
        });
        this.setState({
          data2: this.roleText[this.role].status,
        });
      });
  }

  // 获取维修厂数据
  aquireMainData() {
    Request.client
      .query({
        query: AQUIRE_SINPLE_MAINTENANCE,
        variables: {
          input: {},
        },
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        let { getMaintenanceList } = result.data;
        let children = [];
        // children.push(<Option key="all">项目（全部）</Option>);
        getMaintenanceList.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState(
          {
            mainChild: children,
          },
          () => {
            if (getMaintenanceList.length > 0) {
              // this.mainTable.aquireData(getMaintenanceList[0].id);
              this.mainChange(getMaintenanceList[0].id + "");
            }
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 获取仓库列表
  aquireResitory() {
    axiosRequest
      .post(RepositoryRequestPath.RepositoryList, {})
      .then((res) => {
        if (res.code === 200) {
          let children = [];
          res.data.forEach((item) => {
            children.push(<Option key={item.id}>{item.name}</Option>);
          });
          this.setState({
            repositoryChild: children,
            pro_rep_Select: res.data.length > 0 ? res.data[0].id + "" : "",
          });
          // 项目管理员获取仓库列表时
          if (
            res.data.length > 0 &&
            // this.role === MAINTENANCE_ADMIN
            this.role === PROJECT_ADMIN
          ) {
            this.proRepChange(res.data[0].id + "");
            // 渲染仓库
            // eslint-disable-next-line no-unused-expressions
            this.projectMap ? this.projectMap.initWarehouse(res.data) : "";
          }
          // 超级管理员获取仓库列表时
          if (res.data.length > 0 && this.role === ADMIN) {
            this.repositoryChange(res.data[0].id + "");
          }
        } else {
          message.error("获取仓库列表错误");
        }
      })
      .catch((error) => {
        console.log("获取仓库列表错误");
      });
    // Request.client
    //   .query({
    //     query: REPOSITORY,
    //     variables: {
    //       input: {},
    //     },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     let { getRepositoryListForDashboard } = result.data;
    //     console.log("获取的仓库", getRepositoryListForDashboard);
    //     let children = [];
    //     // children.push(<Option key="all">仓库（全部）</Option>);
    //     getRepositoryListForDashboard.forEach((item) => {
    //       children.push(<Option key={item.id}>{item.name}</Option>);
    //     });
    //     this.setState({
    //       repositoryChild: children,
    //       pro_rep_Select:
    //         getRepositoryListForDashboard.length > 0
    //           ? getRepositoryListForDashboard[0].id + ""
    //           : "",
    //     });
    //     // 项目管理员获取仓库列表时
    //     if (
    //       getRepositoryListForDashboard.length > 0 &&
    //       // this.role === MAINTENANCE_ADMIN
    //       this.role === PROJECT_ADMIN
    //     ) {
    //       this.proRepChange(getRepositoryListForDashboard[0].id + "");
    //     }
    //     // 超级管理员获取项目列表时
    //     if (getRepositoryListForDashboard.length > 0 && this.role === ADMIN) {
    //       this.repositoryChange(getRepositoryListForDashboard[0].id + "");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }
  // 获取项目列表
  aquireProject() {
    axiosRequest
      .post(ProjectRequestPath.GetProjectList, {})
      .then((res) => {
        if (res.code === 200) {
          // console.log("获取项目", res.data);
          this.projectDeal(res.data);
        }
      })
      .catch((error) => {
        console.log("获取项目报错", error);
      });
    // Request.client
    //   .query({
    //     query: AQUIRE_PROJECT,
    //     variables: {
    //       input: {},
    //     },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     console.log("获取项目", result);
    //     let { getProjectLis } = result.data;
    //     this.projectDeal(getProjectLis);
    //   })
    //   .catch((err) => {
    //     console.log("获取项目报错", err);
    //   });
  }

  // 处理获取的项目数据
  projectDeal(projectData) {
    let children = [];
    this.search.projectDeal(projectData);
    // eslint-disable-next-line no-unused-expressions
    this.projectMap ? this.projectMap.initProjectMap(projectData) : "";

    // eslint-disable-next-line no-unused-expressions
    projectData
      ? projectData.forEach((item) => {
          children.push(
            <Option key={item.projectId}>{item.projectName}</Option>
          );
        })
      : "";
    this.setState(
      {
        projectChild: children,
        projectSelect:
          projectData.length > 0 ? projectData[0].projectId + "" : "",
        situationProjectSelect:
          projectData.length > 0 ? projectData[0].projectId + "" : "",
        situationProjectChild: children,
      },
      () => {
        if (projectData.length > 0) {
          this.pieChange(projectData[0].projectId + "");
          this.projectChange(projectData[0].projectId + "");
          if (this.role === ADMIN || this.role === COMPANY_ADMIN) {
            this.projectLine.adminAquireData(
              this.state.companyId * 1,
              projectData[0].projectId * 1
            );
          }
        }
      }
    );
  }

  // 型钢最近使用动态的刷新设置
  handleChange = (e) => {
    // console.log(e);
    let { timer } = this.state;
    clearInterval(timer);
    if (e === "normal") {
      timer = null;
    } else {
      timer = setInterval(() => {
        message.info("正在刷新型钢数据");
        this.aquireThrend(); // 获取型钢最新使用动向
      }, parseInt(e) * 1000);
    }
    this.setState({ timer, refreshType: e });
    localStorage.setItem("refresh", e);
  };
  // 型钢维修动向的刷新设置
  mainhandleChange = (e) => {
    // console.log(e);
    let { mainTimer } = this.state;
    clearInterval(mainTimer);
    if (e === "normal") {
      mainTimer = null;
    } else {
      mainTimer = setInterval(() => {
        message.info("正在刷新型维修动向数据");
        this.mainTable.aquireData(this.state.mainSelect);
      }, parseInt(e) * 1000);
    }
    this.setState({ mainTimer, mainrefreshType: e });
    localStorage.setItem("mainRefresh", e);
  };
  // 项目概况中选择的项目发生改变时
  pieChange = (e) => {
    this.setState(
      {
        situationProjectSelect: e,
      },
      () => {
        // 调用ProjectLine.jsx里的方法 获取项目现场型钢数据
        switch (this.role) {
          case ADMIN:
            this.projectLine.adminAquireData(
              this.state.companyId * 1,
              this.state.situationProjectSelect * 1
            );
            this.statusPic.adminAquireData(
              this.state.companyId,
              this.state.situationProjectSelect
            );
            break;
          case COMPANY_ADMIN:
            this.projectLine.adminAquireData(
              0,
              this.state.situationProjectSelect * 1
            );
            this.statusPic.adminAquireData({
              role: this.role,
              projectId: this.state.situationProjectSelect,
              repositoryId: this.state.repositoryId,
            });
            break;
          case REPOSITORY_ADMIN:
            this.projectLine.aquireData(
              this.state.repositoryId,
              this.state.situationProjectSelect * 1
            );
            this.statusPic.adminAquireData({
              role: this.role,
              projectId: this.state.situationProjectSelect,
              repositoryId: this.state.repositoryId,
            });
            break;
          case PROJECT_ADMIN:
            // this.projectLine.aquireData(
            //   this.state.repositoryId,
            //   this.state.situationProjectSelect * 1
            // );
            // this.statusPic.projectAquireData(
            //   this.state.situationProjectSelect,
            //   this.state.pro_rep_Select
            // );
            break;
          case MAINTENANCE_ADMIN:
            // this.maintemnanceAdminInit();
            break;
          default:
            console.log("没有匹配到角色");
        }
      }
    );
  };
  // 型钢最近使用动向选择的项目改变时
  projectChange = (e) => {
    this.setState(
      {
        projectSelect: e,
      },
      () => {
        this.aquireThrend(); // 获取型钢最新使用动向
      }
    );
  };
  // 型钢最近使用动向仓库改变时
  repositoryChange = (e) => {
    this.setState(
      {
        select: e,
      },
      () => {
        this.aquireThrend(); // 获取型钢最新使用动向
      }
    );
  };

  // 获取型钢最新使用动向
  aquireThrend() {
    // console.log("获取型钢动向");

    let companyId = "";
    let projectId = "";
    let repositoryId = "";
    switch (this.role) {
      case ADMIN:
        companyId = this.state.companyId * 1;
        projectId = this.state.projectSelect * 1;
        repositoryId = this.state.select * 1;
        this.trend.adminInit(companyId, projectId, repositoryId);
        break;
      case PROJECT_ADMIN:
        projectId = this.state.projectId * 1;
        this.trend.commonInit(projectId, 0);
        break;
      case MAINTENANCE_ADMIN:
        break;
      default:
        projectId = this.state.projectSelect * 1;
        repositoryId = this.state.repositoryId * 1;
        this.trend.commonInit(projectId, repositoryId);
    }
  }

  // 型钢查询（由Search组件触发）
  searchFunc = (obj) => {
    this.setState({ modalVisible: true }, () => {
      this.steel.aquireData(obj);
    });
  };
  // 维修厂选项修改时
  mainChange = (e) => {
    this.setState(
      {
        mainSelect: e,
      },
      () => {
        // 重新获取型钢维修动向
        this.mainTable.aquireData(e);
      }
    );
  };

  // 资产状态日期发生改变的时候
  onChange = (date) => {
    let startDate = moment(date[0]).format("YYYY-MM-DD");
    let endDate = moment(date[1]).format("YYYY-MM-DD");
    this.setState(
      {
        startDate,
        endDate,
      },
      () => {
        switch (this.role) {
          case ADMIN:
            this.superManAssetState();
            break;
          case COMPANY_ADMIN:
          case REPOSITORY_ADMIN:
          case PROJECT_ADMIN:
            this.aquireAssetState();
            break;
          case MAINTENANCE_ADMIN:
            // this.maintemnanceAdminInit();
            break;
          default:
            console.log("没有匹配到角色");
        }
      }
    );
  };

  // 项目概况的仓库选择改变时(仅在项目管理员登录时使用)
  proRepChange = (e) => {
    this.setState(
      {
        pro_rep_Select: e,
      },
      () => {
        this.statusPic.adminAquireData(
          // 获取项目型钢状态
          {
            role: this.role,
            projectId: this.state.projectId,
            repositoryId: e,
          }
        );
        this.projectLine.aquireData(e * 1, this.state.projectId * 1);
      }
    );
  };

  render() {
    let {
      repositoryChild,
      pro_rep_Select,
      repositorySelect,
      projectChild,
      projectSelect,
      select,
      mainrefreshType,
      refreshType,
      roleShow,
      situationProjectSelect,
      situationProjectChild,
      mainChild,
      mainSelect,
      repositoryId,
      startDateMoment,
      endDateMoment,
    } = this.state;
    return (
      <div className="index_box">
        {/*资产概况*/}
        {roleShow.assets ? (
          <div className="card_box top_car_box">
            <Card
              title={
                this.role === PROJECT_ADMIN
                  ? "项目概况"
                  : this.role === MAINTENANCE_ADMIN
                  ? "维修概况（吨）"
                  : "资产概况（吨）"
              }
              bordered={false}
              style={{ width: "100%", position: "relative" }}
              className="card"
            >
              {this.state.data1.length > 0 ? (
                <div className="item_box">
                  {this.state.data1.map((item) => {
                    return (
                      <div className="card_item" key={item.icon}>
                        <div className="card_inner_box">
                          <img src={item.icon} alt="" />
                          <div>
                            <div className="num">{item.sum}</div>
                            <div className="txt">{item.text}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="item_box"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Empty />
                </div>
              )}
            </Card>
          </div>
        ) : (
          ""
        )}
        {/* 资产状态 */}
        {roleShow.assets ? (
          <div className="card_box top_car_box">
            <Card
              title={
                this.role === PROJECT_ADMIN
                  ? "使用状态（吨）"
                  : this.role === MAINTENANCE_ADMIN
                  ? "维修状态（时间段）"
                  : "资产状态（吨)"
              }
              //
              bordered={false}
              style={{ width: "100%", position: "relative" }}
              className="card"
            >
              {this.role !== PROJECT_ADMIN &&
              this.role !== MAINTENANCE_ADMIN ? (
                <div
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 17,
                    color: "rgba(0,0,0,.6)",
                  }}
                >
                  <RangePicker
                    defaultValue={[startDateMoment, endDateMoment]}
                    onChange={this.onChange}
                  />
                </div>
              ) : (
                ""
              )}
              {this.state.data2.length > 0 ? (
                <div className="item_box">
                  {this.state.data2.map((item) => {
                    return (
                      <div className="card_item" key={item.icon}>
                        <div className="card_inner_box">
                          <img src={item.icon} alt="" />
                          <div>
                            <div className="num">{item.sum}</div>
                            <div className="txt">{item.text}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className="item_box"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Empty />
                </div>
              )}
            </Card>
          </div>
        ) : (
          ""
        )}

        {/* <Card
            title="最近半年盘点"
            bordered={false}
            style={{ width: "33%", minWidth: "260px" }}
            className="card"
          >
            <div className="rectangle_item">
              {this.state.data3.map((item) => {
                return (
                  <div className="rectangle" key={item.img}>
                    <div className="img_box">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="txt">{item.txt}</div>
                    <div className="num">{item.num}</div>
                  </div>
                );
              })}
            </div>
          </Card> */}

        {/* 型钢查询 */}
        {roleShow.assets ? (
          <div className="card_box top_car_box">
            <Card
              title="型钢查询"
              style={{ width: "100%", position: "relative" }}
              bordered={false}
              className="card"
            >
              <Search
                // projectChild={projectChild}
                ref={(search) => (this.search = search)}
                searchFunc={(obj) => {
                  this.searchFunc(obj);
                }}
              ></Search>
            </Card>
          </div>
        ) : (
          ""
        )}

        {/* 仓库栏 */}
        {roleShow.warehouse ? (
          <div className="card_box pic_box">
            <Card
              title="仓库概览"
              bordered={false}
              className="card"
              style={{ width: "100%" }}
            >
              {/* <div className="pic_select">
                <Select
                  value={repositorySelect}
                  style={{ width: 180 }}
                  onChange={this.projectChange}
                >
                  {repositoryChild}
                </Select>
              </div> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div className="pic_item">
                  <div className="pic_title">型钢使用情况</div>
                  <Pic
                    repositoryId={repositoryId}
                    ref={(pic) => {
                      this.pic = pic;
                    }}
                  ></Pic>
                </div>
                <div className="pic_item">
                  <div className="pic_title">型钢使用率</div>
                  <Line
                    repositoryId={repositorySelect}
                    ref={(line) => {
                      this.line = line;
                    }}
                  ></Line>
                </div>
                <div className="pic_item">
                  <div className="pic_title">型钢储备及损耗量</div>
                  <Cloumn
                    repositoryId={repositorySelect}
                    ref={(cloumn) => {
                      this.cloumn = cloumn;
                    }}
                  ></Cloumn>
                </div>
              </div>
            </Card>
            {/* <Card
            title="型钢查询"
            bordered={false}
            style={{ width: "33%" }}
            className="card"
          >
            <Search
              searchFunc={(obj) => {
                this.searchFunc(obj);
              }}
            ></Search>
          </Card> */}
          </div>
        ) : (
          ""
        )}
        {/* 项目栏 */}
        {roleShow.project ? (
          <div className="card_box pic_box">
            <Card
              title="项目概览"
              bordered={false}
              className="card"
              style={{ width: "100%" }}
            >
              <div className="pic_select">
                {this.role === PROJECT_ADMIN ? (
                  <Select
                    value={pro_rep_Select}
                    style={{ width: 180 }}
                    onChange={this.proRepChange}
                  >
                    {repositoryChild}
                  </Select>
                ) : (
                  <Select
                    value={situationProjectSelect}
                    style={{ width: 180 }}
                    onChange={this.pieChange}
                  >
                    {situationProjectChild}
                  </Select>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div className="pic_item map_item">
                  <Map
                    ref={(projectMap) => (this.projectMap = projectMap)}
                  ></Map>
                </div>
                <div className="pic_item">
                  <div className="pic_title">项目型钢状态</div>
                  <StatusPic
                    repositoryId={repositorySelect}
                    ref={(statusPic) => {
                      this.statusPic = statusPic;
                    }}
                  ></StatusPic>
                </div>
                <div className="pic_item">
                  <div className="pic_title">项目现场型钢数据</div>
                  <ProjectLine
                    repositoryId={repositorySelect}
                    ref={(projectLine) => {
                      this.projectLine = projectLine;
                    }}
                  ></ProjectLine>
                </div>
              </div>
            </Card>
          </div>
        ) : (
          ""
        )}
        {/* 维修栏 */}
        {roleShow.maintenance ? (
          <div className="card_box pic_box">
            <Card
              title="维修概览"
              bordered={false}
              className="card"
              style={{ width: "100%" }}
            >
              <div className="maintenance_item">
                <div className="pic_title">维修概况</div>
                <MainPic
                  repositoryId={repositorySelect}
                  ref={(mainPic) => {
                    this.mainPic = mainPic;
                  }}
                ></MainPic>
              </div>
              <div className="maintenance_item">
                <div className="pic_title">维修率</div>
                <MainDualAxes
                  repositoryId={repositorySelect}
                  ref={(dualAxes) => {
                    this.dualAxes = dualAxes;
                  }}
                ></MainDualAxes>
              </div>
            </Card>
          </div>
        ) : (
          ""
        )}
        {/* 维修动向表 */}
        {roleShow.maintenance ? (
          <div className="card_box table_box">
            <Card
              title="型钢维修动向"
              bordered={false}
              className="left_card card log_card"
              style={{ width: "100%" }}
            >
              <div className="table_select">
                <Select
                  value={mainrefreshType}
                  style={{ width: 140 }}
                  onChange={this.mainhandleChange}
                >
                  <Option value="20">每隔20秒刷新</Option>
                  <Option value="30">每隔30秒刷新</Option>
                  <Option value="60">每隔60秒刷新</Option>
                  <Option value="300">每隔300秒刷新</Option>
                  <Option value="normal">从不刷新</Option>
                </Select>
                <Select
                  value={mainSelect}
                  style={{ width: 140, marginRight: 10, marginLeft: 10 }}
                  onChange={this.mainChange}
                >
                  {mainChild}
                </Select>

                {/* <Select
                  value={select}
                  style={{ width: 140 }}
                  onChange={this.repositoryChange}
                >
                  {repositoryChild}
                </Select> */}
              </div>
              <MainTable
                ref={(mainTable) => {
                  this.mainTable = mainTable;
                }}
              ></MainTable>
            </Card>
          </div>
        ) : (
          ""
        )}
        {/* 型钢栏 */}
        {roleShow.steel ? (
          <div className="card_box table_box">
            <Card
              title="型钢最近使用动向"
              bordered={false}
              className="left_card card log_card"
              style={{ width: "100%" }}
            >
              <div className="table_select">
                <Select
                  value={refreshType}
                  style={{ width: 140 }}
                  onChange={this.handleChange}
                >
                  <Option value="20">每隔20秒刷新</Option>
                  <Option value="30">每隔30秒刷新</Option>
                  <Option value="60">每隔60秒刷新</Option>
                  <Option value="300">每隔300秒刷新</Option>
                  <Option value="normal">从不刷新</Option>
                </Select>
                {this.role !== PROJECT_ADMIN ? (
                  <Select
                    value={projectSelect}
                    style={{ width: 140, marginRight: 10, marginLeft: 10 }}
                    onChange={this.projectChange}
                  >
                    {projectChild}
                  </Select>
                ) : (
                  ""
                )}
                {this.role === ADMIN ? (
                  <Select
                    value={select}
                    style={{ width: 140 }}
                    onChange={this.repositoryChange}
                  >
                    {repositoryChild}
                  </Select>
                ) : (
                  ""
                )}
              </div>
              <Trend
                repositoryId={select}
                projectId={projectSelect}
                ref={(trend) => {
                  this.trend = trend;
                }}
              ></Trend>
            </Card>
          </div>
        ) : (
          ""
        )}
        {/* 日志栏 */}
        {roleShow.log ? (
          <div>
            <Card
              title="日志列表"
              bordered={false}
              style={{ width: "100%" }}
              className="card log_card"
            >
              <Log></Log>
            </Card>
          </div>
        ) : (
          ""
        )}
        <Modal
          title="型钢查询"
          centered
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
          footer={false}
          width={1000}
        >
          <Steel
            ref={(steel) => {
              this.steel = steel;
            }}
          ></Steel>
        </Modal>
      </div>
    );
  }
}
