import React, { Component } from "react";
import { Table, Button, Modal, Input, Select, message, Popconfirm } from "antd";
// import { format } from "../../../utils/dateFormate";
import Request from "../../../utils/client";
import {
  // MAINTENANCE_ORDER_CONFIRM,
  MAINTENANCE_ORDER_DELETE,
} from "../../../graphql/admin/maintenance";
// import AddOrder from "./components/AddOrder";
import root from "../../../config/root";
import util from "../../../utils/util";

import PubSub from "pubsub-js";

import axiosRequest from "../../../utils/request";
import { demandRequestPath } from "../../../request/requestPath";
import { maintenanceRequestPath } from "../../../request/requestPath";

import { connect } from "react-redux";
import { addDemandAction } from "../../../redux/actions/demand";
import { resetAccessoriesAction } from "../../../redux/actions/accessories";
import { resetSteelAction } from "../../../redux/actions/steel";
import { resetEquipmentAction } from "../../../redux/actions/equipment";

import "./order.scss";
import moment from "moment";
const { Option } = Select;
const { TextArea } = Input;
function onChange(pagination, filters, sorter, extra) {
  console.log("params", pagination, filters, sorter, extra);
}
let columns = [
  {
    title: "序号",
    dataIndex: "morderId",
    fixed: "left",
    width: 100,
  },
  {
    title: "报废单号",
    dataIndex: "morderNo",
    width: 180,
  },
  {
    title: "仓库名称",
    dataIndex: "repositoryName",
    width: 150,
  },
  {
    title: "型钢清单",
    dataIndex: "steel",
    width: 200,
  },
  {
    title: "设备清单",
    dataIndex: "equipment",
    width: 200,
  },
  {
    title: "配件清单",
    dataIndex: "part",
    width: 200,
  },
  {
    title: "仓库管理员",
    dataIndex: "repositoryAdmin",
    width: 150,
  },
  {
    title: "公司管理员",
    dataIndex: "companyAdmin",
    width: 150,
  },
];
class Admin extends Component {
  userInfo = localStorage.getItem("userInfo");
  role = JSON.parse(this.userInfo).role;
  pathname = window.location.pathname;
  constructor(props) {
    super(props);
    let newColumns = [];

    this.state = {
      role: this.role,
      modalVisible: false, // 添加需求单的弹窗
      visible: false, // 拒绝的弹窗
      data: [], // 表格数据源
      demandData: [], // 存储获取的数据，用于用户搜索时
      title: "添加需求单",
      pathname: this.pathname,
      columns: [...columns, ...newColumns],
      searchText: "",
      searchedColumn: "",
      filteredInfo: null,
      searchType: "order",
      loading: true, // 表格的loading
      refusedOrderId: "", // 拒绝的需求单id值
      demandType: "1", // 类型  toBeConfirm:待确认  confirmOrder:已确认
      materialVisible: false, // 控制材料列表弹窗的变量
      steelData: [], // 型钢列表
      partData: [], // 配件列表
      equipmentData: [], // 设备列表
      id: "", // 审核的维修单id
      isAccess: true, // 同意还是拒绝维修单的标识
      statusObj: {
        100: "已报废",
        200: "待确认",
        300: "已确认",
        400: "已拒绝",
        500: "已发货",
        700: "部分收货",
        800: "收货",
      },
      popVisible: false,
      confirmLoading: false,
    };
  }

  componentDidMount() {
    this.aquireData();

    PubSub.unsubscribe("aquireDemand"); //销毁订阅 防止多次触发
    // 添加需求单成功后重新获取需求单（由AddDemand组件触发）
    PubSub.subscribe("aquireDemand", (_) => {
      this.aquireData();
    }); //订阅
  }
  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  };

  // 显示气泡框
  showPopconfirm = () => {
    this.setState({
      popVisible: true,
    });
  };

  // 通过报废单
  handleOk = (item) => {
    console.log("点击了通过");
    console.log(item, item.morderId);
    this.confirmScrapMorder({ state: 100, mOrderId: item.morderId });
  };

  // 取消报废单
  handleCancel = (item) => {
    console.log("点击了拒绝");
    this.confirmScrapMorder({ state: 400, mOrderId: item.morderId });
  };

  // 报废单审核接口
  confirmScrapMorder = (data) => {
    axiosRequest
      .post(maintenanceRequestPath.ConfirmScrapMorder, data)
      .then((res) => {
        if (res.code === 200) {
          let msg =
            data.state === 300 ? "执行通过操作成功" : "执行拒绝操作成功";
          message.success(msg);
          this.setState({
            popVisible: false,
          });
          this.aquireData();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        message.error(error);
      });
  };

  // 获取需求单数据
  aquireData = () => {
    this.setState({ loading: true });
    console.log(this.state.demandType);

    if (this.state.demandType === "1") {
      let newColumns = {
        title: "操作",
        key: "operation",
        width: 200,
        fixed: "right",
        align: "center",
        render: (item) => (
          <div>
            {root[this.role][this.pathname].distribution ? (
              <Popconfirm
                title="审核意见"
                visible={this.state.popVisible}
                onConfirm={() => {
                  this.handleOk(item);
                }}
                okButtonProps={{ loading: this.state.confirmLoading }}
                onCancel={() => {
                  this.handleCancel(item);
                }}
                cancelText="拒绝"
                okText="通过"
              >
                <Button
                  type="primary"
                  onClick={this.showPopconfirm}
                  disabled={
                    item.state === "100" || item.state === "400" ? true : false
                  }
                >
                  审核
                </Button>
              </Popconfirm>
            ) : (
              ""
            )}
          </div>
        ),
      };
      this.setState({
        columns: [...columns, newColumns],
      });
    } else {
      this.setState({
        columns: [...columns],
      });
    }

    axiosRequest
      .post(maintenanceRequestPath.GetMaintenanceOrderList)
      .then((res) => {
        let data = res.data;
        if (res.code === 200) {
          if (!data) {
            this.setState({
              data: [],
              demandData: [],
              loading: false,
            });
            return;
          }
          let list = [];
          data.forEach((item) => {
            if (this.state.demandType === item.isXp + "") {
              item.key = item.morderNo;
              item.status = this.state.statusObj[item.state];
              item.equipment = "";
              item.equipments.forEach((child, index) => {
                if (index === 0) {
                  item.equipment = `${child.equipmentName}(数量：${child.total})`;
                } else {
                  item.equipment = `${item.equipment},${child.equipmentName}(数量：${child.total})`;
                }
              });
              item.part = "";
              item.parts.forEach((child, index) => {
                if (index === 0) {
                  item.part = `${child.partName}(数量：${child.partTotal})`;
                } else {
                  item.part = `${item.part},${child.partName}`;
                }
              });
              item.maintenanceDate = moment(item.maintenanceTime).format(
                "YYYY-MM-DD"
              );
              item.expectTime = moment(item.expectedReturnAt).format(
                "YYYY-MM-DD"
              );
              item.outTime = moment(item.outRepositoryAt).format("YYYY-MM-DD");

              item.steel = "";
              item.steels.forEach((child, index) => {
                if (index === 0) {
                  item.steel = `${child.steelSpecification}(重量：${child.steelWeight})`;
                } else {
                  item.steel = `${item.steel},${child.steelSpecification}`;
                }
              });
              list.push(item);
            }
          });
          console.log(list);
          this.setState({
            data: list,
            demandData: list,
            loading: false,
          });
        } else {
          message.error("获取维修单错误");
        }
      })
      .catch((erorr) => {});
  };
  // 删除维修单
  deleteItem = (item) => {
    Request.client
      .mutate({
        mutation: MAINTENANCE_ORDER_DELETE, // 封装好的GraphQL,
        variables: {
          input: {
            id: item.id,
          },
        },
      })
      .then((result) => {
        message.success("删除成功");
        this.aquireData();
      })
      .catch((error) => {
        message.error(error.message);
        console.log(error);
      });
  };
  // 设置弹窗的显示和关闭
  setModalVisible(modalVisible) {
    this.setState({ modalVisible, title: "添加需求单" }, () => {
      if (modalVisible) {
        this.demand.handlerResetForm();
        this.demand.getProject();
        // this.demand.getReposity();
        this.demand.getSize();
        this.demand.getAccessories();
        this.demand.getEquipment();
      }
      // this.demand.getCompanyUser();
    });
  }
  // 点击弹窗的确定按钮
  submit = () => {
    if (this.state.title === "添加需求单") {
      this.demand.addDemand();
    } else {
      this.demand.modifyProjectSubmit();
    }
  };
  // 关闭弹窗，由demand组件成功添加项目后触发
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  // 修改需求单
  modify = (project) => {
    this.setState({ modalVisible: true, title: "修改需求单" }, () => {
      this.demand.handlerResetForm();
      this.demand.getProject();
      this.demand.getReposity();
      this.demand.getSize();
      this.demand.modifyProject(project);
    });
  };

  // 搜索类型改变时
  handleChange = (e) => {
    this.setState({ searchType: e });
  };
  // 类型改变时（维修单列表，报废单列表）
  demandTypeChange = (e) => {
    this.setState(
      {
        demandType: e,
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 搜索的方法
  searchFunc = () => {
    let { demandData, searchType } = this.state;
    let keyword = this.keywordInput.state.value;
    if (keyword === undefined) {
      message.error("请输入关键字搜索");
      return;
    }
    let result = [];
    if (searchType === "order") {
      result = demandData.filter((obj) => {
        return obj.orderNo.indexOf(keyword) !== -1;
      });
    } else if (searchType === "name") {
      result = demandData.filter((obj) => {
        return obj.projectName.indexOf(keyword) !== -1;
      });
    } else if (searchType === "admin") {
      result = demandData.filter((obj) => {
        return obj.create.indexOf(keyword) !== -1;
      });
    } else if (searchType === "repository") {
      result = demandData.filter((obj) => {
        return obj.repositoryName.indexOf(keyword) !== -1;
      });
    } else {
      result = demandData.filter((obj) => {
        return obj.status.indexOf(keyword) !== -1;
      });
    }
    this.setState({ data: result });
  };
  // 重置
  resetFunc = () => {
    this.keywordInput.state.value = "";
    this.setState(
      {
        searchType: "order",
      },
      () => {
        this.aquireData();
      }
    );
  };
  // 审核需求单（拒绝按钮触发的事件）
  refused = (item) => {
    this.setState({ refusedOrderId: item.id, visible: true });
  };
  // 输入拒绝理由后提交触发的事件
  refusedSubmit = () => {
    let reason = this.textarea.resizableTextArea.textArea.value;
    if (util.isNull(reason)) {
      message.error("请输入拒绝理由");
    } else {
      this.refusedRequst(reason);
    }
  };
  // 拒绝需求单的请求
  refusedRequst = (reason) => {
    axiosRequest
      .post(demandRequestPath.RefuseOrder, {
        id: this.state.refusedOrderId,
        isAccess: false,
        reason,
      })
      .then((res) => {
        if (res.code === 200) {
          message.success("操作成功");
          this.hideModal();
          this.aquireData();
        } else {
          message.error(res.message);
        }
        // let { data } = res;
      })
      .catch((error) => {
        console.log("审核需求单", error);
      });
  };
  // 隐藏拒绝需求单的弹窗
  hideModal = () => {
    this.setState({ visible: false });
  };
  // 点击审核按钮
  audit = (item) => {
    this.aquireMaintenance();
    this.setState({
      visible: true,
      id: item.morderId,
      morderNo: item.morderNo,
    });
  };
  // 审核提交
  submitAudit = () => {
    if (
      !this.state.isAccess &&
      util.isNull(this.textarea.resizableTextArea.textArea.value)
    ) {
      message.error("请输入拒绝理由");
      return;
    }
    axiosRequest
      .post(maintenanceRequestPath.ConfirmOrRefuseMorder, {
        id: this.state.id,
        maintenanceId: this.state.isAccess ? this.state.mainSelect * 1 : 0,
        isAccess: this.state.isAccess,
        reason: this.state.isAccess
          ? ""
          : this.textarea.resizableTextArea.textArea.value,
      })
      .then((result) => {
        if (result.code === 200) {
          this.state.isAccess
            ? message.success("维修单确认成功")
            : message.success("拒绝维修单成功");
          this.setState({
            visible: false,
          });
          this.aquireData();
        } else {
          this.state.isAccess
            ? message.error("维修单确认失败")
            : message.error("拒绝维修单失败");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // Request.client
    //   .query({
    //     query: MAINTENANCE_ORDER_CONFIRM,
    //     fetchPolicy: "no-cache",
    //     variables: {
    //       input: {
    //         id: this.state.id,
    //         maintenanceId: this.state.isAccess ? this.state.mainSelect : "",
    //         isAccess: this.state.isAccess,
    //         reason: this.state.isAccess
    //           ? ""
    //           : this.textarea.resizableTextArea.textArea.value,
    //       },
    //     },
    //   })
    //   .then((result) => {
    //     this.state.isAccess
    //       ? message.success("维修单确认成功")
    //       : message.success("拒绝维修单成功");
    //     this.setState({
    //       visible: false,
    //     });
    //     this.aquireData();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  // 取消审核
  canelAudit = () => {
    this.setState({
      visible: false,
    });
  };

  // 点击详情按钮
  orderInfo = (item) => {
    this.props.history.push({
      pathname: `/admin/orderDetail/${item.morderId}`,
    });
  };

  // 获取维修厂列表
  aquireMaintenance = () => {
    axiosRequest
      .post(maintenanceRequestPath.GetMaintenanceList)
      .then((res) => {
        let children = [];
        res.data.forEach((item) => {
          children.push(
            <Option key={item.maintenanceId}>{item.maintenanceName}</Option>
          );
        });
        this.setState({
          mainSelect: res.data.length > 0 ? res.data[0].maintenanceId + "" : "",
          mainChildren: children,
        });
      })
      .catch((error) => {
        console.log("获取维修厂报错");
      });
  };

  // 选择维修厂时的事件
  mainHandleChange = (e) => {
    this.setState({
      mainSelect: e,
    });
  };

  isAccessChange = (e) => {
    this.setState({
      isAccess: e,
    });
  };

  render() {
    let {
      columns,
      data,
      loading,
      mainChildren,
      mainSelect,
      morderNo,
      isAccess,
    } = this.state;
    return (
      <div className="order_box">
        <div className="btn_box">
          {/* <div>
            <Button
              type="primary"
              onClick={() => this.setModalVisible(true)}
              disabled={root[role][pathname].add ? false : true}
            >
              添加
            </Button>
          </div> */}
          <div>
            <span style={{ fontSize: 20, fontWeight: "bold" }}>报废单列表</span>
            {/* <Select
              value={this.state.demandType}
              style={{ width: 120 }}
              onChange={this.demandTypeChange}
            >
              <Option value="0">维修单列表</Option>
              <Option value="1">报废单列表</Option>
            </Select> */}
          </div>
        </div>
        <div className="table_box">
          <Table
            onChange={onChange}
            columns={columns}
            dataSource={data}
            bordered
            scroll={{ x: 600 }}
            pagination={false}
            loading={loading}
          />
        </div>
        {/* <Modal
          title={title}
          centered
          visible={this.state.modalVisible}
          onOk={() => this.submit()}
          onCancel={() => this.setModalVisible(false)}
          width="80%"
        >
          <AddOrder
            ref={(demand) => {
              this.demand = demand;
            }}
            closeModal={this.closeModal}
          ></AddOrder>
        </Modal> */}
        <Modal
          title="审核"
          visible={this.state.visible}
          onOk={this.submitAudit}
          onCancel={this.canelAudit}
          okText="确认"
          cancelText="取消"
        >
          <div style={{ marginBottom: 20 }}>
            <span>维修单编号：{morderNo}</span>
          </div>

          <div>
            <span>审核意见：</span>
            <Select
              value={isAccess}
              style={{ width: 200 }}
              onChange={this.isAccessChange}
              placeholder="选择维修厂"
            >
              <option value={true}>同意</option>
              <option value={false}>拒绝</option>
            </Select>
          </div>

          {isAccess ? (
            <div style={{ marginTop: 20 }}>
              <span>指定维修厂：</span>
              <Select
                value={mainSelect}
                style={{ width: 200 }}
                onChange={this.mainHandleChange}
                placeholder="选择维修厂"
              >
                {mainChildren}
              </Select>
            </div>
          ) : (
            ""
          )}
          {isAccess ? (
            ""
          ) : (
            <div style={{ marginTop: 20 }}>
              <TextArea
                showCount
                maxLength={150}
                rows={4}
                resize="false"
                ref={(textarea) => (this.textarea = textarea)}
                placeholder="请输入拒绝理由"
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
export default connect((state) => ({ demand: state.demand }), {
  addDemandAction,
  resetAccessoriesAction,
  resetSteelAction,
  resetEquipmentAction,
})(Admin);
