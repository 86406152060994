import React, { Component } from "react";
import { Select, Button, message } from "antd";
import {
  MANUFACTURERS,
  MATERIAL,
  STATELIST,
  GETSPECIFICATION,
  // REPOSITORY,
} from "../../../../graphql/admin/index";

import Request from "../../../../utils/client";
import axiosRequest from "../../../../utils/request";
import { indexRequestPath } from "../../../../request/requestPath";

import "./search.scss";

const { Option } = Select;

export default class Search extends Component {
  state = {
    projectChild: [],
    projectSelect: "all",
    manChildren: [],
    manSelect: "all",
    matChildren: [],
    matSelect: "all",
    specChildren: [],
    specSelect: "all",
    stateChildren: [],
    stateSelect: "all",
    repositoryChild: [],
    repositorySelect: "all",
    proExp: "",
    proName: "",
    code: "",
    identifier: "",
    produceAt: "", // 生产时间
    enterRepositoryAt: "", // 入库时间
    selectDate: null, // 选择的生产日期，moment格式的
    selectORA: null, // 选择的入库日期，moment格式的
  };
  componentDidMount() {
    this.aquireMan();
    this.aquireMat();
    this.aquireSpec();
    this.aquireResitory();
    this.aquireState();
  }

  // 处理获取的项目数据
  projectDeal(projectData) {
    let children = [];
    children.push(<Option key="all">项目（全部）</Option>);
    // eslint-disable-next-line no-unused-expressions
    projectData
      ? projectData.forEach((item) => {
          children.push(
            <Option key={item.projectId}>{item.projectName}</Option>
          );
        })
      : "";
    this.setState({ projectChild: children });
  }

  // 获取制造商
  aquireMan = () => {
    Request.client
      .query({
        query: MANUFACTURERS,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("制造商", result);
        let { getManufacturers } = result.data;
        let children = [];
        children.push(<Option key="all">制造商（全部）</Option>);
        getManufacturers.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          manChildren: children,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取材料商
  aquireMat = () => {
    Request.client
      .query({
        query: MATERIAL,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("材料商", result);
        let { getMaterialManufacturers } = result.data;
        let children = [];
        children.push(<Option key="all">材料商（全部）</Option>);
        getMaterialManufacturers.forEach((item) => {
          children.push(<Option key={item.id}>{item.name}</Option>);
        });
        this.setState({
          matChildren: children,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取状态
  aquireState = () => {
    Request.client
      .query({
        query: STATELIST,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("状态", result);
        let { getAllStateList } = result.data;
        let children = [];
        children.push(<Option key="all">状态（全部）</Option>);
        getAllStateList.forEach((item) => {
          children.push(<Option key={item.state}>{item.desc}</Option>);
        });
        this.setState({
          stateChildren: children,
          // defaultValue: getAllStateList[0] ? getAllStateList[0] : {},
          // select: getAllStateList[0] ? getAllStateList[0].flag : "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取规格尺寸
  aquireSpec = () => {
    Request.client
      .query({
        query: GETSPECIFICATION,
        fetchPolicy: "no-cache",
      })
      .then((result) => {
        // console.log("规格", result);
        let { getSpecification } = result.data;
        let children = [];
        children.push(<Option key="all">规格（全部）</Option>);
        getSpecification.forEach((item) => {
          children.push(<Option key={item.id}>{item.specification}</Option>);
        });
        this.setState({
          specChildren: children,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 获取仓库
  aquireResitory() {
    axiosRequest
      .post(indexRequestPath.GetRepository)
      .then((res) => {
        if (res.code === 200) {
          let data = res.data;
          let children = [];
          children.push(<Option key="all">仓库（全部）</Option>);
          data.forEach((item) => {
            item.id = item.repositoryId;
            item.name = item.repositoryName;
            children.push(<Option key={item.id}>{item.name}</Option>);
          });
          this.setState({
            repositoryChild: children,
          });
        } else {
          message.error(res.code);
        }
      })
      .catch((error) => {
        console.log("获取仓库失败", error);
      });
    // Request.client
    //   .query({
    //     query: REPOSITORY,
    //     variables: {
    //       input: {},
    //     },
    //     fetchPolicy: "no-cache",
    //   })
    //   .then((result) => {
    //     // console.log("仓库列表", result);
    //     let { getRepositoryListForDashboard } = result.data;
    //     let children = [];
    //     children.push(<Option key="all">仓库（全部）</Option>);
    //     getRepositoryListForDashboard.forEach((item) => {
    //       children.push(<Option key={item.id}>{item.name}</Option>);
    //     });
    //     this.setState({
    //       repositoryChild: children,
    //     });
    //     // this.setState({
    //     //   data1,
    //     //   data2,
    //     //   data3,
    //     // });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }
  manHandleChange = (e) => {
    this.setState({
      manSelect: e,
    });
  };
  matHandleChange = (e) => {
    this.setState({
      matSelect: e,
    });
  };
  specHandleChange = (e) => {
    this.setState({
      specSelect: e,
    });
  };
  stateHandleChange = (e) => {
    this.setState({
      stateSelect: e,
    });
  };
  repositoryHandleChange = (e) => {
    this.setState({
      repositorySelect: e,
    });
  };
  projectHandleChange = (e) => {
    this.setState({
      projectSelect: e,
    });
  };
  // 重置
  resetFunc = () => {
    this.codeInput.state.value = "";
    this.identifierInput.state.value = "";
    this.setState({
      manSelect: "all",
      matSelect: "all",
      specSelect: "all",
      stateSelect: "all",
      repositorySelect: "all",
      proExp: "",
      proName: "",
      code: "",
      identifier: "",
      produceAt: "",
      enterRepositoryAt: "",
      selectDate: null,
      selectORA: null,
    });
  };
  // 搜索
  sumitSearch = () => {
    this.props.searchFunc({
      materialId: this.state.manSelect === "all" ? 0 : this.state.manSelect * 1, // 材料商
      manufacturerId:
        this.state.matSelect === "all" ? 0 : this.state.matSelect * 1, // 制造商
      specificationId:
        this.state.specSelect === "all" ? 0 : this.state.specSelect * 1, // 规格
      state: this.state.stateSelect === "all" ? 0 : this.state.stateSelect * 1, // 状态
      repositoryId:
        this.state.repositorySelect === "all"
          ? 0
          : this.state.repositorySelect * 1, // 仓库
      projectId:
        this.state.projectSelect === "all" ? 0 : this.state.projectSelect * 1, // 项目
    });
  };

  render() {
    let {
      manChildren,
      manSelect,
      matChildren,
      matSelect,
      specChildren,
      specSelect,
      stateChildren,
      stateSelect,
      repositorySelect,
      repositoryChild,
      projectSelect,
      projectChild,
      // code,
      // identifier,
      // selectDate,
      // selectORA,
    } = this.state;
    return (
      <div className="search_box">
        <div className="form_box">
          <div className="search_item_box">
            <Select
              className="seacrch_item"
              value={specSelect}
              style={{ width: "95%", marginBottom: 12.5 }}
              onChange={this.specHandleChange}
            >
              {specChildren}
            </Select>
          </div>
          <div className="search_item_box">
            <Select
              className="seacrch_item"
              value={repositorySelect}
              style={{ width: "95%", marginBottom: 12.5 }}
              onChange={this.repositoryHandleChange}
            >
              {repositoryChild}
            </Select>
          </div>
          <div className="search_item_box">
            <Select
              className="seacrch_item"
              value={stateSelect}
              style={{ width: "95%", marginBottom: 12.5 }}
              onChange={this.stateHandleChange}
            >
              {stateChildren}
            </Select>
          </div>
          <div className="search_item_box">
            <Select
              className="seacrch_item"
              value={projectSelect}
              style={{ width: "95%", marginBottom: 12.5 }}
              onChange={this.projectHandleChange}
            >
              {projectChild}
            </Select>
          </div>
          <div className="search_item_box">
            <Select
              className="seacrch_item"
              value={matSelect}
              style={{ width: "95%", marginBottom: 12.5 }}
              onChange={this.matHandleChange}
            >
              {matChildren}
            </Select>
          </div>
          <div className="search_item_box">
            <Select
              className="seacrch_item"
              value={manSelect}
              style={{ width: "95%", marginBottom: 12.5 }}
              onChange={this.manHandleChange}
            >
              {manChildren}
            </Select>
          </div>
        </div>
        <div>
          <Button type="primary" onClick={this.sumitSearch}>
            查询
          </Button>
          <Button style={{ marginLeft: "20px" }} onClick={this.resetFunc}>
            重置
          </Button>
        </div>
      </div>
    );
  }
}
